import axios from 'axios'
import constants from "./constants";
import router from "../router";
import store from "../store";
import { SET_DATA } from "../store/storecontants";
import { pii } from "../js/pii";
import {PMTARNGMNT_001, PMTARNGMNT_002,PMTARNGMNT_003, PMTARNGMNT_004, PMTARNGMNT_005, PMTARNGMNT_006, PMTARNGMNT_008, PMTARNGMNT_009, PMTARNGMNT_010, PMTARNGMNT_011,
  PHONE_INVALID_ERROR_CODE, EMAIL_INVALID_ERROR_CODE, ZIP_INVALID_ERROR_CODE, SYSYTEM_DOWN, DEFAULT_ERROR_PA, ACCOUNT_MAINTENANCE, NO_AMOUNT_DUE, PA_PMTARNGMNT_008_ERROR_MESSAGE, REG_CRIS, ACCOUNT_INVALID } from "../utils/ErrorCodes"

const getAccount = async function (accountNumber, zipCode) {
  let attributesData = new Map();
  attributesData.set('showLoading', true);
  store.dispatch(SET_DATA, {attributes:attributesData});
  try {
    // mandatory params
    const params = {
      accountNumber: accountNumber,
      zipCode: zipCode,
    };
    // axios call with params
    const res = await axios.get(constants.PaymentArrangementIdentifyURL, { params });
    if (res.data) {
      attributesData.set("emailAddress", JSON.stringify(res.data.emailAddressOfRecord))
      attributesData.set("firstName", JSON.stringify(res.data.acodFirstName))
      attributesData.set("lastName", JSON.stringify(res.data.acodLastName))
      attributesData.set("AcodAccountNumber", JSON.stringify(res.data.ban))
      store.dispatch(SET_DATA, { attributes: attributesData });
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      company: 'centurylink'
    });
    // error code paymentArrangementIneligibleErrorCodes call gtm
    if (res.data.paymentArrangementIneligibleErrorCodes != null) {
      try {
        gtmErrorLogging(res.data, accountNumber);
        routeToIdentify();
      } catch (e) {
        console.log('gtm error');
      }
    }
    attributesData.set('autopay', res.data.autoPay);
    store.dispatch(SET_DATA, {attributes:attributesData});
    // if there is no error then continue
    if (accountHasErrors(res, accountNumber) == false) { 
      const multi_Accounts = JSON.stringify(res.data.multipleAccounts)
		  const zipCode_multiAccount= JSON.stringify(zipCode)
      // multi-account check
      if (res.data.multiAccount == true) {
        let attributesData = new Map();
        attributesData.set('selectedAccountNumber', JSON.stringify(accountNumber));
        attributesData.set('zipcode', zipCode_multiAccount);
        attributesData.set('multiData', multi_Accounts);
        attributesData.set('status', JSON.stringify(res.data.multiAccount));
        attributesData.set('confirmationEmailAddress', res.data.confirmationEmailAddress);
        store.dispatch(SET_DATA, {attributes:attributesData});
        router.push({
        name: 'PAMultiAccount', 
          params: {
            multiData: multi_Accounts,zipcode: zipCode_multiAccount,status :JSON.stringify(res.data.multiAccount), data: JSON.stringify(res.data)
          }
        });
      } else if (res.data.paymentArrangementEligible == true  && res.data.biller == 1) {
        // check for paymentArrangementEligible
        let attributesData = new Map();
        attributesData.set('accountEntered', JSON.stringify(accountNumber));
        attributesData.set('zipEntered', JSON.stringify(zipCode));
        attributesData.set('data', JSON.stringify(res.data));
        attributesData.set('isMultiAccount', false);
        store.dispatch(SET_DATA, {attributes:attributesData});
        router.push({
          name: "PaymentArrngmtsOptions",
          params: {},
        });
      } else if (res.data.paymentArrangementEligible == true && res.data.biller == 2) {
        // check for paymentArrangementEligible
        let attributesData = new Map();
        attributesData.set('accountEntered', JSON.stringify(accountNumber));
        attributesData.set('data', JSON.stringify(res.data));
        attributesData.set('isMultiAccount', false);
        store.dispatch(SET_DATA, {attributes:attributesData});
        router.push({
          name: "PAConfirm",
          params: {},
        });
      } else if (
        res.data.paymentArrangementIneligibleErrorCodes != null &&
        (res.data.paymentArrangementIneligibleErrorCodes.includes("11002") 
        || res.data.paymentArrangementIneligibleErrorCodes.includes("11008")
        || res.data.paymentArrangementIneligibleErrorCodes.includes("10017")
        || res.data.paymentArrangementIneligibleErrorCodes.includes("11005"))
      ) {
        // check for paymentArrangementIneligibleErrorCodes
        let attributesData = new Map();
        attributesData.set('paymentArrangementIneligibleErrorCodes', JSON.stringify(res.data.paymentArrangementIneligibleErrorCodes));
        attributesData.set('enteredAccountNumber', accountNumber);
        attributesData.set('account', accountNumber)
        attributesData.set('enteredZipCode', zipCode);
        attributesData.set('status', JSON.stringify(res.data));
        attributesData.set('isMultiAccount', false);
        attributesData.set('noAmtDue', false);
        attributesData.set('zipcode', zipCode_multiAccount);
        // if the due amount cross 1000 $ then we will get errorcode 10017
        if (res.data.paymentArrangementIneligibleErrorCodes.includes("10017")) {
          let thresholdErrorMessage = res.data.errorMessage
          let thresholdValAmt = thresholdErrorMessage.substring(
            thresholdErrorMessage.indexOf("=") + 1, 
            thresholdErrorMessage.lastIndexOf("|")
          );
          attributesData.set('thresholdVal', thresholdValAmt);
          attributesData.set('adjustedAmount', res.data.adjustedAmount);
        }
        attributesData.set('multiData', multi_Accounts);  
        store.dispatch(SET_DATA, {attributes:attributesData});
        router.push({
          name: "PaymentArrngmtsIneligibile",
          params: {},
        });
      } else if ((res.data.paymentArrangementEligible == false && res.data.status == 0 && res.data.adjustedAmount == "0.00") 
        || res.data?.account?.errorInfo?.errorMessage == "AdjustedBalance2"
        || res.data.paymentArrangementIneligibleErrorCodes != null
      ) {
        let attributesData = new Map();
        attributesData.set('paymentArrangementIneligibleErrorCodes', JSON.stringify(res.data.paymentArrangementIneligibleErrorCodes));
        attributesData.set('isMultiAccount', false);
        attributesData.set('noAmtDue', true);
        store.dispatch(SET_DATA, { attributes: attributesData });
        router.push({
          name: "PaymentArrngmtsIneligibile",
          params: {},
        });
      }
    } else if (accountHasErrors(res, accountNumber) == true) {
      routeToIdentify();
    }
    attributesData.set('showLoading', false);
    store.dispatch(SET_DATA, {attributes:attributesData});
  } catch (error) {
    // api fails
		window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
			event: 'pa_error',
			accountNumber: pii(accountNumber),
			errorName: 'account not valid',
			flow:{
				name: 'pa',
				step: 'start'
			},
		}); 
    routeToIdentify();
  }
}

// gtm for paymentArrangementIneligibleErrorCodes
function gtmErrorLogging(a, accountNumber) {
  if (a.paymentArrangementIneligibleErrorCodes.includes("11001")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'invalid account status',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
  } else if (a.paymentArrangementIneligibleErrorCodes.includes("11002")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'pa pending',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    }); 
  } else if (a.paymentArrangementIneligibleErrorCodes.includes("11003")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'suspend limit',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
  } else if (a.paymentArrangementIneligibleErrorCodes.includes("11004")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'returned payment',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
  } else if (a.paymentArrangementIneligibleErrorCodes.includes("11005")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'failed payment',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
  } else if (a.paymentArrangementIneligibleErrorCodes.includes("11006")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'canceled payment',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
  } else if (a.paymentArrangementIneligibleErrorCodes.includes("10017")) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'Threshold message',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
  } else {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'undefined error',
      flow:{
        name: 'pa',
        step: 'pa flow'
      },
    });
  }
}

// if there is error code call this function to print proper error
function accountHasErrors(res, accountNumber) {
  if (res.data.errorCode == PMTARNGMNT_001) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'prepaid',
      flow:{
        name: 'pa',
        step: 'eligibility'
      }
    });
    return true;
  } else if (res.data.errorCode == REG_CRIS) {
    return true;
  } else if (res.data.errorCode == PMTARNGMNT_002) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'Suspended Account',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return false;
  } else if (res.data.errorCode == PMTARNGMNT_003) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'CCV Response Null',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    routeToIdentify();
    return true;
  } else if (res.data.errorCode == PMTARNGMNT_004) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'Autopay',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return false;
  } else if (res.data.errorCode == PMTARNGMNT_005) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'DVAR Verification Failure',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return true;
  } else if (res.data.errorCode == PMTARNGMNT_006) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'DVAR Verification Failure',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return true;
  } else if (res.data.errorCode == PMTARNGMNT_005) {
    if (res?.data?.paymentArrangementIneligibleErrorCodes != null && res?.data?.paymentArrangementIneligibleErrorCodes.includes("10015")) {
      return false;
    } else {
      return true;
    }
  } else if (res.data.errorCode == PMTARNGMNT_008) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'Account not recognized - DVAR',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return true;
  } else if (res.data.errorCode == PMTARNGMNT_009) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'Embargo',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return true;
  } else if (res.data.errorCode == PMTARNGMNT_010) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'zero balance due',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return true;
  } else if (res.data.errorCode == PMTARNGMNT_011) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'Account is Closed',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return true;
  } else if (res.data.errorCode == ZIP_INVALID_ERROR_CODE) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'invalid_zip',
      flow:{
        name: 'pa',
        step: 'validation'
      },
    });
    return true;
  } else if (res.data.errorCode == PHONE_INVALID_ERROR_CODE) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'invalid_tn',
      flow:{
        name: 'pa',
        step: 'validation'
      },
    });
    return true;
  } else if (res.data.errorCode == EMAIL_INVALID_ERROR_CODE) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'invalid_email',
      flow:{
        name: 'pa',
        step: 'validation'
      },
    });
    return true;
  } else if (res.data.errorCode == SYSYTEM_DOWN) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'System Error',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return true;
  } else if (res.data.errorCode == ACCOUNT_INVALID) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_error',
      accountNumber: pii(accountNumber),
      errorName: 'Account not recognized',
      flow:{
        name: 'pa',
        step: 'eligibility'
      },
    });
    return true;
  } else if (res.data.errorCode != "" && res.data.errorCode != null) {
    return true;
  } else {
    return false;
  }
}

function routeToIdentify() {
  console.log(error);
  let attributesData = new Map();
  attributesData.set('showLoading', false);
  store.dispatch(SET_DATA, {attributes:attributesData});
  router.push({
      name: "PaymentArrngmtsIdentify",
      params: {},
  });
}

export default {
  getAccount
}