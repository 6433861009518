<template>
  <div class="pleaseEnterEmailMessage">
    <p style="font-weight: bold; font-size: 18px !important">
      This is a Prepaid account.
    </p>
    <p>
      Prepaid customers are billed automatically each month on a credit or debit
      card of choice, and service is auto-renewed until you choose to cancel.
    </p>
    <p>
      To manage or modify your payment information
      <a
        :href="MyaEntryPointURL"
        class="twoErrorZipSys"
        id="qbpSimplePay_signIn"
        style="text-decoration: none"
        >sign into My CenturyLink</a
      >.
    </p>
  </div>
</template>
<script>
import links from "../../js/links";
import { pii } from "../../js/pii";

export default {
  name: "Prepaid",
  data() {
    return {
      MyaEntryPointURL: links.MyaEntryPointURL,
    };
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        pageType: "qbp",
        pageName: "ctl|account|qbp|accountnumber",
      },
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "qbp_error",
      accountNumber: pii(this.accountNumber),
      errorName: "prepaid",
      flow: {
        name: "qbp",
        step: "payment flow",
      },
    });
  },
};
</script>
<style scoped>
.pleaseEnterEmailMessage {
  mix-blend-mode: multiply;
  font-size: 14px;
  line-height: 1.35;
  padding: 5px 2px 11px 45px;
  background: url(../../images/icon-important-orange.svg) no-repeat 1px 1px;
}
</style>