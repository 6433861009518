<template>
    <section class="py-4">
        <div class="container d-flex justify-content-center mw-box"><manage-wallet-details/></div>
        <div class="container d-flex justify-content-center"><manage-wallet-failure/></div>
    </section>
</template>
<script>
import ManageWalletDetails from '../components/wallet/manageWalletDetails.vue';
import ManageWalletFailure from '../components/wallet/manageWalletFailure.vue';
export default {
  name: 'FailureMW',
  components: {
    ManageWalletDetails,
    ManageWalletFailure
  }
};
</script>
<style scoped>
.mw-box {
  width: 65%;
  height: 100%;
  border-radius: 5px;
}
</style>