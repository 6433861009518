<template>
    <div class="container px-4">
        <div class="row gx-4 justify-content-center">
            <div class="col-lg-9 manage-wallet-content">
                <div id="manage-wallet-content-subsection">
                    <br />
                    <p class="text-start">
                        <strong>Your Payment Accounts</strong>
                    </p>
                    <!-- ERRORS -->
                    <div v-if="HCDE110" id="paymentError">
                      <div class="outside outside2">
                        <div class="inside">
                          <div class="pleaseEnterEmailMessage">
                            <h2>Your session has timed out.</h2>
                            <p>
                              For the security of your account, your session was ended due to
                              inactivity.
                            </p>
                            <p style="margin-bottom: 0">
                              Would you like to
                              <a href="/wallet/manage" id="startoverandtryagain" class="manage-wallet-link">
                                start over and try again</a
                              >?
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="HCDE011" id="paymentError">
                        <div class="outside outside2">
                            <div class="inside">
                                <div class="errorBox">
                                    <h2>Sorry - you've reached the maximum allowable attempts.</h2> <br>
                                    <p>We do this for security purposes.</p>
                                    <p style="margin-bottom:0;">Not to worry though, you'll just need to <a href="/wallet/manage" id="startover" class="manage-wallet-link"> start over</a>.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else id="paymentError">
                        <div class="outside outside2">
                            <div class="inside">
                                <manage-wallet-error />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import constants from "../../js/constants";
import axios from "axios";
import { GET_DATA, SET_DATA } from "../../store/sessioncontants";
import ManageWalletError from '../ErrorMessages/manageWalletError.vue';
import { SET_DATA as local_setdata} from "../../store/storecontants";

export default {
    name: 'ManageWalletFailure',
    components: {
        ManageWalletError
    },
    data() {
        return {
            HCDE110: false,
            HCDE011: false,
            errorCode: "",
            accountNumber: "",
        };
    },
    methods: {
        getWalletFailureDetails() {
            if (this.errorCode != null || this.errorCode != "") {
                if (this.errorCode === "HCDE110") {
                    this.HCDE110 = true;
                    this.HCDE011 = false;
                } else if (this.errorCode === "HCDE011") {
                    this.HCDE110 = false;
                    this.HCDE011 = true;
                } else {
                    this.HCDE110 = false;
                    this.HCDE011 = false;
                }
            }
        },
    },
    mounted() {
        let attributesData = new Map();
        let localData = new Map();
        localData.set('showLoading', true);
        this.$store.dispatch(local_setdata, {attributes:localData});
        let result = this.$route.query.ErrorCode ? this.$route.query.ErrorCode : '';
        this.errorCode = result.includes("-") ? result.replace(/-/g, "") : result;
        this.accountNumber = this.$store.getters[GET_DATA]("accountNumber");
        this.getWalletFailureDetails();

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'mw_failure',
            flow: {
                name: 'manage wallet',
                step: 'failure'
            }
        });

        // log to splunk error
        if(this.accountNumber) {
            let params = {};
            if(this.errorCode) {
                params = {
                    logString:
                    "Manage Wallet failure for Account Number " +
                    this.accountNumber +
                    ", HCDE Error code " +
                    this.errorCode
                };
            } else {
                params = {
                    logString:
                    "Manage Wallet failure for Account Number " +
                    this.accountNumber
                };
            }
            axios.get(constants.PaymentFailureCallForOrchestrator, { params });
        }
        localData.set('showLoading', false);
        this.$store.dispatch(local_setdata, {attributes:localData});
    }
}
</script>
<style scoped>
#manage-wallet-content-subsection {
    text-align: left; 
    font-size:18px;
}
#paymentError {
    margin: 0 auto 20px;
}
.errorBox {
    text-align: left;
}
#paymentError h2 {
    text-align: left;
    margin: 20px 0;
    color: #14161a;
    font-size: 24px;
    font-weight: normal;
}
#paymentError p {
    color: #717274;
    margin-bottom: 15px;
    font-size: 16px;
}
div.pleaseEnterEmailMessage {
    background-position: 4px 23px;
}
.manage-wallet-link {
    color: #0047BB;
  }
</style>