<template>
  <div class="overlay"></div>
  <div id="qbp-left-column" class="col-xs-12 col-sm-12">
    <div id = "qbp-left-column-content">
      <div id="dssp-intro-wrapper" class="text-center">
        <h1>Quick Bill Pay</h1>
      </div>
      <!-- error message start -->
      <div v-if="this.status != null" class="orchestrator-error">
        <p class="text-start mt-2 orchestrator-error-message">{{ this.status }}</p>
        <a  v-if="this.brightSpeed == true" class="bspd-url" target="_blank" :href="BrightspeedQbpURL">Make your payment at Brightspeed</a>
      </div>
      <!-- error message end -->
    </div>   
    <!-- BMD-19937 -->
    <!-- New Multi account screen desing -->
    <!-- multiaccount dropdown start -->
    <div class="mb-3 my-2 ms-0 me-0 justify-content-center">
      <div class="container multiOptionDiv">
        <p class="text-start mx-1 mt-3 ms-2">
          <span class="multiTitle" data-test="messageHead">Which account would you like to see? </span>
        </p>
        <p class="text-start ms-2">
          There are multiple accounts connected to that {{this.selectedMutliAccount}}, Please select an account to make a payment.
        </p>
        <div class="cards" style="overflow-y: scroll;">
          <div class="row mx-2 multiCard" v-for="(accountList, index) in MultiAccount.accountList" :key="index" :value="accountList" v-bind:selected="index===0" :id="'acctNo-' + index" :class="{ 'selected': index===this.isSelected}" @click="getSelectedAcct(accountList.accountNumber, index)">
            <div class="col-lg-9 col-md-8 col-10 p-3 px-4 px-sm-0 px-md-4 px-lg-4 px-xl-3 px-xxl-2 text-start mx-2">
              <span>
                <span>Account: {{accountList.maskedAccountNumber}}</span>
                <br>
                <span>Service Address:<br>{{accountList.address}}.</span>
              </span>
            </div>
          </div>
        </div>
        <div class="row mx-2">	
        <div class="col-12 col-lg-6 col-md-6 col-sm-6 gap-2" id="multi-account-next-button-wrapper">
          <button type="button" id="multi-account-next-button" class="btn btn-primary text-center" :class="{ 'disabled': this.isSelected===null}" @click="submitForm">Continue</button>
          <button type="button" id="multi-account-cancel-button" class="btn btn-primary text-center" @click="cancelRedirect">Cancel</button>
        </div>
      </div>
      </div>
    </div>
    <!-- multiaccount dropdown end -->
  </div>
</template>
          
<script>
import axios from 'axios'
import { ref } from "vue";
import store from '../../store'
import constants from '../../js/constants'
import { GET_DATA, SET_DATA } from "../../store/storecontants";
import { pii } from "../../js/pii";
import links from "../../js/links";
export default {
    name : 'QBP_Multi Account',
    data() {
        return{
            MultiAccount:[],
            selected:'',
            isSelected: null,
            selectedAccount:null, 
            selectedZipCode:'',
            isMultiAccount:'',
            status: null,
            select: false ,
            onselect: true,
            isDay1PlusToggleOn: false,
            BrightspeedQbpURL: links.BrightspeedQbpURL,
            onChange(e){
                this.selectedAccount = e.target.options[e.target.options.selectedIndex].text
                if(this.selectedAccount!=null){
                  this.select= true
                  this.onselect = true                  
                }
                else{
                  this.onselect = false
                }                
            }
        }            
    },

  methods : {
  // focus on error message
  statusError () {
    setTimeout(() => { 
      let errorDiv = this.$refs.pleaseSelectAddress
      if(errorDiv) {
        this.$refs.pleaseSelectAddress.focus();
      }
    }, 300) 
  },
  getSelectedAcct(account, index){
    this.isSelected = index
    this.selectedAccount = account;
  },
  cancelRedirect(){
      this.$router.push({
        name: "Identify"
      });
  },
  // call the api and navigate to diffrent page based on condition
  async submitForm(){
    let attributesData = new Map();
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    if(this.selectedAccount !=null){
      try {
				const params = { 
          accountNumber: this.selectedAccount, 
          zipCode: this.selectedZipCode, 
          matchActual:true 
        };
        // api call with params
				const response =  await axios.post(constants.QBPIdentifyURL,params );
        // acod flow set the required attribute and encrypted account number for success and cancel
        if(response.data.account) {
          attributesData.set("emailAddress", JSON.stringify(response.data.account.emailAddressOfRecord))
          attributesData.set("firstName", JSON.stringify(response.data.account.acodFirstName))
          attributesData.set("lastName", JSON.stringify(response.data.account.acodLastName))
          attributesData.set("AcodAccountNumber", JSON.stringify(this.selectedAccount))
          this.$store.dispatch(SET_DATA, { attributes: attributesData });
        }
        this.selectedMutliAccount= JSON.parse(
            this.$store.getters[GET_DATA]("selectedAccountNumber")
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          page:{
            event: 'qbp_secondary_verification',
            accountNumber: pii(this.selectedMutliAccount),
            searchZip: pii(this.selectedZipCode),	
            flow: {
              name: 'qbp',
              step: 'secondary verification'
            },
          }
        });
        //gtm for brightspeed or century link account
        if(response?.data?.errorInfo?.errorMessage == "ERRDSSPQBP0001") {
          window.dataLayer?.push({
            company: 'brightspeed'
          });
        } else {
          window.dataLayer?.push({
            company: 'centurylink'
          })
        }
        // pmtArrangementOnAccount to navigate QbpScheduledToPay page
        if (response != null && response.data.account.pmtArrangementOnAccount == true)
        {
          // GTM
          window.dataLayer?.push({
            page: {
              pageType: "qbp",
              pageName: "ctl|account|qbp|paymentform_iframe",
            },
          });

          // GTM
          window.dataLayer?.push({
            event: "qbp_choose_payment_method",
            accountNumber: pii(this.selectedMutliAccount),
            accountType: response.data.account.accountType,
            flow: {
              name: "qbp",
              step: "payment flow",
            },
          });
          if (response.data.account.promiseToPay == true) {
            if (response.data.account.currMontlyAmt < 0) {
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.state.number)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(response.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(response.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(response.data.account.paymentDueDate)
              );
              attributesData.set("currentMonthlyAmount", JSON.stringify(0));
              attributesData.set(
                "remainingAmount",
                JSON.stringify(response.data.account.remainingArrAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(response.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(response.data.account.installments)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "QbpPromiseToPay",
                params: {},
              });
            } else {
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.state.number)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(response.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(response.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(response.data.account.paymentDueDate)
              );
              attributesData.set(
                "currentMonthlyAmount",
                JSON.stringify(response.data.account.currMonthlyAmt)
              );
              attributesData.set(
                "remainingAmount",
                JSON.stringify(response.data.account.remainingArrAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(response.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(response.data.account.installments)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              this.$router.push({
                name: "QbpPromiseToPay",
                params: {},
              });
            }
          } else if (response.data.account.scheduledPmts == true)
            if (response.data.account.currMontlyAmt < 0) {
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.selectedMutliAccount)
              );
              attributesData.set("multiAccountNumber", JSON.stringify(this.selectedAccount));
              attributesData.set(
                "status",
                JSON.stringify(this.isMultiAccount)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(response.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(response.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(response.data.account.paymentDueDate)
              );
              attributesData.set("currentMonthlyAmount", JSON.stringify(0));
              attributesData.set(
                "remainingAmount",
                JSON.stringify(response.data.account.remainingArrAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(response.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(response.data.account.installments)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "QbpScheduledToPay",
                params: {},
              });
            } else {
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.selectedMutliAccount)
              );
              attributesData.set("multiAccountNumber", JSON.stringify(this.selectedAccount));
              attributesData.set(
                "status",
                JSON.stringify(this.isMultiAccount)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(response.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(response.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(response.data.account.paymentDueDate)
              );
              attributesData.set(
                "currentMonthlyAmount",
                JSON.stringify(response.data.account.currMonthlyAmt)
              );
              attributesData.set(
                "remainingAmount",
                JSON.stringify(response.data.account.remainingArrAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(response.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(response.data.account.installments)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "QbpScheduledToPay",
                params: {},
              });
            }
        } else {
          if(response.data.status==0 && response.data.errorInfo==null)
          {
            // navigate to confirm page by setting required field 
            if(response.data.epwfSession != null){ 
              const iFrame  = response.data.epwfSession.sessionURL;
              this.selectedMutliAccount = JSON.parse(
                this.$store.getters[GET_DATA]("selectedAccountNumber")
              );
              let attributesData = new Map();
              attributesData.set("selectedAccountNumber", JSON.stringify(this.selectedMutliAccount));
              attributesData.set("multiAccountNumber", JSON.stringify(this.selectedAccount));
              attributesData.set("account", JSON.stringify(this.selectedMutliAccount));
              attributesData.set("EpwfIframe", JSON.stringify(iFrame));
              attributesData.set(
                "status",
                JSON.stringify(this.isMultiAccount)
              );
              attributesData.set(
                "balanceDue",
                JSON.stringify(response.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "prepaidAccount",
                JSON.stringify(response.data.account.prepaidAccount)
              );
              attributesData.set(
                "prepaidInfo",
                JSON.stringify(response.data.account.prepaidSuspendedAccount)
              );
              attributesData.set(
                "paymentDate", JSON.stringify(response.data.account.paymentDueDate)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              this.$router.push({ 
                name: 'Confirm',
                params: {}
              })
            }else if(response.data.epwfSession == null ){
                // epwf is null
                this.selectedMutliAccount = JSON.parse(
                  this.$store.getters[GET_DATA]("selectedAccountNumber")
                );
                let attributesData = new Map();
                attributesData.set("selectedAccountNumber", JSON.stringify(this.selectedMutliAccount));
                attributesData.set("multiAccountNumber", JSON.stringify(this.selectedAccount));
                attributesData.set("account", JSON.stringify(this.selectedMutliAccount));
                attributesData.set(
                  "EpwfIframe", 
                  null
                );
                attributesData.set(
                  "errorInfo", 
                  ""
                );
                attributesData.set(
                  "paymentDate", 
                  JSON.stringify(response.data.account.paymentDueDate)
                );
                attributesData.set(
                  "status",
                  JSON.stringify(this.isMultiAccount)
                );
                attributesData.set(
                  "balanceDue",
                  JSON.stringify(response.data.account.adjustedAmountDue)
                );
                attributesData.set(
                  "prepaidAccount",
                  JSON.stringify(response.data.account.prepaidAccount)
                );
                attributesData.set(
                  "paymentDate", 
                  JSON.stringify(response.data.account.paymentDueDate)
                );
                attributesData.set(
                  "prepaidInfo",
                  JSON.stringify(response.data.account.prepaidSuspendedAccount)
                );
                this.$store.dispatch(SET_DATA, { attributes: attributesData });

                this.$router.push({ name: 'Confirm', params: {}} )
            }
          } else if (response.data.status==0 && response.data.errorInfo!=null) {
            // display diffrent error message based response
            if(response.data.errorInfo.errorMessage == 'QBP_NO_BALANCE'||
              response.data.errorInfo.errorMessage == "QBP_NO_BALANCE" ||
              response.data.errorInfo.errorMessage == "QBP_AUTOPAY" ||
              response.data.errorInfo.errorMessage == "QBP_BILLING_NAME_UNAVAILABLE" ||
              response.data.errorInfo.errorMessage == "QBP_SERVICE_ERROR" ||
              response.data.errorInfo.errorMessage == "QBP_BLOCKED_ACCOUNT" ||
              response.data.errorInfo.errorMessage == "QBP_AMOUNT_DUE_UNAVAILABLE" ||
              response.data.errorInfo.errorMessage == "QBP_SIMPLE_PAY_ACCOUNTS" || 
              response.data.errorInfo.errorMessage == "QBP_ACCOUNT_STATUS_EXCEPTION" ||
              response.data.errorInfo.errorMessage == "QBP_PREPAID_NON_SUSPENDED_ACCOUNT") {
              this.selectedMutliAccount = JSON.parse(
                this.$store.getters[GET_DATA]("selectedAccountNumber")
              );
              let attributesData = new Map();
              attributesData.set("selectedAccountNumber", JSON.stringify(this.selectedMutliAccount));
              attributesData.set("multiAccountNumber", JSON.stringify(this.selectedAccount));
              attributesData.set("account", JSON.stringify(this.selectedMutliAccount));
              attributesData.set(
                "EpwfIframe", 
                null
              );
              attributesData.set(
                "status",
                JSON.stringify(this.isMultiAccount)
              );
              attributesData.set(
                "balanceDue",
                JSON.stringify(response.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "prepaidAccount",
                JSON.stringify(response.data.account.prepaidAccount)
              );
              attributesData.set(
                "prepaidInfo",
                JSON.stringify(response.data.account.prepaidSuspendedAccount)
              );
              attributesData.set(
                "errorInfo",
                JSON.stringify(response.data.errorInfo.errorMessage)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              this.$router.push({ name: 'Confirm', params: {}})
            } else if (response.data.errorInfo.errorMessage == "QBP_ACCOUNT_STATUS_NOT_LIVE") {
              // not live account
              this.status = " Sorry, the status of this account doesn't allow for Quick Bill Pay payments. ";
              let attributesData = new Map();
              attributesData.set('showLoading', false);
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              // GTM for not live
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                page: {
                  pageType: "qbp",
                  pageName: "ctl|account|qbp|exception|status|not_live",
                },
              });
            } else if (response.data.errorInfo.errorMessage == "QBP_ACCOUNT_STATUS_EXCEPTION") {
              this.status = " We’re sorry. We can’t complete this transaction. Please try again later. ";
              attributesData.set('showLoading', false);
              this.$store.dispatch(SET_DATA, {attributes:attributesData});
              // GTM
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                page: {
                  pageType: "qbp",
                  pageName: "ctl|account|qbp|exception|dvar_transaction",
                },
              });
            } else if (response.data.errorInfo.errorMessage == "ERRDSSPQBP0001") {
              //brightspeed customer
              window.dataLayer?.push({
                event: 'my_ctl_exception_message',
                errorName: 'bspd_PA'
              });
              this.status = "Hello. Your service provider is now Brightspeed.";
              this.brightSpeed = true;
              attributesData.set('showLoading', false);
              this.$store.dispatch(SET_DATA, {attributes:attributesData});
            }
          }  else {
            attributesData.set('showLoading', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
          }
        }
      } catch (error) {
        console.log(error)
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
      }
    } else {
      this.onselect = false;
      this.statusError();     
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
    }
  }
  },
    
    mounted(){
      // gtm for multiaccount page
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'qbp_multi_account',
        flow:{
          name: 'qbp',
          step: 'multi_account_page'
        },
      });
      // get the required fields from store to display page
      this.isDay1PlusToggleOn = process.env.VUE_APP_DAY1_PLUS_TOGGLE
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      this.MultiAccount = JSON.parse(this.$store.getters[GET_DATA]("multiData"));
      this.selectedZipCode = JSON.parse(this.$store.getters[GET_DATA]("zipcode"));
      this.isMultiAccount = JSON.parse(this.$store.getters[GET_DATA]("status"));
      this.selectedMutliAccount = JSON.parse(this.$store.getters[GET_DATA]("selectedAccountNumber"));
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
   },
 }
            
</script>

<style scoped>

#qbp-left-column-content{
    position: relative;
}

#h2text{
  font-weight: bold;
  color: black !important;
}

#rightBoxContent{
  margin-top: 15px;
}

@media screen and(max-width: 767px) {
  .mobileview-right-column{
      margin-top: -19px;
  }
}

.formError{
  background:  url(../../images/global-alert-icon-2.svg) no-repeat 1px 1px;
  background-repeat: no-repeat;
  background-size: 36px 30px;
  padding: 2px 15px 3px 38px;
	color:#d0021b;
	font-size: 14px;
	line-height: 1.5;
  margin-bottom: 0px;    
}
#dssp-intro-wrapper h1 {
  color: #14161A;
  font-size: 36px;
  letter-spacing: 0px;
  margin-bottom: 16px;
  padding: 0;
}
#dssp-intro-wrapper h2 {
  color: #14161A;
  font-size: 24px;
  letter-spacing: 0.24px;
}

.multiOptionDiv{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border: 1px solid #EEEEEE33;
  border-radius: 8px;
  opacity: 1;
  z-index:1001;
  position: relative;
  width: 505px;
}

.multiTitle{
  text-align: left;
  font-family: MaisonNeue-Light, serif;
  font-size: 24px;
  letter-spacing: 0.16px;
  color: #14161a;
  opacity: 1;
}

.multiCard{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border: 1px solid #EEEEEE33;
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 12px;
}

.cards {
  height: 218px;
  margin-bottom: 24px;
  line-height: 22px;
}

.selected {
  background-color: #0047BB;
  color: #FFFFFF;
}

.overlay{
    opacity:0.8;
    background-color:rgba(0,0,0,.32);
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.8; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.8; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.8; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.8; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 0.8; }
}

@media all and (max-width: 540px) {
  .multiOptionDiv {
    width: 85%;
    height: 100%;
  }
  #multi-account-next-button {
    margin-left: 0px
  }
}

.orchestrator-error {
  position: relative;
  z-index:1001;
}
</style>
