<template>
  <div class="container d-flex justify-content-center">
    <div class="row">
      <div class="col-md-12 col-sm-12 col-lg-12 col-xs-12">
        <div v-if="HCDE400">
          <div id="paymentError" class="qbp-hcde-error-wrapper">
            <h2>CenturyLink - QuickBillPay error</h2>
            <h3>Sorry. Your payment was not completed.</h3>
            <p>
              It looks like maybe you've already made an identical payment
              today.
            </p>
            <p>
              If you're intentionally trying to make an extra payment on your
              CenturyLink account, you'll have to
              <a href="/pa/identify" id="tryagainhcde400">try again</a>
              using a different payment method or for a different dollar amount.
            </p>
          </div>
        </div>
        <div v-if="HCDE403">
          <h2>CenturyLink - QuickBillPay error</h2>
          <div id="paymentError" class="qbp-hcde-error-wrapper">
            <h3>Sorry. Your payment cannot be completed.</h3>
            <p>It looks like you've made several payments lately.</p>
            <p>
              We only allow up to 5 payments every 30 days per CenturyLink
              account.
              <b
                ><a :href="this.signInURL" id="signinhcde403"
                  >Sign into My CenturyLink</a
                ></b
              >
              to review your payment history and try again at a later date.
            </p>
          </div>
        </div>
        <div v-if="HCDE128">
          <h2>CenturyLink - QuickBillPay error</h2>
          <div id="paymentError">
            <div class="outside outside2 hcde128-outside-padding">
              <div class="inside">
                <div class="errorBox">
                  <h2 class="hcde128-err-msg">
                    Whoops! Something didn't go quite as expected.
                  </h2>
                  <p class="hcde128-err-text">
                    No payment was submitted. Would you like to
                    <a href="/pa/identify" id="qbpduplicatesession"
                      >start over and try again</a
                    >?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="HCDE110">
          <div id="paymentError">
            <div class="outside outside2">
              <div class="inside">
                <div class="pleaseEnterEmailMessage">
                  <h2>Your session has timed out.</h2>
                  <p>
                    For the security of your account, your session was ended due
                    to inactivity.
                  </p>
                  <p style="margin-bottom: 0">
                    Would you like to
                    <a href="/pa/identify" id="startoverandtryagain">
                      start over and try again</a
                    >?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!HCDE400 && !HCDE403 && !HCDE128 && !HCDE110">
          <h2>Our apologies, this page is experiencing technical issues.</h2>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import constants from "../js/constants";
import {GET_DATA, SET_DATA} from '../store/storecontants';
import axios from "axios";
import { pii } from "../js/pii";
export default {
  name: "FailureOfPayment",
  data() {
    return {
      signInURL : process.env.VUE_APP_EAM_DOMAIN+ '/eam/entryPoint.do',
      HCDE400: null,
      HCDE403: null,
      HCDE128: null,
      HCDE110: null,
      accountNumber: "",
      urlRouter: "",
    };
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_failure',
      flow:{
        name: 'pa',
        step: 'failure_page'
      },
    });
    
    this.accountNumber = (this.$store.getters[GET_DATA]('maskedAccount') != "null") ? this.$store.getters[GET_DATA]('maskedAccount') : this.$store.getters[GET_DATA]('accountEntered') ? JSON.parse(this.$store.getters[GET_DATA]('accountEntered')) : "NA";
    let attributesData = new Map();
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    let result = this.$route.query.ErrorCode;
    this.urlRouter = result.includes("-") ? result.replace(/-/g, "") : result;
    if (this.accountNumber) {
      this.setLogger();
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
    } else {
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
    }
    this.getPaymentFailureDetails();
    attributesData.set('showLoading', false);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
  },
  methods: {
    getPaymentFailureDetails() {
      if (this.urlRouter != null || this.urlRouter != "") {
        if (this.urlRouter === "HCDE400") {
          this.HCDE400 = true;
          this.HCDE403 = false;
          this.HCDE128 = false;
          this.HCDE110 = false;
        } else if (this.urlRouter === "HCDE403") {
          this.HCDE400 = false;
          this.HCDE403 = true;
          this.HCDE128 = false;
          this.HCDE110 = false;
        } else if (this.urlRouter === "HCDE128") {
          this.HCDE400 = false;
          this.HCDE403 = false;
          this.HCDE128 = true;
          this.HCDE110 = false;
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "pa_error",
            accountNumber: pii(this.accountNumber),
            errorName: "HCDE 128",
            flow: {
              name: "pa",
              step: "payment_flow",
            },
          });
        } else if (this.urlRouter === "HCDE110") {
          this.HCDE400 = false;
          this.HCDE403 = false;
          this.HCDE128 = false;
          this.HCDE110 = true;

          window.dataLayer?.push({
            event: 'pa_error',
            accountNumber: pii(this.accountNumber),
            errorName: 'HCDE110 HCDE timeout',
            flow:{
              name: 'pa',
              step: 'payment flow'
            },
          });
        } else {
          this.HCDE400 = false;
          this.HCDE403 = false;
          this.HCDE128 = false;
          this.HCDE110 = false;
        }
      }
    },
    async setLogger() {
      let params = {}
      if (this.urlRouter) {
          params = {
            logString :  "payment arrangement payment failure for accountNumber=" + this.accountNumber + " HCDE ErrorCode=" + this.urlRouter
          };
      } else {
          params = {
            logString: "payment arrangement Payment failure for accountNumber=" + this.accountNumber
          };
      }
      try {
        await axios.get(constants.PaymentFailureCallForOrchestrator, { params });
      } catch (error) {
        console.log(error);
      }
    }
  },
  updated() {
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
  },
};
</script>
<style scoped></style>
