<template>
  <div class="container-fluid container-color">
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <qbpCancel />
      </div>
      <div class="col-md-12 col-xs-12">
        <QuickPayRightBar />
      </div>
    </div>
  </div>
</template>
<script>
import QuickPayRightBar from "../components/qbp/quickPayRightBar.vue";
import qbpCancel from "../components/qbp/qbpCancel.vue";

export default {
  name: "Cancel",
  components: {
    qbpCancel,
    QuickPayRightBar,
  }
};
</script>
<style scoped>
@media(min-width: 768px) {
  .qbp-wrapper {
    max-width: 700px;
  }
}
@media(min-width: 1024px) {
  .qbp-wrapper {
    max-width: 900px;
  }
}
.container-color {
  background-color: #FAFAFA;
  margin-top: -20px;
  padding-top: 20px;
}
</style>