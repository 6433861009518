<template>
  <div class="container qbp-wrapper d-flex justify-content-center">
    <div class="row">
      <div class="col-md-7 col-xs-12">
        <PACancel />
      </div>
      <div class="col-md-5 col-xs-12">
        <PaymentArrangementRightBar />
      </div>
    </div>
  </div>
</template>
<script>
import PaymentArrangementRightBar from "../components/paymentArrngmts/payArrngmtsRightBar.vue";
import PACancel from "../components/paymentArrngmts/PaymentArrangementsCancel.vue";

export default {
  name: "Cancel",
  components: {
    PACancel,
    PaymentArrangementRightBar
  }
};
</script>
<style scoped>
@media(min-width: 768px) {
  .qbp-wrapper {
    max-width: 700px;
  }
}
@media(min-width: 1024px) {
  .qbp-wrapper {
    max-width: 900px;
  }
}
</style>