<template>
  <div id="paymentError">
    <div class="outside">
      <div class="inside">
        <div class="errorBox">
          <h2>
            We're very sorry.
          </h2>
        </div>
        <div>
          <p>
            We're experiencing technical difficulties retrieving your saved payment methods information from the billing and payment system. We do all we can to make My Account perform perfectly all the time, but the truth is...sometimes it just doesn't.	
          </p> 
          <p>If this is the first time you're seeing this message <a href="/wallet/manage" class="manage-wallet-link" id="tryagain">try again</a> in 15 minutes or so. Or, 
            <a v-if="isBillerEnsemble" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" class="manage-wallet-link" id="chatwithus" data-galink-type="chat">chat with us.</a>
            <a v-else href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" class="manage-wallet-link" id="chatwithus" data-galink-type="chat">chat with us.</a>
            so we can help. 
          </p>
          <p>And again, we sincerely apologize for the inconvenience. Your patience is appreciated.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_DATA } from "../../store/storecontants";

export default {
  name: "manageWalletError",
  data() {
    return {
      isBillerEnsemble: false,
    };
  },
  mounted() {
    this.isBillerEnsemble = this.$store.getters[GET_DATA]("isBillerEnsemble");

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'mw_error',
        flow: {
            name: 'manage wallet',
            step: 'error'
        }
    });
  }
};
</script>
<style scoped>
  .manage-wallet-link {
    color: #0047BB;
  }
  #paymentError {
    margin: 0 auto 20px;
  }
  #paymentError h2, #paymentError p {
      text-align: left;
  }
  #paymentError h2 {
      margin: 20px 0;
      color: #14161a;
      font-size: 24px;
      font-weight: normal;
  }
  #paymentError p {
      color: #717274;
      margin-bottom: 15px;
      font-size: 16px;
  }
</style>