<template>
  <div class="container qbp-promise-to-pay d-flex justify-content-center">
    <div class="row">
      <div class="col-md-7 col-xs-12"  id="left_content">
        <QBPPromiseToPay/>
      </div>
      <div class="col-md-5 col-xs-12" id="right_content">
        <QuickPayRightBar/>
      </div>
    </div>
  </div>
</template>

<script>   
import QBPPromiseToPay from '../components/qbp/QBPPromiseToPay.vue'  
import QuickPayRightBar from '../components/qbp/quickPayRightBar.vue'

export default {
  name: 'Promise To Pay',
  components: {
    QBPPromiseToPay,
    QuickPayRightBar
  }
}

</script>
<style scoped>
#left_content{
     align-content: flex-end;
}
#right_content{
    align-content: flex-end;
}
@media(min-width: 768px) {
  .qbp-promise-to-pay {
    max-width: 700px;
  }
}
@media(min-width: 1024px) {
  .qbp-promise-to-pay {
    max-width: 900px;
  }
.okBtn {
    padding-right: 20px;
    padding-bottom: 20px;
}
.okBtn a{
    color: #fff ;
    text-decoration: none ;
}
.okBtn a:hover{
    color: #fff;
    text-decoration: none;
}
.modalContent {
    padding-bottom: 0;
    padding-top: 35px;
}
}
</style>