export const PMTARNGMNT_001 ="PMTARNGMNT_001";
export const PMTARNGMNT_002 ="PMTARNGMNT_002";
export const PMTARNGMNT_003 ="PMTARNGMNT_003";
export const PMTARNGMNT_004 ="PMTARNGMNT_004";
export const PMTARNGMNT_005 ="PMTARNGMNT_005";
export const PMTARNGMNT_006 ="PMTARNGMNT_006";
export const PMTARNGMNT_008 ="PMTARNGMNT_008";
export const PMTARNGMNT_009 ="PMTARNGMNT_009";
export const PMTARNGMNT_010 ="PMTARNGMNT_010";
export const PMTARNGMNT_011 ="PMTARNGMNT_011";
export const PHONE_INVALID_ERROR_CODE = "PHONE_INVALID";
export const EMAIL_INVALID_ERROR_CODE = "EMAIL_INVALID";
export const ZIP_INVALID_ERROR_CODE = "ZIP_INVALID";
export const SYSYTEM_DOWN = "500";
export const REG_CRIS = "REG_CRIS";
export const ACCOUNT_INVALID = "ACCOUNT_INVALID";

/**
 * Error messages
 */
export const DEFAULT_ERROR_PA = "We are currently unable to retrieve the account data needed to complete your request as one or more of our systems are currently unavailable. Please try again later."
export const ACCOUNT_MAINTENANCE = "Your account is currently unavailable due to planned maintenance. This maintenance period may take up to 6 hours. Please try again later"
export const NO_AMOUNT_DUE = "Sorry, you are not eligible. You do not currently qualify to make a payment plan, as there is no amount due on your account at this time."
export const PA_PMTARNGMNT_008_ERROR_MESSAGE = "'Your entry doesn't match what's on record for this account. Please try again.'"