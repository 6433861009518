<template>
  <div class="root">
    <div class="row" id="dssp-intro-wrapper">
      <h1 class="text-center">Quick Bill Pay</h1>
      <h2 class="text-center" data-test="messageHead">Safe and secure one-time bill payment</h2>
    </div>
    <!-- error message -->
    <div v-if="dvarError">
      <div class="row ms-5" id="qbp-intro-wrapper">
        <div class="col-md-12 col-sm-12 col-lg-12">
          <div class="outside outside2" style="background: #faf8f8">
            <div class="inside">
              <div class="pleaseEnterEmailMessage">
                <p style="line-height: 1.5em">
                  {{ this.errorMessage }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- error message for brightspeed customers-->
    <div v-if="this.status != null" class="orchestrator-error">
      <p class="text-start mt-2 orchestrator-error-message" tabindex="0" ref="statusError">{{ this.status }}</p>
      <div v-if="embargo" id="outsideLink">
        <a :href="EmbargoURL" id="outsideLinkURL" target="blank">
          Learn More</a>
      </div>
      <div v-if="this.brightspeed" id="outsideLink">
        <a :href="BrightspeedQbpURL" id="outsideLinkURL" target="blank">
          Make your payment at Brightspeed</a>
      </div>
      <div v-if="this.businessAccount" id="outsideLink">
        <a :href="ControlCenterURL" id="outsideLinkURL" target="blank">
          https://www.lumen.com/login/</a>
      </div>
    </div>

    <!-- error message for email/phone customers-->
    <div class="orchestrator-error" v-if="v$.number.$error && (v$.number.TN.$invalid || v$.number.Email.$invalid)">
      <p class="text-start mt-2 orchestrator-error-message" tabindex="0" ref="statusError">
        To keep your data secure, please use your account number to use Quick Bill Pay or <a :href="MyaEntryPointURL">sign in</a>.
      </p>
    </div>

    <!-- form account number and zipcode -->
    <div class="account-struct" data-test="toggleAccount">
      <form  @submit.prevent="submit" role="form">
      <div class="row justify-content-center">
        <div class="col-11 col-md-11 col-sm-11 account-error-input" v-if="v$.number.$error">
          <label
            class="d-inline-block text-start text-truncate col-12 form-error-message"
            :class="{ 'text-danger': v$.number.$error }"
            for="number">
            <span v-if="this.enableTnEmail=='true'">An account, telephone number or email ID is required.</span>  
            <span v-else>A valid account number is required.</span>  
          </label>
        </div>
        <div class="col-11 col-md-11 col-sm-11" id="phone-account-label-wrapper">

          <div class="form-floating form-group">
            <input
              class="form-control"
              :class="{ 'is-invalid': v$.number.$error }"
              type="text"
              id="number"
              aria-required="true"
              ref="accountRef"
              v-model="state.number"
              placeholder=" "
              maxlength="101"
              v-on:keyup.enter="submitForm()"
            />
            <label
              for="number"
              class="d-inline-block text-start text-truncate form-label col-12"
              :class="{ 'text-danger': v$.number.$error, 'form-error-label': v$.zip.$error }"
              >
              <span v-if="this.enableTnEmail=='true'">Account, Phone Number or Email:</span>
              <span v-else>Account Number:</span>
            </label>
          </div>
        </div>
        <div class="col-1 p-0" id="popover-wrapper" style="width: auto;">
          <button v-if="this.enableTnEmail=='true'" aria-label="help text" type="button" id="dssp-popover" class="btn btn-white" data-bs-placement="top" data-bs-boundary="window" data-bs-html="true" data-bs-toggle="popover" data-bs-trigger="hover focus" tabindex="0"
            data-bs-content="<b>Please enter one of the following:</b><br><br><b>Account number</b> in the upper corner of your CenturyLink bill or online (9, 11 or 13 digits)<br><br><b>Phone number - </b>any 10 digit number we have on file with your account<br><br><b>Email address</b> we have on file with your account">
            <img src="../../images/svg/Info_Outline.svg" alt="outline-info" class="filter-blue" />
          </button>

          <button v-else aria-label="help text" type="button" id="dssp-popover" class="btn btn-white" data-bs-placement="top" data-bs-boundary="window" data-bs-html="true" data-bs-toggle="popover" data-bs-trigger="hover focus" tabindex="0"
            data-bs-content="<b>Please enter your account number.</b><br><br>You can find your account number in the upper corner of your bill or in your online account.">
            <img src="../../images/svg/Info_Outline.svg" alt="outline-info" class="filter-blue" />
          </button>
        </div>
        <br>
        <a :href="RetrieveAmountNumberURL" id="RetrieveAmountNumberLink" target="blank">
          Find Your Account Number</a>
        <div class="col-11 col-md-11 col-sm-11 input-placement" v-if="v$.zip.$error">
          <label
            class="text-start col-12 form-error-message label-zip pad-left"
            :class="{ 'text-danger': v$.zip.$error }"
            for="zip">
            A zip code is required.
          </label>
        </div>
        <div class="col-11 col-md-11 col-sm-11" id="zip-label-wrapper">
          <div class="form-floating form-group mt-3">
            <input
              class="form-control"
              :class="{ 'is-invalid': v$.zip.$error }"
              type="text"
              id="zip"
              aria-required="true"
              ref="zipRef"
              placeholder=" "
              maxlength="5"
              v-model="state.zip"
              @input="onlyNumber"
              v-on:keyup.enter="submitForm()"
            />
            <label
              for="zip"
              class="d-inline-block text-start text-truncate form-label col-12"
              :class="{ 'text-danger': v$.zip.$error, 'form-error-label': v$.zip.$error }"
              >Billing Zip Code:</label
            >
          </div>
        </div>
        <div class="col-1 p-0" id="popover-wrapper"  style="width: 25px;"></div>
      </div>

      <!-- Continue button -->
      <div class="row">
        <div class="col-6 col-md-6 col-sm-6">
          <br />
          <div id="norton-logo"></div>
        </div>
        <div class="col-6 col-md-6 col-sm-6">
          <button
            type="submit"
            id="next-button"
            class="btn btn-primary btn-lg text-center"
            :aria-disabled="this.nextButtonCheck"
            @click="submitForm"
          >Next</button>
        </div>
      </div>
      </form>
    </div>
    <div class="row margin-18">
      <h4 style="font-style:italic;">or</h4>
    </div>
    <div class="row justify-content-center">
      <button type="button" class="btn sign-in" @click="authenticate()"><a id="signintomyaccount" target="_blank" rel="noopener">Sign in to My CenturyLink</a></button>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import {
  required,
  email,
  minLength,
  maxLength,
  integer,
  helpers,
  or,
  and,
  not
} from "@vuelidate/validators";
import { reactive, computed, ref } from "vue";
import axios from "axios";
import { Popover } from "bootstrap/dist/js/bootstrap.bundle";
import constants from "../../js/constants";
import { SET_DATA, RESET } from "../../store/storecontants";
import store from "../../store";
import { DEFAULT_ERROR_PA } from "../../utils/ErrorCodes";
import { pii } from "../../js/pii";
import links from "../../js/links";
import router from '../../router'

export default {
  name: "QBPForm",
  beforeMount(){
    this.$store.dispatch(RESET);
  },
  data() {
    return {
      dvarError: false,
      errorMessage: DEFAULT_ERROR_PA,
      status: null,
      singleAccount: "",
      EmbargoURL: links.EmbargoURL,
      isDay1PlusToggleOn: false,
      enableTnEmail: Boolean,
      MyaEntryPointURL: links.MyaEntryPointURL,
      AuthHome: links.AuthHome,
      BrightspeedQbpURL: links.BrightspeedQbpURL,
      brightspeed: false,
      businessAccount:false,
      ControlCenterURL: links.ControlCenterURL,
      PayBillEntryPoint: links.PayBillEntryPoint,
      RetrieveAmountNumberURL: links.RetrieveAmountURL
    };
  },
  setup() {
    const embargo = ref(false);
    const state = reactive({
      number: null,
      zip: null,
    });
    const isPrepaid = (value) => value.toUpperCase() && (value.includes('PPB') || value.includes('ppb'));
    /* eslint-disable */
    const isNumber = helpers.regex(
      /^[\s]*[(]{0,1}[0-9]{9}[)]{0,1}[\s]*$/
    );
    const isPhone = helpers.regex(
      /^[\s]*[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}[\s]*$/
    );
    /* eslint-disable */
    const isCris = helpers.regex(
      /^[\s]*[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}[-\s\.]{0,1}[0-9]{3}[\s]*$/
    );
    const isCrisAllDigits = helpers.regex(
      /^[\s]*[(]{0,1}[0-9]{13}[)]{0,1}[\s]*$/
    );
    const TN = helpers.withMessage('ENTERED_PHONE', not(isPhone))
    const Email = helpers.withMessage('ENTERED_EMAIL', not(email))
    // validation
    const rules = computed(() => {
      return {
        number: {
          required,
          or: or(
            isNumber,
            isCris,
            isCrisAllDigits,
            and(integer, minLength(9), maxLength(9)),
            and(isPrepaid, minLength(11), maxLength(11))
          ),
          TN,
          Email
        },
        zip: {
          required,
          integer,
          minLength: minLength(5),
        },
      };
    });
    const v$ = useValidate(rules, state);
    return {
      state,
      embargo,
      v$,
    };
  },
  methods: {
    // GTM and redirect for Sign in to My CenturyLink
    authenticate() {
      window.dataLayer?.push({
        event: 'qbp_start',
        accountNumber: 'null',
        flow:{
          name: 'qbp',
          step: 'start'
        },
        buttonName: 'QBP full auth',
      });
      
      window.location.href = this.MyaEntryPointURL;
    },
    // accept only number for zipcode
    onlyNumber() {
      this.state.zip = this.state.zip.replace(/[^0-9]/g, "");
    },
    // accessability error message focus
    statusError () {
      setTimeout(() => { 
        let errorDiv = this.$refs.statusError
        if(errorDiv) {
          this.$refs.statusError.focus();
        }
      }, 500) 
    },
    // its for account number to remove - _ "" user entered
    replaceData(data){
      let regex = /\(|\)/g;      
      var newStr = data.replace(/-/g, "").replace(/ /g, "").replace(/_/g, "").replace(regex, "");
      return newStr;
    },
    initialState(){
      this.brightspeed = false;
      this.dvarError = false;
      this.embargo = false;
      this.businessAccount = false;
    },
    submitForm() {
      //accessability disable attribute
      const nextButton = document.getElementById("next-button");
      const isDisabled = nextButton.getAttribute('aria-disabled') === 'true';
      if(isDisabled){
        e.preventDefault();
        return;
      }
      this.initialState();
      this.v$.$validate();
      if(this.state.number != null)  {
        if(this.state.number.startsWith("ppb")){
          this.state.number = this.state.number.toUpperCase()
        }
      }
      this.state.number = (this.state.number != null) ? this.state.number.trim() : null;
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
     
      attributesData.set("account", this.state.number);
      this.$store.dispatch(SET_DATA, { attributes: attributesData });
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      //check for no error then call getaccount function to call api
      if (!this.v$.$error) {
        this.embargo = false;
        this.getAccount();
      } else {
        // error focus for accessability
        this.dvarError = false;
        if(this.v$.zip.$error && this.v$.number.$error) {
          this.$refs.accountRef.focus();
        } else if (this.v$.number.$error) {
          this.$refs.accountRef.focus();
        } else if (this.v$.zip.$error) {
          this.$refs.zipRef.focus();
        }
        if (this.v$.zip.$error) {
          // GTM for zip error
          window.dataLayer?.push({
            event: "qbp_error",
            accountNumber: pii(this.state.number),
            errorName: "zip code required",
            flow: {
              name: "qbp",
              step: "start",
            },
          });
        }
        if (this.v$.number.$error) {
          // GTM for account number invalid
          window.dataLayer?.push({
            event: "qbp_error",
            accountNumber: pii(this.state.number),
            errorName: "invalid account",
            flow: {
              name: "qbp",
              step: "start",
            },
          });
        }
      }
      // GTM while entering the flow
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "qbp_start",
        accountNumber: pii(this.state.number),
        flow: {
          name: "qbp",
          step: "start",
        },
        buttonName: 'QBP profile lookup',
      });
    },
    //call api check the condition set the required values and route to particular page
    async getAccount() {
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      const prepaidSuspendedAccountboolean = false;
      const suspendedAccountboolean = false;
      let strAccount = this.state.number;
      let atChar = '@';
      // user enter gmail then no need to remove - _ "" else call replaceData to remove
      if(strAccount.includes(atChar)){
        this.state.number = this.state.number.toUpperCase()
      }else {
        this.state.number = this.replaceData(this.state.number.toUpperCase())
      }
      try {
        const params = {
          accountNumber: this.state.number,
          zipCode: this.state.zip,
        };
        //call api with params
        const res = await axios.post(constants.QBPIdentifyURL, params);
        //set the below fields for acod and encrypted account number to call success api
        if(res.data.account) {
          attributesData.set("emailAddress", JSON.stringify(res.data.account.emailAddressOfRecord))
          attributesData.set("firstName", JSON.stringify(res.data.account.acodFirstName))
          attributesData.set("lastName", JSON.stringify(res.data.account.acodLastName))
          attributesData.set("AcodAccountNumber", JSON.stringify(res.data.account.accountNumber))
          attributesData.set("pmtArrangementOnAccount", JSON.stringify(res.data.account.pmtArrangementOnAccount))
          this.$store.dispatch(SET_DATA, { attributes: attributesData });
        }
        if(res.data.errorInfo!=null && res.data.errorInfo.errorMessage == "ERRDSSPQBP0004"){
          prepaidSuspendedAccountboolean = true;
          res.data.errorInfo = null;
        } else if(res.data.errorInfo!=null && res.data.errorInfo.errorMessage == "ERRDSSPQBP0005"){
          suspendedAccountboolean = true;
          res.data.errorInfo = null;
        }
        const multi_Accounts = JSON.stringify(res.data.account.multipleAccounts);
        const zipCode_multiAccount = JSON.stringify(this.state.zip);
        //gtm for brightspeed customer and centurylink customer
        if (res?.data?.errorInfo?.errorMessage == "ERRDSSPQBP0001") {
          window.dataLayer?.push({
            company: 'brightspeed'
          });
        } else {
          window.dataLayer?.push({
            company: 'centurylink'
          })
        }
        // account status exception then set the error message to show
        if (
          res == null ||
          (res.data.errorInfo != null &&
            res.data.errorInfo.errorMessage == "QBP_ACCOUNT_STATUS_EXCEPTION")
        ) {
          this.status = " We’re sorry. We can’t complete this transaction. Please try again later. ";
          this.statusError(); 
          // GTM for exception
          window.dataLayer?.push({
            page: {
              pageType: "qbp",
              pageName: "ctl|account|qbp|exception|dvar_transaction",
            },
          });
        } 
        //error code is 400 
        else if (res.data.errorInfo != null && res.data.errorInfo.errorCode === '400') {
          this.status = "Your entry doesn't match what's on record for this account. Please try again."
          this.statusError();
        }
        // not live account
        else if (
          res == null ||
          (res.data.errorInfo != null &&
            res.data.errorInfo.errorMessage == "QBP_ACCOUNT_STATUS_NOT_LIVE")
        ) {
          this.status = " Sorry, the status of this account doesn't allow for Quick Bill Pay payments. ";
          this.statusError();
          // GTM
          window.dataLayer?.push({
            page: {
              pageType: "qbp",
              pageName: "ctl|account|qbp|exception|status|not_live",
            },
          });
        } 
        // large business account
        else if (
          res == null ||
          (res.data.errorInfo != null &&
            res.data.errorInfo.errorMessage == "QBP_BUSINESS_ACCOUNT")
        ) {
          this.businessAccount = true;
          this.status = " Quick Bill Pay doesn’t support this account type. Please use Control Center to manage your account. ";
          this.statusError();
          // GTM
          window.dataLayer?.push({
            page: {
              pageType: "qbp",
              pageName: "ctl|account|qbp|exception|status|large_business_account",
            },
          });
        } 
        // pmtArrangementOnAccount true navigate to scheduled payment page
        else if (
          res != null &&
          res.data.account.pmtArrangementOnAccount == true
        ) {
          // GTM
          window.dataLayer?.push({
            page: {
              pageType: "qbp",
              pageName: "ctl|account|qbp|paymentform_iframe",
            },
          });

          // GTM
          window.dataLayer?.push({
            event: "qbp_choose_payment_method",
            accountNumber: pii(this.state.number),
            accountType: res.data.account.accountType,
            flow: {
              name: "qbp",
              step: "payment flow",
            },
          });
          if (res.data.account.promiseToPay == true) {
            if (res.data.account.currMontlyAmt < 0) {
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.state.number)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "EpwfSessionId",
                JSON.stringify(res.data.epwfSession.sessionId)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set("currentMonthlyAmount", JSON.stringify(0));
              attributesData.set(
                "remainingAmount",
                JSON.stringify(res.data.account.remainingArrAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(res.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(res.data.account.installments)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "QbpPromiseToPay",
                params: {},
              });
            } else {
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.state.number)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "EpwfSessionId",
                JSON.stringify(res.data.epwfSession.sessionId)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set(
                "currentMonthlyAmount",
                JSON.stringify(res.data.account.currMonthlyAmt)
              );
              attributesData.set(
                "remainingAmount",
                JSON.stringify(res.data.account.remainingArrAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(res.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(res.data.account.installments)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              this.$router.push({
                name: "QbpPromiseToPay",
                params: {},
              });
            }
          } else if (res.data.account.scheduledPmts == true)
            if (res.data.account.currMontlyAmt < 0) {
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.state.number)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "EpwfSessionId",
                JSON.stringify(res.data.epwfSession.sessionId)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set("currentMonthlyAmount", JSON.stringify(0));
              attributesData.set(
                "remainingAmount",
                JSON.stringify(res.data.account.remainingArrAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(res.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(res.data.account.installments)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "QbpScheduledToPay",
                params: {},
              });
            } else {
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.state.number)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "EpwfSessionId",
                JSON.stringify(res.data.epwfSession.sessionId)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set(
                "currentMonthlyAmount",
                JSON.stringify(res.data.account.currMonthlyAmt)
              );
              attributesData.set(
                "remainingAmount",
                JSON.stringify(res.data.account.remainingArrAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(res.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(res.data.account.installments)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "QbpScheduledToPay",
                params: {},
              });
            }
        } else {
          if (res.data.status == 0 && res.data.errorInfo == null) {
            if (res.data.account.multipleAccounts != null) {
              // qbp multiaccount is not null navigate to multiaccount page
              // GTM
              window.dataLayer?.push({
                page: {
                  pageType: "qbp",
                  pageName: "ctl|account|qbp|verification_info",
                },
              });
              attributesData.set("zipcode", zipCode_multiAccount);
              attributesData.set("multiData", multi_Accounts);
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.state.number)
              );
              attributesData.set(
                "status",
                JSON.stringify(res.data.account.multiAccount)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              this.$router.push({
                name: "MultiAccount",
                params: {},
              });
              } else if (res.data.account.accountEmbargo) {
                this.embargo = res.data.account.accountEmbargo;
              } 
              else if (res.data.epwfSession != null) {
              // single account navigate confirmation page 
              // GTM
              window.dataLayer?.push({
                page: {
                  pageType: "qbp",
                  pageName: "ctl|account|qbp|paymentform_iframe",
                },
              });

              // GTM
              window.dataLayer?.push({
                event: "qbp_choose_payment_method",
                accountNumber: pii(this.state.number),
                accountType: res.data.account.accountType,
                flow: {
                  name: "qbp",
                  step: "payment flow",
                },
              });
              this.singleAccount = JSON.stringify(
                this.state.number
              );
              const iFrame = JSON.stringify(res.data.epwfSession.sessionURL);
              attributesData.set("selectedAccountNumber", this.singleAccount);
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "EpwfSessionId",
                JSON.stringify(res.data.epwfSession.sessionId)
              );
              attributesData.set(
                "balanceDue",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set("errorInfo", null);
              attributesData.set(
                "prepaidAccount",
                JSON.stringify(res.data.account.prepaidAccount)
              );
              attributesData.set(
                "prepaidInfo",
                JSON.stringify(res.data.account.prepaidSuspendedAccount)
              );
              attributesData.set(
                "suspendedAccount", 
                JSON.stringify(res.data.account.accountStatus)
              );
              attributesData.set(
                "isAchAllowed", 
                JSON.stringify(res.data.account.achAllowed)
              );
              attributesData.set(
                "isCardAllowed", 
                JSON.stringify(res.data.account.cardAllowed)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              attributesData.set(
                "amountOwed",
                JSON.stringify(res.data.account.totalBalanceOwed)
              );
              this.$router.push({
                name: "Confirm",
                params: {},
              });
            } else if (res.data.epwfSession == null) {
              // if we are not getting iframe show confirmation page with sorry message on iframe place
              // GTM
              window.dataLayer?.push({
                page: {
                  pageType: "qbp",
                  pageName: "ctl|account|qbp|paymentform_iframe",
                },
              });

              // GTM
              window.dataLayer?.push({
                event: "qbp_choose_payment_method",
                accountNumber: pii(this.state.number),
                accountType: res.data.account.accountType,
                flow: {
                  name: "qbp",
                  step: "payment flow",
                },
              });
              this.singleAccount = JSON.stringify(
                this.state.number
              );
              attributesData.set("selectedAccountNumber", this.singleAccount);
              attributesData.set("EpwfIframe", null);
              attributesData.set("EpwfSessionId",null);
              attributesData.set(
                "status",
                JSON.stringify(res.data.account.multiAccount)
              );
              attributesData.set(
                "balanceDue",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set("errorInfo", "");
              attributesData.set(
                "prepaidAccount",
                JSON.stringify(res.data.account.prepaidAccount)
              );
              attributesData.set(
                "prepaidInfo",
                JSON.stringify(res.data.account.prepaidSuspendedAccount)
              );
              attributesData.set(
                "isAchAllowed", 
                JSON.stringify(res.data.account.achAllowed)
              );
              attributesData.set(
                "isCardAllowed", 
                JSON.stringify(res.data.account.cardAllowed)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              this.$router.push({
                name: "Confirm",
                params: {},
              });
            }
          } else if (res.data.status == 0 && res.data.account.accountEmbargo) {
            //embargo account show error message
            this.embargo = true
            this.status =
              "Your account is currently unavailable due to planned maintenance. This maintenance period may take up to 6 hours. Please try again later";
            this.statusError();
          } else if (res.data.status == 0 && res.data.errorInfo != null) {
            // error message in response then we show one of the view from Errormessage folder
            if (
              res.data.errorInfo.errorMessage == "QBP_NO_BALANCE" ||
              res.data.errorInfo.errorMessage == "QBP_AUTOPAY" ||
              res.data.errorInfo.errorMessage == "QBP_BILLING_NAME_UNAVAILABLE" ||
              res.data.errorInfo.errorMessage == "QBP_SERVICE_ERROR" ||
              res.data.errorInfo.errorMessage == "QBP_BLOCKED_ACCOUNT" ||
              res.data.errorInfo.errorMessage == "QBP_AMOUNT_DUE_UNAVAILABLE" ||
              res.data.errorInfo.errorMessage == "QBP_SIMPLE_PAY_ACCOUNTS" ||
              res.data.errorInfo.errorMessage == "QBP_PREPAID_NON_SUSPENDED_ACCOUNT"
            ) {
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.state.number)
              );
              attributesData.set("EpwfIframe", null);
              attributesData.set("EpwfSessionId",null);
              attributesData.set(
                "status",
                JSON.stringify(res.data.account.multiAccount)
              );
              attributesData.set(
                "balanceDue",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set(
                "errorInfo",
                JSON.stringify(res.data.errorInfo.errorMessage)
              );
              attributesData.set(
                "prepaidAccount",
                JSON.stringify(res.data.account.prepaidAccount)
              );
              attributesData.set(
                "prepaidInfo",
                JSON.stringify(res.data.account.prepaidSuspendedAccount)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              
              this.$router.push({
                name: "Confirm",
                params: {},
              });
            } else if (res.data.errorInfo.errorMessage == "ERRDSSPQBP0001"){
              // brightspeed customer
              window.dataLayer?.push({
                event: 'my_ctl_exception_message',
                errorName: 'bspd_QBP'
              });
              this.brightspeed = true;
              this.status = "Hello. Your service provider is now Brightspeed.";
              this.statusError();
            } else if (res.data.errorInfo.errorMessage == "ERRDSSPQBP0002"){
              window.location.href = this.AuthHome;
            }
          } else if (
            res.data.account.prepaidAccount == "true" &&
            res.data.errorInfo.errorMessage == "QBP_PREPAID_BILLING_NAME_ERROR"
          ) {
            attributesData.set(
              "selectedAccountNumber",
              JSON.stringify(this.state.number)
            );
            attributesData.set("EpwfIframe", null);
            attributesData.set("EpwfSessionId",null);
            attributesData.set(
              "status",
              JSON.stringify(res.data.account.multiAccount)
            );
            attributesData.set(
              "balanceDue",
              JSON.stringify(res.data.account.adjustedAmountDue)
            );
            attributesData.set(
              "paymentDate",
              JSON.stringify(res.data.account.paymentDueDate)
            );
            attributesData.set("errorInfo", res.data.errorInfo.errorMessage);
            attributesData.set(
              "prepaidAccount",
              JSON.stringify(res.data.account.prepaidAccount)
            );
            attributesData.set(
              "prepaidInfo",
              JSON.stringify(res.data.account.prepaidSuspendedAccount)
            );
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
            this.$router.push({
              name: "Confirm",
              params: {},
            });
          } else if (res.data.errorInfo != null && res.data.errorInfo.errorCode == "ZIP_INVALID") {
            //show error message at the top for zip invalid
            this.status = res.data.errorInfo.errorMessage;
            this.statusError();

            // GTM
            window.dataLayer?.push({
              event: "qbp_error",
              accountNumber: pii(this.state.number),
              errorName: "invalid_zip",
              flow: {
                name: "qbp",
                step: "validation",
              },
            });
          } else if (res.data.errorInfo != null && res.data.errorInfo.errorCode == "EMAIL_INVALID") {
            // show error message at the top email invalid
            this.status = res.data.errorInfo.errorMessage;
            this.statusError();

            // GTM
            window.dataLayer?.push({
              event: "qbp_error",
              accountNumber: pii(this.state.number),
              errorName: "invalid_email",
              flow: {
                name: "qbp",
                step: "validation",
              },
            });
          } else if (res.data.errorInfo != null && res.data.errorInfo.errorCode == "PHONE_INVALID") {
            //show error message for phone invalid 
            this.status = res.data.errorInfo.errorMessage;
            this.statusError();

            // GTM
            window.dataLayer?.push({
              event: "qbp_error",
              accountNumber: pii(this.state.number),
              errorName: "invalid_tn",
              flow: {
                name: "qbp",
                step: "validation",
              },
            });
          } else {
            // show error message for c360 enrty doesnot match
            if(res.data.errorInfo.errorCode == "C360_ENTRY_NOT_MATCH") {
              this.status = "Your entry doesn't match what's on record for this account. Please try again."
              this.statusError();
            } else {
              this.status = "We’re sorry. We can’t complete this transaction. Please try again later."
              this.statusError();
            }
            // GTM
            window.dataLayer?.push({
              event: "qbp_error",
              accountNumber: pii(this.state.number),
              errorName: res.data.errorInfo.errorCode,
              flow: {
                name: "qbp",
                step: "validation",
              },
            });

          }
        }
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      } catch (error) {
        //something went wrong in api
        console.log(error);
        this.status = "Something went wrong! Please try again.";
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, {attributes:attributesData}); 
        this.statusError();    
      }
    },
  },
  mounted() {
    this.$store.dispatch(RESET);
    this.enableTnEmail = process.env.VUE_APP_ENABLE_TN_EMAIL;
    this.isDay1PlusToggleOn = process.env.VUE_APP_DAY1_PLUS_TOGGLE
    Array.from(
      document.querySelectorAll('button[data-bs-toggle="popover"]')
    ).forEach((popoverNode) => new Popover(popoverNode));
    // GTM
    window.dataLayer?.push({
      page: {
        pageType: "qbp",
        pageName: "ctl|account|qbp|accountnumber",
      },
    });

    window.dataLayer?.push({
      event: 'qbp_identify',
      flow:{
        name: 'qbp',
        step: 'identify_page'
      },
    });
  },
  computed: {
    // to enable and disable next button
    nextButtonCheck() {
      if (this.state.number == null || this.state.number == "" || this.state.zip == null || this.state.zip == "") {
        return true
      } else {
        return false
      }
    }
  }
};
</script>

<style scoped>
  #norton-logo {
    background-image: url(../../assets/images/mya_paybill_sprite_blue.png);
    background-repeat: no-repeat;
    background-position: -40px -335px;
    height: 70px;
    width: 110px;
    display: inline-block;
    margin: 0;
  }
  #embargoId {
    background: url(../../images/global-alert-icon-2.svg) no-repeat 1px 1px;
    background-size: 36px 30px;
    padding: 2px 15px 3px 38px;
    color: #d0021b;
    font-size: 14px;
    line-height: 1.5;
  }
  #outsideLink {
    color: #0047bb;
    font-size: 14px;
    line-height: 1.5;
  }
  #outsideLinkURL {
    text-decoration: none;
  }
  #RetrieveAmountNumberLink {
    text-decoration: none;
    display: flex;
    padding-left: 1.25rem;
    font-size: 14px;
    padding-top: 1rem;
  }
  #signintomyaccount {
    text-decoration: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  @media(max-width: 767px) {
    #norton-logo {
  		margin: 0;
  	}
  }

button[aria-disabled="true"] {
    background-color: #fff;
    border-color: #fff;
    color: #14161A99;
    opacity: 1.0;
    pointer-events: none;
}
#dssp-intro-wrapper h1 {
	color: #14161A;
  font-size: 36px;
	letter-spacing: 0px;
  margin-bottom: 16px;
	opacity: 1;
  padding: 0;
}
#dssp-intro-wrapper h2 {
    color: #14161A;
    font-size: 24px;
    letter-spacing: 0.24px;
    opacity: 1;
    margin-bottom: 0;
}
.field-radiobutton-container {
  margin-bottom: 1rem;
}
.field-radiobutton:first-of-type {
  margin-bottom: 1rem;
}
.field-radiobutton-container label {
  margin-left: 0.5rem;
  font-size:16px;
}
.payBy{
    font-family: Maison Neue, sans-serif;
    font-weight: bold;
    text-align: start;
    margin-bottom: 0.25rem;
}
.filter-blue {
  filter: invert(17%) sepia(100%) saturate(2799%) hue-rotate(213deg) brightness(88%) contrast(103%);
}
.margin-18 {
  margin-top: 18px;
  margin-bottom: 18px;
  width: 368px;
  display: inline-block;
}
h4 {
  display: flex;
  flex-direction: row;
}
h4:before, h4:after{
  content: "";
  flex: 1 1;
  margin: auto;
  border: 1px solid #707070;
  opacity: 0.2;
}
h4:before {
  margin-right: 10px
}
h4:after {
  margin-left: 10px
}
.sign-in {
  top: 525px;
  left: 456px;
  width: 368px;
  height: 36px;
  border: 1px solid #0047BB;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0px;
  color: #0047BB;
  display: inline-block;
  float: none;
}
.sign-in:hover {
  border: 1.2px solid #0047BB;
}
.label-zip {
  position: absolute;
  left: 10px;
  top: -8px;
}
.input-placement {
  position: relative;
  margin-top: 1rem;
}
.pad-label {
    padding-left: 12px;
  }
.account-struct {
  width: 400px;
  margin: 0 auto;
}
@media(min-width: 768px) {
  .width-input {
    width: 368px;
  }
  .account-error-input {
    width: 368px;
    padding-left: 0;
  }
  .pad-label {
    padding-left: 0;
  }
}
@media(min-width: 1824px) {
  .width-input {
    width: 438px;
  }
}
@media(min-width: 420px) {
  .orchestrator-error {
    width: 420px;
    margin: 0 auto 20px;
  }
}
</style>