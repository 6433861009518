<template>
  <div class="root">
    <div v-if="this.routeName == 'ScheduleToPayPurl'" class="row" id="dssp-intro-wrapper">
      <h2 class="text-start">Scheduled Payments Set Up</h2>
      <h3 class="text-start" data-test="messageHead">
        Setting up Scheduled Payments is easy. All you need is the payment source you wish to use.
      </h3>
    </div>

    <div v-if="this.routeName == 'purlPaymentArrangement'" class="row" id="dssp-intro-wrapper">
      <h2 class="text-start">Make Payment Plans</h2>
      <h3 class="text-start" data-test="messageHead">
        This option is available to customers who need additional time to pay their bill.
      </h3>
    </div>

    <div v-if="this.routeName == 'purlpaperbilling'" class="row" id="dssp-intro-wrapper" data-test="purlpaperbilling">
      <h2 class="text-start">Paperless Billing</h2>
      <h3 class="text-start" data-test="messageHead">
        With paperless billing, you access the same information that would appear on a printed bill, but without all the paper clutter.
      </h3>
    </div>

    <div v-if="this.routeName == 'purlsetupAutoPay'" class="row" id="dssp-intro-wrapper" data-test="purlsetupAutoPay">
      <h2 class="text-start">AutoPay Set Up</h2>
      <h3 class="text-start" data-test="messageHead">
        Setting up AutoPay is easy. All you need is the payment source you wish to use.
      </h3>
    </div>
    <div v-if="!this.paperLessBillingBrim">
      <div v-if="this.Iframe != 'errorIframe'"
            class="col-12"
            id="grid1" style="padding: 0; overflow: hidden">
            <iframe
              style="border:none; height: 950px; width: 100%; overflow: hidden;"
              :onload="this.onPurlIframResolutionChange" :src="this.Iframe"
              title="purlIframe"
            ></iframe>
      </div>
    </div>
    <div v-if="this.brimSuccess" class="brim-success">
      <img alt="success icon" src="../../images/billdisputesuccess.svg" />
      <p>Paperless Billing Enrollment Successfull!!</p>
    </div>
    <div v-if="this.paperLessBillingBrim">
      <div class="brim-purl" v-if="this.brimError">
        <form  @submit.prevent="submit" role="form">
          <div class="row justify-content-center">
            <div class="col-12 account-error-input" v-if="v$.purlEmailAddress.$error">
              <label
              class="d-inline-block text-start text-truncate col-12 form-error-message"
              :class="{ 'text-danger': v$.purlEmailAddress.$error }"
              for="number">
              Email Paperless Billing / receipt to: <span>*</span>
              </label>
            </div>
            <div class="col-12" id="phone-account-label-wrapper">
              <div class="form-floating form-group">
              <input
                class="form-control"
                :class="{ 'is-invalid': v$.purlEmailAddress.$error }"
                type="text"
                id="number"
                aria-required="true"
                ref="accountRef"
                v-model="state.purlEmailAddress"
                placeholder=" "
                maxlength="101"
                v-on:keyup.enter="submitForm()"
              />
              <label
                for="number"
                class="d-inline-block text-start text-truncate form-label col-12"
                :class="{ 'text-danger': v$.purlEmailAddress.$error }"
              >
              Email Paperless Billing / receipt to: <span>*</span>
              </label>
              </div>
            </div>
            <div class="terms-checkbox">
              <input type="checkbox" id="terms" v-model="terms" v-on:change="checkboxChange()">
              <span for="terms"><a :href="TermsandConditions" target="_blank">I accept to these Terms and Conditions</a></span>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
              type="submit"
              id="next-button"
              class="btn btn-primary btn-lg text-center"
              :aria-disabled="this.nextButtonCheck"
              @click="submitForm"
              >Next</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    
    <div v-if="this.Iframe === 'errorIframe' && this.errorMessage === 'errorSession' ">
      <p><IframeError /></p>
    </div>
    <div v-if="this.errorMessage === 'serviceError'">
      <p><ServiceError/></p>
    </div>
    <div v-if="this.errorMessage === 'serverError'">
      <p><ServerError/></p>
    </div>
    
    <div v-if="this.errorMessage === 'expiredToken'">         
      <Transition name="toast">
        <div v-if="show" class="custom-block fade-in-image">
          <div class="wrapper">
            <img src="../../images/error_icon.png" class="iframe-error-margin" alt="error icon"/>
          </div>
          
          <p class="font-style"> This link has expired Please <a href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" id="epwf_10017_chatwithus" data-galink-type="chat">chat with us</a> to get a new link sent to you.</p>
        </div>      
      </Transition>      
    </div>
  </div>

</template>
<script>
import useValidate from "@vuelidate/core";
import {
  required,
  email,
  or,
} from "@vuelidate/validators";
import { reactive, computed, ref } from "vue";
  import IframeError from "../ErrorMessages/IframeError";
  import ServiceError from "./errors/404Error.vue"
  import ServerError from "./errors/500Error.vue"
  import { SET_DATA } from "../../store/storecontants";
  import axios from "axios";
  import constants from "../../js/constants";
  import $ from 'jquery';
  import links from "../../js/links";
  export default {
    components: {
      IframeError,
      ServiceError,
      ServerError,
    },
    data() {
      return {
        iframe: {
          src: "",
        },
        purliframeHeight: 800,
        purlIframechange: 0,
        errorMessage: null,
        Iframe: null, 
        routeName:'',
        show:false,
        singleAccount: "",
        termsCheck: null,
        brimOn: false,
        paperLessBillingBrim: false,
        brimSuccess: false,
        brimError: true,
        TermsandConditions: links.TermsandConditions
      };
    },
    setup() {
      const state = reactive({
        purlEmailAddress: null
      });
      // validation
      const rules = computed(() => {
        return {
          purlEmailAddress: {
            required,
            or: or(
              email,
            ),
          },
        };
      });
      const v$ = useValidate(rules, state);
      return {
        state,
        v$,
      };
    },
    methods: {
      checkboxChange(){
        if(this.termsCheck) {
          this.termsCheck = false;
        } else {
          this.termsCheck = true;
        }
      },
      submitForm() {
        this.v$.$validate();
        if (!this.v$.$error) {
          this.submitPaperLess();
        }
      },
      async submitPaperLess() {
        this.customerEmail = this.state.purlEmailAddress;
        let params = {
            billingAccountNumber: this.billingAccountNumber,
            emailAddress: this.customerEmail,
            userId: this.userId
        };
        let resData = '';
        try{
          resData = await axios.post(constants.SetPaperLessBilling, params);
          if(resData.data.pbResponse && resData.data.pbResponse.responseInfo.transactionStatus === 'S'){
            this.brimSuccess = true
            this.brimError = false
          }else if (resData.data.pbResponse && resData.data.pbResponse.responseInfo.transactionStatus === 'F'){
            this.errorMessage = "serverError"
            this.brimSuccess = false
            this.brimError = false
          }else if(resData.data.errorInfo.errorMessage) {
            this.errorMessage = "serverError"
            this.brimSuccess = false
            this.brimError = false
          }else {
            this.errorMessage = "serverError"
            this.brimSuccess = false
            this.brimError = false
          }
        }catch(error){
          if(error.response){
            if(error.response.status === 404) {
              this.errorMessage = "serverError"
              this.brimSuccess = false
              this.brimError = false
            }else{
              this.errorMessage = "serverError"
              this.brimSuccess = false
              this.brimError = false
            }
          }
        }
      },
      async getIframe(){
        const ipsTokenCd = this.$route.query.ipsTokenCd != null ? this.$route?.query?.ipsTokenCd : this.$route?.params?.ipsTokenCd;
        if (ipsTokenCd === undefined ||ipsTokenCd === null || ipsTokenCd.trim() === "") {
          return null;
        }
        
        const params = {
              ipsTokenCd: ipsTokenCd,
        };
        let endpoint = '';
        let res = '';
        let gtmPageName = '';
        let gtmChatbotName = '';
        if(this.routeName === 'ScheduleToPayPurl'){
          endpoint = constants.SchedulePaymentURL;
          gtmPageName = 'ctl|account|purl|setup_scheduledpayment';
          gtmChatbotName = 'setup_scheduledpayment';
        }
        if(this.routeName === 'purlPaymentArrangement'){
          endpoint = constants.PaymentArrangementURL;
          gtmPageName = 'ctl|account|purl|setup_paymentarrangement';
          gtmChatbotName = 'setup_paymentarrangement';
        }
        if(this.routeName === 'purlpaperbilling'){
          endpoint = constants.PaperlessBillingURL;
          gtmPageName = 'ctl|account|purl|paperless_billing';
          gtmChatbotName = 'setup_paperlessbilling';
        }
        if(this.routeName === 'purlsetupAutoPay'){
          endpoint = constants.SetAutoPayURL;
          gtmPageName = 'ctl|account|purl|setup_autopay';
          gtmChatbotName = 'setup_autopay';
        }
        // GTM TAGGING - START
        window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'page': {
              'pageType': 'pa',
              'pageName': gtmPageName
            }
        });
        $(document).ready(function(){
            $("#autoPayPurl_chat").click(function(){ 
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
              'event': 'Watson_Chatbot',
              'flow': {
              'name': gtmChatbotName,
              'step': gtmPageName + '|system_error_msg|link|chat_with_us'
              }	
              }); 
            });
        });
        // GTM TAGGING - END
        try{
            res = await axios.get(endpoint,{ params });
            this.show = false;
            if(this.routeName === 'ScheduleToPayPurl'){
              this.Iframe = res.data.epwfSession.sessionURL
              if(res.data.errorInfo != null || res.data.epwfErrorReturned){
              this.Iframe = res.data.epwfSession.sessionURL
              return res.data.epwfSession.sessionURL
              }
              else{
                let attributesData = new Map()
                this.singleAccount = JSON.stringify(
                  res.data.accountNumber
                )
                const iFrame = JSON.stringify(res.data.epwfSession.sessionURL)
                if (res.data.account !== null) {
                  attributesData.set("accountNumber", JSON.stringify(res.data.account.accountNumber));
                  attributesData.set("emailAddressOfRecord", JSON.stringify(res.data.account.emailAddressOfRecord));
                  //acod
                  attributesData.set("emailAddress", JSON.stringify(res.data.account.emailAddressOfRecord))
                  attributesData.set("firstName", JSON.stringify(res.data.account.acodFirstName))
                  attributesData.set("lastName", JSON.stringify(res.data.account.acodLastName))
                  attributesData.set("AcodAccountNumber", JSON.stringify(res.data.account.accountNumber))
                } else if (res.data.account == null) {
                  attributesData.set("accountNumber", JSON.stringify(res.data.accountNumber));
                  attributesData.set("emailAddressOfRecord", JSON.stringify(res.data.emailAddressOfRecord));
                  //acod
                  attributesData.set("emailAddress", JSON.stringify(res.data.emailAddressOfRecord))
                  attributesData.set("firstName", JSON.stringify(res.data.acodFirstName))
                  attributesData.set("lastName", JSON.stringify(res.data.acodLastName))
                  attributesData.set("AcodAccountNumber", JSON.stringify(res.data.accountNumber))
                }
                
                attributesData.set("selectedAccountNumber", this.singleAccount)
                attributesData.set(
                  "EpwfIframe",
                  JSON.stringify(res.data.epwfSession.sessionURL)
                )
                attributesData.set(
                  "status",
                  JSON.stringify(res.data.multiAccount)
                )
                attributesData.set(
                  "balanceDue",
                  JSON.stringify(res.data.adjustedAmount)
                )
                attributesData.set(
                  "paymentDate",
                  JSON.stringify(res.data.paymentDueDate)
                )
                attributesData.set("errorInfo", null);
                attributesData.set(
                  "prepaidAccount",
                  JSON.stringify(res.data.prepaidAccount)
                )
                attributesData.set(
                  "prepaidInfo",
                  JSON.stringify(res.data.prepaidSuspendedAccount)
                )
                attributesData.set("suspendedAccount", JSON.stringify(res.data.accountStatus));
                this.$store.dispatch(SET_DATA, { attributes: attributesData });
  
                this.$router.push({
                  name: "Confirm",
                  params: {},
                })
              }
              if(res.data.epwfSession.sessionURL === null){
                this.errorMessage = 'expiredToken'
                this.Iframe = 'errorIframe'
                this.show = true
                return null
              }
            }
            else if (this.routeName === 'purlPaymentArrangement'){
              if(res.data.paymentArrangementResponse.biller == "2" || res.data.paymentArrangementResponse.paymentArrangementIneligibleErrorCodes != null || (res.data.paymentArrangementResponse.paymentArrangementEligible == false && res.data.paymentArrangementResponse.status == 0 && res.data.paymentArrangementResponse.adjustedAmount == "0.00")) {
              this.Iframe = res.data.paymentArrangementResponse.iframeUrl
              return res.data.paymentArrangementResponse.iframeUrl
              }
              else{
                let attributesData = new Map();
                const accountEntered= JSON.stringify(res.data.accountEntered)
                const zipEntered= JSON.stringify(res.data.zipEntered)
                attributesData.set('accountEntered', accountEntered)
                attributesData.set('zipEntered', zipEntered);
                //acod
                attributesData.set("emailAddress", JSON.stringify(res.data.paymentArrangementResponse.emailAddressOfRecord))
                attributesData.set("firstName", JSON.stringify(res.data.paymentArrangementResponse.acodFirstName))
                attributesData.set("lastName", JSON.stringify(res.data.paymentArrangementResponse.acodLastName))
                attributesData.set("AcodAccountNumber", JSON.stringify(res.data.paymentArrangementResponse.ban))
                attributesData.set('data', JSON.stringify(res.data.paymentArrangementResponse))
                attributesData.set('isMultiAccount', false)
                this.$store.dispatch(SET_DATA, {attributes:attributesData})
                this.$router.push({
                  name: "PaymentArrngmtsOptions",
                  params: {},
                })
              }
            } else if ((this.routeName.includes('purlpaperbilling')) && (this.brimOn == 'true') ) {
              if(res.data.sessionDetails.sessionURL === null){
                this.errorMessage = 'expiredToken'
                this.Iframe = 'errorIframe'
                this.show = true
                return null;
              }else {
                this.paperLessBillingBrim = true;
                this.noError = true;
                if(res.data.customerDetails.customerEmail) {
                  this.state.purlEmailAddress = res.data.customerDetails.customerEmail;
                }
                this.billingAccountNumber = res.data.customerDetails.billingAccountNumber
                this.userId = "dssp"
              }
            }
            else {
              if(res.data.sessionDetails.sessionURL != null){
                this.Iframe = res.data.sessionDetails.sessionURL;
                return res.data.sessionDetails.sessionURL;
              }
              if(res.data.sessionDetails.sessionURL === null){
                this.errorMessage = 'expiredToken'
                this.Iframe = 'errorIframe'
                this.show = true
                return null;
              }
            }
            // this.errorMessage = "errorSession"
            // this.Iframe = 'errorIframe'
            }catch(error){
              console.log(error)
              if(error.response.status === 400){
                  this.errorMessage = "serviceError"
              }else {
                this.errorMessage = "serverError"
              }
              this.Iframe = 'errorIframe'
              return null;
            }
        return null;
      },
      onPurlIframResolutionChange() {
        if( this.purlIframechange == 1 ){
            this.purliframeHeight = 900;
        }else{
            this.purlIframechange++;
        }
      },
      
    },

    mounted() {
        this.termsCheck = null;
        this.routeName = this.$route.name;
        let attributesData = new Map();
        attributesData.set('showLoading', false);
        attributesData.set('ToastMessage', "Expired Token")
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
        this.brimOn = process.env.VUE_APP_BRIM;
        this.Iframe = this.getIframe().then(val => console.log(val));
        this.errorMessage = null;

        let gtmPageType = '';
        let gtmPageName = '';
        let gtmEventName = '';
        let gtmFlowStep = '';
        if (this.routeName == 'purlPaymentArrangement') {
          gtmPageType = 'pa';
          gtmPageName = 'ctl|account|pa|purl';
          gtmEventName = 'pa_purl_visit';
          gtmFlowStep = 'pa';
        } else if (this.routeName == 'ScheduleToPayPurl') {
          gtmPageType = 'qbp';
          gtmPageName = 'ctl|account|qbp|purl';
          gtmEventName = 'qbp_purl_visit';
          gtmFlowStep = 'qbp';
        } else if (this.routeName == 'purlpaperbilling') {
          gtmPageType = 'paperless_billing';
          gtmPageName = 'ctl|account|paperless_billing|purl';
          gtmEventName = 'pb_purl_visit';
          gtmFlowStep = 'paperless_billing';
        } else if (this.routeName == 'purlsetupAutoPay') {
          gtmPageType = 'autopay';
          gtmPageName = 'ctl|account|autopay|purl';
          gtmEventName = 'ap_purl_visit';
          gtmFlowStep = 'autopay';
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          page: {
            pageType: gtmPageType,
            pageName: gtmPageName,
          },
        });

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: gtmEventName,
          flow:{
            name: 'purl',
            step: gtmFlowStep
          },
        });
    },
    computed: {
    // to enable and disable next button
      nextButtonCheck() {
        if (this.state.purlEmailAddress && this.termsCheck) {
          return false;
        } else { 
          return true
        }
      }
    }
    
  };
</script>

<style scoped>
#norton-logo {
  background-image: url(../../assets/images/mya_paybill_sprite_blue.png);
  background-repeat: no-repeat;
  background-position: -40px -335px;
  height: 70px;
  width: 110px;
}
#embargoLink {
  color: #0047bb;
  font-size: 14px;
  line-height: 1.5;
}
#embargoLearnMoreLink {
  text-decoration: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.font-style{
  color: black;
  padding-left: 35px;
}
.custom-block {
margin: 28px 0;
padding: 10px 24px 1px 5px;
border-radius: 8px;
overflow-x: auto;
transition: color .8s,background-color .8s;
position: relative;
font-size: 14px;
line-height: 1.6;
font-weight: 500;
color: #0000008c;
border: 1px solid red;
}

.slide-fade-enter-active {
  transition: all 0.8s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(0.35, 0.35, 0.35, 0.35);
  transition-delay: 2s;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.fade-in-image {
   animation: fadeIn 5s; 
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.iframe-error-margin {
  margin: 0rem 0 1rem 0;
  display: flex;
  height: 25px;
  width: 25px;
}

.wrapper {
  position: absolute;
}
button[aria-disabled="true"] {
    background-color: #fff;
    border-color: #fff;
    color: #14161A99;
    opacity: 1.0;
    pointer-events: none;
}
input[type=checkbox] {
    position: static;
    width: 22px !important;
    height: 17px !important;
    margin-left: 0 !important
}
.terms-checkbox {
  margin-top: 20px;
}
.terms-checkbox span {
  padding-left: 8px;
  vertical-align: text-bottom;
}
#next-button {
  margin-top: 10px;
}
.brim-success {
  padding: 20px;
  background-color: #0047BB1F;
  box-shadow: 0px 1px 4px #000029;
  border: 1px solid #0047BB1F;
  border-radius: 8px;
  position: relative;
}
.brim-success p {
  margin-bottom: 0;
  padding-left: 50px;
  text-align: left;
}
.brim-success img{ 
  position: absolute;
  top: 12px;
}
#dssp-intro-wrapper h3 {
  font-size: 16px;
}
</style>