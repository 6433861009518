<template>
    <div v-if="isDataAvailable">
        <div class="container" id="post-login-bill-success">
            <div class="row print-container">
                <h2 class="text-start text-placement">Thanks for your payment!</h2>
                <h2 class="text-start text-placement">Confirmation number: {{paymentConfirmationNumber}}</h2>
                <div class="text-color-black" v-if="showPaymentBlock">
                    <h3 class="text-start text-placement text-color-black"><strong>Payment Details:</strong></h3>
                    <div class="row text-start">
                    <div class="col-xs-3 col-sm-3">Bill Payment: </div>
                    <div class="col-xs-9 col-sm-9">$ {{paymentAmount}}</div>
                    </div>
                    <div class="row text-start">
                        <div class="col-xs-3 col-sm-3">Convenience Fee: </div>
                        <div class="col-xs-9 col-sm-9">$ {{convenienceFee}}</div>
                    </div>
                    <div class="row text-start">
                        <div class="col-xs-3 col-sm-3">Total Payment: </div>
                        <div class="col-xs-9 col-sm-9">$ {{totalPayment}}</div>
                    </div>
                    <div class="row text-start">
                        <div class="col-xs-3 col-sm-3">{{ this.displayPaymentDateLabel }}: </div>
                        <div class="col-xs-9 col-sm-9">{{paymentDate}}</div>
                    </div>
                    <div class="row text-start">
                        <div class="col-xs-3 col-sm-3">Payment Method: </div>
                        <div class="col-xs-9 col-sm-9">{{paymentMethod}}</div>
                    </div>
                </div>
                <p class="text-start text-placement text-color-black">An email has also been sent to <strong>{{emailAddress}} </strong> with your receipt.</p>
                <p class="text-start text-placement" id="printButton"><a tabindex="0" aria-label="Print this page for your records." class="print-text-color" @click="printPage" @keydown.enter="printPage">Print this page for your records.</a></p>
            </div>
            <div id="norton-logo" ><img src="../../images/norton.png" alt="nortonLogo" /></div>
        </div>
    </div>
    <div v-if="!isWaiting && !isDataAvailable">
        <h2>Sorry, We could not find the page which you have requested.</h2>
    </div>
    <div v-if="isWaiting && !isDataAvailable">
        <h3>Please wait While we are fetching the data for you.</h3>
    </div>
</template>
<script>
import constants from '../../js/constants'
import {GET_DATA} from '../../store/sessioncontants'
import { SET_DATA as local_setdata} from "../../store/storecontants";
import axios from 'axios'
import links from '../../js/links'

export default {
    name: 'postBillSuccess',
    data() {
        return {
            accountNumber: '',
            confirmationNumber: null,
            paymentConfirmationNumber: null,
            emailAddress: null,
            paymentAmount: '',
            convenienceFee: '',
            totalPayment: '',
            paymentDate: '',
            paymentMethod: '',
            showPaymentBlock: false,
            isDataAvailable: false,
            isWaiting: false,
            AuthSignOut: constants.AzureLogout,
            PrivacyNoticeURL: links.PrivacyNoticeURL,
            displayPaymentDateLabel: '',
            brimOn: false
        }
    },
    mounted() {
        this.brimOn = process.env.VUE_APP_BRIM;
        if(this.brimOn == 'true'){
            this.confirmationNumber = this.$store.getters[GET_DATA]('confirmationNumber') ? this.$store.getters[GET_DATA]('confirmationNumber') : 'NA'
        } else {
            this.confirmationNumber = this.$route.query.PaymentID ? this.$route.query.PaymentID : null
        }
        this.emailAddress = this.$store.getters[GET_DATA]('emailAddress') ? this.$store.getters[GET_DATA]('emailAddress') : 'NA'
        this.accountNumber = this.$store.getters[GET_DATA]('accountNumber') ? this.$store.getters[GET_DATA]('accountNumber') : 'NA'
        window.dataLayer?.push({
            event: 'lbp_success',
            flow: {
                name: 'logged in bill pay',
                step: 'bill pay success'
            },
        });
        this.getPostLoginPaymentConfirmationDetails()
    },
    methods: {
        async getPostLoginPaymentConfirmationDetails() {
            let localData = new Map();
            localData.set('showLoading', true);
            this.$store.dispatch(local_setdata, {attributes:localData});
            this.isWaiting = true
            const params = {
                accountNumber: this.accountNumber,
                paymentConfirmationNumber: this.confirmationNumber,
                emailAddress: this.emailAddress
            }
            try {
                const successfulResult = await axios.post(constants.PostLoginBillPaymentSuccessURL, params)
                if(successfulResult.data.paymentConfirmationNumber && successfulResult.data.paymentConfirmationNumber !== null && successfulResult.data.paymentConfirmationNumber !== '') {
                    this.isDataAvailable = true;
                    this.isWaiting = false;
                    this.paymentConfirmationNumber = successfulResult.data.paymentConfirmationNumber;
                    if(this.emailAddress !== null && 
                    successfulResult.data.paymentHistory.amountPaid !== null && 
                    successfulResult.data.paymentHistory.convenienceFeeAmt !== null &&
                    successfulResult.data.paymentHistory.totalPaymentAmt !== null &&
                    successfulResult.data.paymentHistory.datePaid !== null &&
                    successfulResult.data.paymentHistory.paymentMethod !== null &&
                    this.emailAddress !== '' && 
                    successfulResult.data.paymentHistory.amountPaid !== '' &&
                    successfulResult.data.paymentHistory.convenienceFeeAmt !== '' &&
                    successfulResult.data.paymentHistory.totalPaymentAmt !== '' && 
                    successfulResult.data.paymentHistory.datePaid !== '' &&
                    successfulResult.data.paymentHistory.paymentMethod !== '') {
                        this.paymentAmount = Number(successfulResult.data.paymentHistory.amountPaid).toFixed(2)
                        this.convenienceFee = Number(successfulResult.data.paymentHistory.convenienceFeeAmt).toFixed(2)
                        this.totalPayment = Number(successfulResult.data.paymentHistory.totalPaymentAmt).toFixed(2)
                        this.paymentDate = successfulResult.data.paymentHistory.paymentProcessDate
                        this.paymentMethod = successfulResult.data.paymentHistory.paymentMethod + ' account ending in ' + successfulResult.data.paymentHistory.maskedPaymentAccountID
                        const dateObj = new Date();
                        const currMonth = dateObj.getMonth() + 1;
                        const currentDate = currMonth + "/" + dateObj.getDate() + "/" + dateObj.getFullYear();
                        const newDateObj = new Date(currentDate)
                        const dateOfPayment = new Date(this.paymentDate)
                        if (dateOfPayment > newDateObj) {
                            this.displayPaymentDateLabel = 'Payment Scheduled Date'
                        } else {this.displayPaymentDateLabel = 'Payment Date'}
                        this.showPaymentBlock = true
                    } else {
                        this.showPaymentBlock = false
                    }
                } else {
                    this.isDataAvailable = false
                    this.isWaiting = false
                }
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            } catch (error) {
                console.log(error)
                this.isWaiting = false
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
        },
        printPage() {
            var restorePage = document.body.innerHtml;
            var printContent = document.getElementById('post-login-bill-success').innerHtml;
            document.body.innerHtml = printContent;
            window.print();
            document.body.innerHtml = restorePage;
        },
        signOutUser() {
            localStorage.clear();
            sessionStorage.clear();
        }
    }
}
</script>
<style>
#norton-logo {
  height: 70px;
  width: 110px;
}
.text-placement {
    margin: 0.5rem 0 1rem 0;
}
.print-text-color {
    color: #0047bb;
    text-decoration: none !important;
    cursor: pointer;
}
.print-text-color:hover {
    color: #0047bb;
    font-weight: bold;
    cursor: pointer;
}
.text-color-black {
    color: #000;
    font-size: 1.1rem;
}  
h1.text-placement {
    font-size: 36px;
}

</style>