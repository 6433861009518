
import { defineComponent } from 'vue';
import links from '../../js/links';
import payArrngmtsRightBarVue from '../paymentArrngmts/payArrngmtsRightBar.vue';

export default defineComponent({
	name: 'PurlRightBar',
	props: {
		msg: String,
	},
	data () {
		return {
			PrivacyPolicyURL: links.PrivacyPolicyURL,
			OnlineSecurityURL: links.OnlineSecurityURL,
			LearnMoreAutoPayURL : links.LearnMoreAutoPayURL,
			MyaEntryPointURL : links.MyaEntryPointURL,
			PaymentMethodsURL: links.PaymentMethodsURL,
			ServiceRestoreURL: links.ServiceRestoreURL
		}
	},
	methods: {
		gtmLogging(event: string){
		payArrngmtsRightBarVue.methods?.gtmLogging(event);
		}
	}
});
