<template>
  <div id="qbp-exception-message" class="qbpMessageBox">
    <div class="qbpTextBox">
      <p id="simple_pay_p">
        This account is a CenturyLink SimplePay account. Please go to
        <a
          href="${conportHost}/InstaLink/existingCustomer/QC/toLogin/"
          id="qbp_simple_pay_accts"
          >CenturyLink SimplePay</a
        >
        to make payment on this account. Thank you for being a CenturyLink
        customer!
      </p>
    </div>
  </div>
</template>
<script>
import { pii } from "../../js/pii";

export default {
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "qbp_error",
      accountNumber: pii(this.accountNumber),
      errorName: "undefined error",
      flow: {
        name: "qbp",
        step: "payment flow",
      },
    });
  },
};
</script>

<style scoped>
.qbpTextBox {
  position: relative;
}
.qbpTextBox:before {
  content: "";
  position: absolute;
  background: url(../../images/icon-important-orange.svg) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
}
#simple_pay_p {
  padding-left: 40px;
}
</style>