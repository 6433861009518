<template>
  <div id="qbp-left-column" class="col-xs-12 col-sm-6 col-md-12">
    <div id="dssp-intro-wrapper" class="text-start">
      <h1 class="text-start " >Quick Bill Pay</h1>
      <h2 class="text-start " data-test="messageHead">Safe and secure one-time bill payment</h2>
    </div>
    <div class="text-start">
      <div>You're making a one-time payment for:</div>
      <div>
        <p class="text-start qbp-bold-black" id="qbp" data-test="accountNumber">{{this.accountNumber}}</p>
      </div>
      <div
        id="payBillDisclaimer"
        class="payBillDisclaimerCont qbpP2P_mt_0 qbpP2P_mb_0 qbpP2P_bb_none"
      >
        <div class="col text-start">
         
          <div class="pmtArr qbpP2P_Bold qbp-bold-black" data-test="paymentArragementHeader">
            You are currently on a payment plan.
          </div>
        </div>
      </div>
      

      <div>
        <div class="qbpP2P_Bold">
          <strong>Remaining Arrangement Amount :</strong>
        </div>
        <!-- if else statement on the value recieved from the AP1 -->
        
        <div  v-if="this.remainingAmt != null" id="qbpP2P_remainingArrAmt" class="qbpP2P_fs_40 qbp-bold-black ">${{twoDecimal(this.remainingAmt)}}</div>
        <div v-else>
          <span class="unavailable">temporarily unavailable</span>
        </div>
      </div>

      <hr class="qbpP2P_hr" />
      <div v-if="this.installments != null">
        <div
        class="row col-12 qbp-instalment">
          <table class="table table-borderless mb-0 ">
            <caption>Arrangement Schedule</caption>
            <thead>
              <tr class="sr-only">
                <th scope="col">Installment</th>
                <th scope="col">Installment amount</th>
                <th scope="col">Date/paid</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(installment, index) in installments"
              v-bind:installment="installment"
              v-bind:index="index"
              v-bind:key="installment.installAmount">
                <th scope="row"> <strong>Installment {{index+1}}:</strong>
                </th>
                <td v-if="installment.status=='Open'">
                  ${{twoDecimal(installment.installAmount - installment.coveredAmount)}}
                </td>
                <td v-else-if="installment.status=='Completed'">
                    Paid  ${{twoDecimal(installment.installAmount) }}
                </td>
                <td v-if="installment.status=='Open'">
                  Date:{{formatDate(installment.installDate)}}
                </td>
                <td v-else-if="installment.status=='Completed'">
                  Paid:{{formatDate(installment.statusDate)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      Installments need to be paid in addition to your current monthly amount on
      or before the installment due date.

      <hr class="qbpP2P_hr" />

      <!-- Check for current monthly amount  -->
      <div v-if="this.currMonthlyAmt > 0">
         <div class="qbpP2P_fs_16 qbpP2P_Bold">
          <strong> Current Monthly Amount : </strong>
         </div>

         <div class="currentMnthlyAmt">
         <div v-if="this.adjustedAmountAvailable" id="qbpP2P_totalBalOwed" class="qbpP2P_fs_40 qbpP2P_OtherAmtW3 qbp-totalBalOwed">${{twoDecimal(currMonthlyAmt)}}</div>
         <div v-else class="qbp-totalBalOwed">temporarily unavailable</div>


         <div class="paymentDueDate "> Payment Due by
            <div v-if="this.paymentDueDate !=null" >{{paymentDueDate}}</div>
            <div v-else>Temporarily unavailable</div>
         </div>
         </div>
      </div>
      <hr class="qbpP2P_hr" />
      <div>
        <div class="qbpP2P_fs_16 qbpP2P_Bold">
          <strong>Total Balance Owed:</strong>
        </div>
        <!-- Check for adjusted amount -->
        <div v-if="this.adjustedAmountAvailable" class="qbp-totalBalOwed-payment">
          <div id="qbpP2P_totalBalOwed" class="qbpP2P_fs_40 qbpP2P_OtherAmtW3 qbp-totalBalOwed">
            ${{twoDecimal(totalBalanceOwed)}}
          </div>
        
          <div class="qbpP2P_display_inline_block qbpP2P_w_8 qbpP2P_v_align_bottom qbp-payment-due">
            Includes remaining payment plan and other charges
          </div>
        </div>
        <div v-else>Temporarily unavailable</div>
      
      
      </div>

      <hr class="qbpP2P_hr" />
      <div class="col sm-8">
        <div v-if="(this.Iframe) != '' " class="col-lg-6" id="grid1">
          <iframe
            width="100%"
            style="height: 850px; width: 300px; border: none; overflow: hidden;"
            :style="{height: qbpIframeHeight + 'px'}"
            :onload="this.onQbpIframeChange"
            :src="this.Iframe" title="qbpIframe"
          ></iframe>
        </div>
        <div v-else>
           <p><Iframe title="noIframe"/> </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Iframe from '../ErrorMessages/IframeError';
import { GET_DATA, SET_DATA } from "../../store/storecontants";
import store from "../../store";
import { pii } from "../../js/pii";

export default {
  name: 'QBP_Promise_To_Pay',
   components :{
    Iframe
  },
  data() {
    return {
     installments : [],
     Iframe: null ,
     totalBalanceOwed: null,
     accountNumber: null,
     currMonthlyAmt : null,
     remainingAmt : null,
     adjustedAmountAvailable : null,
     paymentDueDate: null,
     qbpIframeChange: 0,
     qbpIframeHeight: 850,
  }
  },
  methods :{
    onQbpIframeChange(){
      if( this.qbpIframeChange == 1 ){
        this.qbpIframeHeight = 1300;
      }else{
        this.qbpIframeChange++;
      }
    },
    formatDate(dateString){
      const date = dateString.split("-");
      return (date[1]+ '/' + date[2]  + '/' + date[0]);
    },
    twoDecimal(twodecimal) {
      let num = parseFloat(twodecimal);
      let with2Decimals = num.toFixed(2)
      return with2Decimals
    }

  },

  mounted(){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'qbp_promise_to_pay',
      flow:{
        name: 'qbp',
        step: 'promise_to_pay_page'
      },
    });

    let attributesData = new Map();
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    this.installments =  JSON.parse(this.$store.getters[GET_DATA]("installmentsArray"))
    this.Iframe = JSON.parse(this.$store.getters[GET_DATA]("EpwfIframe"))
    this.totalBalanceOwed = JSON.parse(this.$store.getters[GET_DATA]("amountOwed"))
    this.remainingAmt = JSON.parse(this.$store.getters[GET_DATA]("remainingAmount"));
    this.accountNumber =  JSON.parse(this.$store.getters[GET_DATA]("selectedAccountNumber"))
    this.currMonthlyAmt = JSON.parse(this.$store.getters[GET_DATA]("currentMonthlyAmount"))
    this.adjustedAmountAvailable = JSON.parse(this.$store.getters[GET_DATA]("adjustedAmountAvl"))
    if(!this.adjustedAmountAvailable){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "qbp_error",
        accountNumber: pii(this.accountNumber),
        errorName: "amount unavailable",
        flow: {
          name: "qbp",
          step: "payment flow",
        },
      });
    }
    this.paymentDueDate = JSON.parse(this.$store.getters[GET_DATA]("paymentDate"))
    attributesData.set("totalBalanceOwed", JSON.stringify(this.totalBalanceOwed));
    attributesData.set('showLoading', false);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
  },
}


</script>

<style scoped>

#qbp-intro-wrapper {
  position: relative;
  font-family: MaisonNeue-Light, sans-serif;
}
.importantMessage {
  background-image: url(../../assets/images/icon-important.svg);
  background-repeat: no-repeat;
  height: 32px;
  width: 32px;
  position: absolute;
}
#qbp{
  color: black;
    font-size: 25px;
    line-height: 1;
    margin-top: 5px;
   
}

.qbpP2P_mt_0 {
  margin-top: 0 !important;
}
.qbpP2P_mb_0 {
  margin-bottom: 0 !important;
}
.qbpP2P_bb_none {
  border-bottom: none !important;
}
.qbpP2P_padding {
  padding-left: 1.5rem !important;
  text-indent: 0 !important;
  background-position: 0 0.5rem;
}
.qbpP2P_fs_init {
  font-style: initial !important;
}
.qbpP2P_fs_16 {
  font-size: 16px;
}
.qbpP2P_fs_40 {
  font-size: 40px;
}
.qbpP2P_space_1 {
  margin: 1rem;
}

.qbpP2P_InstlW1 {
  display: inline-block;
  width: 6rem;
}
.qbpP2P_InstlW2 {
  display: inline-block;
  width: 10rem;
  margin-right: 1.5rem;
}
.qbpP2P_FR {
  float: right;
}
#qbpP2P_InstallmentsULOverride {
  margin-bottom: 1rem;
  list-style-type: none;
  padding-left: 2px;
}
.qbpP2P_OtherAmtW3 {
  display: inline-block;
  width: 11rem;
}
.qbpP2P_display_inline_block {
  display: inline-block;
}
.qbpP2P_w_8 {
  width: 8rem;
}
.qbpP2P_v_align_bottom {
  vertical-align: bottom;
}
.qbpP2P_hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top: 1px solid #ccc;
}
.qbp-bold-black {
  color: #000;
  font-weight: bold;
}
strong {
  color: #000;
  font-weight: bold;
}
.qbp-totalBalOwed-payment {
  display: flex;
}
.qbp-totalBalOwed {
  flex-grow: 5;
  color: #000;
  font-weight: bold;
}
.currentMnthlyAmt{
  display:flex;
}
.paymentDueDate{
  flex-grow: 7;
}     
.qbp-payment-due {
  flex-grow: 7;
}
.qbp-instalment {
  padding-left: 5px;
}
#dssp-intro-wrapper h1 {
  color: #14161A;
  font-size: 36px;
  letter-spacing: 0px;
  margin-bottom: 16px;
  opacity: 1;
}
#dssp-intro-wrapper h2 {
  color: #14161A;
  font-size: 24px;
  letter-spacing: 0.24px;
  opacity: 1;
}
.qbp-instalment table {
  caption-side: top;
}
.qbp-instalment table caption {
  color: #000;
  font-weight: bold;
  font-size: 16px;
  padding: 8px;
}
#payBillDisclaimer div.pmtArr {
  background-position: 0px 9px;
}
</style>
