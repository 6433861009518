<template>
  <div class="row ms-1" id="qbp-intro-wrapper">
    <h1 class="text-start" data-test="messageHead">Payment Plans</h1>
    <p class="text-start">Get extra time to pay your bill.</p>
  </div>


  
  <div id="paymentError" >
    <div class="outside">
      <div class="inside">
        <div class="green-checkmark-round-icon">
          <h2 data-test="messageData">Your transaction was cancelled - no payment was submitted.</h2>
          <p>Thanks for stopping by.</p>

          Would you like to
          <a @click="startOverAgain()" v-on:keyup.enter="startOverAgain()" id="startoverandtryagain" tabindex="0">
            start over and try again
            </a
          >?
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RESET, SET_DATA } from "../../store/storecontants";
import store from "../../store";
export default { 
  name: "PACancel",
  
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_cancel',
      flow:{
        name: 'pa',
        step: 'cancel_page'
      },
    });

    let attributesData = new Map();
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    if(!window.location.hash) {
      window.parent.location = "/pa/payment/cancel#loaded";
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
    }
    attributesData.set('showLoading', false);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
  },

  methods: {
    startOverAgain () {
      if((window.location.href.indexOf('pa') !== -1 )){
        if(window.location.href.indexOf('pa/multiAccount') !== -1) {
          this.$router.push("/pa/multiAccount");
        }else {
          this.$router.push("/pa/identify");
          this.$store.dispatch(RESET);
          history.pushState(null, null, location.href);
        }
      }
    },
  },
  
}
</script>

<style scoped>
#startoverandtryagain {
  color: #0d6efd;
  cursor: pointer;
}
#startoverandtryagain:hover {
  color: #0a58ca;
}
#qbp-intro-wrapper h1 {
  font-weight: normal;
  font-size: 32px;
}
</style>

        
   