<template>
  <div>
    <PAOptions/>
  </div>
  <link rel="stylesheet" type="text/css" :href="this.hcdeTokenTheme"/>
  <link rel="stylesheet" type="text/css" :href="this.hcdewalletTheme"/>
</template>

<script>
  import PAOptions from '../components/paymentArrngmts/PAOptions.vue'
  import links from '../js/links';
  export default {
    name: 'PaymentArrngmtsIdentify',
    components: {
      PAOptions
    },
    data() {
      return {
        isBrimOn: false,
        hcdeTokenTheme: null,
        hcdewalletTheme: null
      }
    },
    mounted() {
      this.isBrimOn = process.env.VUE_APP_BRIM;
      if(this.isBrimOn == 'true'){
        this.hcdeTokenTheme = links.hcdeTokenTheme;
        this.hcdewalletTheme = links.hcdewalletTheme;
      }
    }
  }
</script>

<style scoped>
</style>