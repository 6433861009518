<template>
    <!-- Top box displaying the Non payment error or notification -->
    <div class="container">
        <div class="pay-balance-notif-box">
            <div class="title-box" data-test="WalledGardenNotif"><img class="img-yellow" src="../../images/warning.svg" alt="Payment-notification"/><p class="notification-head">Your internet service has been suspended.</p></div>    
            <div class="notif-box-text">
                <span v-if="this.accountType === 'prepaid'">
                    We have not received payment for your CenturyLink service. Please pay your balance to restore your service. We must receive full payment within 30 days of the initial failed payment, or your internet service will be disconnected.
                </span>
                <span v-if="this.accountType === 'postpaid'">
                    We have not received payment for your CenturyLink service, and your past due balance has exceeded the allowable amount. To restore your internet service, a payment is required.
                </span>
            </div>
        </div>
        <div class="title-align">
            <h2 class="marg-2" data-test="WalledGardenHeader">Suspended Service</h2>
            <h3>Sign in to make a payment, or use Quick Bill Pay without signing in.</h3>
        </div>
    </div>

    <!-- Bottom blue area where content for prepaid and postpaid along with an image is getting displayed -->
    <div class="row-margin">
        <div class="notif-container">
            <div class="row">
                <div class="col-12 text-start row-notif">
                    <h2 class="mb-2 susp">SUSPENDED SERVICES</h2>
                    <!-- Condition for postpaid customer. Rest of the content is common for both prepaid and postpaid customers -->
                    <div>The quickest way to make a payment is to submit it online.</div>
                    <div class="marg-1">
                        <div>Sign in to My CenturyLink or use the My CenturyLink app to pay your bill to use a saved payment method.</div>
                        <div class="registerStyle mt-3">
                            <button type="button" class="btn btn-primary" @click="tagging('NP Sign In to MyCenturyLink')"><a :href="CtlSignInURL" class="sign-in-align">Sign In to MyCenturyLink</a></button>
                        </div>
                        <div>Use Quick Bill Pay to pay without signing in.</div>
                        <div class="registerStyle mt-3">
                            <button type="button" @click="qbpButton()" class="btn btn-primary">Quick Bill Pay</button>
                        </div>
                        <div v-if="this.accountType === 'postpaid'" class="marg-1">
                        <div><span class="text-weight">Please note-</span>
                            A processing fee will be assessed if you make a one-time payment by credit or debit card. You can avoid this fee by setting up AutoPay or by paying through your bank account.</div>
                        </div>
                        You can expect your service to be restored within 24 hours after your payment is processed.  If you have already made a payment, reboot your modem & re-launch your browser to get back online.
                    </div>
                    <div class="marg-1">
                        Thank you for choosing CenturyLink - we value you as our customer.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal pop-up for Walled garden where we show masked account detail and let user authenticate themselves or cancel the process -->
    <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-box-style">
                    <h3>Please confirm your service address to continue.</h3>
                    <div class="marg-1">To pay your balance and re-enable services, please verify the service address below.</div>
                    <div>{{ this.maskedAccNum }}</div>
                    <div>Service Address:</div>
                    <div>{{ this.serviceAddress }}</div>
                    <div class="modalBtns marg-1">
                        <button type="button" class="btn btn-primary btn-sm text-center" data-dismiss="modal" @click="authenticate(this.isDataAvail)">Confirm</button>
                        <button type="button" class="btn-sm text-center go-back-btn" data-dismiss="modal" @click="modalClose()">Not my account</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import axios from 'axios';
import constants from '../../js/constants';
import links from '../../js/links';
import { GET_DATA } from "../../store/sessioncontants";
import { SET_DATA } from "../../store/storecontants";
import { pii } from "../../js/pii";
import quickBillPay from "../../js/quickBillPay";

export default {
    name: "WGNonpaid",
    data() { 
        return {
            CtlSignInURL: links.CtlSignInURL,
            isDataAvail: false,
            accountNumber: '',
            zipCode: '',
            serviceAddress: '',
            telephoneNumber: '',
            userClickedConfirm: false,
            accountType: '',
            customerIdentificationResponse: [],
            maskedAccNum: '',
            wgModal: null
    }},
    mounted() {
        this.wgModal = new Modal(document.getElementById('myModal'),{
            backdrop: 'static',
            keyboard: false
        });
        let attributesData = new Map();
        attributesData.set('showLoading', true);
        this.$store.dispatch(SET_DATA, { attributes: attributesData });
        this.telephoneNumber = this.$store.getters[GET_DATA]("telephoneNumber") ? this.$store.getters[GET_DATA]("telephoneNumber") : '';
        this.accountType = this.$store.getters[GET_DATA]("accountType") ? this.$store.getters[GET_DATA]("accountType") : 'postpaid';
        this.customerIdentificationResponse = this.$store.getters[GET_DATA]("customerIdentificationResponse") ? this.$store.getters[GET_DATA]("customerIdentificationResponse") : null;
        if (this.customerIdentificationResponse != null && this.customerIdentificationResponse != "" && this.customerIdentificationResponse.length == 1 && this.customerIdentificationResponse[0].serviceAddresses !== null) {
            this.accountNumber = this.customerIdentificationResponse[0].accountNumber;
            this.zipCode = this.customerIdentificationResponse[0].serviceAddresses[0].zipCode;
            this.serviceAddress = this.customerIdentificationResponse[0].serviceAddresses[0].address;
            const maskedDigits = '*'.repeat(this.accountNumber.length - 4);
            const lastFourDigits = this.accountNumber.slice(-4);
            this.maskedAccNum = maskedDigits + lastFourDigits;
            this.isDataAvail = true;
            this.openWgModal();
        } else {
            this.isDataAvail = false;
        }
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, { attributes: attributesData });
    },
    methods: {
        tagging(buttonName) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'wg_susp_service_restore_click',
                flow:{
                      name: 'wgnp',
                      step: 'step1'
                },
                customer:{
                     accountType: this.accountType
                },
                buttonName: buttonName,
            });
        },
        authenticate(dataAvail) {
            if(dataAvail == true) {
                this.userClickedConfirm = true;
            }
            else {
                this.userClickedConfirm = false;
            }
            this.modalClose();
        }, 
        qbpButton() {
            this.tagging('NP Quick Bill Pay');
            if(this.isDataAvail == true && this.userClickedConfirm == true) {
                quickBillPay.getAccount(this.accountNumber, this.zipCode);
            }
            else {
                this.$router.push({
                    name: "Identify",
                    params: {},
                });
            }
        },
        openWgModal() {
            this.wgModal.show();
        },
        modalClose() {
            this.wgModal.hide();
        },
        maskAccountToDisplay (accNum) {
            const maskedDigits = '*'.repeat(accNum - 4);
            const lastFourDigits = accNum.slice(-4);
            return maskedDigits + lastFourDigits;
        }
    }
}
</script>

<style scoped>
.notification-head {
    color: #14161A;
    font-size: 20px;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin-left: 28px;
}
.notif-box-text {
    margin-left: 30px;
    color: #000000;
}
 .pay-balance-notif-box {
    position: relative;
    z-index: 1001;
    font-size: 14px;
    line-height: 1.35;
    padding: 20px;
    text-align: left;
    background-color: #FFFFFF;
    margin: 0 0 20px 0;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #000000;
    border-radius: 8px;
    opacity: 1;
    display: inline-block;
    width: 50%;
}
.row-margin {
    background: #ebeff6 0% 0% no-repeat padding-box;
}
.row-notif {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.registerStyle {
    margin-bottom: 1rem;
    font-family: Maison Neue, sans-serif;
}
.title-align {
    margin: 3rem 0 3rem 0;
    position: relative;
}
.marg-2 {
    margin-bottom: 2rem;
}
.marg-left {
    margin-left: 1rem;
}
.due-date-text {
    margin-left: 1rem;
}
.sign-in-align {
    color: #FFFFFF;
    font-size: 14px;
    text-decoration: none;
}
.text-weight {
    font-weight: bold;
}
.img-yellow {
    position: absolute;
}
.title-box {
    display: inline-flex;
    position: relative;
}
.modalBtns {
    display: flex;
    justify-content: left;
    align-items: center;
}
.go-back-btn {
    margin-left: 2rem;
    background-color: #FFFFFF;
    color: #0047BB;
    border: none;
}
.go-back-btn:hover {
    border: 1 px solid #0047BB;
}
.marg-1 {
    margin: 1rem 0 1rem 0;
}
@media (max-width: 768px) and (min-width: 240px) {
    .notif-container {
        width: 100%;
        margin: 0 auto;
        padding: 2rem;
    }
    .pay-balance-notif-box {
        width: 100%;
    }
}
@media (min-width: 770px) and (max-width: 1149px) {
    .notif-container {
        width: 44%;
        margin: 0 auto;
    }
    .due-date-alignment {
        position: absolute;
        left: -30px;
        top: 4px;
    }
    .due-date-text {
        margin-left: 0;
    }
}
@media (min-width: 1150px) and (max-width: 5200px) {
    .notif-container {
        width: 45%;
        margin: 0 auto;
    }
}
.susp{
    font-family: MaisonNeue-Medium, serif;
    text-align: left;
    font-size:  14px;
    letter-spacing: 0.45px;
    color: #14161ABF;
    text-transform: uppercase;
    opacity: 1;
}
.modal-box-style {
    padding: 2rem; 
    box-shadow: 0px 1px 4px #00000029;
    text-align: left;
}
</style>