<template>
  <div id="qbp-exception-message" class="qbpMessageBox">
    <div class="qbpTextBox">
      <div class="text-start">
        <h3>We're very sorry.</h3>
        <p>
          We don't have the necessary account information to complete a Quick
          Bill Pay transaction for you.
        </p>
        <p>
          If you continue seeing this error message, please
          <a
            href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})"
            id="qbpNameOrAmtErrorChatWithUs"
            data-galink-type="chat"
            >chat with us</a
          >
          so we can help.
        </p>
        <p>
          We sincerely apologize for the inconvenience. Your patience is
          appreciated.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
};
</script>

<style scoped>
#qbp-exception-message h3 {
  font-weight: bold;
}
#qbp-exception-message p {
  margin-bottom: 5px;
}
.qbpTextBox {
  position: relative;
}
.qbpTextBox:before {
  content: "";
  position: absolute;
  background: url(../../images/icon-important-orange.svg) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
}
@media (max-width: 767px) {
  #qbp-exception-message h3 {
    min-height: 30px;
  }
}
</style>