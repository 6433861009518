<template>
  <div class="container d-flex justify-content-center autopaysuccessbox">
    <success-autopay />
  </div>
</template>
<script>
import SuccessAutopay from '../components/Autopay/autopaySuccess.vue';
export default {
  name: 'SuccessAP',
  components: {
    SuccessAutopay
  }
};
</script>
<style>
.autopay-box {
  width: 58%;
  height: 100%;
  border-radius: 5px;
}

.autopaysuccessbox {
  margin-top: 1rem;
  width: 58%;
  height: 100%;
  border-radius: 5px;
}

@media(max-width: 979px) {
  .autopay-box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .autopaysuccessbox {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
</style>
