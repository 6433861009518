import axios from 'axios'
import constants from "./constants";
import links from "./links";
import router from "../router";
import store from "../store";
import { SET_DATA } from "../store/storecontants";
import { pii } from "../js/pii";

const getAccount = async function (accountNumber, zipCode) {
    let attributesData = new Map();
    attributesData.set('showLoading', true);
    store.dispatch(SET_DATA, {attributes:attributesData});
    try {
        const params = {
            accountNumber: accountNumber,
            zipCode: zipCode,
        };
        // call api with params
        const res = await axios.post(constants.QBPIdentifyURL, params);
        // set the below fields for acod and encrypted account number to call success api
        if (res.data.account) {
            attributesData.set("emailAddress", JSON.stringify(res.data.account.emailAddressOfRecord))
            attributesData.set("firstName", JSON.stringify(res.data.account.acodFirstName))
            attributesData.set("lastName", JSON.stringify(res.data.account.acodLastName))
            attributesData.set("AcodAccountNumber", JSON.stringify(res.data.account.accountNumber))
            attributesData.set("pmtArrangementOnAccount", JSON.stringify(res.data.account.pmtArrangementOnAccount))
            store.dispatch(SET_DATA, { attributes: attributesData });
        }
        if (res.data.errorInfo!=null && res.data.errorInfo.errorMessage == "ERRDSSPQBP0004") {
            res.data.errorInfo = null;
        } else if (res.data.errorInfo!=null && res.data.errorInfo.errorMessage == "ERRDSSPQBP0005") {
            res.data.errorInfo = null;
        }
        const multi_Accounts = JSON.stringify(res.data.account.multipleAccounts);
        const zipCode_multiAccount = JSON.stringify(zipCode);
        // gtm 
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            company: 'centurylink'
        });
        // account status exception then set the error message to show
        if (res == null || (res.data.errorInfo != null && res.data.errorInfo.errorMessage == "QBP_ACCOUNT_STATUS_EXCEPTION")) {
            // GTM for exception
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                page: {
                    pageType: "qbp",
                    pageName: "ctl|account|qbp|exception|dvar_transaction",
                },
            });
            routeToIdentify();
        }
        // not live account
        else if (res == null || (res.data.errorInfo != null && res.data.errorInfo.errorMessage == "QBP_ACCOUNT_STATUS_NOT_LIVE")) {
            // GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                page: {
                    pageType: "qbp",
                    pageName: "ctl|account|qbp|exception|status|not_live",
                },
            });
            routeToIdentify();
        } 
        // pmtArrangementOnAccount true navigate to scheduled payment page
        else if (res != null && res.data.account.pmtArrangementOnAccount == true) {
            // GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                page: {
                    pageType: "qbp",
                    pageName: "ctl|account|qbp|paymentform_iframe",
                },
            });
            // GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "qbp_choose_payment_method",
                accountNumber: pii(accountNumber),
                accountType: res.data.account.accountType,
                flow: {
                    name: "qbp",
                    step: "payment flow",
                },
            });
            if (res.data.account.promiseToPay == true) {
                if (res.data.account.currMontlyAmt < 0) {
                    attributesData.set("selectedAccountNumber", JSON.stringify(accountNumber));
                    attributesData.set("EpwfIframe", JSON.stringify(res.data.epwfSession.sessionURL));
                    attributesData.set("adjustedAmountAvl", JSON.stringify(res.data.account.adjustedAmountDue));
                    attributesData.set("paymentDate", JSON.stringify(res.data.account.paymentDueDate));
                    attributesData.set("currentMonthlyAmount", JSON.stringify(0));
                    attributesData.set("remainingAmount", JSON.stringify(res.data.account.remainingArrAmt));
                    attributesData.set("amountOwed", JSON.stringify(res.data.account.totalBalanceOwed));
                    attributesData.set("installmentsArray", JSON.stringify(res.data.account.installments));
                    store.dispatch(SET_DATA, { attributes: attributesData });
                    router.push({
                        name: "QbpPromiseToPay",
                        params: {},
                    });
                } else {
                    attributesData.set("selectedAccountNumber", JSON.stringify(accountNumber));
                    attributesData.set("EpwfIframe", JSON.stringify(res.data.epwfSession.sessionURL));
                    attributesData.set("adjustedAmountAvl", JSON.stringify(res.data.account.adjustedAmountDue));
                    attributesData.set("paymentDate", JSON.stringify(res.data.account.paymentDueDate));
                    attributesData.set("currentMonthlyAmount", JSON.stringify(res.data.account.currMonthlyAmt));
                    attributesData.set("remainingAmount", JSON.stringify(res.data.account.remainingArrAmt));
                    attributesData.set("amountOwed", JSON.stringify(res.data.account.totalBalanceOwed));
                    attributesData.set("installmentsArray", JSON.stringify(res.data.account.installments));
                    store.dispatch(SET_DATA, { attributes: attributesData });
                    router.push({
                        name: "QbpPromiseToPay",
                        params: {},
                    });
                }
            } else if (res.data.account.scheduledPmts == true) {
                if (res.data.account.currMontlyAmt < 0) {
                    attributesData.set("selectedAccountNumber", JSON.stringify(accountNumber));
                    attributesData.set("EpwfIframe", JSON.stringify(res.data.epwfSession.sessionURL));
                    attributesData.set("adjustedAmountAvl", JSON.stringify(res.data.account.adjustedAmountDue));
                    attributesData.set("paymentDate", JSON.stringify(res.data.account.paymentDueDate));
                    attributesData.set("currentMonthlyAmount", JSON.stringify(0));
                    attributesData.set("remainingAmount", JSON.stringify(res.data.account.remainingArrAmt));
                    attributesData.set("amountOwed", JSON.stringify(res.data.account.totalBalanceOwed));
                    attributesData.set("installmentsArray", JSON.stringify(res.data.account.installments));
                    store.dispatch(SET_DATA, { attributes: attributesData });
                    router.push({
                        name: "QbpScheduledToPay",
                        params: {},
                    });
                } else {
                    attributesData.set("selectedAccountNumber", JSON.stringify(accountNumber));
                    attributesData.set("EpwfIframe", JSON.stringify(res.data.epwfSession.sessionURL));
                    attributesData.set("adjustedAmountAvl", JSON.stringify(res.data.account.adjustedAmountDue));
                    attributesData.set("paymentDate", JSON.stringify(res.data.account.paymentDueDate));
                    attributesData.set("currentMonthlyAmount", JSON.stringify(res.data.account.currMonthlyAmt));
                    attributesData.set("remainingAmount", JSON.stringify(res.data.account.remainingArrAmt));
                    attributesData.set("amountOwed", JSON.stringify(res.data.account.totalBalanceOwed));
                    attributesData.set("installmentsArray", JSON.stringify(res.data.account.installments));
                    store.dispatch(SET_DATA, { attributes: attributesData });  
                    router.push({
                        name: "QbpScheduledToPay",
                        params: {},
                    });
                }
            }
        } else {
            if (res.data.status == 0 && res.data.errorInfo == null) {
                if (res.data.account.multipleAccounts != null) {
                    // if multi-account is not null navigate to multi-account page
                    // GTM
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        page: {
                            pageType: "qbp",
                            pageName: "ctl|account|qbp|verification_info",
                        },
                    });
                    attributesData.set("zipcode", zipCode_multiAccount);
                    attributesData.set("multiData", multi_Accounts);
                    attributesData.set("selectedAccountNumber", JSON.stringify(accountNumber));
                    attributesData.set("status", JSON.stringify(res.data.account.multiAccount));
                    store.dispatch(SET_DATA, { attributes: attributesData });
                    router.push({
                        name: "MultiAccount",
                        params: {},
                    });
                } else if (res.data.epwfSession != null) {
                    // single account navigate confirmation page 
                    // GTM
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        page: {
                            pageType: "qbp",
                            pageName: "ctl|account|qbp|paymentform_iframe",
                        },
                    });
                    // GTM
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "qbp_choose_payment_method",
                        accountNumber: pii(accountNumber),
                        accountType: res.data.account.accountType,
                        flow: {
                            name: "qbp",
                            step: "payment flow",
                        },
                    });
                    attributesData.set("selectedAccountNumber", JSON.stringify(accountNumber));
                    attributesData.set("EpwfIframe", JSON.stringify(res.data.epwfSession.sessionURL));
                    attributesData.set("balanceDue", JSON.stringify(res.data.account.adjustedAmountDue));
                    attributesData.set("paymentDate", JSON.stringify(res.data.account.paymentDueDate));
                    attributesData.set("errorInfo", null);
                    attributesData.set("prepaidAccount", JSON.stringify(res.data.account.prepaidAccount));
                    attributesData.set("prepaidInfo", JSON.stringify(res.data.account.prepaidSuspendedAccount));
                    attributesData.set("suspendedAccount", JSON.stringify(res.data.account.accountStatus));
                    attributesData.set("amountOwed", JSON.stringify(res.data.account.totalBalanceOwed));
                    store.dispatch(SET_DATA, { attributes: attributesData });
                    router.push({
                        name: "Confirm",
                        params: {},
                    });
                } else if (res.data.epwfSession == null) {
                    // if we are not getting iframe show confirmation page with sorry message on iframe place
                    // GTM
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        page: {
                            pageType: "qbp",
                            pageName: "ctl|account|qbp|paymentform_iframe",
                        },
                    });
                    // GTM
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: "qbp_choose_payment_method",
                        accountNumber: pii(accountNumber),
                        accountType: res.data.account.accountType,
                        flow: {
                            name: "qbp",
                            step: "payment flow",
                        },
                    });
                    attributesData.set("selectedAccountNumber", JSON.stringify(accountNumber));
                    attributesData.set("EpwfIframe", null);
                    attributesData.set("status", JSON.stringify(res.data.account.multiAccount));
                    attributesData.set("balanceDue", JSON.stringify(res.data.account.adjustedAmountDue));
                    attributesData.set("paymentDate", JSON.stringify(res.data.account.paymentDueDate));
                    attributesData.set("errorInfo", "");
                    attributesData.set("prepaidAccount", JSON.stringify(res.data.account.prepaidAccount));
                    attributesData.set("prepaidInfo", JSON.stringify(res.data.account.prepaidSuspendedAccount));
                    store.dispatch(SET_DATA, { attributes: attributesData });
                    router.push({
                        name: "Confirm",
                        params: {},
                    });
                }
            } else if (res.data.status == 0 && res.data.errorInfo != null) {
                // error message in response then we show one of the view from Errormessage folder
                if (
                    res.data.errorInfo.errorMessage == "QBP_NO_BALANCE" ||
                    res.data.errorInfo.errorMessage == "QBP_AUTOPAY" ||
                    res.data.errorInfo.errorMessage == "QBP_BILLING_NAME_UNAVAILABLE" ||
                    res.data.errorInfo.errorMessage == "QBP_SERVICE_ERROR" ||
                    res.data.errorInfo.errorMessage == "QBP_BLOCKED_ACCOUNT" ||
                    res.data.errorInfo.errorMessage == "QBP_AMOUNT_DUE_UNAVAILABLE" ||
                    res.data.errorInfo.errorMessage == "QBP_SIMPLE_PAY_ACCOUNTS" ||
                    res.data.errorInfo.errorMessage == "QBP_PREPAID_NON_SUSPENDED_ACCOUNT"
                ) {
                    attributesData.set("selectedAccountNumber", JSON.stringify(accountNumber));
                    attributesData.set("EpwfIframe", null);
                    attributesData.set("status", JSON.stringify(res.data.account.multiAccount));
                    attributesData.set("balanceDue", JSON.stringify(res.data.account.adjustedAmountDue));
                    attributesData.set("paymentDate", JSON.stringify(res.data.account.paymentDueDate));
                    attributesData.set("errorInfo", JSON.stringify(res.data.errorInfo.errorMessage));
                    attributesData.set("prepaidAccount", JSON.stringify(res.data.account.prepaidAccount));
                    attributesData.set("prepaidInfo", JSON.stringify(res.data.account.prepaidSuspendedAccount));
                    store.dispatch(SET_DATA, { attributes: attributesData });
                    router.push({
                        name: "Confirm",
                        params: {},
                    });
                } else if (res.data.errorInfo.errorMessage == "ERRDSSPQBP0002") {
                    window.location.href = links.AuthHome;
                }
            } else if (res.data.account.prepaidAccount == "true" && res.data.errorInfo.errorMessage == "QBP_PREPAID_BILLING_NAME_ERROR") {
                attributesData.set("selectedAccountNumber", JSON.stringify(accountNumber));
                attributesData.set("EpwfIframe", null);
                attributesData.set("status", JSON.stringify(res.data.account.multiAccount));
                attributesData.set("balanceDue", JSON.stringify(res.data.account.adjustedAmountDue));
                attributesData.set("paymentDate", JSON.stringify(res.data.account.paymentDueDate));
                attributesData.set("errorInfo", res.data.errorInfo.errorMessage);
                attributesData.set("prepaidAccount", JSON.stringify(res.data.account.prepaidAccount));
                attributesData.set("prepaidInfo", JSON.stringify(res.data.account.prepaidSuspendedAccount));
                store.dispatch(SET_DATA, { attributes: attributesData });
                router.push({
                    name: "Confirm",
                    params: {},
                });
            } else if (res.data.errorInfo != null && res.data.errorInfo.errorCode == "ZIP_INVALID") {
                // GTM
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "qbp_error",
                    accountNumber: pii(accountNumber),
                    errorName: "invalid_zip",
                    flow: {
                        name: "qbp",
                        step: "validation",
                    },
                });
                routeToIdentify();
            } else if (res.data.errorInfo != null && res.data.errorInfo.errorCode == "EMAIL_INVALID") {
                // GTM
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "qbp_error",
                    accountNumber: pii(accountNumber),
                    errorName: "invalid_email",
                    flow: {
                        name: "qbp",
                        step: "validation",
                    },
                });
                routeToIdentify();
            } else if (res.data.errorInfo != null && res.data.errorInfo.errorCode == "PHONE_INVALID") {
                // GTM
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "qbp_error",
                    accountNumber: pii(accountNumber),
                    errorName: "invalid_tn",
                    flow: {
                        name: "qbp",
                        step: "validation",
                    },
                });
                routeToIdentify();
            } else {
                // GTM
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: "qbp_error",
                    accountNumber: pii(accountNumber),
                    errorName: res.data.errorInfo.errorCode,
                    flow: {
                        name: "qbp",
                        step: "validation",
                    },
                });
                routeToIdentify();
            }
        }
        attributesData.set('showLoading', false);
        store.dispatch(SET_DATA, {attributes:attributesData});
    } catch (error) {
        // api fails
        routeToIdentify(); 
    }
}

function routeToIdentify() {
    console.log(error);
    let attributesData = new Map();
    attributesData.set('showLoading', false);
    store.dispatch(SET_DATA, {attributes:attributesData}); 
    router.push({
        name: "Identify",
        params: {},
    });
}

export default {
    getAccount
}