<template>
    <div class="row justify-content-md-center">
      <div class="form-column pa-identifypage">
        <PAForm />
      </div>
    </div>
    <div class="row row-margin">
      <div class="col-md-6 col-xs-6 form-column col-lg-6">
        <img class="img-people" src="../images/svg/qbp_image.svg" />
      </div>
      <div class="col-md-6 col-xs-6 form-column col-lg-6">
        <PayArrngmtsRightBar />
      </div>
    </div>
</template>

<script>

import PAForm from '../components/paymentArrngmts/PAForm.vue'
import PayArrngmtsRightBar from '../components/paymentArrngmts/payArrngmtsRightBar.vue'
import {RESET} from '../store/storecontants'

export default {
  name: 'PaymentArrngmtsIdentify',
  components: {
    PAForm,
    PayArrngmtsRightBar
  },
  mounted() {
    this.$store.dispatch(RESET);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        pageType: 'pa',
        pageName: 'ctl|account|payment_arrangement|account_number'
      }
    });
  }
}
</script>
<style scoped>
  .img-people {
    width: 285px;
    height: 274px;
    margin-top: 0;
  }

  @media(min-width: 768px){
  .img-people {
    float: right;
   }
  }
  
  .row-margin {
    margin-top: 20px;
    padding-left: 10%;
    background: #ebeff6 0% 0% no-repeat padding-box;
    margin-right: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .pa-identifypage{
    width: 100%;
    margin: 0 auto;
  }

  @media(min-width: 440px){
  .pa-identifypage{
    width: 420px;
  }
}

@media(min-width: 768px){
  .pa-identifypage{
    width: 570px;
  }
}

</style>