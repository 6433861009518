<template>
  <div class="root">
    <div class="row" id="dssp-intro-wrapper">
      <h1 class="text-center">Payment Plans</h1>
      <h2 class="text-center" data-test="messageHead">Request extra time to pay your bill</h2>
    </div>
    <!-- prepaid error message starts -->
    <div v-if="ppbError && apiResponseErrorCode == 'PMTARNGMNT_001'">
      <div id="qbp-intro-wrapper" class="marg-right">
        <div id="paymentError" class="col-md-12 col-sm-12 col-lg-12">
				<div class="outside outside2 outside-box">
            <div class="inside">
              <div class="prepaidAccountError">
                <h3 style="line-height: 1.35em;margin:0.5rem 0rem;" tabindex="0" ref="errorMessageText">
                  <strong>This is a Prepaid account.</strong>
                </h3>
                <p class="marg-right">Prepaid customers are billed automatically each month on a credit or debit card of choice, and service is auto-renewed untill you choose to cancel.</p>
                <p class="marg-right">To manage or modify your payment information <a id="signintomyaccount" :href="MyaEntryPointURL">sign in to My CenturyLink.</a></p>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- prepaid error message ends -->
    <!-- error message for dvar and error codes starts -->
    <div v-if="dvarError">
      <div id="qbp-intro-wrapper" class="marg-right">
        <div class="col-md-12 col-sm-12 col-lg-12">
          <div class="outside outside2 outside-box" style="background: #faf8f8 ;">
            <div class="inside">
              <div class="dvar-error">
                  <p style="line-height: 1.35em;margin:0.5rem 0rem;" tabindex="0" ref="errorMessageText">
                    {{ this.errorMessage }}
                  </p>
                <div v-if="this.insufficientDue">
                  <a href="/qbp/identify">click here</a>
                </div>
                <div v-if="this.brightspeed" id="brightspeedLink">
                  <a :href="BrightspeedPaURL" id="brightspeedLearnMoreLink" target="blank">
                    Make your payment at Brightspeed</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- error message for dvar and error codes ends -->
    <!-- error message for cris account starts -->
    <div v-if="regCris" class="pa-cris">
      <h3>We're sorry, we're unable to process your request.</h3>
      <p>Please chat with an agent.</p>
      <a
      href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})"
      class="cta-btn">
      Chat With Us</a>
    </div>
    <!-- error message for cris account ends -->
    <!-- error message for email/phone customers starts-->
    <div class="orchestrator-error" v-if="v$.number.$error && (v$.number.TN.$invalid || v$.number.Email.$invalid)">
      <p class="text-start mt-2 orchestrator-error-message" tabindex="0" ref="statusError">
        To keep your data secure, please use your account number to use Payment Plans or <a :href="MyaEntryPointURL">sign in</a>.
      </p>
    </div>
    <!-- error message for email/phone customers ends-->
    <!-- user enter form starts -->
    <div id="access-option-one-input" class="row grid-align">
      <div class="col-11 col-md-11 col-sm-11" id="phone-account-label-wrapper">
        <div class="form-floating form-group">
          <input type="text" class="form-control" :class="{ 'is-invalid': v$.number.$error }" id="number" ref="accountRef" aria-required="true"
            placeholder=" " maxlength="101" v-model="state.number" v-on:keyup.enter="submitForm()"/>
          <label for="number" class="d-inline-block text-start text-truncate form-label col-12"
            :class="{ 'text-danger': v$.number.$error, 'form-error-label': v$.zip.$error }">
            <span v-if="this.enableTnEmail=='true'">Account, Phone Number or Email:</span>
            <span v-else>Account Number:</span>
          </label>
        </div>
      </div>
      <div class="col-1 p-0 text-left" id="popover-wrapper" style="width: auto;">
        <button v-if="this.enableTnEmail=='true'" aria-label="help text" type="button" id="dssp-popover" class="btn btn-white" data-bs-placement="top" data-bs-boundary="window" data-bs-html="true" data-bs-toggle="popover" data-bs-trigger="hover focus" tabindex="0"
          data-bs-content="<b>Please enter one of the following:</b><br><br><b>Account number</b> in the upper corner of your CenturyLink bill or online (9, 11 or 13 digits)<br><br><b>Phone number - </b>any 10 digit number we have on file with your account<br><br><b>Email address</b> we have on file with your account">
          <img src="../../images/svg/Info_Outline.svg" alt="outline-info" class="filter-blue" />
        </button>

        <button v-else aria-label="help text" type="button" id="dssp-popover" class="btn btn-white" data-bs-placement="top" data-bs-boundary="window" data-bs-html="true" data-bs-toggle="popover" data-bs-trigger="hover focus" tabindex="0"
          data-bs-content="<b>Please enter your account number.</b><br><br>You can find your account number in the upper corner of your bill or in your online account.">
          <img src="../../images/svg/Info_Outline.svg" alt="outline-info" class="filter-blue" />
        </button>
      </div>
      <div class="col-11 col-md-11 col-sm-11 form-error-wrapper" v-if="v$.number.$error">
        <label class="d-inline-block text-start text-truncate col-12 form-error-message"
          :class="{ 'text-danger': v$.number.$error }" for="number">
          <span v-if="this.enableTnEmail=='true'">An account, telephone number or email ID is required.</span>  
          <span v-else>A valid account number is required.</span>  
        </label>
      </div>
      <div class="col-11 col-md-11 col-sm-11" id="zip-label-wrapper">
        <div class="form-floating form-group mt-3">
          <input type="text" class="form-control" :class="{ 'is-invalid': v$.zip.$error }" id="zip" placeholder=" "
            maxlength="5" @input="onlyNumber" v-model="state.zip" v-on:keyup.enter="submitForm()"/>
          <label 
            for="zip" 
            class="d-inline-block text-start text-truncate form-label col-12"
            :class="{ 'text-danger': v$.zip.$error, 'form-error-label': v$.zip.$error }"
            >Billing Zip Code:</label>
        </div>
      </div>
      <div class="col-11 col-md-11 col-sm-11 form-error-wrapper" v-if="v$.zip.$error">
        <label class="d-inline-block text-start text-truncate col-12 form-error-message"
          :class="{ 'text-danger': v$.zip.$error }" for="zip" aria-required>
          A zip code is required.
        </label>
      </div>
    </div>   
    <!-- user enter form ends --> 
    <div class="row pb-3 grid-align">
      <div class="col-6 col-md-6 col-sm-6">
        <br>
        <div id="norton-logo"></div>
      </div>
      <div class="col-6 col-md-6 col-sm-6 d-grid gap-2 button-align">
        <button type="button" id="next-button" class="btn btn-primary btn-lg text-center" 
        :aria-disabled="this.nextButtonCheck" @click="submitForm">Next</button>
      </div>
    </div>
    <div class="row pa-or">
      <h4 class="col-11" >or</h4>
    </div>
    <div class="pa-signin">
      <div class="col-11">
      <button type="button" class="btn sign-in" @click="authenticate()"><a id="signintomyaccount" target="_blank" rel="noopener">Sign in to My CenturyLink</a></button>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import {
  required,
  email,
  minLength,
  maxLength,
  integer,
  helpers,
  or,
  and,
  not
} from "@vuelidate/validators";
import { reactive, computed } from "vue";
import axios from "axios";
import { SET_DATA, RESET } from "../../store/storecontants";
import {PMTARNGMNT_001, PMTARNGMNT_002,PMTARNGMNT_003, PMTARNGMNT_004, PMTARNGMNT_005, PMTARNGMNT_006, PMTARNGMNT_008, PMTARNGMNT_009, PMTARNGMNT_010, PMTARNGMNT_011,
PHONE_INVALID_ERROR_CODE, EMAIL_INVALID_ERROR_CODE, ZIP_INVALID_ERROR_CODE, SYSYTEM_DOWN, DEFAULT_ERROR_PA, ACCOUNT_MAINTENANCE, NO_AMOUNT_DUE, PA_PMTARNGMNT_008_ERROR_MESSAGE, REG_CRIS, ACCOUNT_INVALID } from "../../utils/ErrorCodes"
import constants from "../../js/constants";
import {pii} from "../../js/pii";
import links from "../../js/links";
import { Popover } from "bootstrap/dist/js/bootstrap.bundle";

export default {
  name: "PAForm",
  data(){
    return{
      CtlSignInURL: links.CtlSignInURL,
      dvarError: false,
      errorMessage: DEFAULT_ERROR_PA,
      ppbError:false,
      isDay1PlusToggleOn: false,
      enableTnEmail: Boolean,
      MyaEntryPointURL: links.MyaEntryPointURL,
      BrightspeedPaURL: links.BrightspeedPaURL,
      brightspeed: false,
      isMultiAcct: false,
      insufficientDue: false,
      regCris: false,
      DhJumpOutPaymentPlan: links.DhJumpOutPaymentPlan
      // apiResponseErrorCode: null
    }  
  },
  // reset store
  beforeMount(){
    this.$store.dispatch(RESET);
  },
  setup() {
    const state = reactive({
      number: null,
      zip: null,
    });

    const isPrepaid = (value) => value.toUpperCase() && (value.includes('PPB') || value.includes('ppb'));
    const isNumber = helpers.regex(
      /^[\s]*[(]{0,1}[0-9]{9}[)]{0,1}[\s]*$/
    );
    /* eslint-disable */
    const isPhone = helpers.regex(
      /^[\s]*[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}[\s]*$/
    );
    /* eslint-disable */
    const isCris = helpers.regex(
      /^[\s]*[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}[-\s\.]{0,1}[0-9]{3}[\s]*$/
    );
    const isCrisAllDigits = helpers.regex(
      /^[\s]*[(]{0,1}[0-9]{13}[)]{0,1}[\s]*$/
    );
    const TN = helpers.withMessage('ENTERED_PHONE', not(isPhone))
    const Email = helpers.withMessage('ENTERED_EMAIL', not(email))
    // validation for account and zipcode
    const rules = computed(() => {
      return {
        number: {
          required,
          or: or(
            isNumber,
            isCris,
            isCrisAllDigits,
            and(integer, minLength(9), maxLength(9)),
            and(isPrepaid, minLength(11), maxLength(11))
          ),
          TN,
          Email
        },
        zip: {
          required,
          integer,
          minLength: minLength(5),
        },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      state,
      v$,
    };
  },

  methods: {
    // when select Sign into My CenturyLink next button will jump to dh signin
    authenticate() {
      window.dataLayer?.push({
        event: 'pa_start',
        accountNumber: 'null',
        flow:{
          name: 'pa',
          step: 'start'
        },
        buttonName: 'pa full auth',
      });

      window.location.href = this.DhJumpOutPaymentPlan;
    },
    //accessability focus on error field
    statusError () {
      setTimeout(() => { 
        let errorDiv = this.$refs.errorMessageText
        if(errorDiv) {
          this.$refs.errorMessageText.focus();
        }
      }, 500) 
    },
    //accept only number for zipcode
    onlyNumber () {
      this.state.zip = this.state.zip.replace(/[^0-9]/g, "");
    },
    //removing - _ and space on account number field
    replaceData(data){
      let regex = /\(|\)/g;      
      var newStr = data.replace(/-/g, "").replace(/ /g, "").replace(/_/g, "").replace(regex, "");
      return newStr;
    },
  //initally set to false below value
  initialState(){
    this.ppbError = false;
    this.dvarError = false;
    this.embargoError = false;
    this.brightspeed = false;
    this.regCris = false;
  },
  // submit form after entering account and zipcode
    submitForm() {
      this.initialState();
      this.v$.$validate();
      // accessability to read disable button
      const nextButton = document.getElementById("next-button");
      const isDisabled = nextButton.getAttribute('aria-disabled') === 'true';
      if(isDisabled){
        e.preventDefault();
        return;
      }
      if(this.state.number != null)  {
        if(this.state.number.toLowerCase().startsWith("ppb")){
          this.state.number = this.state.number.toUpperCase()
        }
      }
      this.state.number = (this.state.number != null) ? this.state.number.trim() : null;
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});  
      //gtm to shows enter the flow    
      window.dataLayer?.push({
        event: 'pa_start',
        accountNumber: pii(this.state.number),
        flow:{
          name: 'pa',
          step: 'start'
        },
        buttonName: 'pa profile lookup',
      });
      //If there is no error then set all the default error to false
      if (this.v$.number.required.$invalid) {
        this.ppbError = false;
        this.dvarError = false;
        this.embargoError = false;
        this.regCris = false;
      }
      // check the validation and call the getAccount which call api
      if (!this.v$.$error) {
        this.getAccount();
      } else {
        //accessability focus on error message
        if(this.v$.zip.$error && this.v$.number.$error) {
          this.$refs.accountRef.focus();
        } else if (this.v$.number.$error) {
          this.$refs.accountRef.focus();
        } else if (this.v$.zip.$error) {
          this.$refs.zipRef.focus();
        }
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
      }
    },
    // if there is error code call this function to print proper error
    accountHasErrors(res){
      if (res.data.errorCode == PMTARNGMNT_001) {
          this.ppbError = true;
          window.dataLayer?.push({
            event: 'pa_error',
            accountNumber: pii(this.state.number),
            errorName: 'prepaid',
            flow:{
              name: 'pa',
              step: 'eligibility'
            }
          });
          return true;
      }
      if (res.data.errorCode == REG_CRIS) {
          this.regCris = true;
          return true;
      }
      if (res.data.errorCode == PMTARNGMNT_002) {
          window.dataLayer?.push({
            event: 'pa_error',
            accountNumber: pii(this.state.number),
            errorName: 'Suspended Account',
            flow:{
              name: 'pa',
              step: 'eligibility'
            },
          });
          return false;
      }
      if (res.data.errorCode == PMTARNGMNT_003) {
          this.dvarError = true;
          this.errorMessage = 'We’re sorry. We can’t complete this transaction. Please try again later.';
          this.statusError();
          window.dataLayer?.push({
            event: 'pa_error',
            accountNumber: pii(this.state.number),
            errorName: 'CCV Response Null',
            flow:{
              name: 'pa',
              step: 'eligibility'
            },
          });
          return true;
      }  
      if (res.data.errorCode == PMTARNGMNT_004) {
          window.dataLayer?.push({
            event: 'pa_error',
            accountNumber: pii(this.state.number),
            errorName: 'Autopay',
            flow:{
              name: 'pa',
              step: 'eligibility'
            },
          });
          return false;
      }
      if (res.data.errorCode == PMTARNGMNT_005) {
          this.dvarError = true;
          window.dataLayer?.push({
            event: 'pa_error',
            accountNumber: pii(this.state.number),
            errorName: 'DVAR Verification Failure',
            flow:{
              name: 'pa',
              step: 'eligibility'
            },
          });
          return true;
      }
      if (res.data.errorCode == PMTARNGMNT_006) {
          this.dvarError = true;
          this.errorMessage = DEFAULT_ERROR_PA;
          this.statusError();
          window.dataLayer?.push({
            event: 'pa_error',
            accountNumber: pii(this.state.number),
            errorName: 'DVAR Verification Failure',
            flow:{
              name: 'pa',
              step: 'eligibility'
            },
          });
          return true;
      }
      if (res.data.errorCode == PMTARNGMNT_005) {
        if (res?.data?.paymentArrangementIneligibleErrorCodes != null && res?.data?.paymentArrangementIneligibleErrorCodes.includes("10015")) {
          this.dvarError = true;
          this.errorMessage = res.data.errorMessage;
          this.statusError();
          return false;
        } else {
          this.dvarError = true;
          this.errorMessage = DEFAULT_ERROR_PA;
          this.statusError();
          return true;
        }
      }
      if (res.data.errorCode == PMTARNGMNT_008) {
        this.dvarError = true;
        // this.errorMessage = res.data.errorMessage;
        this.errorMessage = PA_PMTARNGMNT_008_ERROR_MESSAGE;
        this.statusError();
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.state.number),
          errorName: 'Account not recognized - DVAR',
          flow:{
            name: 'pa',
            step: 'eligibility'
          },
        });
        return true;
      }
      if (res.data.errorCode == PMTARNGMNT_009) {
        this.dvarError = true;
        this.errorMessage = ACCOUNT_MAINTENANCE;
        this.statusError();
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.state.number),
          errorName: 'Embargo',
          flow:{
            name: 'pa',
            step: 'eligibility'
          },
        });
        return true;
      }
      if (res.data.errorCode == PMTARNGMNT_010) {
        this.dvarError = true;
        this.insufficientDue = true;
        this.errorMessage = "Sorry, you are not eligible. You do not currently qualify to make a payment plan, as there is no amount due on your account at this time. To make a one-time payment,";
        this.statusError();
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.state.number),
          errorName: 'zero balance due',
          flow:{
            name: 'pa',
            step: 'eligibility'
          },
        });
        return true;
      }
      if (res.data.errorCode == PMTARNGMNT_011) {
        // this.apiResponseErrorCode = PMTARNGMNT_011;
          this.dvarError = true;
          this.errorMessage = 'We’re sorry. We can’t complete this transaction. Please try again later.';
          this.statusError();
          window.dataLayer?.push({
            event: 'pa_error',
            accountNumber: pii(this.state.number),
            errorName: 'Account is Closed',
            flow:{
              name: 'pa',
              step: 'eligibility'
            },
          });
          return true;
      }
      if (res.data.errorCode == ZIP_INVALID_ERROR_CODE) {
        this.dvarError = true;
        this.errorMessage = res.data.errorMessage;
        this.statusError();
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.state.number),
          errorName: 'invalid_zip',
          flow:{
            name: 'pa',
            step: 'validation'
          },
        });
        return true;
      }
      if (res.data.errorCode == PHONE_INVALID_ERROR_CODE) {
        this.dvarError = true;
        this.errorMessage = res.data.errorMessage;
        this.statusError();
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.state.number),
          errorName: 'invalid_tn',
          flow:{
            name: 'pa',
            step: 'validation'
          },
        });
        return true;
      }
      if (res.data.errorCode == EMAIL_INVALID_ERROR_CODE) {
        this.dvarError = true;
        this.errorMessage = res.data.errorMessage;
        this.statusError();
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.state.number),
          errorName: 'invalid_email',
          flow:{
            name: 'pa',
            step: 'validation'
          },
        });
        return true;
      }
      if (res.data.errorCode == SYSYTEM_DOWN) {
        this.dvarError = true;
        this.errorMessage = res.data.errorMessage;
        this.statusError();
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.state.number),
          errorName: 'System Error',
          flow:{
            name: 'pa',
            step: 'eligibility'
          },
        });
        return true;
      }
      if (res.data.errorCode == ACCOUNT_INVALID) {
        this.dvarError = true;
        this.errorMessage = res.data.errorMessage;
        this.statusError();
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.state.number),
          errorName: 'Account not recognized',
          flow:{
            name: 'pa',
            step: 'eligibility'
          },
        });
        return true;
      }
      //brightspeed postpaid show the error message
      if (res.data.errorInfo != null){
        if (res.data.errorInfo.errorMessage == "ERRDSSPQBP0001"){
          window.dataLayer?.push({
            event: 'my_ctl_exception_message',
            errorName: 'bspd_PA'
          });
          this.dvarError = true;
          this.brightspeed = true;
          this.errorMessage = "Hello. Your service provider is now Brightspeed.";
          return true;
        }
      }
      //brightspeed prepaid show the error message
      if (res.data.errorCode == "ERRDSSPQBP0001"){
          window.dataLayer?.push({
            event: 'my_ctl_exception_message',
            errorName: 'bspd_PA'
          });
          this.dvarError = true;
          this.brightspeed = true;
          this.errorMessage = "Hello. Your service provider is now Brightspeed.";
          return true;
      }
      //default error message
      if(res.data.errorCode != "" && res.data.errorCode != null){
        this.dvarError = true;
        this.errorMessage = 'We’re sorry. We can’t complete this transaction. Please try again later.';
        this.statusError();
        return true;
      }
      return false;
    },
  // gtm for paymentArrangementIneligibleErrorCodes
  gtmErrorLogging(a) {
    if (a.paymentArrangementIneligibleErrorCodes.includes("11001")) {
      window.dataLayer?.push({
        event: 'pa_error',
        accountNumber: pii(this.state.number),
        errorName: 'invalid account status',
	      flow:{
		      name: 'pa',
		      step: 'eligibility'
	      },
      });
    } else if (a.paymentArrangementIneligibleErrorCodes.includes("11002")) {
      window.dataLayer?.push({
        event: 'pa_error',
        accountNumber: pii(this.state.number),
        errorName: 'pa pending',
	      flow:{
		      name: 'pa',
		      step: 'eligibility'
	      },
      }); 
    } else if (a.paymentArrangementIneligibleErrorCodes.includes("11003")) {
      window.dataLayer?.push({
        event: 'pa_error',
        accountNumber: pii(this.state.number),
        errorName: 'suspend limit',
	      flow:{
		      name: 'pa',
		      step: 'eligibility'
	      },
      });
    } else if (a.paymentArrangementIneligibleErrorCodes.includes("11004")) {
      window.dataLayer?.push({
        event: 'pa_error',
        accountNumber: pii(this.state.number),
        errorName: 'returned payment',
	      flow:{
		      name: 'pa',
		      step: 'eligibility'
	      },
      });
    } else if (a.paymentArrangementIneligibleErrorCodes.includes("11005")) {
      window.dataLayer?.push({
        event: 'pa_error',
        accountNumber: pii(this.state.number),
        errorName: 'failed payment',
	      flow:{
		      name: 'pa',
		      step: 'eligibility'
	      },
      });
    } else if (a.paymentArrangementIneligibleErrorCodes.includes("11006")) {
      window.dataLayer?.push({
        event: 'pa_error',
        accountNumber: pii(this.state.number),
        errorName: 'canceled payment',
	      flow:{
		      name: 'pa',
		      step: 'eligibility'
	      },
      });
    } else if (a.paymentArrangementIneligibleErrorCodes.includes("10017")) {
      window.dataLayer?.push({
        event: 'pa_error',
        accountNumber: pii(this.state.number),
        errorName: 'Threshold message',
	      flow:{
		      name: 'pa',
		      step: 'eligibility'
	      },
      });
    } else {
      window.dataLayer?.push({
        event: 'pa_error',
        accountNumber: pii(this.state.number),
        errorName: 'undefined error',
	      flow:{
		      name: 'pa',
		      step: 'pa flow'
	      },
      });
    }
  },
  // api call and push to perticular view depends on response
  async getAccount() {
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      try {
        let strAccount = this.state.number;
        let atChar = '@';
        if(strAccount.includes(atChar)){
          this.state.number = this.state.number
        }else {
          this.state.number = this.replaceData(this.state.number)
        }
        // mendatory param
        const params = {
          accountNumber: this.state.number,
          zipCode: this.state.zip,
        };
        // axios call with params
        const res = await axios.get(constants.PaymentArrangementIdentifyURL, { params });
        if(res.data) {
          attributesData.set("emailAddress", JSON.stringify(res.data.emailAddressOfRecord))
          attributesData.set("firstName", JSON.stringify(res.data.acodFirstName))
          attributesData.set("lastName", JSON.stringify(res.data.acodLastName))
          attributesData.set("AcodAccountNumber", JSON.stringify(res.data.ban))
          this.$store.dispatch(SET_DATA, { attributes: attributesData });
        }
        if(res?.data?.errorInfo?.errorMessage == "ERRDSSPQBP0001") {
          window.dataLayer?.push({
            company: 'brightspeed'
          });
        } else {
          window.dataLayer?.push({
            company: 'centurylink'
          })
        }
        //error code paymentArrangementIneligibleErrorCodes call gtm
        if(res.data.paymentArrangementIneligibleErrorCodes != null) {
          try{
            this.gtmErrorLogging(res.data);
          }catch(e) {
            console.log('gtm error');
          }
        }
        attributesData.set('autopay', res.data.autoPay);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
        // Storing value after checking in response Whether card or bank is allowed flag
        attributesData.set('isCardAllowed', res.data.cardAllowed);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
        attributesData.set('isAchAllowed', res.data.achAllowed);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
        //if there is no error then continue
        if(this.accountHasErrors(res) == false){ 
          const multi_Accounts = JSON.stringify(res.data.multipleAccounts)
				  const zipCode_multiAccount= JSON.stringify(this.state.zip)
          // multiaccount check
          if (res.data.multiAccount == true) {
            let attributesData = new Map();
            attributesData.set('selectedAccountNumber', JSON.stringify(this.state.number));
            attributesData.set('zipcode', zipCode_multiAccount);
            attributesData.set('multiData', multi_Accounts);
            attributesData.set('status', JSON.stringify(res.data.multiAccount));
            attributesData.set('confirmationEmailAddress', res.data.confirmationEmailAddress);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            this.$router.push({
            name: 'PAMultiAccount', 
              params: {
                multiData: multi_Accounts,zipcode: zipCode_multiAccount,status :JSON.stringify(res.data.multiAccount), data: JSON.stringify(res.data)
              }
            });
          } else if (res.data.paymentArrangementEligible == true  && res.data.biller == 1) {
            //check for paymentArrangementEligible
            let attributesData = new Map();
            attributesData.set('accountEntered', JSON.stringify(this.state.number));
            attributesData.set('zipEntered', JSON.stringify(this.state.zip));
            attributesData.set('data', JSON.stringify(res.data));
            attributesData.set('isMultiAccount', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            this.$router.push({
              name: "PaymentArrngmtsOptions",
              params: {},
            });
          } else if (res.data.paymentArrangementEligible == true && res.data.biller == 2) {
            //check for paymentArrangementEligible
            let attributesData = new Map();
            attributesData.set('accountEntered', JSON.stringify(this.state.number));
            attributesData.set('data', JSON.stringify(res.data));
            attributesData.set('isMultiAccount', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            this.$router.push({
              name: "PAConfirm",
              params: {},
            });
          } else if (
            res.data.paymentArrangementIneligibleErrorCodes != null &&
            (res.data.paymentArrangementIneligibleErrorCodes.includes("11002") 
            || res.data.paymentArrangementIneligibleErrorCodes.includes("11008")
            || res.data.paymentArrangementIneligibleErrorCodes.includes("10017")
            || res.data.paymentArrangementIneligibleErrorCodes.includes("11005"))
          ) {
            // check for paymentArrangementIneligibleErrorCodes
            let attributesData = new Map();
            attributesData.set('paymentArrangementIneligibleErrorCodes', JSON.stringify(res.data.paymentArrangementIneligibleErrorCodes));
            attributesData.set('enteredAccountNumber', this.state.number);
            attributesData.set('account', this.state.number)
            attributesData.set('enteredZipCode', this.state.zip);
            attributesData.set('status', JSON.stringify(res.data));
            attributesData.set('isMultiAccount', false);
            attributesData.set('noAmtDue', false);
            attributesData.set('zipcode', zipCode_multiAccount);
            //if the due amount cross 1000 $ then we will get errorcode 10017
            if(res.data.paymentArrangementIneligibleErrorCodes.includes("10017")) {
              let thresholdErrorMessage = res.data.errorMessage
              let thresholdValAmt = thresholdErrorMessage.substring(
                  thresholdErrorMessage.indexOf("=") + 1, 
                  thresholdErrorMessage.lastIndexOf("|")
              );
              attributesData.set('thresholdVal', thresholdValAmt);
              attributesData.set('adjustedAmount', res.data.adjustedAmount);
            }
            attributesData.set('multiData', multi_Accounts);  
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            this.$router.push({
              name: "PaymentArrngmtsIneligibile",
              params: {},
            });
          } else if ((res.data.paymentArrangementEligible == false && res.data.status == 0 && res.data.adjustedAmount == "0.00") 
              || res.data?.account?.errorInfo?.errorMessage == "AdjustedBalance2"
              || res.data.paymentArrangementIneligibleErrorCodes != null) {
            let attributesData = new Map();
            attributesData.set('paymentArrangementIneligibleErrorCodes', JSON.stringify(res.data.paymentArrangementIneligibleErrorCodes));
            attributesData.set('isMultiAccount', false);
            attributesData.set('noAmtDue', true);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
            this.$router.push({
              name: "PaymentArrngmtsIneligibile",
              params: {},
            });
          }else{
            // default error
            this.dvarError = true;
            this.errorMessage = 'We’re sorry. We can’t complete this transaction. Please try again later.';
            this.statusError();
          }
        }
        
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      } catch (error) {
        // api fails
        this.dvarError = true;
        this.errorMessage = " We’re sorry. We can’t complete this transaction. Please try again later.";
        this.statusError();
				window.dataLayer?.push({
					event: 'pa_error',
					accountNumber: pii(this.state.number),
					errorName: 'account not valid',
					flow:{
						name: 'pa',
						step: 'start'
					},
				}); 
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
      }
    },

    gtmLogging(event){
      if(event == 'PPBSignIn') {
        window.dataLayer?.push({
          event: 'pa_ppb_sign_in_link',
          flow:{
            name: 'pa',
            step: 'ctl|account|payment_arrangements|prepaid_acct_msg|link|sign_in'
          },
        });
      }
    }
  },

  mounted() {
    this.$store.dispatch(RESET);
    this.enableTnEmail = process.env.VUE_APP_ENABLE_TN_EMAIL;
    this.isDay1PlusToggleOn = process.env.VUE_APP_DAY1_PLUS_TOGGLE
    let attributesData = new Map();
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    Array.from(
      document.querySelectorAll('button[data-bs-toggle="popover"]')
    ).forEach((popoverNode) => new Popover(popoverNode));
    attributesData.set('showLoading', false);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});

    window.dataLayer?.push({
      event: 'pa_identify',
      flow:{
        name: 'pa',
        step: 'identify_page'
      },
    });
  },
  computed: {
    //next button disable and enable
    nextButtonCheck() {
      if (this.state.number == null || this.state.number == "" || this.state.zip == null || this.state.zip == "") {
        return true
      } else {
        return false
      }
    }
  }
};
</script>


<style scoped>
  #payBy {
    font-family: Maison Neue, sans-serif;
    font-weight: bold;
    text-align: start;
    margin-bottom: 0.25rem;
    padding-left: 0.5rem;
  }
  .radio-button {
    display: block;
    min-height: 1.5rem;
    padding-left: 0.5rem;
    margin-bottom: 0.125rem;
  }
  .radio-input {
    float: left;
    height: 1rem;
    width: 1rem;
    margin-top: 0.25rem;
  }
  .radio-input:checked {
    accent-color: #0047bb;
  }
  .radio-label {
    float: left;
    padding-left: 0.5rem;
    font-size:16px;
  }
  #norton-logo {
    background-image: url(../../assets/images/mya_paybill_sprite_blue.png);
    background-repeat: no-repeat;
    background-position: -40px -335px;
    height: 70px;
    width: 110px;
    margin: 0;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .prepaidAccountError {
    font-size: 14px;
    padding: 5px 0 0 40px;
    background: url("../../images/svg/warning_black_24dp.svg") no-repeat 1px 1px;
    text-align: left;
    margin: 0 20px 20px 0;
    background-position: 5px 5px;
  }
  .outside-box {
    background: #faf8f8;
    border: #E9B932 solid 1px;
    margin: 10px;
  }
  .heading-style {
    font-family: MaisonNeue-Light, serif;
    font-size: 36px;
    color: #14161A;
  }
  .label-style {
    color: #14161A99;
  }
  .prpd-qdd-err {
    line-height: 1.5em; 
    font-weight: bold; 
    font-size: 16px !important;
  }
  .marg-right {
    margin-right: 3rem;
  }
  #qbp-intro-wrapper {
    text-decoration: none;
  }
  .dvar-error {
    font-size: 14px;
    line-height: 1.35;  
    padding: 5px 2px 11px 45px;
    background: url("../../images/svg/icon-important.svg") no-repeat 1px 1px;
    text-align: left;
    background-position: 5px 10px;
  }
  #brightspeedLink {
    color: #0047bb;
    font-size: 14px;
    line-height: 1.5;
  }
  #brightspeedLearnMoreLink {
    text-decoration: none;
  }
  @media(max-width: 767px) {
    #norton-logo {
      margin: 0;
    }
  }
  button[aria-disabled="true"] {
    background-color: #fff;
    border-color: #fff;
    color: #14161A99;
    opacity: 1.0;
    pointer-events: none;
}
#dssp-intro-wrapper h1 {
	color: #14161A;
  font-size: 36px;
	letter-spacing: 0px;
  margin-bottom: 16px;
	opacity: 1;
  padding: 0;
}
#dssp-intro-wrapper h2 {
    color: #14161A;
    font-size: 24px;
    letter-spacing: 0.24px;
    opacity: 1;
    margin-bottom: 0;
}
.grid-align {
  padding: 0 2rem 0 4rem;

}

.button-align {
  justify-content: flex-end !important;
  padding-right: 2.5rem;
}
.pa-cris h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #000;
    padding-left: 35px;
    font-weight: bold;
}
.pa-cris p {
    margin-bottom: 10px;
    color: #000;
    padding-left: 35px;
    font-size: 20px;
}
.pa-cris {
    margin-bottom: 25px;
    box-shadow: 0px 1px 2px #d3d305;
    border: 1px solid #E9B932;
    border-radius: 8px;
    position: relative;
    text-align: left;
    padding: 15px 20px 10px;
}
.pa-cris:before {
    content: "";
    position: absolute;
    background: url(../../images/warning_black.svg) no-repeat;
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
    left: 18px;
    top: 15px;
}
.pa-cris a {
  padding-left: 35px;
}
.margin-18 {
  margin-top: 18px;
  margin-bottom: 18px;
  width: 368px;
  display: inline-block;
}
h4 {
  display: flex;
  flex-direction: row;
}
h4:before, h4:after{
  content: "";
  flex: 1 1;
  margin: auto;
  border: 1px solid #707070;
  opacity: 0.2;
}
h4:before {
  margin-right: 10px
}
h4:after {
  margin-left: 10px
}
.sign-in {
  top: 525px;
  left: 456px;
  width: 100%;
  height: 36px;
  border: 1px solid #0047BB;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0px;
  color: #0047BB;
  display: inline-block;
  float: none;
}
.sign-in:hover {
  border: 1.2px solid #0047BB;
}
#signintomyaccount {
  text-decoration: none;
}

.pa-or {
    padding: 0 2rem 0 4rem;
    margin-bottom: 20px;
}

.pa-or h4{
  font-style: italic;
  padding: 0 30px;
}

.pa-signin {
    padding: 0 2rem 0 4rem;
}

.pa-signin .col-11{
    padding-left: 8px;
}

@media(max-width: 767px){
  .pa-signin {
    padding: 0 0 0 1rem;
}

.pa-or {
    padding: 0 0 0 1rem;
}

.grid-align {
    padding: 0 0 0 1rem;
}
}
.filtr-blue {
  filter: invert(17%) sepia(100%) saturate(2799%) hue-rotate(213deg) brightness(88%) contrast(103%);
}
</style>