<template>
    <div class="justify-content-center">
        <div>
            <PASuccessSchedulePmts/>
        </div>
    </div>
</template>


<script>
import PASuccessSchedulePmts from '../components/paymentArrngmts/PASuccessSchedulePmts.vue';

export default {
    name: 'Confirm',
    components: {
       PASuccessSchedulePmts,
    }
}
</script>
<style scoped>
#left_content{
    padding-right:20px;
    align-content: flex-end;
}
#right_content{
    padding-left: 20px;
    align-content: flex-end;
}
#app .qbp-confirm {
    text-align: left;
}
.okBtn {
    padding-right: 20px;
    padding-bottom: 20px;
}
.okBtn a{
    color: #fff ;
    text-decoration: none ;
}
.okBtn a:hover{
    color: #fff;
    text-decoration: none;
}
.modalContent {
    padding-bottom: 0;
    padding-top: 35px;
}
</style>
