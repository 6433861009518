<template>
    <div class="wallet-autopay">
        <div class="autopay-link">
            <p>Updating your payment method in your wallet does not modify your payment method for your Auto Pay. Go to <a :href="`/autopay/manage?token=${token}`">Auto Pay</a> to modify your auto pay payment method after you have updated your wallet.</p>
        </div>
        <div class="norton-logo-container">
            <div id="norton-logo"><img alt="norton logo" src="../../images/norton.png" /></div>
        </div>
    </div>
</template>
<script>
import { GET_DATA } from "../../store/sessioncontants";

export default {
  name: "manageWalletAutopay",
  data() {
    return {
      token: null,
    };
  },
  mounted() {
    this.token = this.$store.getters[GET_DATA]("token");
  }
};
</script>
<style scoped>
.autopay-link {
    text-align: left;
    margin-top: 20px;
}
#norton-logo {
    background-position: initial;
    height: auto;
    width: auto;
    text-align: left;
    margin: 10px 0 20px 0;
}
.wallet-autopay {
    clear:both;
}
</style>