<template>
  <div class="container d-flex justify-content-center mb-3">
    <div class="row">
      <QBPMultiAccount/>  
    </div>
  </div>
</template>

<script>
import QBPMultiAccount from '../components/qbp/QBPMultiAccount.vue'
export default {
  name: 'PaymentArrngmtsIdentify',
  components: {
    QBPMultiAccount,
  }
}
</script>