
import { defineComponent } from 'vue';
import links from "../../js/links";
export default defineComponent({
  name: 'QuickPayRightBar',
  props: {
    msg: String,
  },
  data () {
	return {
		PaymentMethodsURL: links.PaymentMethodsURL,
		ServiceRestoreURL: links.ServiceRestoreURL,
		PaymentArrangementsIdentifyURL : links.PaymentArrangementsIdentifyURL,
		AutoPayURL: links.AutoPayURL,
		MyaEntryPointURL: links.MyaEntryPointURL,
		PaperlessBillingInfoURL: links.PaperlessBillingInfoURL
	}
  },
});
