<template>
  <div id="qbp-exception-message" class="qbpMessageBox">
    <div class="qbpTextBox">
      <h3>No need to make a payment today.</h3>
      <p>Looks like your account is all paid up.</p>
      <p>
        To make an additional payment, please
        <a :href="MyaEntryPointURL" id="qbpSignIn">sign in to My CenturyLink</a
        >.
      </p>
    </div>
  </div>
</template>
<script>
import links from "../../js/links";
import { pii } from "../../js/pii";
import { GET_DATA } from "../../store/storecontants";

export default {
  name: "noBalance",
  data() {
    return {
      MyaEntryPointURL: links.MyaEntryPointURL,
    };
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "qbp_error",
      accountNumber: pii(this.accountNumber),
      errorName: "no payment due",
      flow: {
        name: "qbp",
        step: "payment flow",
        event: "no payment due",
      },
    });
  },
};
</script>
<style scoped>
#qbp-exception-message h3 {
  font-weight: bold;
}
#qbp-exception-message p {
  margin-bottom: 5px;
}
.qbpTextBox {
  position: relative;
}
.qbpTextBox:before {
  content: "";
  position: absolute;
  background: url(../../images/icon-important-orange.svg) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
}
@media (max-width: 767px) {
  #qbp-exception-message h3 {
    min-height: 30px;
    padding-top: 3px;
  }
}
</style>