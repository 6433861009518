<template>
    <div id="twoTier-pageBody" class="container contentBlocks homeContentBlocks">
                <div id="page-content-tireTwo-error" class="page-content-tireTwo-error">
                    <div class="row">			
                        <div id="serverError" class="error-wrapper2">
                            <h3>Oops. That's not quite right.</h3>
                            <p>Sorry, but the page you're looking for wasn't found. You may have clicked an outdated or broken link, or we may have moved or deleted the page.</p>
                            <p>Don't worry, everything is going to be ok.</p>
                            <p><strong>To get back on track, please use the other links on this page.</strong></p>
                            <p>If this is the first time you're seeing this message try again in 15 minutes or so. Or, <a id="autoPayPurl_chat" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" data-galink-type="chat" rel="noopener">chat with us</a> so we can help.</p>	
                        </div>
                    </div>
                </div>
    </div>
</template>
<style scoped>

/* System Error start */

.page-content-tireTwo-error .error-wrapper2{
  width: 100%;
  max-width: 700px;
  margin: auto;
}

@media (min-width: 768px) {
  .page-content-tireTwo-error {
    border: 0.1875rem solid white;
    margin-bottom: 1rem;
    padding: 2.625rem;
    min-height: 300px;
  }
}

.page-content-tireTwo-error h2 {
  text-align: left;
  margin-left: 35px;
}

.page-content-tireTwo-error h3 {
  margin-left: 50px;
  margin-bottom: 20px;
}


#systemError1 a {
  text-decoration: underline;
}


.error-wrapper2 {
  padding: 0px 10px 0px;
}

/* System Error end */
</style>