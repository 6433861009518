<template>
  <div class="overlay"></div>
  <div id="qbp-left-column" class=" col-xs-12 col-sm-12">
    <div id = "qbp-left-column-content">
      <div id="dssp-intro-wrapper" class="text-center">
        <h1>Payment Plans</h1>
      </div>
      <!-- message for brightspeed and prepaid account start -->
      <div v-if="this.status != null" class="orchestrator-error">
        <p class="text-start mt-2 orchestrator-error-message" tabindex="0" ref="statusError">{{ this.status }}</p>
        <a  v-if="this.brightspeed == true" class="bspd-url" target="_blank" :href="BrightspeedPaURL">Make your payment at Brightspeed</a>
        <div v-if="this.ppb == true">
          <p>Prepaid customers are billed automatically each month on a credit or debit card of choice.</p> 
          <p>Service is auto-renewed until you choose to cancel.</p>
          <p>Manage or modify your payment info? </p>
          <p><a :href="this.signInURL" class="twoErrorZipSys" id="qbpSimplePay_signIn">Sign into My CenturyLink</a></p>
        </div>
      </div>
      <!-- message for brightspeed and prepaid account end -->
      <!-- BMD-20024 -->
      <!-- New Multi account screen desing -->
      <!-- multiaccount form start -->
      <div class="mb-3 my-2 ms-0 me-0 justify-content-center">
      <div class="container multiOptionDiv">
        <p class="text-start mx-1 mt-3 ms-2">
          <span class="multiTitle" data-test="messageHead">Which account would you like to see? </span>
        </p>
        <p class="text-start ms-2">
          There are multiple accounts connected to that {{this.selectedMutliAccount}}, Please select an account to make a payment.
        </p>
        <div class="cards" style="overflow-y: scroll;">
          <div class="row mx-2 multiCard" v-for="(accountList, index) in MultiAccount.accountList" :key="index" :value="accountList" v-bind:selected="index===0" :id="'acctNo-' + index" :class="{ 'selected': index===this.isSelected}" @click="getSelectedAcct(accountList.accountNumber, index)">
            <div class="col-lg-9 col-md-8 col-10 p-3 px-4 px-sm-0 px-md-4 px-lg-4 px-xl-3 px-xxl-2 text-start mx-2">
              <span>
                <span>Account: {{accountList.maskedAccountNumber}}</span>
                <br>
                <span>Service Address:<br>{{accountList.address}}.</span>
              </span>
            </div>
          </div>
        </div>
        <div class="row mx-2">	
        <div class="col-12 col-lg-6 col-md-6 col-sm-6 gap-2" id="multi-account-next-button-wrapper">
          <button type="button" id="multi-account-next-button" class="btn btn-primary text-center" :class="{ 'disabled': this.isSelected===null}" @click="submitForm">Continue</button>
          <button type="button" id="multi-account-cancel-button" class="btn btn-primary text-center" @click="cancelRedirect">Cancel</button>
        </div>
      </div>
      </div>
    </div>
      <!-- multiaccount form end -->
    </div>   
  </div>
</template>
               
<script>
  import axios from 'axios'
  import {GET_DATA, SET_DATA } from "../../store/storecontants";
  import constants from "../../js/constants";
  import links from "../../js/links";
  import {pii} from "../../js/pii";
  import {PMTARNGMNT_001, PMTARNGMNT_002,PMTARNGMNT_003, PMTARNGMNT_006, PMTARNGMNT_005, PMTARNGMNT_008, PMTARNGMNT_009, PMTARNGMNT_010, NO_AMOUNT_DUE, PHONE_INVALID_ERROR_CODE,
   EMAIL_INVALID_ERROR_CODE, ZIP_INVALID_ERROR_CODE, SYSYTEM_DOWN, DEFAULT_ERROR_PA, ACCOUNT_MAINTENANCE, PA_PMTARNGMNT_008_ERROR_MESSAGE} from "../../utils/ErrorCodes"

  export default {
    name : 'PAMultiAccount',
    data() {
      return{
        MultiAccount:[],
        selected:'',
        isSelected: null,
        selectedAccount:null, 
        selectedZipCode:'',
        isMultiAccount:'',
        select: false ,
        onselect: true,
        status: null,
        ppb: false,
        brightspeed: false,
        BrightspeedPaURL: links.BrightspeedPaURL,
        signInURL : process.env.VUE_APP_EAM_DOMAIN+ '/eam/entryPoint.do',
        //onchange get the selected account number
        onChange(e){
          this.ppb = false;
          this.brightspeed = false;
          this.status = null;
          this.selectedAccount = e.target.options[e.target.options.selectedIndex].text;
          if(this.selectedAccount!=null){
            this.select= true;
            this.onselect = true;
          } else {
            this.onselect = false;
          }
        }
      }
    },

  methods : {
    // accessability error message focus
    statusError () {
      setTimeout(() => { 
        let errorDiv = this.$refs.statusError
        if(errorDiv) {
          this.$refs.statusError.focus();
        }
      }, 500) 
    },
    getSelectedAcct(account, index){
      this.isSelected = index
      this.selectedAccount = account;
    },
    cancelRedirect(){
      this.$router.push({
        name: "PaymentArrngmtsIdentify"
      });
    },
    async submitForm(){
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      if(this.selectedAccount !=null){
        // selcted account with params to call api
        try {
          const params = { 
            accountNumber: this.selectedAccount, 
            zipCode: this.selectedZipCode,
            matchActual: true 
          };
          const res = await axios.get(constants.PaymentArrangementIdentifyURL, { params });
          // Acod profile required field and encrypted account number for success call 
          if(res.data) {
            attributesData.set("emailAddress", JSON.stringify(res.data.emailAddressOfRecord))
            attributesData.set("firstName", JSON.stringify(res.data.acodFirstName))
            attributesData.set("lastName", JSON.stringify(res.data.acodLastName))
            //encrypted account number for success, cancel and failure call
            attributesData.set("AcodAccountNumber", JSON.stringify(this.selectedAccount))
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
          }
          if(res?.data?.errorInfo?.errorMessage == "ERRDSSPQBP0001") {
            window.dataLayer?.push({
              company: 'brightspeed',
              event: 'my_ctl_exception_message',
              errorName: 'bspd_PA'
            });
          } else {
            window.dataLayer?.push({
              company: 'centurylink'
            })
          }
          // accountHasErrors function check any error code else continue
          if(this.accountHasErrors(res) == false){
            if (res.data.paymentArrangementEligible == true && res.data.biller == 1) {
              let attributesData = new Map();
              attributesData.set('accountEntered', JSON.stringify(this.selectedMutliAccount));
              attributesData.set('zipEntered', JSON.stringify(this.selectedZipCode));
              attributesData.set('data', JSON.stringify(res.data));
              attributesData.set('isMultiAccount', true);
              this.$store.dispatch(SET_DATA, {attributes:attributesData});
              this.$router.push({
                name: "PaymentArrngmtsOptions",
                params: {},
              });
            } else if (res.data.paymentArrangementEligible == true && res.data.biller == 2) {
              let attributesData = new Map();
              attributesData.set('accountEntered', JSON.stringify(this.selectedMutliAccount));
              attributesData.set('data', JSON.stringify(res.data));
              attributesData.set('isMultiAccount', true);
              this.$store.dispatch(SET_DATA, {attributes:attributesData});
              this.$router.push({
                name: "PAConfirm",
                params: {},
              });
            } else if (
              res.data.paymentArrangementIneligibleErrorCodes != null &&
              res.data.paymentArrangementIneligibleErrorCodes.includes("11002")
            ) {
              //check and navigate to paymentArrangementIneligible
              let attributesData = new Map();
              attributesData.set('paymentArrangementIneligibleErrorCodes', JSON.stringify(res.data.paymentArrangementIneligibleErrorCodes));
              attributesData.set('enteredAccountNumber', this.selectedMutliAccount);
              attributesData.set('enteredZipCode', this.selectedZipCode);
              attributesData.set('selectedAcctNumber', this.selectedAccount);
              attributesData.set('status', JSON.stringify(res.data));
              attributesData.set('noAmtDue', false);
              attributesData.set('isMultiAccount', true);
              this.$store.dispatch(SET_DATA, {attributes:attributesData});
              this.$router.push({
                name: "PaymentArrngmtsIneligibile",
                params: {},
              });
            }else if (
              res.data.paymentArrangementIneligibleErrorCodes != null &&
              res.data.paymentArrangementIneligibleErrorCodes.includes("10017")
            ) {
              // navtigate to paymentArrangementIneligible with threshold 
              let attributesData = new Map();
              attributesData.set('paymentArrangementIneligibleErrorCodes', JSON.stringify(res.data.paymentArrangementIneligibleErrorCodes));
              attributesData.set('enteredAccountNumber', this.selectedMutliAccount);
              attributesData.set('enteredZipCode', this.selectedZipCode);
              attributesData.set('selectedAcctNumber', this.selectedAccount);
              attributesData.set('status', JSON.stringify(res.data));
              attributesData.set('noAmtDue', false);
              attributesData.set('isMultiAccount', true);
              let thresholdErrorMessage = res.data.errorMessage
              let thresholdValAmt = thresholdErrorMessage.substring(
                  thresholdErrorMessage.indexOf("=") + 1, 
                  thresholdErrorMessage.lastIndexOf("|")
              );
              attributesData.set('thresholdVal', thresholdValAmt);
              attributesData.set('adjustedAmount', res.data.adjustedAmount);
              this.$store.dispatch(SET_DATA, {attributes:attributesData});
              this.$router.push({
                name: "PaymentArrngmtsIneligibile",
                params: {},
              });
            }else if ((res.data.paymentArrangementEligible == false && res.data.status == 0 && res.data.adjustedAmount == "0.00") 
                  || res.data?.account?.errorInfo?.errorMessage == "AdjustedBalance2"
                  || res.data.paymentArrangementIneligibleErrorCodes != null) {
              let attributesData = new Map();
              attributesData.set('paymentArrangementIneligibleErrorCodes', JSON.stringify(res.data.paymentArrangementIneligibleErrorCodes));
              attributesData.set('enteredAccountNumber', this.selectedMutliAccount);
              attributesData.set('enteredZipCode', this.selectedZipCode);
              attributesData.set('selectedAcctNumber', this.selectedAccount);
              attributesData.set('status', JSON.stringify(res.data));
              attributesData.set('noAmtDue', true);
              attributesData.set('isMultiAccount', true);
              this.$store.dispatch(SET_DATA, {attributes:attributesData});
              this.$router.push({
                name: "PaymentArrngmtsIneligibile",
                params: {},
              });
            }
            
          }
        } catch (error) {
          console.log(error)
        }
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
      } else {
        this.select = false;
        this.onselect = false;
        this.statusError();
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, {attributes:attributesData});
      }
    },
      // any error code display the error message at the top
      accountHasErrors(res){
        this.ppb = false;
        this.brightspeed = false;
        this.status = null;
        if (res.data.errorCode == PMTARNGMNT_001) {
          this.ppb = true;
          this.status = "This is a Prepaid account.";
          this.statusError();
          return true;
        }
        if (res.data.errorCode == PMTARNGMNT_002) {
          return false;
        }
        if (res.data.errorCode == PMTARNGMNT_003) {
          this.status = 'We’re sorry. We can’t complete this transaction. Please try again later.';
          this.statusError();
          return true;
        }  
        if (res.data.errorCode == PMTARNGMNT_006) {
          this.status = DEFAULT_ERROR_PA;
          this.statusError();
          return true;
        }
        if (res.data.errorCode == PMTARNGMNT_005) {
          if (res?.data?.paymentArrangementIneligibleErrorCodes != null && res?.data?.paymentArrangementIneligibleErrorCodes.includes("10015")) {
            return false;
          } else {
            this.status = DEFAULT_ERROR_PA
            this.statusError();
            return true;
          }
        }
        if (res.data.errorCode == PMTARNGMNT_008) {
          this.status = PA_PMTARNGMNT_008_ERROR_MESSAGE;
          this.statusError();
          return true;
        }
        if (res.data.errorCode == PMTARNGMNT_009) {
          this.status = ACCOUNT_MAINTENANCE;
          this.statusError();
          return true;
        }
        if (res.data.errorCode == PMTARNGMNT_010) {
        this.status = NO_AMOUNT_DUE;
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.accountNumber),
          errorName: 'zero balance due',
          flow:{
            name: 'pa',
            step: 'eligibility'
          },
        });
          return true;
        }
        if (res.data.errorCode == ZIP_INVALID_ERROR_CODE) {
          this.status = res.data.errorMessage;
          return true;
        }
        if (res.data.errorCode == PHONE_INVALID_ERROR_CODE) {
          this.status = res.data.errorMessage;
          return true;
        }
        if (res.data.errorCode == EMAIL_INVALID_ERROR_CODE) {
          this.status = res.data.errorMessage;
          return true;
        }
        if (res.data.errorCode == EMAIL_INVALID_ERROR_CODE) {
          this.status = res.data.errorMessage;
          return true;
        }
        if (res.data.errorCode == SYSYTEM_DOWN) {
          this.status = res.data.errorMessage;
          this.statusError();
          return true;
        }
        if (res.data.errorInfo != null) {
          if (res.data.errorInfo.errorMessage == "ERRDSSPQBP0001"){
            window.dataLayer?.push({
              event: 'my_ctl_exception_message',
              errorName: 'bspd_PA'
            });
            this.brightspeed = true;
            this.status = "Hello. Your service provider is now Brightspeed.";
            this.statusError();
            return true;
          }
        }
        if(res.data.errorCode != "" && res.data.errorCode != null){
          this.status = 'We’re sorry. We can’t complete this transaction. Please try again later.';
          this.statusError();
          return true;
        }
        return false;
      },
    },

    mounted(){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pa_multi_account',
        flow:{
          name: 'pa',
          step: 'multi_account_page'
        },
      });
      
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      this.MultiAccount = JSON.parse(this.$store.getters[GET_DATA]('multiData'))
      this.selectedZipCode = JSON.parse(this.$store.getters[GET_DATA]('zipcode'))
      this.isMultiAccount =  JSON.parse(this.$store.getters[GET_DATA]('status'))
      this.selectedMutliAccount = JSON.parse(this.$store.getters[GET_DATA]("selectedAccountNumber"));
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
    },

 }
 
</script>

<style scoped>

  #qbp-left-column-content{
    position: relative;
  }

  #h2text{
    font-weight: bold;
    color: black !important;
  }

  #rightBoxContent{
    margin-top: 59.2px;
  }

  @media screen and(max-width: 767px) {
    .mobileview-right-column{
      margin-top: -19px;
    }
    #rightBoxContent{
      margin-top: 15px;
    }
  }

  #norton-logo {
    background-image: url(../../assets/images/mya_paybill_sprite_blue.png);
    background-repeat: no-repeat;
    background-position: -40px -335px;
    height: 70px;
    width: 110px;
  }

  .formError{
    background:  url(../../images/global-alert-icon-2.svg) no-repeat 1px 1px;
    background-repeat: no-repeat;
    background-size: 36px 30px;
    padding: 2px 15px 3px 38px;
    color:#d0021b;
    font-size: 14px;
    line-height: 1.5;
  }

  .overlay{
    opacity:0.8;
    background-color:rgba(0,0,0,.32);
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;

    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
  }

  @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 0.8; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 0.8; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 0.8; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 0.8; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 0.8; }
  }


  #dssp-intro-wrapper h1 {
  color: #14161A;
  font-size: 36px;
  letter-spacing: 0px;
  margin-bottom: 16px;
  padding: 0;
  }
  #dssp-intro-wrapper h2 {
    color: #14161A;
    font-size: 24px;
    letter-spacing: 0.24px;
  }


  .orchestrator-error {
    position: relative;
    z-index:1001;
  }

  .multiOptionDiv{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border: 1px solid #EEEEEE33;
  border-radius: 8px;
  opacity: 1;
  z-index:1001;
  position: relative;
  width: 505px;
  }

  @media all and (max-width: 540px) {
  .multiOptionDiv {
    width: 85%;
    height: 100%;
  }
  #multi-account-next-button {
    margin-left: 0px
  }
}

.multiTitle{
  text-align: left;
  font-family: MaisonNeue-Light, serif;
  font-size: 24px;
  letter-spacing: 0.16px;
  color: #14161a;
  opacity: 1;
}

.multiCard{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border: 1px solid #EEEEEE33;
  border-radius: 8px;
  opacity: 1;
  margin-bottom: 12px;
}

.cards {
  height: 218px;
  margin-bottom: 24px;
  line-height: 22px;
}

.selected {
  background-color: #0047BB;
  color: #FFFFFF;
}
</style>
