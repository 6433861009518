<template>
    <div class="mwContainer">
        <div class="row">
            <h1 class="manage-wallet-heading">Manage Saved Payment Methods</h1>
            <div class="col-sm-6 col-xs-12 text-start content-margin">
                <p>Paying your bill gets even faster and easier when you ask us to remember your payment information.</p>
                <p>By securely storing one or more different payment accounts, you won't need to enter all those numbers each time you pay online or pay through our automated phone system.</p>
                <p>For your security, sensitive payment information cannot be edited. However, you can remove outdated saved payment accounts, update the expiration date of any credit cards you have on file and choose your preferred default payment method.</p>
            </div>
            <div class="col-sm-6 col-xs-12 content-margin">
                <img class="manage-wallet-img" src="../../images/manage_wallet_landing.svg" alt="manage-wallet-img" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ManageWalletFailure',
}
</script>
<style scoped>
    .manage-wallet-content .manage-wallet-heading {
        color: #000;
    }
    .manage-wallet-heading {
        font-weight: 500;
        margin-top: 1rem;
        color: #000;
        font-family: 'MaisonNeue-Light', sans-serif;
        font-size: 36px;
    }
    .manage-wallet-content .manage-wallet-paragraph {
        text-align: left;
    }
    .manage-wallet-paragraph p {
        margin-bottom: 20px;
    }
    .content-margin {
        margin: 2rem 0 2rem 0;
    }
    .content-margin p {
        line-height: 24px;
    }
    .pre-iframe-heading {
        color: #000;
    }
</style>