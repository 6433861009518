<template>
    <div class="container d-flex justify-content-center mb-3">
        <div class="row">
            <PAMultiAccount/>
        </div>
    </div>
</template>

<script>

import PAMultiAccount from '../components/paymentArrngmts/PAMultiAccount.vue'

export default {
  name: 'PaymentArrngmtsMultiAccount',
  components: {
    PAMultiAccount,
  }
}

</script>