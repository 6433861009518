<template>
    <div class="container qbp-confirm">
        <div class="row">
            <div class="col-md-8 col-xs-12" id="left_content">
                <PAConfirm/>
            </div>
            <div class="col-md-4 col-xs-12" id="right_content">
                <payArrngmtsRightBar/>
            </div>
        </div>
    </div>
</template>




<script>
import PAConfirm from '../components/paymentArrngmts/PAConfirm.vue'
import payArrngmtsRightBar from '../components/paymentArrngmts/payArrngmtsRightBar.vue'
export default {
    name: 'Confirm',
    components: {
        PAConfirm,
        payArrngmtsRightBar
    },
    mounted() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            page: {
                pageType: 'pa',
                pageName: 'ctl|account|payment_arrangement|payment_form_iframe'
            }
        });
   }
}
</script>
<style scoped>
.qbp-confirm {
    max-width: 800px;
    font-family: Maison Neue,sans-serif;
}
#left_content{
    padding-right:20px;
    align-content: flex-end;
}
#right_content{
    padding-left: 20px;
    align-content: flex-end;
}
#app .qbp-confirm {
    text-align: left;
}
</style>
