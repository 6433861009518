<template>
    <div class="container d-flex justify-content-center bill-box"><post-bill-success/></div>
</template>
<script>
import postBillSuccess from '../components/Postbill/postBillSuccess.vue'
export default {
    name: 'PostLoginBillSuccess',
    components: {
        postBillSuccess
    }
}
</script>
<style>
.bill-box {
    width: 60%;
    height: 100%;
    border-radius: 5px;
    margin-bottom: 1rem;
}

@media screen and (min-width: 375px) and (max-width: 979px) {
    .bill-box {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
}
@media print{
    .bill-box {
        width: 100%;
    }
}
</style>