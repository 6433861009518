<template>
  <div class="root">
    <div id="qbp-left-column-content">
      <div class="row" id="dssp-intro-wrapper">
        <h2 class="text-start p-0">Make Payment Plans</h2>
        <p class="text-start p-0" data-test="messageData">
          This option is available to customers who need additional time to pay their bill.
        </p>
        <p class="text-start p-0" data-test="paymentArrangement">
          <strong>You're making payment plans for:</strong>
        </p>
        <div class="row p-0">
          <div class="col-md-5 col-sm-12">
            <h3 class="text-start">
              <p style="color: black">{{ this.formattedNumber }}</p>
            </h3>
          </div>
          <div class="col-md col-sm-12" v-if="this.isMultiaccount">
            <span class="text-start" id="changeAccount" @click="changeAcct()">Change Account</span>
          </div>
        </div>
      </div>
      <div class="col">
        <div v-if="this.paData.iframeUrl != ''" class="col-12" id="grid1">
          <h3 style="margin-bottom: 0px">
            <spinner class="mx-auto d-block" style="height:100px; width:100px;" v-if="showLoader"></spinner>
            <iframe @load="checkIframeLoaded()" width="100%"
              style="height: 1300px; width: 100%; border: none; overflow: hidden;"
              :src="this.paData.iframeUrl" title="paIframe" class="pa-iframe-container"
            ></iframe>
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_DATA, SET_DATA } from "../../store/storecontants";
import { reactive } from "vue";
import { DEFAULT_ERROR_PA } from "../../utils/ErrorCodes";
import Spinner from "../../components/Spinner.vue";

export default {
  name: "PAConfirm",
  components: {
    Spinner,
  },

  data() {
    return {
      dvarError: false,
      errorMessage: DEFAULT_ERROR_PA,
      status: null,
      singleAccount: "",
      showLoader: false,
      formattedNumber: null,
      isMultiaccount: false
    };
  },

  setup() {
    const paData = reactive({
      accountNumber: null,
      iframeUrl: null,
    });
    return {
      paData,
    };
  },

  mounted() {
    let attributesData = new Map();
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    this.responseData = JSON.parse(this.$store.getters[GET_DATA]("data"));
    this.paData.accountNumber = this.responseData.maskedAccount ? this.responseData.maskedAccount : JSON.parse(this.$store.getters[GET_DATA]("accountEntered"));
    this.paData.iframeUrl = this.responseData.iframeUrl;
    this.isMultiaccount = JSON.parse(this.$store.getters[GET_DATA]("isMultiAccount"));
    this.formatter();
    attributesData.set('showLoading', false);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    this.showLoader = true;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        pageType: 'pa',
        pageName: 'ctl|account|payment_arrangement|make_payment'
      }
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pa_cris_customer_eligible',
      flow:{
        name: 'pa',
        step: 'make_payment_page'
			},
    });
  },

  methods: {
    // when iframe load turn off spinner
    checkIframeLoaded(){
      if(this.$store.getters[GET_DATA]('showLoading') == false){
        this.showLoader = false;
      }
    },
    // click on multiaccount link navigate to multiaccount
    changeAcct() {
      this.$router.push({
        name: "PAMultiAccount",
        params: {},
      });
    },
    formatter: function () {
      if (this.paData.accountNumber.length > 12) {
        this.formattedNumber =
          this.paData.accountNumber.substr(0, 3) +
          "-" +
          this.paData.accountNumber.substr(3, 3) +
          "-" +
          this.paData.accountNumber.substr(6, 4) +
          " " +
          this.paData.accountNumber.substr(10);
      } else if (this.paData.accountNumber.length == 10) {
        this.formattedNumber =
          this.paData.accountNumber.substr(0, 3) +
          "-" +
          this.paData.accountNumber.substr(3, 3) +
          "-" +
          this.paData.accountNumber.substr(6, 4);
      } else {
        this.formattedNumber = this.paData.accountNumber;
      }
    },
  },
};
</script>

<style scoped>
#qbp-left-column-content strong {
  font-weight: bold;
}
#qbp-left-column-content h2 p {
  font-weight: bold;
}
#changeAccount {
  color: #0047BB;
  cursor: pointer;
}
#changeAccount:hover {
  text-decoration: underline;
}
</style>
