<template>
    <div class="justify-content-center">
      <WGNonpaid/>
    </div>
  </template>
  <script>
  import WGNonpaid from '../components/WalledGarden/WGNonpaid.vue'
  export default {
    name: 'WalledGardenNP',
    components: { WGNonpaid }
  };
  </script>
  <style>
  </style>