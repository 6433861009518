<template>
  <div class="grey-bg">
      <div class="col-md-12 col-xs-12">
        <SuccessfulPayments />
      </div>
      <!-- <div class="col-md-6 col-xs-12">
        <QuickPayRightBar />
      </div> -->
  </div>
</template>
<script>
// import QuickPayRightBar from "../components/qbp/quickPayRightBar.vue";
import SuccessfulPayments from "../components/qbp/SuccessfulPayments.vue";

export default {
  name: "Success",
  components: {
    // QuickPayRightBar,
    SuccessfulPayments,
  }
}
</script>

<style scoped>
.home-page {
  color: #0d6efd;
  cursor: pointer;
  text-decoration: none;
}

.grey-bg{
  background-color: #FAFAFA;
  margin-top: -20px;
  padding-top: 40px;
}

</style>
