<template>
    <div class="container d-flex justify-content-center autopay-box"><autopay-details/></div>
    <div class="container d-flex justify-content-center autopaysuccessbox"><autopayFailure/></div>
</template>
<script>
import autopayFailure from '../components/Autopay/autopayFailure.vue'
import autopayDetails from '../components/Autopay/AutopayDetails.vue'

export default {
  name: 'FailureAP',
  components: {
    autopayDetails,
    autopayFailure
  }
};
</script>
<style scoped>
.autopay-box {
  width: 58%;
  height: 100%;
  border-radius: 5px;
}

.autopaysuccessbox {
  margin-top: 1rem;
  width: 58%;
  height: 100%;
  border-radius: 5px;
}

@media(max-width: 979px) {
  .autopay-box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .autopaysuccessbox {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}

</style>