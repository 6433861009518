<template>
  <div class="container d-flex justify-content-center">
    <div class="row">
      <div id="qbp-left-column" class=" col-xs-12 col-sm-9">
        <div id = "qbp-left-column-content">
          <div id="dssp-intro-wrapper" class="text-start">
            <h3 data-test="messageHead"></h3>
          </div>
          <div v-if="this.status != null" class="orchestrator-error">
            <p class="text-start mt-2 orchestrator-error-message">{{ this.status }}</p>
            <a  v-if="this.brightspeed == true" class="bspd-url" target="_blank" :href="BrightspeedPaURL">Make your payment at Brightspeed</a>
            <div v-if="this.ppb == true">
              <p>Prepaid customers are billed automatically each month on a credit or debit card of choice.</p> 
              <p>Service is auto-renewed until you choose to cancel.</p>
              <p>Manage or modify your payment info? </p>
              <p><a :href="this.signInURL" class="twoErrorZipSys" id="qbpSimplePay_signIn">Sign into My CenturyLink</a></p>
            </div>
          </div>      
        </div>   
      </div>
    
    </div>
  </div>
</template>
               
<script>
  import axios from 'axios'
  import { SET_DATA, GET_DATA} from "../store/sessioncontants";
  import { SET_DATA as local_setdata } from "../store/storecontants";
  import constants from "../js/constants";
  import links from "../js/links";
  import {pii} from "../js/pii";
  import {PMTARNGMNT_001, PMTARNGMNT_002,PMTARNGMNT_003, PMTARNGMNT_006, PMTARNGMNT_005, PMTARNGMNT_008, PMTARNGMNT_009, PMTARNGMNT_010, NO_AMOUNT_DUE, PHONE_INVALID_ERROR_CODE,
   EMAIL_INVALID_ERROR_CODE, ZIP_INVALID_ERROR_CODE, SYSYTEM_DOWN, DEFAULT_ERROR_PA, ACCOUNT_MAINTENANCE, PA_PMTARNGMNT_008_ERROR_MESSAGE} from "../utils/ErrorCodes"

  export default {
    name : 'PostPAIdentify',
    data() {
      return{
        accountNumber: "",
        zipCode: "",
        status: null,
        ppb: false,
        brightspeed: false,
        BrightspeedPaURL: links.BrightspeedPaURL,
        signInURL : process.env.VUE_APP_EAM_DOMAIN+ '/eam/entryPoint.do',
      }
    },

  methods : {
      
    async submitForm(){
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
        try {
          const postPArequest = {
            accountNumber: this.$store.getters[GET_DATA]('accountNumber'),
            biller: this.$store.getters[GET_DATA]('biller'),
            firstName: this.$store.getters[GET_DATA]('firstName'),
            lastName: this.$store.getters[GET_DATA]('lastName'),
            companyOwnerId: this.$store.getters[GET_DATA]('companyOwnerId'),
            autoPay: this.$store.getters[GET_DATA]('autoPay'),
            paymentDueDate: this.$store.getters[GET_DATA]('paymentDueDate'),
            adjustedAmount: this.$store.getters[GET_DATA]('adjustedAmount'),
            confirmationEmailAddress:  this.$store.getters[GET_DATA]('confirmationEmailAddress'),
            billingAddressPrimaryLine: this.$store.getters[GET_DATA]('billingAddressPrimaryLine'),
            billingAddressCity: this.$store.getters[GET_DATA]('billingAddressCity'),
            billingAddressZip: this.$store.getters[GET_DATA]('billingAddressZip'),
            billingAddressStateCode: this.$store.getters[GET_DATA]('billingAddressStateCode'),
            billingAddressAddressLine1:  this.$store.getters[GET_DATA]('billingAddressAddressLine1'),
            billingAddressAddressLine2: this.$store.getters[GET_DATA]('billingAddressAddressLine2')

          };
          const res = await axios.post(constants.PaymentArrangementPostLoginIdentifyURL, postPArequest);
          if(res?.data?.errorInfo?.errorMessage == "ERRDSSPQBP0001") {
            window.dataLayer?.push({
              company: 'brightspeed',
              event: 'my_ctl_exception_message',
              errorName: 'bspd_PA'
            });
          } else {
            window.dataLayer?.push({
              company: 'centurylink'
            })
          }
          if(res.data) {
            let localData = new Map();
            localData.set("emailAddress", JSON.stringify(res.data.emailAddressOfRecord))
            localData.set("firstName", JSON.stringify(res.data.acodFirstName))
            localData.set("lastName", JSON.stringify(res.data.acodLastName))
            localData.set("AcodAccountNumber", JSON.stringify(res.data.ban))
            this.$store.dispatch(local_setdata, {attributes:localData});
          }
          if(this.accountHasErrors(res) == false){
            if (res.data.paymentArrangementEligible == true && res.data.biller == 1) {
              let localData = new Map();
              localData.set('accountEntered', JSON.stringify(this.accountNumber));
              localData.set('zipEntered', JSON.stringify(this.zipCode));
              localData.set('data', JSON.stringify(res.data));
              localData.set('isMultiAccount', false);
              localData.set('authHeader', true);
              this.$store.dispatch(local_setdata, {attributes:localData});

              this.$router.push({
                name: "PaymentArrngmtsOptions",
                params: {},
              });
            } else if (res.data.paymentArrangementEligible == true && res.data.biller == 2) {
              let localData = new Map();
              localData.set('accountEntered', JSON.stringify(this.accountNumber));
              localData.set('data', JSON.stringify(res.data));
              localData.set('isMultiAccount', false);
              this.$store.dispatch(local_setdata, {attributes:localData});
              this.$router.push({
                name: "PAConfirm",
                params: {},
              });
            } else if (
              res.data.paymentArrangementIneligibleErrorCodes != null &&
              res.data.paymentArrangementIneligibleErrorCodes.includes("11002")
            ) {
              let localData = new Map();
              localData.set('paymentArrangementIneligibleErrorCodes', JSON.stringify(res.data.paymentArrangementIneligibleErrorCodes));
              localData.set('enteredAccountNumber', this.accountNumber);
              localData.set('enteredZipCode', this.zipCode);
              localData.set('status', JSON.stringify(res.data));
              localData.set('noAmtDue', false);
              localData.set('isMultiAccount', false);
              this.$store.dispatch(local_setdata, {attributes:localData});
              this.$router.push({
                name: "PaymentArrngmtsIneligibile",
                params: {},
              });
            }else if (
              res.data.paymentArrangementIneligibleErrorCodes != null &&
              res.data.paymentArrangementIneligibleErrorCodes.includes("10017")
            ) {
              let localData = new Map();
              localData.set('paymentArrangementIneligibleErrorCodes', JSON.stringify(res.data.paymentArrangementIneligibleErrorCodes));
              localData.set('enteredAccountNumber', this.accountNumber);
              localData.set('enteredZipCode', this.zipCode);
              localData.set('status', JSON.stringify(res.data));
              localData.set('noAmtDue', false);
              localData.set('isMultiAccount', false);
              let thresholdErrorMessage = res.data.errorMessage
              let thresholdValAmt = thresholdErrorMessage.substring(
                  thresholdErrorMessage.indexOf("=") + 1, 
                  thresholdErrorMessage.lastIndexOf("|")
              );
              localData.set('thresholdVal', thresholdValAmt);
              localData.set('adjustedAmount', res.data.adjustedAmount);
              this.$store.dispatch(local_setdata, {attributes:localData});
              this.$router.push({
                name: "PaymentArrngmtsIneligibile",
                params: {},
              });
            }else if ((res.data.paymentArrangementEligible == false && res.data.status == 0 && res.data.adjustedAmount == "0.00") 
                  || res.data?.account?.errorInfo?.errorMessage == "AdjustedBalance2"
                  || res.data.paymentArrangementIneligibleErrorCodes != null
                  || res.data.errorCode == PMTARNGMNT_010) {
              let localData = new Map();
              const paymentArrangementIneligibleErrorCodes = res.data.paymentArrangementIneligibleErrorCodes ? res.data.paymentArrangementIneligibleErrorCodes : "10015";
              localData.set('paymentArrangementIneligibleErrorCodes', paymentArrangementIneligibleErrorCodes);
              localData.set('enteredAccountNumber', this.accountNumber);
              localData.set('enteredZipCode', this.zipCode);
              localData.set('status', JSON.stringify(res.data));
              localData.set('noAmtDue', true);
              localData.set('isMultiAccount', false);
              this.$store.dispatch(local_setdata, {attributes:localData});
              this.$router.push({
                name: "PaymentArrngmtsIneligibile",
                params: {},
              });
            }
            
          }
        let localData = new Map();
        localData.set('showLoading', false);
        this.$store.dispatch(local_setdata, {attributes:localData});
        } catch (error) {
          console.log(error)
        }
    },

      accountHasErrors(res){
        if (res.data.errorCode == PMTARNGMNT_001) {
          this.ppb = true;
          this.status = "This is a Prepaid account.";
          return true;
        }
        if (res.data.errorCode == PMTARNGMNT_002) {
          return false;
        }
        if (res.data.errorCode == PMTARNGMNT_003) {
          this.status = 'We’re sorry. We can’t complete this transaction. Please try again later.';
          return true;
        }  
        if (res.data.errorCode == PMTARNGMNT_006) {
          this.status = DEFAULT_ERROR_PA;
          return true;
        }
        if (res.data.errorCode == PMTARNGMNT_005) {
          if (res?.data?.paymentArrangementIneligibleErrorCodes != null && res?.data?.paymentArrangementIneligibleErrorCodes.includes("10015")) {
            return false;
          } else {
            this.status = DEFAULT_ERROR_PA
            return true;
          }
        }
        if (res.data.errorCode == PMTARNGMNT_008) {
          this.status = PA_PMTARNGMNT_008_ERROR_MESSAGE;
          return true;
        }
        if (res.data.errorCode == PMTARNGMNT_009) {
          this.status = ACCOUNT_MAINTENANCE;
          return true;
        }
        if (res.data.errorCode == PMTARNGMNT_010) {
        this.status = NO_AMOUNT_DUE;
        window.dataLayer?.push({
          event: 'pa_error',
          accountNumber: pii(this.accountNumber),
          errorName: 'zero balance due',
          flow:{
            name: 'pa',
            step: 'eligibility'
          },
        });
          return false;
        }
        if (res.data.errorCode == ZIP_INVALID_ERROR_CODE) {
          this.status = res.data.errorMessage;
          return true;
        }
        if (res.data.errorCode == PHONE_INVALID_ERROR_CODE) {
          this.status = res.data.errorMessage;
          return true;
        }
        if (res.data.errorCode == EMAIL_INVALID_ERROR_CODE) {
          this.status = res.data.errorMessage;
          return true;
        }
        if (res.data.errorCode == EMAIL_INVALID_ERROR_CODE) {
          this.status = res.data.errorMessage;
          return true;
        }
        if (res.data.errorCode == SYSYTEM_DOWN) {
          this.status = res.data.errorMessage;
          return true;
        }
        if (res.data.errorInfo != null) {
          if (res.data.errorInfo.errorMessage == "ERRDSSPQBP0001"){
            window.dataLayer?.push({
              event: 'my_ctl_exception_message',
              errorName: 'bspd_PA'
            });
            this.brightspeed = true;
            this.status = "Hello. Your service provider is now Brightspeed.";
            return true;
          }
        }
        if(res.data.errorCode != "" && res.data.errorCode != null){
          this.status = 'We’re sorry. We can’t complete this transaction. Please try again later.';
          return true;
        }
        return false;
      },
    },

    mounted(){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pa_post_identify',
        flow:{
          name: 'pa',
          step: 'pa_post_identify_page'
        },
      });

      this.accountNumber = this.$store.getters[GET_DATA]('accountNumber')
      this.zipCode =  this.$store.getters[GET_DATA]('billingAddressZip')
    
      this.submitForm();
    },

 }
 
</script>

<style scoped>

  #qbp-left-column-content{
    position: relative;
  }

  #h2text{
    font-weight: bold;
    color: black !important;
  }

  #rightBoxContent{
    margin-top: 59.2px;
  }

  @media screen and(max-width: 767px) {
    .mobileview-right-column{
      margin-top: -19px;
    }
    #rightBoxContent{
      margin-top: 15px;
    }
  }

  #norton-logo {
    background-image: url(../assets/images/mya_paybill_sprite_blue.png);
    background-repeat: no-repeat;
    background-position: -40px -335px;
    height: 70px;
    width: 110px;
  }

  .formError{
    background:  url(../images/global-alert-icon-2.svg) no-repeat 1px 1px;
    background-repeat: no-repeat;
    background-size: 36px 30px;
    padding: 2px 15px 3px 38px;
    color:#d0021b;
    font-size: 14px;
    line-height: 1.5;
    background-size: 27px 29px;
  }

</style>