<template>
    <!-- Top box displaying the Non payment error or notification -->
    <div class="container">
        <div class="pay-balance-notif-box">
            <div class="title-box" data-test="WalledGardenNotif"><img class="img-yellow" src="../../images/warning.svg" alt="Payment-notification"/><p class="notification-head">Your CenturyLink payment is past due.</p></div>    
            <div class="notif-box-text">
                <span v-if="this.accountType === 'prepaid'">
                 Your recent payment for your CenturyLink service could not be processed.  Please make a payment right away to avoid suspension or disconnection.  Payment must be received within 3 calendar days of your missed payment to avoid an interruption to your internet access.               
                </span>
                <span v-if="this.accountType === 'postpaid'">
                 We have not received payment for your CenturyLink service.  A payment is required to avoid your service being suspended or disconnected.
                </span>
            </div>
        </div>
        <div class="title-align">
            <h2 class="marg-2" data-test="WalledGardenHeader">Suspended Service</h2>
            <h3>Sign in to make a payment, or use Quick Bill Pay without signing in.</h3>
        </div>
    </div>
    <!-- Bottom blue area where content for prepaid and postpaid along with an image is getting displayed -->
    <div class="row-margin">
        <div class="notif-container">
            <div class="row">
                <div class="col-12 text-start row-notif">
                    <h2 class="mb-2 susp">SUSPENDED SERVICES</h2>
                    <!-- Condition for postpaid customer. Rest of the content is common for both prepaid and postpaid customers -->
                    <div class="marg-1">
                        <div>Sign in to My CenturyLink to pay your bill to use a saved payment method.</div>
                        <div class="registerStyle mt-3">
                            <button type="button" class="btn btn-primary sign-in-align" @click="signInButton()">Sign In to MyCenturyLink</button>
                        </div>
                        <div class="h-divider"></div>
                        <div class="registerStyle mt-3">
                            <button type="button" @click="qbpButton()" class="btn btn-primary">Quick Bill Pay</button>
                        </div>
                        <div v-if="this.accountType === 'postpaid'" class="marg-1 txt-1">If you can’t pay the full amount due, you may be able to establish a <button type="button" @click="paButton()" class="link p-0">payment plan.</button></div>
                        <div class="h-divider"></div>
                    </div>
                    <div v-if="this.successWG==='fail'"><b>We’re sorry. Please 
                        <a
                        href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})"
                        class="cta-btn" @click="customerType" v-on:keyup.enter="customerType" tabindex="0">
                        Chat With Us</a>
                        or try again later.</b>
                    </div>
                    <div>Clicking the Continue button will resume your internet connection.
                        <div class="continue-btn mt-3">
                            <button type="submit" :aria-disabled="this.continueButtonCheck" @click="continueButton()"  class="btn btn-primary">Continue</button>
                        </div>
                    </div>
                    <div class="marg-1 txt-1">
                        <span class="text-weight">Please note -</span> a processing fee will be assessed if you make a one-time payment by credit or debit card.  You can avoid this fee by setting up AutoPay or by paying through your bank account.
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal pop-up for Walled garden where we show masked account detail and let user authenticate themselves or cancel the process -->
    <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-box-style">
                    <h3>Please confirm your service address to continue.</h3>
                    <div class="marg-1">To pay your balance and re-enable services, please verify the service address below.</div>
                    <div>{{ this.maskedAccNum }}</div>
                    <div>Service Address:</div>
                    <div>{{ this.serviceAddress }}</div>
                    <div class="modalBtns marg-1">
                        <button type="button" class="btn btn-primary btn-sm text-center" data-dismiss="modal" @click="authenticate(this.isDataAvail)">Confirm</button>
                        <button type="button" class="btn btn-primary btn-sm text-center go-back-btn" data-dismiss="modal" @click="modalClose()">Not my account</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import axios from 'axios';
import constants from '../../js/constants';
import links from '../../js/links';
import { GET_DATA } from "../../store/sessioncontants";
import { SET_DATA } from "../../store/storecontants";
import paymentArrangements from "../../js/paymentArrangements";
import quickBillPay from "../../js/quickBillPay";
export default {
    name: "WGLatePay",
    data() { 
        return {
            CtlSignInURL: links.CtlSignInURL,
            CtlNetURL: links.CtlNetURL,
            isDataAvail: false,
            accountNumber: '',
            zipCode: '',
            serviceAddress: '',
            telephoneNumber: '',
            userClickedConfirm: false,
            accountType: '',
            customerIdentificationResponse: [],
            maskedAccNum: '',
            successWG: '',
            wgModal: null
    }},
    mounted() {
        this.wgModal = new Modal(document.getElementById('myModal'),{
            backdrop: 'static',
            keyboard: false
        });
        let attributesData = new Map();
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, { attributes: attributesData });
        this.telephoneNumber = this.$store.getters[GET_DATA]("telephoneNumber") ? this.$store.getters[GET_DATA]("telephoneNumber") : '';
        this.accountType = this.$store.getters[GET_DATA]("accountType") ? this.$store.getters[GET_DATA]("accountType") : 'postpaid';
        this.customerIdentificationResponse = this.$store.getters[GET_DATA]("customerIdentificationResponse") ? this.$store.getters[GET_DATA]("customerIdentificationResponse") : null;
        if (this.customerIdentificationResponse != null && this.customerIdentificationResponse != "" && this.customerIdentificationResponse.length == 1 && this.customerIdentificationResponse[0].serviceAddresses !== null) {
            this.accountNumber = this.customerIdentificationResponse[0].accountNumber;
            this.zipCode = this.customerIdentificationResponse[0].serviceAddresses[0].zipCode;
            this.serviceAddress = this.customerIdentificationResponse[0].serviceAddresses[0].address;
            const maskedDigits = '*'.repeat(this.accountNumber.length - 4);
            const lastFourDigits = this.accountNumber.slice(-4);
            this.maskedAccNum = maskedDigits + lastFourDigits;
            this.isDataAvail = true;
            this.openWgModal();
        } else {
            this.isDataAvail = false;
        }
        attributesData.set('showLoading', false);
        this.$store.dispatch(SET_DATA, { attributes: attributesData });
    },
    methods: {
        tagging(buttonName) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'wg_susp_service_past_due_click',
                flow:{
                      name: 'wglp',
                      step: 'step1'
                },
                customer:{
                     accountType: this.accountType
                },
                buttonName: buttonName,
            });
        },
        authenticate(dataAvail) {
            if(dataAvail == true) {
                this.userClickedConfirm = true;
            }
            else {
                this.userClickedConfirm = false;
            }
            this.modalClose();
        }, 

        signInButton(){
            this.tagging('LP Sign In to MyCenturyLink');
            this.deleteWGStatus()
            .then(()=>{
                window.location.href = this.CtlSignInURL
            })
        },
        qbpButton() {
            this.tagging('LP Quick Bill Pay');
            this.deleteWGStatus()
            .then(()=>{
                if(this.isDataAvail == true && this.userClickedConfirm == true) {
                    quickBillPay.getAccount(this.accountNumber, this.zipCode);
                }
                else {
                    this.$router.push({
                        name: "Identify",
                        params: {},
                    });
                }
            })
        },
        paButton() {
            this.tagging('LP Payment Plan');
            this.deleteWGStatus()
            .then(()=>{
                if(this.isDataAvail == true && this.userClickedConfirm == true) {
                    paymentArrangements.getAccount(this.accountNumber, this.zipCode);
                }
                else {
                    this.$router.push({
                        name: "PaymentArrngmtsIdentify",
                        params: {},
                    });
                }
            })
        }, 
        continueButton(){
            this.tagging('LP Continue');
            let attributesData = new Map();
            this.deleteWGStatus()
            .then(()=>{
                window.location.href = this.CtlNetURL
            });
        },

        async deleteWGStatus(){
            let attributesData = new Map();
            attributesData.set('showLoading', true);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });

            try {
                let Wgdata = JSON.parse(this.$store.getters[GET_DATA]('wgData'));
                this.wgWtn = Wgdata.wtn
                this.wgName = Wgdata.wgName

                if(this.wgName == "Latepay/Notice"){
                    this.wgName = "LatePay/Notice";
                }
                if(this.wgName == "Latepay/Postpaid"){
                    this.wgName = "LatePay/Postpaid";
                }
                if(this.wgName == "Latepay/Prepaid"){
                    this.wgName = "LatePay/Prepaid";
                }
                const params = {
                    wtn: this.wgWtn,    
                    wgname: this.wgName,
                };
                const r = await axios.get(constants.WalledGardenDelete, {params});
                this.successWG=r.data.status;
            } catch(e){
                console.log(e)
            }
        },
        openWgModal() {
            this.wgModal.show();
        },
        modalClose() {
            this.wgModal.hide();
        },
        maskAccountToDisplay (accNum) {
            const maskedDigits = '*'.repeat(accNum - 4);
            const lastFourDigits = accNum.slice(-4);
            return maskedDigits + lastFourDigits;
        }
    },
    computed: {
        // to enable and disable next button
        continueButtonCheck() {
        if (this.successWG==='success') {
            return true
        } else {
            return false
        }
        }
    }
}
</script>

<style scoped>
.h-divider {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 1px;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.notification-head {
    color: #14161A;
    font-size: 20px;
    letter-spacing: 0.2px;
    font-weight: bold;
    margin-left: 28px;
}
.notif-box-text {
    margin-left: 30px;
    color: #000000;
}
 .pay-balance-notif-box {
    position: relative;
    z-index: 1001;
    font-size: 16px;
    line-height: 1.35;
    padding: 20px;
    text-align: left;
    background-color: #FFFFFF;
    margin: 0 0 20px 0;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #000000;
    border-radius: 8px;
    opacity: 1;
    display: inline-block;
    width: 50%;
}
.row-margin {
    background: #ebeff6 0% 0% no-repeat padding-box;
}
.row-notif {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.registerStyle {
    margin-bottom: 1rem;
    font-family: Maison Neue, sans-serif;
}
.title-align {
    margin: 3rem 0 3rem 0;
    position: relative;
}
.marg-2 {
    margin-bottom: 2rem;
}
.marg-left {
    margin-left: 1rem;
}
.due-date-text {
    margin-left: 1rem;
}
.sign-in-align {
    color: #FFFFFF;
    font-size: 14px;
    text-decoration: none;
}
.text-weight {
    font-weight: bold;
}
.img-yellow {
    position: absolute;
}
.title-box {
    display: inline-flex;
    position: relative;
}
.modalBtns {
    display: flex;
    justify-content: left;
    align-items: center;
}
.go-back-btn {
    margin-left: 2rem;
    background-color: #FFFFFF;
    color: #0047BB;
    border: none;
}
.go-back-btn:hover {
    border: 1 px solid #0047BB;
}
.marg-1 {
    margin: 1rem 0 1rem 0;
}
@media (max-width: 768px) and (min-width: 240px) {
    .notif-container {
        width: 100%;
        margin: 0 auto;
        padding: 2rem;
    }
    .pay-balance-notif-box {
        width: 98%;
    }
}
@media(min-width: 768px){
    .pay-balance-notif-box{
        width: 750px;
    }
    .title-align{
        margin: 20px auto 30px;
        width: 750px;
    }
    .notif-container{
        width: 750px;
        margin: 0 auto;
    }
}
.txt-1{
    margin-bottom: 15px;
}
.link {
      background-color: transparent;
      border: none;
      color: #0047BB;
      cursor: pointer;
    }
    .link:hover {
       text-decoration: underline;   
    }
.susp{
    font-family: MaisonNeue-Medium, serif;
    text-align: left;
    font-size:  14px;
    letter-spacing: 0.45px;
    color: #14161ABF;
    text-transform: uppercase;
    opacity: 1;
}
.modal-box-style {
    padding: 2rem; 
    box-shadow: 0px 1px 4px #00000029;
    text-align: left;
}
button[aria-disabled="true"] {
    background-color: #fff;
    border-color: #fff;
    color: #14161A99;
    opacity: 1.0;
    pointer-events: none;
}
</style>