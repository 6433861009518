<template>
  <div id="qbp-exception-message" class="qbpMessageBox">
    <div class="qbpTextBox">
        <h3>Are you sure you want to make a one-time payment?</h3>
        <p class="text-start content-margin">You're signed up for AutoPay. The amount due on your account will be processed automatically.<br />
           To make an additional payment, please <a class="text-color" :href="MyaEntryPointURL" id="qbpSignIn">sign in to My CenturyLink</a>.
        </p>
    </div>
</div>
</template>
<script>
import links from "../../js/links";
import { pii } from "../../js/pii";

export default {
  name: "autoPayQbp",
  data() {
    return {
      MyaEntryPointURL: links.MyaEntryPointURL,
    };
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "qbp_error",
      accountNumber: pii(this.accountNumber),
      errorName: "autopay enabled",
      flow: {
        name: "qbp",
        step: "payment flow",
        event: "autopay enabled",
      },
    });
  },
};
</script>
<style scoped>
.text-color {
  color: #0176d3;
  text-decoration: none !important;
}
.content-margin {
  margin: 2rem 0 2rem 0;
}

.iframe-error-margin {
  margin: 1rem 0 1rem 0;
  display: flex;
}

#qbp-exception-message h3 {
  font-weight: bold;
}

#qbp-exception-message p {
  margin-bottom: 5px;
}

.qbpTextBox {
  position: relative;
}

.qbpTextBox:before {
  content: "";
  position: absolute;
  background: url(../../images/icon-important-orange.svg) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
}

@media (max-width: 767px) {
  #qbp-exception-message h3 {
    min-height: 30px;
  }
}
</style>