<template>
    <div class="container justify-content-center autopay-box">
        <autopayDetails/>
        <autopayCancel />
    </div>
</template>
<script>
import autopayCancel from '../components/Autopay/autopayCancel.vue'
import autopayDetails from '../components/Autopay/AutopayDetails.vue'
export default {
  name: 'CancelAP',
  components: {
    autopayDetails,
    autopayCancel
  }
};
</script>
<style scoped>
.autopay-box {
  width: 58%;
  height: 100%;
  border-radius: 5px;
}
@media(max-width: 979px) {
  .autopay-box {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
}
</style>