<template>
    <div class="container text-start paSuccessBox">
        <div class="container qbp-wrapper col-xs-12 col-sm-12 col-md-12 no-pad" v-if="!isWaiting && !isDataAvailable">
            <div v-if="brimOn">
                <div class="container" id="post-login-bill-success">
                    <div class="row print-container">
                        <h2 class="text-start text-placement">Thanks for your payment!</h2>
                        <h2 class="text-start text-placement">Confirmation number: {{paymentConfirmationNumber}}</h2>
                    </div>
                </div>
            </div>
            <div v-else class="row mb-3">
                <div class="col text-start">
                    <p>Sorry, We could not find the page which you have requested. Kindly navigate to our 
                        <a class="home-page" @click="startOverAgain()" v-on:keyup.enter="startOverAgain()" tabindex="0">Home Page.</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="container qbp-wrapper col-xs-12 col-sm-12 col-md-12 no-pad" v-if="isWaiting && !isDataAvailable">
            <div class="row mb-3">
                <div class="col text-start">
                    <p>Please wait while we are fetching the data for you.</p>
                </div>
            </div>
        </div>

        <div id="pa-success-wrapper" v-if="!isWaiting && isDataAvailable">
            <div class="display-flex-style"><img alt="checkmark-icon" class="checkmark-marg"
                    src="../images/checkmark_nucleus.png" />
                <h2 class="checkmark-heading">Thanks for your payment!</h2>
            </div>
            <div class="col-12 text-start text-font-style marg-top-heading">An email has also been sent to <b>{{ this.confirmationEmailAddress }}</b>  with your receipt.</div>
            <div class="col-12 text-start marg-top-heading"><img alt="confirmation-number" class="marg-right" src="../images/receipt_long_black_24dp.svg" />CONFIRMATION NUMBER</div>
            <div class="col-12 text-start marg-confirmation-num"><b>{{ this.paymentConfirmationNumber }}</b></div>
            <div class="details">
                <div class="text-summary">
                    <h2>Payment Summary</h2>
                </div>
                <div class="row">
                    <div class="col-2 col-xl-1">
                        <div class="bill-icon"><img alt="payment-icon" src="../images/attach_money_black_24dp.svg" /></div>
                    </div>
                    <div class="text-pmnt col-7 col-xl-9">Total payment</div>
                    <div class="payment-amt col-3 col-xl-2">{{ totalPayment }}</div>
                </div>
                <div class="horizontal-bord offset-2 offset-xl-1"></div>
                <div class="row">
                    <div class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Convenience fee</div>
                    <div class="text-conv-fee payment-amt col-3 col-xl-2">{{ convenienceFee }}</div>
                </div>
                <div class="row">
                    <div class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Payment amount</div>
                    <div class="text-conv-fee payment-amt col-3 col-xl-2">{{ paymentAmount }}</div>
                </div>
                <div class="row pay-date-margin">
                    <div class="col-2 col-xl-1">
                        <div class="qbp-success-circle">
                            <img alt="calendar icon" class="calendar-icon"
                                src="../images/calendar_today_black_24dp.svg" />
                        </div>
                    </div>
                    <div class="col-10 col-xl-11 txt-payment-method">
                        <div class="payment-method">Payment date</div>
                        <div class="text-pay">{{ paymentDate }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2 col-xl-1">
                        <div class="qbp-success-circle">
                            <img alt="payment-method-icon" src="../images/payments_black_24dp.svg" />
                        </div>
                    </div>
                    <div class="col-10 col-xl-11"> 
                        <div class="payment-method">Payment method</div>
                        <div class="text-pay">{{ paymentAcctLast4 }}</div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div id="norton-logo" class="offset-2 col-2" />
                    <div class="col-10 text-end text-style" id="printButton"><img alt="print" class="icon-style" src="../images/print_black.svg" />
                        <span class="print-style text-end" @click="printPage" v-on:keyup.enter="printPage" tabindex="0">Print this page for your records</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="acodSuccess" class="row-margin" id="acodContent">
        <div class="container paSuccessBox">
            <div class="row">
                <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 text-start row-acod">
                    <h2>Next time, just sign in!</h2>
                    <p>My CenturyLink is an easy way to pay your bill, get troubleshooting help, and submit repair requests.
                        It
                        only takes a few minutes to set up your online account and get the most CenturyLink has to offer
                        you.
                    </p>
                    <div class="registerStyle">
                        <button type="button" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#myModal"
                            @click="getAcodRegisterationDetail()">Complete Your Account</button>

                    </div>
                </div>
                <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 row-acod">
                    <img class="img-people" src="../images/Layer2.svg" />
                </div>
            </div>
        </div>
    </div>
    <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content text-placement">
                <div v-if="acodSuccessModal" class="modal-body">
                    <div class="align-heading success-heading">
                        <img alt="success icon" class="checkmark" src="../images/successicon.gif" />
                        <h3>Your profile has been created</h3>
                    </div>
                    <span>An email has been sent to <strong> {{ showEmailAddress }} </strong> with your
                        account with a link to activate your profile and set your password.</span>
                </div>
                <div v-else-if="acodFailModal" class="modal-body">
                    <h3 class="align-heading">Sorry! There seems to be an issue. Please try again later.
                    </h3>
                </div>
                <div v-else-if="loginExist" class="modal-body">
                    <p class="text-start">Great news! You already have an online profile. To connect this account with your existing profile, 
                    <a :href="CtlSignInURL">Sign into My CenturyLink</a> and add this account to your profile.
                    </p>
                    <a class="signin-cta" :href="CtlSignInURL">Sign into My CenturyLink</a>
                </div>
                <div class="modal-footer closeButton">
                    <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal"
                        @click="modalClose()">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div v-if="acodSuccess" class="container paSuccessBox marg-top-bottom" id="requestDetail">
        <div v-if="!isWaiting && isDataAvailable" class="text-start marg-two">
            <h2>Why create an account?</h2>
            <p>When you're signed in, we can give you targeted and specific information about your account, bill, service,
                and more.</p>
        </div>
        <div v-if="!isWaiting && isDataAvailable" class="row">
            <div class="col-4 line">
                <img class="icon-style-bill" src="../images/headset_mic_black_24dp.svg" />
                <p>Submit repair requests</p>
                <label>Skip the phone call and waiting on hold to make a repair appointment. Request service help from My
                    CenturyLink.</label>
            </div>
            <div class="col-4 line">
                <img class="icon-style-bill" src="../images/dollar.png" />
                <p>Pay your bills</p>
                <label>Easy and complete access to your current and past bills, plus easy enrollment in AutoPay.</label>
            </div>
            <div class="col-4">
                <img class="icon-style-bill" src="../images/icon-action-build_24px.svg" />
                <p>Get troubleshooting help</p>
                <label>When you’re signed in, we can provide detailed troubleshooting steps for your specific service and
                    equipment.</label>
            </div>
        </div>
    </div>
</template> 
<script>
import { SET_DATA, GET_DATA, RESET } from "../store/storecontants";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import constants from "../js/constants";
import axios from "axios";
import links from "../js/links";
import { pii } from "../js/pii";

export default {
    name: 'PaymentArrangmentSuccess',
    data() {
        return {
            paymentConfirmationNumber: "",
            confirmationEmailAddress: "",
            accountNumber: "",
            isWaiting: true,
            isDataAvailable: false,
            paymentMethod: "",
            isConfirmationAvail: false,
            firstName: '',
            lastName: '',
            acodSuccess: false,
            ACODRegisterationURL: constants.ACODRegisterationURL,
            AcodAccountNumber: "",
            acodSuccessModal: false,
            acodFailModal: false,
            emailAddressOfRecord: "",
            loginExist: false,
            showEmailAddress: "",
            paymentDate: "",
            totalPayment: "",
            convenienceFee: "",
            paymentAmount: "",
            CtlSignInURL: links.CtlSignInURL,
            brimOn: false
        }
    },
    mounted() {
        this.brimOn = process.env.VUE_APP_BRIM;
        window.dataLayer?.push({
            page: {
                pageType: 'pa',
                pageName: 'ctl|account|payment_arrangement|success|payment'
            }
        });
        if (this.$route.query.PaymentID != null) {
            let attributesData = new Map();
            attributesData.set('paymentConfirmationNumber', this.$route.query.PaymentID ? this.$route.query.PaymentID : this.$route.params.PaymentID);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
        }
        this.paymentConfirmationNumber = this.$store.getters[GET_DATA]('paymentConfirmationNumber') ? this.$store.getters[GET_DATA]('paymentConfirmationNumber') : "NA";
        this.confirmationEmailAddress = this.$store.getters[GET_DATA]('confirmationEmailAddress') ? this.$store.getters[GET_DATA]('confirmationEmailAddress') : this.$route.params.confirmationEmailAddress;
        this.accountNumber = (this.$store.getters[GET_DATA]('maskedAccount') != "null") ? this.$store.getters[GET_DATA]('maskedAccount') : this.$store.getters[GET_DATA]('accountEntered') ? JSON.parse(this.$store.getters[GET_DATA]('accountEntered')) : "NA";
        this.firstName = this.$store.getters[GET_DATA]('firstName') ? JSON.parse(this.$store.getters[GET_DATA]('firstName')) : "NA";
        this.lastName = this.$store.getters[GET_DATA]('lastName') ? JSON.parse(this.$store.getters[GET_DATA]('lastName')) : "NA";
        this.AcodAccountNumber = this.$store.getters[GET_DATA]('AcodAccountNumber') ? JSON.parse(this.$store.getters[GET_DATA]('AcodAccountNumber')) : "NA";
        this.emailAddressOfRecord = this.$store.getters[GET_DATA]('emailAddress') ? JSON.parse(this.$store.getters[GET_DATA]('emailAddress')) : "NA";
        this.getPaymentDetails();
        window.dataLayer?.push({
            event: 'pa_payment_success',
            accountNumber: pii(this.accountNumber),
            paymentOption: this.paymentMethod,
            flow: {
                name: 'pa',
                step: 'success screen'
            },
        });
    },
    methods: {
        openModalNow() {
            const modal = document.getElementById("myModal");
            document.body.appendChild(modal);
            var myModal = new Modal(modal, {
                keyboard: false,
            });
            myModal.show();
        },
        modalClose() {
            var modalElement = document.getElementById('myModal');
            var modal = Modal.getInstance(modalElement);
            modal.hide();
        },
        async getAcodRegisterationDetail() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'page': {
                    'pageType': 'pa',
                    'pageName': 'pa success'
                },
                'event': 'pa_acod_register_click',
                'flow': {
                    'name': 'pa',
                    'step': 'success screen',
                },
                'buttonName': 'Register'
            });
            let attributesData = new Map();
            attributesData.set('showLoading', true);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
            this.isWaiting = true;
            const params = {
                accountNumber: this.AcodAccountNumber,
                firstName: this.firstName,
                lastName: this.lastName,
                emailAddress: this.emailAddressOfRecord
            };
            try {
                const acodResult = await axios.post(constants.ACODRegisterationURL, params);
                if (acodResult.data.statusInfo.message == "4000 Login already exists in system"
                    || acodResult.data.statusInfo.message == "800 This account is already registered to a user") {
                    this.acodSuccessModal = false;
                    this.acodFailModal = false;
                    this.loginExist = true;
                    this.acodSuccess = false;
                    this.openModalNow();
                    this.isWaiting = false;
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                } else if (acodResult.data.acodprofileCreated == true) {
                    this.showEmailAddress = acodResult.data.emailAddress;
                    this.acodSuccessModal = true;
                    this.acodFailModal = false;
                    this.loginExist = false;
                    this.acodSuccess = false;
                    this.openModalNow();
                    this.isWaiting = false;
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'page': {
                            'pageType': 'pa',
                            'pageName': 'acod success'
                        },
                        'event': 'pa_acod_success',
                        'flow': {
                            'name': 'pa',
                            'step': 'success screen'
                        },
                        'accountNumber': pii(this.accountNumber)
                    });
                } else if (acodResult.data.acodprofileCreated == false) {
                    this.acodFailModal = true;
                    this.acodSuccessModal = false;
                    this.loginExist = false;
                    this.acodSuccess = true;
                    this.openModalNow();
                    this.isWaiting = false;
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                } else {
                    this.acodFailModal = true;
                    this.acodSuccessModal = false;
                    this.loginExist = false;
                    this.acodSuccess = true;
                    this.openModalNow();
                    this.isWaiting = false;
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'page': {
                            'pageType': 'pa',
                            'pageName': 'acod error'
                        },
                        'event': 'pa_acod_error',
                        'flow': {
                            'name': 'pa',
                            'step': 'success screen'
                        },
                        'accountNumber': pii(this.accountNumber)
                    });
                }
            } catch (error) {
                console.log(error);
                this.acodFailModal = true;
                this.acodSuccessModal = false;
                this.isWaiting = false;
                this.acodSuccess = true;
                this.openModalNow();
                attributesData.set('showLoading', false);
                this.$store.dispatch(SET_DATA, { attributes: attributesData });
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'page': {
                        'pageType': 'pa',
                        'pageName': 'acod error'
                    },
                    'event': 'pa_acod_error',
                    'flow': {
                        'name': 'pa',
                        'step': 'success screen'
                    },
                    'accountNumber': pii(this.accountNumber)
                });
            }
        },
        getHumanReadbledt(timeStamp) {
            if (timeStamp == 'NA') {
                return null;
            }
            const monthArr = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const date = new Date(timeStamp);
            const year = date.getFullYear();
            const month = monthArr[date.getMonth()];
            const newDate = date.getDate();
            const readableDate = month + " " + newDate + ", " + year;
            return readableDate;

        },
        async getPaymentDetails() {
            this.isWaiting = true;
            let attributesData = new Map();
            attributesData.set('showLoading', true);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
            const params = {
                accountNumber: this.AcodAccountNumber,
                paymentConfirmationNumber: this.paymentConfirmationNumber,
                emailAddress: this.emailAddressOfRecord  
            };
            try {
                const res = await axios.post(constants.PaymentSuccessURL, params);
                if (res.data.acodSuccess == true) {
                    this.acodSuccess = true;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'page': {
                            'pageType': 'pa',
                            'pageName': 'pa success'
                        },
                        'event': 'pa_acod_eligible',
                        'flow': {
                            'name': 'pa',
                            'step': 'success screen',
                        },
                        'accountNumber': pii(this.accountNumber),
                    });
                } else {
                    window.dataLayer?.push({
                        page: {
                            pageType: 'pa',
                            pageName: 'pa success'
                        },
                        event: 'ACOD PA ineligible',
                        flow: {
                            name: 'pa',
                            step: 'success screen'
                        }
                    });
                }
                if (res.data.paymentDate != null) {
                    this.isWaiting = false;
                    this.isDataAvailable = true;
                    this.paymentMethod = res.data.paymentMethod;
                    this.confirmationEmailAddress = res.data.confirmationEmailAddress ? res.data.confirmationEmailAddress : "NA";
                    let totalPaymentDec = res.data.totalPayment ? res.data.totalPayment : "NA";
                    let payTotalSumNum = parseFloat(totalPaymentDec);
                    this.totalPayment = "$ " + Number(payTotalSumNum).toFixed(2);
                    this.convenienceFee = "$ " + Number(res.data.convenienceFee).toFixed(2) ? res.data.convenienceFee : "NA";
                    this.paymentAmount = "$ " + Number(res.data.paymentAmount) ? res.data.paymentAmount : "NA";
                    this.paymentAcctLast4 = res.data.paymentAcctLast4 ? res.data.paymentAcctLast4 : "NA";
                    this.getHumanReadbledt(res.data.paymentDate);
                    this.paymentDate = this.getHumanReadbledt(res.data.paymentDate);
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                } else {
                    this.isWaiting = false;
                    this.isDataAvailable = false;
                    this.paymentMethod = "NA";
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                }
            } catch (error) {
                console.log(error);
                this.isWaiting = false;
                this.isDataAvailable = false;
                this.paymentMethod = "NA";
                attributesData.set('showLoading', false);
                this.$store.dispatch(SET_DATA, { attributes: attributesData });
            }
        },
        printPage() {
            var restorePage = document.body.innerHtml;
            var printContent = document.getElementById('pa-success-wrapper').innerHtml;
            document.body.innerHtml = printContent;
            window.print();
            document.body.innerHtml = restorePage;
        },
        startOverAgain() {
            if ((window.location.href.indexOf('pa') !== -1)) {
                if (window.location.href.indexOf('pa/multiAccount') !== -1) {
                    this.$router.push("/pa/multiAccount");
                } else {
                    this.$router.push("/pa/identify");
                    this.$store.dispatch(RESET);
                    history.pushState(null, null, location.href);
                }
            }
        },
    },
}
</script>

<style scoped>
.importantMessage {
    background-image: url(../assets/images/icon-important.svg);
    background-repeat: no-repeat;
    height: 32px;
    width: 32px;
    position: absolute;
}

#pmtarrSuccessP2PPrint {
    color: #0047BB;
}

#makePayment {
    background-color: #0047BB;
    font-family: MaisonNeue-Medium, sans-serif;
}

#norton-logo {
    background-image: url(../assets/images/mya_paybill_sprite_blue.png);
    background-repeat: no-repeat;
    background-position: -40px -335px;
    height: 70px;
    width: 110px;
}

h2 {
    font-size: 36px;
    font-family: MaisonNeue-Light, sans-serif;
}

p,
span,
form,
strong {
    font-family: MaisonNeue, sans-serif;

}

label {
    font-size: 12px;
    font-family: MaisonNeue, sans-serif;
}

@media(min-width: 768px) {
    .qbp-wrapper {
        max-width: 700px;
    }
}

@media(min-width: 1024px) {
    .qbp-wrapper {
        max-width: 900px;
    }
}

.home-page {
    color: #0d6efd;
    cursor: pointer;
}

.home-page:hover {
    color: #0a58ca;
}

.registerStyle {
    margin-bottom: 1rem;
}

.closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-placement {
    text-align: center;
}

.checkmark {
    height: 25px;
    width: 25px;
    flex-flow: column wrap;
    align-content: flex-start;
    display: inline-flex;
    margin-right: 8px;
}

.align-heading {
    margin-bottom: 20px;
}

.success-heading img {
    position: absolute;
    left: 20px;
}

.success-heading h3 {
    padding-left: 50px;
    font-weight: bold;
    text-align: left;
}

.paSuccessBox {
    margin-top: 1rem;
    width: 60%;
    height: 100%;
    border-radius: 5px;
}

.checkmark-marg {
    width: 32px;
    height: 32px;
    margin-right: 1rem;
    margin-bottom: 5px;
    position: absolute;
    left: 0;
    top: 5px;
}

.marg-right {
    margin-right: 0.5rem;
}

.detail-box {
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    opacity: 1;
}

.text-style {
    color: #14161ABF;
    margin-top: 2rem;
    font-size: 14px !important;
    font-family: MaisonNeue-Medium, sans-serif !important;
}

.text-decor {
    margin: 1rem 0 1rem 1rem;
}

.icon-circle {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0 0 2px #888;
    padding: 0.5em 0.6em;
    color: #0047bb;
    background-color: #e0e8f7;
    height: 40px;
    width: 40px;
}

.icon-style {
    width: 20px;
    height: 18px;
}

.print-style {
    font-size: 14px;
    color: #0047BB;
    text-decoration: underline;
    margin-left: 0.5rem;
}

.print-style:hover {
    cursor: pointer;
}

.row-margin {
    background: #ebeff6 0% 0% no-repeat padding-box;
}

.complete-account-button {
    background-color: #0047BB;
    box-shadow: 0px 2px 4px #00000033;
    border: 1px solid #0047bb;
    color: #ffffff;
    width: 12rem;
}

.complete-account-button:hover {
    background-color: rgba(0, 71, 187, 0.039);
    color: #0047BB;
    border: 1px solid #0047BB;
}

.row-acod {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.marg-two {
    margin: 2rem 0 2rem 2rem;
}

.marg-top-bottom {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.icon-style-bill {
    width: 60px;
    height: 60px;
    margin: 1rem 0 1rem 0;
}

@media (max-width: 768px) and (min-width: 240px) {
    .paSuccessBox {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .right-grid {
        width: 290px;
        height: 220px;
    }

    .styleMargin {
        display: inline-flex;
        margin-top: 1rem;
        font-size: 16px;
        font-family: MaisonNeue-Light, sans-serif;
    }

    .left-grid {
        width: 100%;
        padding-left: 1rem;
    }

    .line {
        position: relative;
        height: 250px;
    }
}

@media (min-width: 770px) {
    .styleMargin {
        display: inline-flex;
        margin-top: 1rem;
        font-size: 16px;
        font-family: MaisonNeue-Light, sans-serif;
    }

    .left-grid {
        width: 100%;
        padding-left: 13rem;
    }

    .right-grid {
        width: 290px;
        height: 220px;
        margin-left: 2rem;
    }

    .line {
        position: relative;
        height: 200px;
    }
}

.display-flex-style {
    position: relative;
    margin-left: 0;
}

.text-font-style {
    font-size: 16px;
    color: #14161A;
    opacity: 0.7;
}

.detail-margin {
    margin-left: 1rem;
}

.checkmark-heading {
    padding-left: 40px;
}

.line:after {
    content: '';
    position: absolute;
    right: 0;
    border-right: 1px solid #cfc7c0;
    top: 1rem;
    bottom: 1rem;
}

@media print {
    #acodContent {
        display: none;
    }

    #requestDetail {
        display: none;
    }
}

#qbp-intro-wrapper h1 {
    padding-left: 45px;
    color: #14161A;
    letter-spacing: 0px;
    margin-bottom: 16px;
    opacity: 1;
    font-size: 36px;
    font-weight: 400;
    font-family: MaisonNeue-Light, serif;
}

#qbp-intro-wrapper h2 {
    color: #14161A;
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0.24px;
    margin-bottom: 0;
}

.details {
    position: relative;
    border-radius: 8px;
    border: 1px solid #EEEEEE33;
    box-shadow: 0px 1px 4px #00000029;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin: 20px 0 20px 0;
    padding: 15px;
}

.text-summary {
    font-family: MaisonNeue-Medium, sans-serif;
    font-size: 24px;
    color: #14161A;
    text-align: left;
    margin-bottom : 20px;
}

.qbp-success-circle {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E0E8F7 0% 0% no-repeat padding-box;
    opacity: 1;
}
.qbp-success-circle img {
    position: absolute;
    top: 11px;
    left: 11px;
}
.bill-icon {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E0E8F7 0% 0% no-repeat padding-box;
    opacity: 1;
}
.bill-icon img {
    position: absolute;
    top: 11px;
    left: 11px;
}
.text-pmnt {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
    margin-top: 0;
    display: flex;
    align-items: center;
}

.text-conv {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
    margin-top: 10px;
    opacity: 0.6;
}

.text-conv-fee {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: right;
    margin-top: 10px;
    opacity: 0.6;
}

.horizontal-bord {
    border: 1px solid #dad7d7;
}

.payment-method {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
}

.payment-method-icon {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E0E8F7 0% 0% no-repeat padding-box;
    opacity: 1;
    display: inline-block;
    vertical-align: middle;
}

.payment-method-icon img {
    position: absolute;
    top: 20px;
    left: 20px;
}
.payment-amt {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.text-pay{
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 15px;
    opacity: 0.6;
}
.marg-top-heading {
    margin-top: 30px;
}
.marg-confirmation-num {
    margin-top: 12px;
    font-size: 36px;
}
.pay-date-margin {
    margin-top: 15px;
}
a.signin-cta {
    text-decoration: none;
    border: 1px solid #0047BB;
    padding: 5px 10px;
    border-radius: 5px;
}
</style>
