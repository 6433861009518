<template>
    <div class="mwContainer">
        <div class="row">
            <h1 class="manage-wallet-heading">Wallet Management</h1>
            <h2 class="manage-wallet-subtitle">Manage Saved Payment Methods</h2>
            <div class="col-sm-12 col-xs-12 text-start content-margin">
                <p>Paying your bill gets even faster and easier when you ask us to remember your payment information.</p>
                <p>By securely storing one or more different payment accounts, you won't need to enter all those numbers each time you pay online or pay through our automated phone system.</p>
                <p>For your security, sensitive payment information cannot be edited. However, you can remove outdated saved payment accounts, update the expiration date of any credit cards you have on file.</p>
            </div>
            <div v-if="this.brim">
                <div v-if="this.cardDetails != null || this.bankDetails != null" class="wallet-container">
                    <div v-for="(option, index) in cardDetails" :key="index" :class="option.cardType" class="wallet-cards wallet-box" @click="showEditPopUp(option.maskedAccountNumber, option.cardExpirationDate, option.cardBillingZipCd, option.instrumentId, option.cardType)"  tabindex="0">
                        <div class="img-content-container">
                            <div class="img-card"></div>
                            <p><span>{{option.cardType}}</span> <span>{{option.maskedAccountNumber}}</span></p>
                        </div>
                    </div>
                    <div v-for="(option, index) in bankDetails" :key="index" :class="option.bankAccountType" class="wallet-bank wallet-box" @click="showDeletePopUp(option.instrumentId,'',option.bankAccountType)" tabindex="0">
                        <div class="img-content-container">
                            <div class="img-bank"></div>
                            <p><span>{{option.bankAccountType}}</span> <span>{{option.maskedAccountNumber}}</span></p>
                        </div>
                    </div>
                    <div class="wallet-add wallet-box" tabindex="0" @click="showAddPopUp()">
                        <div class="img-content-add">
                            <div class="img-add"></div>
                            <p>Add new payment method</p>
                        </div>
                    </div>
                    <manage-Wallet-Autopay />
                </div>
                <div v-else-if="this.cardDetails == null && this.bankDetails == null && (this.brimStatus !== 'ERROR')">
                    <div class="wallet-add wallet-box" tabindex="0" @click="showAddPopUp()">
                        <div class="img-content-add">
                            <div class="img-add"></div>
                            <p>Add new payment method</p>
                        </div>
                    </div>
                    <manage-Wallet-Autopay />
                </div>
                <div v-else-if="(this.brimStatus === 'ERROR')" class="grid1">
                    <manage-wallet-error />
                </div>
            </div>
            
            <div v-if="this.epwf" id="manage-wallet-content-subsection content-margin">
                <h3 class="text-start pre-iframe-heading">
                    <strong>Your Payment Accounts</strong>
                </h3>
                <div v-if="this.iframeStatus === 'SUCCESS'" id="mw-iframe">
                    <spinner v-if="showLoader"></spinner>
                    <iframe
                        style="height: 450px; margin-top: 20px; border: 0; overflow: hidden;"
                        :src="this.iframeUrl" width="100%" title="manageWalletIframe">
                    </iframe>
                    <manage-Wallet-Autopay />
                </div>
                <div v-else-if="this.iframeStatus === 'ERROR'" class="grid1">
                    <manage-wallet-error />
                </div>
            </div>
            <div v-if="this.showSuccessMsg" id="successMsg" class="d-flex">
                <img class="me-auto ps-3" src="../../images/svg/circle_green_checkmark.svg" alt="green-checkmark">
                <p v-if="this.successAddBankMsg" class="py-1 mx-auto my-0">New bank account successfully added!</p>
                <p v-if="this.successAddCardMsg" class="py-1 mx-auto my-0">New card successfully added!</p>
                <p v-if="this.successEditCardMsg" class="py-1 mx-auto my-0">Card successfully updated!</p>
                <button type="button" class="btn border-0 ms-auto py-0 ps-0 pe-3" @click="successMsgClose()">
                    <img class="" src="../../images/svg/icon-navigation-close_24px.svg" alt="close-success-message"/>
                </button>
            </div> 
        </div>
    </div>
    <!-- modal code -->
    <div id="myModal" class="modal fade manage-wallet-bank-card" role="dialog">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header no-border">
					<h5 v-if="this.showAddPaymentMethod" class="modal-title">Add a payment method</h5>
                    <h5 v-else-if="this.deletePaymentTitle" class="modal-title">Delete payment method</h5>
                    <h5 v-else-if="this.bankOptionTitle" class="modal-title">Enter bank account info</h5>
                    <h5 v-else-if="this.cardOptionTitle" class="modal-title">Enter card info</h5>
                    <h5 v-else-if="this.editCardTitle" class="modal-title">Edit card info</h5>
					<button type="button" class="btn" @click="modalClose()" aria-label="Close">
                        <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info"/>
                    </button>
				</div>
                <div class="bank-img-section" v-if="this.cardImg">
                    <img class="visa-img" src="../../images/visa.svg" alt="visa">
                    <img class="mastercard-img" src="../../images/mastercard.svg" alt="mastercard">
                    <img class="discover-img" src="../../images/discover.svg" alt="discover">
                </div>
                <div v-if="this.invalidExpDate" id="invalidExpDateErrorMsg">
                    <ul class="m-0 ps-3">
                        <li>Invalid credit card expiration date.</li>
                    </ul>
                </div>
                <div  v-if="this.showAddPaymentMethod" class="bank-card-section">
                    <div v-if='this.isAchAllowed' class="managewallet-bank" @click="bankOption">
                        <div class="managewallet-bank-icon-circle">
                            <img alt="payment method icon" class="bank-method-icon" src="../../images/institute.png">
                        </div>
                        <div class="bank-accnt">
                            <div class="content-style">Bank Account</div>
                        </div>
                    </div>  
                    <div v-if='this.isCardAllowed' class="managewallet-card"  @click="cardOption">
                        <div class="managewallet-card-circle">
                            <img alt="payment method icon" class="card-method-icon" src="../../images/Card.png">
                        </div>
                        <div class="bank-accnt">
                            <div class="content-style">Credit/Debit Card</div>
                        </div>					
                    </div>
                </div>
                <div v-if="this.showDeletePaymentMethod">
                    <div v-if="this.deleteHide" class="modal-body text-start" id="modal-delete-payment-method-body">
                        <p>Are you sure you want to delete this payment method? You will not be able to use it for payments and will have to re-add this method in the future if you would like to use it again.</p> 
                    </div>
                    <div class="delete-success" v-if="this.succesfulDelete"> Your payment method was deleted successfully. </div>      
                    <div class="delete-fail" v-if="this.failedDelete"> Failed Deleting </div> 
                    <div class="modal-footer border-0 justify-content-start">
                      <button v-if="this.deleteHide" type="button" class="btn btn-primary" id="modal-delete-btn" @click="Delete()">Delete</button>
                      <button v-if="this.deleteHide" type="button" class="btn btn-primary" id="modal-back-btn" @click="modalClose()">Keep</button>
                    </div>
                    <div class="modal-footer-ok border-0">
                      <button v-if="this.deleteShow" type="button" class="btn btn-primary" id="modal-back-btn" @click="pageRefresh()">Ok</button>
                    </div>
                </div>
                <!-- component -->
				<div class="hcde-comp">
					<hcde-tokenization v-if="this.hcdeReady" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showCardResponse" callbackField="myTokenVar" statusFunction="showTokenStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="307902021108">Loading...</hcde-tokenization>
					<hcde-wallet v-if="this.hcdeReadyWallet" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showBankResponse" callbackRefNumField="walletRefNum"  callbackInstrumentField="walletInstrumentId" statusFunction="showWalletStatus" statusField="statusVar" srcSysTransId="1234567890" >Loading...</hcde-wallet>
                </div>
                <div v-if="this.editCardFooter" class="mt-4 mb-2">
                    <button id="editCardBtn" type="button" class="btn btn-primary btn-lg text-center" @click="updateCardDetails()">Save</button>
                    <button id="deleteCardBtn" type="button" class="btn btn-primary btn-lg text-center" @click="showDeletePopUp(this.instrumentId, this.cardtype, this.accounttype)">Delete payment method</button>
                    <button id="cancelBtn" type="button" class="btn btn-primary btn-lg text-center" @click="modalClose()">Cancel</button>
                </div>
			</div>
		</div>
	</div>
    <div id="limitModal" class="modal fade manage-wallet-bank-card" role="dialog">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header no-border">
					<button type="button" class="btn" @click="RemoveModalClose()" aria-label="Close">
                        <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info"/>
                    </button>
				</div>
                <div class="modal-body">
                    <p>Please delete a payment method before adding a new one.</p>
                </div>
                <div class="modal-footer">
                      <button type="button" class="btn btn-primary" @click="RemoveModalClose()">Ok</button>
                </div>
			</div>
		</div>
	</div>
    <div id="errorModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header no-border">
                    <h5 class="modal-title">Unable to add account</h5>
                    <button type="button" class="btn" @click="errorModalClose()" aria-label="Close">
                        <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info"/>
                    </button>
                </div>
                <div class="modal-body no-border">
                    <p>Sorry, we weren't able to add that account. It could be an issue with your bank or a fault in our system. You can chat with us to learn more or try again later.</p>
                </div>
                <div class="modal-footer no-border">
                    <button type="button" class="btn btn-primary" data-dismiss="modal" @click="errorModalClose()">Try again</button>
                    <a href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})"
                        class="cta-btn">Chat With Us
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import constants from "../../js/constants";
import axios from "axios";
import { SET_DATA, GET_DATA } from "../../store/sessioncontants";
import { SET_DATA as local_setdata} from "../../store/storecontants";
import Spinner from "../../components/Spinner.vue";
import ManageWalletError from '../ErrorMessages/manageWalletError.vue';
import links from '../../js/links';
import manageWalletAutopay from '../wallet/manageWalletAutopay.vue';

export default {
    name: 'ManageWallet',
    components: {
        ManageWalletError,
        Spinner,
        manageWalletAutopay
    },
    data() {
        return {
            securityIssue: false,
            iframeUrl: null,
            iframeStatus: '',
            showLoader: true,
            brimOn: false,
            bankDetails: null,
            cardDetails: null,
            brimStatus: '',
            token: null,
            manageModal: null,
            addNewPayment: false,
            hcdeReady: false,
            hcdeReadyWallet: false,
            envn: '',
            walletInstrumentId: null,
            hcdeTagString: null,
            WalletJSEncrypt: null,
            hcdeWalletServiceApi: null,
            hcdeTokenServiceApi: null,
            hcdeWalletComponent: null,
            hcdeTokenComponent: null,
            jsWalletMain: null,
            jsTokenMain: null,
            deletePaymentTitle: false,
            bankOptionTitle: false,
            cardOptionTitle: false,
            cardImg: false,
            editCardTitle: false,
            epwf: false,
            brim: false,
            showAddPaymentMethod: true,
            showDeletePaymentMethod: false,
            deleteHide: false,
            deleteShow: false,
            editCardFooter: false,
            showSuccessMsg: false,
            successAddCardMsg: false,
            successEditCardMsg: false,
            successAddBankMsg: false,
            totalLength: null,
            removeModal: null,
            errorModal: null,
            invalidExpDate: false,
            isCardAllowed: true,
            isAchAllowed: true
        };
    },
    updated() {
    this.isBrimOn = process.env.VUE_APP_BRIM;
    if(this.isBrimOn == 'true'){
        this.createHcdeTagListener() 
        }   
    },
    mounted() {
        localStorage.clear();
        let localData = new Map();
        localData.set('showLoading', true);
        this.$store.dispatch(local_setdata, {attributes:localData});
        this.manageModal = new Modal(document.getElementById('myModal'),{
            backdrop: 'static',
            keyboard: false
        });
        this.removeModal = new Modal(document.getElementById('limitModal'),{
            backdrop: 'static',
            keyboard: false
        });
        this.brimOn = process.env.VUE_APP_BRIM;
        this.env = process.env.VUE_APP_env;
        this.token = this.$store.getters[GET_DATA]("token");
        if(this.brimOn == "true"){
            this.WalletJSEncrypt = links.WalletJSEncrypt;
            this.hcdeWalletServiceApi = links.hcdeWalletServiceApi;
            this.hcdeTokenServiceApi = links.hcdeTokenServiceApi;
            this.hcdeWalletComponent = links.hcdeWalletComponent;
            this.hcdeTokenComponent = links.hcdeTokenComponent;
            this.jsWalletMain = links.jsWalletMain;
            this.jsTokenMain = links.jsTokenMain;
            this.brim = true;
            this.epwf = false;
            this.getManageWalletBrimDetails();
            this.getAutopayCustomerDetails();
        }else {
            this.brim = false
            this.epwf = true
            this.getManageWalletDetails();

        }

        // eslint-disable-next-line
        const self = this;
        window.showCardResponse = function (){
            //alert("HCDE Token ID="+window.myTokenVar);
            self.createCard(window.myTokenVar);
        };
        // eslint-disable-next-line
        window.showBankResponse = function(){
            //alert("HCDE walletInstrumentId= "+window.walletInstrumentId);
            self.createBank(window.walletInstrumentId);
        };

        this.errorModal = new Modal(document.getElementById('errorModal'),{
            backdrop: 'static',
            keyboard: false
        });
    },
    beforeUnmount() {
        // It's a good practice to clean up by removing the function when the component is destroyed
        delete window.showBankResponse;
        delete window.showCardResponse;
    },
    methods: {
        async getAutopayCustomerDetails() {
            try {
                const params = { ban: this.$store.getters[GET_DATA]('accountNumber') };
                const res = await axios.get(constants.BrimAutopayCustomerDetails, { params });
                if (res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo !== null) {
                    this.isAchAllowed = JSON.stringify(res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.achAllowedFlag);
                    this.isCardAllowed = JSON.stringify(res.data.brimcpdServiceResponse.responseInfo.accountDetailsResponseList[0].accountInfo.cardAllowedFlag);
                }
            } catch (err) {
                console.log(err);
            }
        },
        async createCard(token){
            try {
                let params = {
                    billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    walletReferenceNumber: this.$store.getters[GET_DATA]('walletRefNo'),
                    walletInstrumentDetails: {
                        cardDetails: {
                            cardToken: token
                        }
                    }
                }
                const res = await axios.post(constants.BrimManageWalletCreate, params);
                //alert("Transaction Status: " + res.data.transactionStatus);
                if (res.data.transactionStatus === 'S') {
                    this.pageRefresh();
                    this.successMsgShow("addCard");
                } else if (res.data.transactionStatus === 'F') {
                    this.manageModal.hide();
                    this.errorModal.show();
                }
            } catch (error) {
                console.log(error);
                this.manageModal.hide();
                this.errorModal.show();
            }
        },
        async createBank(token){
            try {
                let params = {
                    billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    walletReferenceNumber: this.$store.getters[GET_DATA]('walletRefNo'),
                    walletInstrumentDetails: {
                        bankDetails: {
                            achToken: token
                        }
                    }
                }
                const res = await axios.post(constants.BrimManageWalletCreate, params);
                //alert("Transaction Status: " + res.data.transactionStatus);
                if (res.data.transactionStatus === 'S') {
                    this.pageRefresh();
                    this.successMsgShow("addBank");
                }  else if (res.data.transactionStatus === 'F') {
                    this.manageModal.hide();
                    this.errorModal.show();
                }
            } catch (error) {
                console.log(error);
                this.manageModal.hide();
                this.errorModal.show();
            }
        },
        removeJSfile(oldfilename) {
            let targetelement = 'script';
            let targetattr = 'src';
            let allsuspects = document.getElementsByTagName(targetelement);
            for (let i = allsuspects.length; i >= 0; i--) {
                //search backwards within nodelist for matching elements to remove
                if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(oldfilename) != -1) {
                    allsuspects[i].parentNode.removeChild(allsuspects[i]);
                }
            }
        },
        async createHcdeTagListener() {
            const scripts = [
                this.WalletJSEncrypt,
                this.hcdeTokenServiceApi,
                this.WalletJSEncrypt,
                this.hcdeWalletServiceApi
            ];
            scripts.forEach(script => {
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    document.head.appendChild(tag); 
                }
            });
        },
        cardOption() {
            this.hcdeReady = true;
            this.hcdeReadyWallet = false;  
            const cardscripts = [
                this.hcdeTokenComponent				
            ];
            cardscripts.forEach(script=>{
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    document.head.appendChild(tag);
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDETokenServiceComponent();
                        
                        const digitsOnlyRegExp = /^[0-9]*$/;

                        let hcdeCardNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeCardNumCon.setAttribute('id', 'hcdeCardNumberContainer');
                        hcdeCardNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeCardNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeCardNumLabel.innerText = "";

                        let hcdeCardNumInput = document.getElementById('hcdeCardNumber');
                        hcdeCardNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeCardNumInput.setAttribute('placeholder', ' ');
                        hcdeCardNumInput.setAttribute('maxlength', '16');
                        hcdeCardNumInput.setAttribute('oninput', 'onChangeCardNum()');
                        hcdeCardNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newCardNumLabel = document.createElement('label');
                        newCardNumLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newCardNumLabel.setAttribute('id', 'hcdeCardNumberLabel');
                        newCardNumLabel.innerText = "Card Number";
                        hcdeCardNumCon.appendChild(newCardNumLabel);
                        
                        let hcdeExpMonthCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeExpMonthCon.setAttribute('id', 'hcdeExpMonthContainer');
                        hcdeExpMonthCon.setAttribute('class', 'hcdeComponentInputContainer col-6 pe-1');

                        let hcdeExpMonthLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeExpMonthLabel.innerText = "";

                        let hcdeExpMonthInput = document.getElementById('hcdeCardExpiryMonth');
                        hcdeExpMonthInput.options[0].innerText = 'Expiration Month';
                        hcdeExpMonthInput.setAttribute('onchange', 'onChangeExpMth()');

                        let hcdeExpYearCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeExpYearCon.setAttribute('id', 'hcdeExpYearContainer');
                        hcdeExpYearCon.setAttribute('class', 'hcdeComponentInputContainer col-6 ps-1');

                        let hcdeExpYearLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeExpYearLabel.innerText = "";
                        
                        let hcdeExpYearInput = document.getElementById('hcdeCardExpiryYear');
                        hcdeExpYearInput.options[0].innerText = 'Expiration Year';
                        hcdeExpYearInput.setAttribute('onchange', 'onChangeExpYr()');

                        let hcdeZipCodeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4)');
                        hcdeZipCodeCon.setAttribute('id', 'hcdeZipCodeContainer');
                        hcdeZipCodeCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-6 pe-1');

                        let hcdeZipCodeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4) > label');
                        hcdeZipCodeLabel.innerText = "";

                        let hcdeZipCodeInput = document.getElementById('hcdeZipCode');
                        hcdeZipCodeInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeZipCodeInput.setAttribute('placeholder', ' ');
                        hcdeZipCodeInput.setAttribute('maxlength', '5');
                        hcdeZipCodeInput.setAttribute('oninput', 'onChangeZipCode()');
                        hcdeZipCodeInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newZipCodeLabel = document.createElement('label');
                        newZipCodeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newZipCodeLabel.setAttribute('id', 'hcdeZipCodeLabel');
                        newZipCodeLabel.innerText = "Zip Code";
                        hcdeZipCodeCon.appendChild(newZipCodeLabel);

                        let hcdeButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                        hcdeButtonCon.setAttribute('id', 'hcdeSaveButtonContainer');

                        let hcdeSaveButton = document.getElementById('hcdeComponentSubmitButton');
                        hcdeSaveButton.setAttribute('value', 'Save');

                        // eslint-disable-next-line no-undef
                        checkCardInfo();
                    });
                }
                setTimeout(() => (
                    this.cardOptionTitle = true,
                    this.cardImg = true,
                    this.showAddPaymentMethod = false
                ), 135);  
            });
        },
        editCardOption(maskedAcctNum, expDate, zipCode) {
            this.hcdeReady = true;
            this.hcdeReadyWallet = false;     
            const cardscripts = [
                this.hcdeTokenComponent				
            ];
            cardscripts.forEach(script=>{
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    document.head.appendChild(tag);
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDETokenServiceComponent();
                        
                        let hcdeCardNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeCardNumCon.setAttribute('id', 'hcdeCardNumberContainer');
                        hcdeCardNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeCardNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeCardNumLabel.innerText = "";

                        let hcdeCardNumInput = document.getElementById('hcdeCardNumber');
                        hcdeCardNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeCardNumInput.setAttribute('placeholder', ' ');
                        hcdeCardNumInput.value = maskedAcctNum;
                        hcdeCardNumInput.disabled = true;

                        if(!document.getElementById("hcdeCardNumberLabel")){ 
                            let newCardNumLabel = document.createElement('label');
                            newCardNumLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                            newCardNumLabel.setAttribute('id', 'hcdeCardNumberLabel');
                            newCardNumLabel.innerText = "Card Number";
                            hcdeCardNumCon.appendChild(newCardNumLabel);
                        }
                        
                        let hcdeExpMonthCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeExpMonthCon.setAttribute('id', 'hcdeExpMonthContainer');
                        hcdeExpMonthCon.setAttribute('class', 'hcdeComponentInputContainer col-6 pe-1');

                        let hcdeExpMonthLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeExpMonthLabel.innerText = "";

                        let hcdeExpMonthInput = document.getElementById('hcdeCardExpiryMonth');
                        hcdeExpMonthInput.options[0].innerText = 'Expiration Month';
                        hcdeExpMonthInput.setAttribute('onchange', 'onChangeExpMth()');

                        let expMonth = expDate.slice(0,2);
                        hcdeExpMonthInput.value = expMonth;

                        let hcdeExpYearCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeExpYearCon.setAttribute('id', 'hcdeExpYearContainer');
                        hcdeExpYearCon.setAttribute('class', 'hcdeComponentInputContainer col-6 ps-1');

                        let hcdeExpYearLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeExpYearLabel.innerText = "";
                        
                        let hcdeExpYearInput = document.getElementById('hcdeCardExpiryYear');
                        hcdeExpYearInput.options[0].innerText = 'Expiration Year';
                        hcdeExpYearInput.setAttribute('onchange', 'onChangeExpYr()');

                        let expYear = expDate.slice(-4);
                        hcdeExpYearInput.value = expYear;

                        let hcdeZipCodeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4)');
                        hcdeZipCodeCon.setAttribute('id', 'hcdeZipCodeContainer');
                        hcdeZipCodeCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-6 pe-1');

                        let hcdeZipCodeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4) > label');
                        hcdeZipCodeLabel.innerText = "";

                        let hcdeZipCodeInput = document.getElementById('hcdeZipCode');
                        hcdeZipCodeInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeZipCodeInput.setAttribute('placeholder', ' ');
                        hcdeZipCodeInput.value = zipCode;
                        hcdeZipCodeInput.disabled = true;

                        if(!document.getElementById("hcdeZipCodeLabel")){
                            let newZipCodeLabel = document.createElement('label');
                            newZipCodeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                            newZipCodeLabel.setAttribute('id', 'hcdeZipCodeLabel');
                            newZipCodeLabel.innerText = "Zip Code";
                            hcdeZipCodeCon.appendChild(newZipCodeLabel);
                        }

                        let hcdeButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');

                        if(hcdeButtonCon){
                            hcdeButtonCon.remove();
                        }
                    });
                } 
            });
            setTimeout(() => (
                this.editCardFooter = true
            ), 250); 
            
        },
        bankOption() {
            this.hcdeReady = false;
            this.hcdeReadyWallet = true;
            const bankscripts = [
                this.hcdeWalletComponent				
            ];
            bankscripts.forEach(script => {
                let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                if (scriptFound) {
                    this.removeJSfile(script);
                }
                let tag = document.head.querySelector(`[src="${ script }"`);
                if (!tag) {
                    tag = document.createElement("script");
                    tag.setAttribute("src", script);
                    tag.setAttribute("type", 'text/javascript');
                    document.head.appendChild(tag); 
                    tag.addEventListener('load', function () {
                        // eslint-disable-next-line no-undef
                        this.hcdeTagString = new HCDEWalletServiceComponent();

                        const digitsOnlyRegExp = /^[0-9]*$/;

                        let hcdeBankDetailCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                        hcdeBankDetailCon.setAttribute('id', 'hcdeBankDetailContainer');
                        hcdeBankDetailCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeRouteNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                        hcdeRouteNumLabel.innerText = "";

                        let hcdeRouteNumInput = document.getElementById('bankRoutingNumber');
                        hcdeRouteNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeRouteNumInput.setAttribute('placeholder', ' ');
                        hcdeRouteNumInput.setAttribute('maxlength', '9');
                        hcdeRouteNumInput.setAttribute('oninput', 'onChangeRouteNum()');
                        hcdeRouteNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newRoutingNumberLabel = document.createElement('label');
                        newRoutingNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newRoutingNumberLabel.setAttribute('id', 'hcdeRouteNumberLabel');
                        newRoutingNumberLabel.innerText = "Routing Number";
                        hcdeBankDetailCon.appendChild(newRoutingNumberLabel);

                        let hcdeAcctNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                        hcdeAcctNumCon.setAttribute('id', 'hcdeAccountNumberContainer');
                        hcdeAcctNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                        let hcdeAcctNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                        hcdeAcctNumLabel.innerText = "";

                        let hcdeAcctNumInput = document.getElementById('bankAccountNumber');
                        hcdeAcctNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                        hcdeAcctNumInput.setAttribute('placeholder', '');
                        hcdeAcctNumInput.setAttribute('maxlength', '17');
                        hcdeAcctNumInput.setAttribute('oninput', 'onChangeAcctNum()');
                        hcdeAcctNumInput.addEventListener("keypress", e => {
                            if (!digitsOnlyRegExp.test(e.key)) {
                                e.preventDefault();
                            }
                        });

                        let newAcctNumberLabel = document.createElement('label');
                        newAcctNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                        newAcctNumberLabel.setAttribute('id', 'hcdeAccountNumberLabel');
                        newAcctNumberLabel.innerText = "Account Number";
                        hcdeAcctNumCon.appendChild(newAcctNumberLabel);

                        let hcdeAcctTypeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                        hcdeAcctTypeCon.setAttribute('id', 'hcdeAccountTypeContainer');
                        hcdeAcctTypeCon.setAttribute('class', 'hcdeComponentInputContainer col-12');

                        let hcdeAcctTypeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                        hcdeAcctTypeLabel.innerHTML = '';

                        let hcdeAcctTypeInput = document.getElementById('bankAccountType');
                        hcdeAcctTypeInput.options[0].innerText = 'Bank account type';
                        hcdeAcctTypeInput.setAttribute('onchange', 'onChangeAcctType()');

                        let hcdeBankButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                        hcdeBankButtonCon.setAttribute('id', 'hcdeSaveBankButtonContainer');

                        let hcdeSaveBankButton = document.getElementById('hcdeComponentSubmitButton');
                        hcdeSaveBankButton.setAttribute('value', 'Save');

                        // eslint-disable-next-line no-undef
                        checkBankInfo();
                    });
                }
                setTimeout(() => (
                    this.bankOptionTitle = true,
                    this.showAddPaymentMethod = false
                ), 135);   
            });
        },
        showAddPopUp() {
            if(this.totalLength >= 5){
                this.removeModal.show();
            }else {
                this.showAddPaymentMethod = true;
                this.deletePaymentTitle = false;
                this.bankOptionTitle = false;
                this.cardOptionTitle = false;
                this.showDeletePaymentMethod = false;
                this.editCardTitle = false;
                this.editCardFooter = false;
                this.invalidExpDate = false;
                this.cardImg = false;
                this.hcdeReady = false;
                this.hcdeReadyWallet = false;
                this.manageModal.show();
            }
        },
        RemoveModalClose() {
            this.removeModal.hide()
        },
        showEditPopUp(maskedAcctNum, expDate, zipCode, instrumentId, cardType) {
            this.editCardTitle = true;
            this.showAddPaymentMethod = false;
            this.showDeletePaymentMethod = false;
            this.cardImg = true;
            this.deletePaymentTitle = false;
            this.bankOptionTitle = false;
            this.cardOptionTitle = false;
            this.deleteHide = false;
            this.deleteShow = false;
            this.hcdeReady = false;
            this.hcdeReadyWallet = false;
            this.invalidExpDate = false;
            this.instrumentId= instrumentId;
            this.cardtype= cardType;
            this.accounttype= '';
            this.manageModal.show();

            setTimeout(() => (
                this.editCardOption(maskedAcctNum, expDate, zipCode)
            ), 250);
        },
        showDeletePopUp(a,b,c) {
          this.showDeletePaymentMethod = true;
          this.deletePaymentTitle = true;
          this.succesfulDelete = false;
          this.showAddPaymentMethod = false;
          this.bankOptionTitle = false;
          this.cardOptionTitle = false;
          this.hcdeReady = false;
          this.hcdeReadyWallet = false;
          this.deleteHide = true;
          this.deleteShow = false;
          this.editCardTitle = false;
          this.editCardFooter = false;
          this.invalidExpDate = false;
          this.cardImg = false;
          this.instrumentId= a;
          this.cardtype=b;
          this.accounttype=c;
          this.manageModal.show();
        },
        pageRefresh() {
            this.modalClose();
            this.initiate();
        },
        async callDelete(pa){
            try {
                const resDelete = await axios.post(constants.BrimManageWalletDelete, pa);
                if(resDelete.data.responseInfo.transactionStatus === "S"){
                    this.showAddPaymentMethod = false;
                    this.deletePaymentTitle = false;
                    this.bankOptionTitle = false;
                    this.cardOptionTitle = false;
                    this.showDeletePaymentMethod = true;
                    this.succesfulDelete = true;
                    this.failedDelete = false;
                    this.hcdeReady = false;
                    this.hcdeReadyWallet = false;
                    this.deleteHide = false;
                    this.deleteShow = true;
                } else if (resDelete.data.responseInfo.transactionStatus === "F"){
                    this.showAddPaymentMethod = false;
                    this.deletePaymentTitle = false;
                    this.bankOptionTitle = false;
                    this.cardOptionTitle = false;
                    this.showDeletePaymentMethod = true;
                    this.succesfulDelete = false;
                    this.failedDelete = true;
                    this.hcdeReady = false;
                    this.hcdeReadyWallet = false;
                    this.deleteHide = false;
                    this.deleteShow = true;
                }
            }catch(error){
                console.log(error);
                this.showAddPaymentMethod = false;
                this.deletePaymentTitle = false;
                this.bankOptionTitle = false;
                this.cardOptionTitle = false;
                this.showDeletePaymentMethod = true;
                this.succesfulDelete = false;
                this.failedDelete = true;
                this.hcdeReady = false;
                this.hcdeReadyWallet = false;
                this.deleteHide = false;
                this.deleteShow = true;
            }
          
        },
        Delete() {
            if(this.accounttype == "Savings" || this.accounttype == "Checking"){
                let params = {
                    billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    walletReferenceNumber: this.$store.getters[GET_DATA]('walletRefNo'),
                    walletInstrumentDetails: {
                        bankDetails: {
                            instrumentId: this.instrumentId
                        }
                    }
                }
                this.callDelete(params);
            }else if(this.cardtype=="VISA" || this.cardtype=="MASTER" || this.cardtype=="DISCOVER"){
                let params={
                    billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    walletReferenceNumber: this.$store.getters[GET_DATA]('walletRefNo'),
                    walletInstrumentDetails: {
                        cardDetails: {
                            instrumentId: this.instrumentId
                        }
                    }
                }
                this.callDelete(params);
            }
            else {
                let params={
                    billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    walletReferenceNumber: this.$store.getters[GET_DATA]('walletRefNo'),
                    walletInstrumentDetails: {
                        cardDetails: {
                            instrumentId: this.instrumentId
                        }
                    }
                }
                this.setLogger(this.cardtype, this.$store.getters[GET_DATA]('accountNumber'))
                this.callDelete(params);
            }
        },
        async updateCardDetails() {
            try {
                let x = document.getElementById('hcdeCardExpiryMonth').value;
                let y = document.getElementById('hcdeCardExpiryYear').value;
                let expDate = y + "-" + x + "-01"
                let params = {
                    billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    walletReferenceNumber: this.$store.getters[GET_DATA]('walletRefNo'),
                    walletInstrumentDetails: {
                        cardDetails: {
                            cardExpirationDate: expDate,
                            instrumentId: this.instrumentId
                        }
                    }
                }
                const res = await axios.post(constants.BrimManageWalletUpdate, params);
                if(res.data.responseInfo.transactionStatus === 'S') {
                    this.pageRefresh();
                    this.successMsgShow("editCard");
                } else if (res.data.responseInfo.transactionStatus === 'F') {
                    if (res.data.responseInfo.errorList[0].errorCd == '10002') {
                        this.invalidExpDate = true;
                        let expMonthDropdown = document.getElementById('hcdeCardExpiryMonth');
                        expMonthDropdown.classList.add("text-danger");
                        expMonthDropdown.classList.add("is-invalid");
                        let expYearDropdown = document.getElementById('hcdeCardExpiryYear');
                        expYearDropdown.classList.add("text-danger");
                        expYearDropdown.classList.add("is-invalid");
                    } else {
                        this.manageModal.hide();
                        this.errorModal.show();
                    }
                }
            } catch(error) {
                console.log(error);
            }
        },
        modalClose() {
            this.manageModal.hide();
        },
        successMsgClose() {
            this.showSuccessMsg = false;
            this.successAddBankMsg = false;
            this.successAddCardMsg = false;
            this.successEditCardMsg = false;
        },
        successMsgShow(x) {
            this.showSuccessMsg = true;
            if (x == "addBank") {
                this.successAddBankMsg = true;
                this.successAddCardMsg = false;
                this.successEditCardMsg = false;
            } else if (x == "addCard") {
                this.successAddCardMsg = true;
                this.successEditCardMsg = false;
                this.successAddBankMsg = false;
            }  else if (x == "editCard") {
                this.successAddCardMsg = false;
                this.successEditCardMsg = true;
                this.successAddBankMsg = false;
            }
        },
        async initiate() {
            let localData = new Map();
            try{
                const params = {
                    billingAccountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    walletType: "PRF",
                    walletReferenceNumber: this.$store.getters[GET_DATA]('walletRefNo')
                };
                const resData = await axios.post(constants.WalletInitiate, params);
                if(resData.data) {
                    this.bankDetails = resData.data.bankAcctDetails;
                    this.cardDetails = resData.data.cardDetails;
                    let cardLenght = this.cardDetails ? this.cardDetails.length : 0;
                    let bankLenght = this.bankDetails ? this.bankDetails.length : 0;
                    this.totalLength = cardLenght + bankLenght;
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                }else{
                    this.brimStatus = 'ERROR';
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                }
            }catch (error) {
                console.log(error);
                this.brimStatus = 'ERROR';
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
        },
        async getManageWalletBrimDetails () {
            let localData = new Map();
            let params = {
                accountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                walletRefNo: this.$store.getters[GET_DATA]('walletRefNo'),
                srcSystem: this.$store.getters[GET_DATA]('srcSystem')
            };
            try{
                const resSession = await axios.post(constants.ManageWalletOrchestratorURL, params);
                if(resSession.data.epwfSessionDetails.sessionId){
                    this.sessionId = resSession.data.epwfSessionDetails.sessionId;
                    this.initiate();
                }else {
                    this.brimStatus = 'ERROR';
                    localData.set('showLoading', false);
                    this.$store.dispatch(local_setdata, {attributes:localData});
                }
            } catch(error) {
                console.log(error);
                this.brimStatus = 'ERROR';
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
            }
        },
        async getManageWalletDetails() {
            let localData = new Map();
            try {
                const params = {
                    accountNumber: this.$store.getters[GET_DATA]('accountNumber'),
                    walletRefNo: this.$store.getters[GET_DATA]('walletRefNo'),
                    srcSystem: this.$store.getters[GET_DATA]('srcSystem')
                };
                const res = await axios.post(constants.ManageWalletOrchestratorURL, params);
                this.iframeUrl = res.data.epwfSessionDetails.sessionURL;
                this.iframeStatus = res.data.statusMessage;
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
                setTimeout(() => (this.showLoader = false), 3000);
            } catch (error) {
                console.log(error);
                this.iframeStatus = 'ERROR';
                localData.set('showLoading', false);
                this.$store.dispatch(local_setdata, {attributes:localData});
                setTimeout(() => (this.showLoader = false), 3000);
            }
        },
        errorModalClose() {
            this.errorModal.hide();
        },

        async setLogger (type, account) {
            let params = {
                    logString: "Manage Wallet Delete -  Received non authorized Card type:" + type + ", for Account number: " + account
                };
            try {
                await axios.get(constants.SplunkLogger, { params });
            } catch (error) {
                console.log(error);
            }
    },
    }
}
</script>
<style scoped>
#invalidExpDateErrorMsg {
    display: block;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    background-color: #f2dede;
    color: #a94442;
    height: fit-content;
    width: fit-content;
    padding: 10px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    border-radius: 2px;
    border-color: #ebccd1;
}
#errorModal .modal-header {
    padding-bottom: 0;
}
#errorModal .modal-content {
    text-align:left;
}
#errorModal .modal-content h3 {
    margin-bottom: 10px;
}
#errorModal .modal-content p {
    margin-bottom: 10px;
}
#errorModal .modal-body {
    padding-top: 0;
    padding-bottom: 0;
}
#errorModal .modal-footer .btn-primary {
    margin-right: 10px;
}
#errorModal .modal-footer {
    padding-top: 0;
    justify-content: flex-start;
}
#errorModal .close {
    background: transparent;
    border: none;
    font-size: 28px;
    padding: 0;
}
#successMsg {
    box-shadow: 0px 1px 4px #00000029;
    width: 329px;
    height: 68px;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding: 17px 0;
    background: #E1F0E9 0% 0% no-repeat padding-box;
    border-radius: 8px;
}
@media (min-width: 767px){
    #successMsg {
        margin-top: -88px;
    }
}
.manage-wallet-content .manage-wallet-heading {
    color: #000;
}
.manage-wallet-subtitle {
    font-size: 20px;
    margin-top: 20px;
}
.manage-wallet-heading {
    font-weight: 500;
    margin-top: 1rem;
    color: #000;
    font-family: 'MaisonNeue-Light', sans-serif;
    font-size: 36px;
}
.manage-wallet-content .manage-wallet-paragraph {
    text-align: left;
}
#manage-wallet-content-subsection {
    text-align: left; 
    font-size:18px;
}
.content-margin {
    margin: 2rem 0 1rem 0;
}
.pre-iframe-heading {
    color: #000;
}
@media (min-width: 767px) {
    .wallet-container {
        width: 100%;
        float: left;
    }
    .wallet-cards {
        width: 48%;
        float: left;
    }
    .wallet-bank {
        width: 48%;
        float: left;
    }
    .wallet-add {
        width:48%;
        float: left;
    }
    .wallet-box:nth-child(even) {
        margin-left: 1%;
    }
    .wallet-box:nth-child(odd) {
        margin-right: 1%;
    }
}
.wallet-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    opacity: 1;
    padding: 16px;
    text-align: left;
    margin-bottom: 12px;
    min-height: 70px;
    cursor: pointer;
}
.img-content-container p {
    text-transform: lowercase;
    padding: 10px 35px 0 60px;
    color:#000000;
}
.img-content-add p{
    color:#000000;
    padding: 10px 35px 0 60px;
}
.img-content-container p:before, .img-content-add p:before {
    background-image: url(../../images/icon-navigation.png);
    background-repeat: no-repeat;
    position: absolute;
    right: 5px;
    content: "";
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: 100%;
    top:2px;
}
.img-content-container p > span {
    display: inline-block;
}
.img-content-container p > span:first-letter {
    text-transform: uppercase;
}
.img-card {
    position: relative;
}

.MASTERCARD .img-card:before {
    background-image: url(../../images/Card.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 100%;
    top: -7px;
}
.VISA .img-card:before {
    background-image: url(../../images/visacard.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 65%;
    top: -7px;
    border-radius: 50%;
    background-color: #E0E8F7;
}
.MASTER .img-card:before {
    background-image: url(../../images/visacard.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 65%;
    top: -7px;
    border-radius: 50%;
    background-color: #E0E8F7;
}
.DISCOVER .img-card:before {
    background-image: url(../../images/visacard.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 65%;
    top: -7px;
    border-radius: 50%;
    background-color: #E0E8F7;
}
.img-bank {
    position: relative;
}
.Savings .img-bank:before {
    background-image: url(../../images/institute.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 100%;
    top: -7px;
}
.Checking .img-bank:before {
    background-image: url(../../images/institute.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 5px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 100%;
    top: -7px;
}
.img-content-container p, .img-content-add p {
    margin-bottom: 0;
}
.img-content-container, .img-content-add {
    position: relative;
}
.img-add {
    position: relative;
}
.img-add:before {
    background-image: url(../../images/pluse_blue.png);
    background-repeat: no-repeat;
    position: absolute;
    left: 4px;
    content: "";
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 30%;
    top: -7px;
    border-radius: 50%;
    background-color: #E0E8F7;
}
.close {
    position: absolute;
    top: 8px;
    right: 15px;
    border: none;
    background: #fff;
    font-size: 25px;
}
.bank, .card {
    width: 50%;
    float: left;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}
.modalBtns {
    display: flex;
    justify-content: left;
    align-items: center;
}
.manage-wallet-bank-card .modal-content {
    box-shadow: 0px 1px 4px #00000029;
    text-align: left;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    position: relative;
    padding: 10px;
    min-height: 150px;
}
.content-style{
    text-align: center;
    font-size: 16px;
    font-family: Maison Neue, sans-serif;
    letter-spacing: 0.16px;
    color: #000000;
    opacity: 1;
}
.managewallet-bank-icon-circle{
    position: relative;
    width: 48px;
    height: 48px;
    margin: 0 auto;
}
.managewallet-bank-icon-circle img{
    width: 100%;
}
.managewallet-card-circle{
    width: 48px;
    height: 48px;
    margin: 0 auto;
} 
.managewallet-card-circle img{
    width: 100%;
}
.managewallet-bank{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    opacity: 1;
    width: 48%;
    margin-right: 2%;
    float: left;
    padding: 15px 0;
    cursor: pointer;
}
.managewallet-card{
    width: 48%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    opacity: 1;
    align-items: right;
    float: left;
    margin-left: 2%;
    padding: 15px 0;
    cursor: pointer;
}
.manage-wallet-bank-card .modal-body {
float:left;
}
.hcde-comp {
    float: left;
}
.manage-wallet-bank-card .modal-header{
    padding:0;
    min-height: 50px;
    position: relative;
}
.manage-wallet-bank-card .modal-header .btn {
    position: absolute;
    right: 10px;
}

.bank-card-section {
    width: 94%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom:60px;
}
@media (min-width: 767px) {
   .bank-card-section {
        width: 70%;
    } 
}
.visa-img, .mastercard-img, .discover-img {
    height: 30px;
    width: 32px;
    margin-left: 10px;
}

@media screen and (min-width: 800px) {
   .modal-title{
        padding:3%;
    } 
}
.delete-success, .delete-fail {
	text-align: center;
}
#limitModal .modal-footer {
    justify-content: center;
    border-top: 0;
}
#limitModal .modal-content {
    max-width: 400px;
}
#limitModal .modal-content p {
    margin-bottom: 0;
}
#errorModal .modal-title {
    padding-left:0;
}
.modal-footer-ok {
    text-align: center;
    margin-bottom: 15px;
}
</style>