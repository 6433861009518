<template>
  <div id="qbp-left-column-content">
    <div v-if="isDataAvailable">
      <div class="container">
        <div id="qbp-intro-wrapper">
          
          <div class="text-start">
            
            <div class="align-div-header">
              <img alt="success icon" src="../../images/billdisputesuccess.svg" />
              <h1 data-test="messageHead">Thanks for your payment!</h1>
              <div class= "text-email">A receipt has been sent to the email address on file for this account.</div>
            </div>

            <div class="grid">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-conf-num">
                  <div class="text-conf-num" data-test="messageData">CONFIRMATION NUMBER</div> 
                  <div class="text-num">{{ confirmationNumber }}</div>              
                </div>
              </div>
            <div>
            <p v-if="this.eligibleForPa">You are now eligible to set up a payment plan.</p>
              <div v-if="this.prepaidSuspended == true">
                <strong>
                  <h3 class="text-color" data-test="messageData">
                    Your Service will be restored momentarily.
                  </h3><br>
                </strong>
                <div id="succMsg" style="margin-right: 9%;">
                  <p> Your payment method has been successfully updated. </p>
                  Future monthly payments for the full amount of your subscription will be paid to CenturyLink
                  automatically deducting a payment from
                  <div v-if="!showPaymentBlock">
                    <div id="paymentMethod">{{ paymentMethod }}</div>
                  </div>
                  <div v-else>
                    this card.
                  </div>
                </div>
              </div>
              <div v-else-if="this.viewPaymentDetailBlockBasedOnAccount == true && this.suspendedAccount == true">
                <strong>
                  <h3 class="text-color" data-test="messageData">
                    Your service will be restored within 24 to 48 hours, and please note that you'll see a reconnection fee on your next bill.
                  </h3><br>
                </strong>
                <div id="succMsg" style="margin-right: 9%;">
                  <p> Your payment method has been successfully updated. </p>
                  Future monthly payments for the full amount of your subscription will be paid to CenturyLink
                  automatically deducting a payment from
                  <div v-if="!showPaymentBlock">
                    <div id="paymentMethod">{{ paymentMethod }}</div>
                  </div>
                  <div v-else>
                    this card.
                  </div>
                </div>
              </div>
            <div class="details" v-else-if="!showPaymentBlock">
              <div class= "text-summary"><h2>Payment Summary</h2>
              </div>
              <div class="row">
                <div class="col-8 col-sm-6 text-payment">
                  <div class="qbp-success-circle">
                      <img alt="total payment icon" class="payment-icon" src="../../images/attach_money_black_24dp.svg" /> 
                  </div> 
                  <div class="text-pmnt"> Total payment</div>
                </div>
                  <div class="col-4 col-sm-6 payment-amt">
                    <div class="text-pmnt-amt">{{ totalPayment }}</div>
                  </div>
              </div>   
              <div class="horizontal-row">
                <div class="horizontal-border">
                </div>
              </div>
              <div class="row">
                <div class="col-8 col-sm-6 convenience-fee">
                  <div class="text-conv">Convenience fee</div>
                </div>
                <div class="col-4 col-sm-6 payment-amt">
                  <div class="text-conv-fee">{{ convenienceFee }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-8 col-sm-6 payment-amount">
                  <div class="text-pay">Payment amount</div>
                </div>
                <div class="col-4 col-sm-6 payment-amt">
                  <div class="text-pay-pmt">{{ paymentAmount }}</div>
                </div>
              </div>
              <div class="qbp-calendar-circle">
                  <img alt="calendar icon" class="calendar-icon" src="../../images/calendar_today_black_24dp.svg" /> 
              </div> 
              <div class="col-12 col-sm-12 txt-payment-method">
                <div class="payment-method">Payment date</div>
              </div>  
              <div class="col-12 col-sm-12 text-payment">
                <div class="text-pay">{{ paymentDate }}</div>
              </div>
              <div class="QBP-pmnt-icon-circle">
                  <img alt="payment method icon" class="pmnt-method-icon" src="../../images/payments_black_24dp.svg" /> 
              </div>
              <div class="col-12 col-sm-12 payment-method"> Payment method</div>
              <div class="text-pay">{{ paymentAcctLast4 }}</div>
              </div>
            </div>
            </div>
              <div class="print-icon">
                  <a @click="printPage" @keydown.enter="printPage" aria-label="Print receipt" id="printButton" >
                  Print receipt
                  </a>
              </div>
              
            </div>
          </div>
        </div> 
          </div>
        <!-- if everything looks good show the content respectively end -->
        <!-- show the acod profile creation button when acod flag is true on success call --> 
        <div v-if="acodSuccess" class="mt-5 mb-3" id="acodContent">
          <div class="row acod-content">
              <div class="col-12 col-md-6 row-acod">
                <div class="left-grid text-start">
                  <h2 class="mb-2">Next time, just sign in!</h2>
                  <p>My CenturyLink is an easy way to pay your bill, get troubleshooting help, and submit repair requests. It
                  only takes a few minutes to set up your online account and get the most CenturyLink has to offer you.</p>
                  <div class="registerStyle mt-3">
                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal"
                      @click="getAcodRegisterationDetail()">Complete Your Account</button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 order-first order-xl-2 order-lg-2 order-md-2 row-acod">
                  <img alt="acod-image" class="img-people" src="../../images/Layer2.svg" />
              </div>
            </div>
        </div>
        <div class="container" id="requestContent">
        <div v-if="acodSuccess" class="row justify-content-center qbp-acod-container marg-top-bottom">
            <div v-if="!isWaiting && isDataAvailable" class="col-12 col-xl-12 text-start">
                <h2>Why create an account?</h2>
                <p>When you're signed in, we can give you targeted and specific information about your account, bill, service, and more.</p>
            </div>
            <div v-if="!isWaiting && isDataAvailable" class="row justify-content-center">
                <div class="col-12 col-lg-4 col-md-4 line">
                    <img alt="bill-icon" class="icon-style-bill" src="../../images/headset_mic_black_24dp.svg" />
                    <p>Submit repair requests</p>
                    <label>Skip the phone call and waiting on hold to make a repair appointment. Request service help from My CenturyLink.</label>
                </div>
                <div class="col-12 col-lg-4 col-md-4 line">
                    <img alt="bill-icon" class="icon-style-dollar" src="../../images/svg/Dollar.svg" />
                    <p>Pay your bills</p>
                    <label>Easy and complete access to your current and past bills, plus easy enrollment in AutoPay.</label>
                </div>
                <div class="col-12 col-lg-4 col-md-4">
                    <img alt="bill-icon" class="icon-style-ts" src="../../images/icon-action-build_24px.svg" />
                    <p>Get troubleshooting help</p>
                    <label>When you’re signed in, we can provide detailed troubleshooting steps for your specific service and equipment.</label>
                </div>
            </div>
        </div>
    </div>
      <div class="norton-logo-container">
          <div id="norton-logo"><img alt="norton logo" src="../../images/norton.png" /></div>
      </div>
  <div v-if="!isWaiting && !isDataAvailable">
    Sorry, We could not find the page which you have requested. Kindly navigate
    to our <a class="home-page" @click="startOverAgain()">Home Page.</a>
  </div>
  <div v-if="isWaiting && !isDataAvailable">
    Please wait While we are fetching the data for you.
  </div>
  <div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content text-placement">
        <div v-if="acodSuccessModal" class="modal-body">
          <div class="align-heading success-heading">
            <img alt="success icon" class="checkmark" src="../../images/successicon.gif" />
            <h3>Your profile has been created</h3>
          </div>
          <span>An email has been sent to <strong> {{ showEmailAddress }} </strong> with a link to activate your profile and set your password.</span>
        </div>
        <div v-else-if="acodFailModal" class="modal-body">
          <h3 class="align-heading">Sorry! There seems to be an issue. Please try again later.</h3>
        </div>
        <div v-else-if="loginExist" class="modal-body">
          <p class="text-start">Great news! You already have an online profile. To connect this account with your existing profile, 
          <a :href="CtlSignInURL">Sign into My CenturyLink</a> and add this account to your profile.
          </p>
          <a class="signin-cta" :href="CtlSignInURL">Sign into My CenturyLink</a>
        </div>
        <div class="modal-footer closeButton">
          <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal" @click="modalClose()">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_DATA, SET_DATA } from "../../store/storecontants";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import constants from "../../js/constants";
import axios from "axios";
import links from "../../js/links";
import { RESET } from "../../store/storecontants";
import { pii } from "../../js/pii";

export default {
  name: "SuccessfulPayments",
  data() {
    return {
      accountNumber: "",
      emailAddressOfRecord: "",
      isDataAvailable: false,
      paymentAmount: "",
      convenienceFee: "",
      totalPayment: "",
      paymentAcctLast4: "",
      paymentDate: "",
      paymentMethod: "",
      isWaiting: false,
      showPaymentBlock: false,
      prepaidSuspended: false,
      postedHereUrl: links.MyTermsCondition,
      suspendedAccount: "",
      balance: "",
      totalBalanceOwed: "",
      viewPaymentDetailBlockBasedOnAccount: "",
      isSuspendedAccount: "",
      isMultiAccount: "",
      eligibleForPa: false,
      qbpPaymentAmount: "",
      PrivacyNoticeURL: links.PrivacyNoticeURL,
      acodSuccess: false,
      acodSuccessModal: false,
      acodFailModal: false,
      firstName : "",
      lastName : "",
      AcodAccountNumber : "",
      loginExist: false,
      showEmailAddress: "",
      CtlSignInURL: links.CtlSignInURL,
      brimOn: false,
      confirmationNumber: null
    };
  },
  mounted() {
    //gtm for success page 
    window.dataLayer?.push({
      event: 'qbp_successful_payments',
      flow:{
        name: 'qbp',
        step: 'successful_payments_page'
      },
    });
    // get the payment id from url
    this.brimOn = process.env.VUE_APP_BRIM;
      if(this.brimOn == 'true'){
        this.confirmationNumber = this.$store.getters[GET_DATA]('confirmationNumber') ? this.$store.getters[GET_DATA]('confirmationNumber') : 'NA'
      } else {
        this.confirmationNumber = this.$route.query.PaymentID;
      }
    // get the required values to display success page
    this.accountNumber = JSON.parse(this.$store.getters[GET_DATA]("selectedAccountNumber"))
    ? JSON.parse(this.$store.getters[GET_DATA]("selectedAccountNumber"))
    : "NA";   
    this.prepaidSuspended = this.$store.getters[GET_DATA]("prepaidSuspendedAccount") ? this.$store.getters[GET_DATA]("prepaidSuspendedAccount") : 'NA';
    this.suspendedAccount = this.$store.getters[GET_DATA]("suspendedAccount") ? this.$store.getters[GET_DATA]("suspendedAccount") : 'NA';
    this.balance = this.$store.getters[GET_DATA]("balance") ? this.$store.getters[GET_DATA]("balance") : 'NA';
    this.totalBalanceOwed = this.$store.getters[GET_DATA]("amountOwed") ? this.$store.getters[GET_DATA]("amountOwed") : 'NA';
    this.suspendedAccount = this.suspendedAccount == 'NA' ? this.suspendedAccount : JSON.parse(this.suspendedAccount);
    this.emailAddressOfRecord = this.$store.getters[GET_DATA]('emailAddress') ? JSON.parse(this.$store.getters[GET_DATA]('emailAddress')) : "NA";
    this.AcodAccountNumber = this.$store.getters[GET_DATA]('AcodAccountNumber') ? JSON.parse(this.$store.getters[GET_DATA]('AcodAccountNumber')) : "NA";
    if(this.suspendedAccount !== null && this.suspendedAccount !== 'NA' && (this.suspendedAccount.toLowerCase () == "s" || this.suspendedAccount == "SUSPENDED" || this.suspendedAccount == "SUSP")){
        this.isSuspendedAccount = true;
    } else {
      this.isSuspendedAccount = false;
    }
    this.elgibleAmtPa = JSON.parse(this.$store.getters[GET_DATA]("elgibleAmtPa"));
    this.getPaymentDetails();
  },
  methods: {
      getHumanReadbledt(timeStamp) {
        if (timeStamp == 'NA') {
            return null;
        }
        const monthArr = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const date = new Date(timeStamp);
        const month = monthArr[date.getMonth()];
        const newDate = date.getDate();
        const readableDate = month + " " + newDate ;
        return readableDate;
    },
    async getPaymentDetails() {
      this.isWaiting = true;
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      this.isMultiAccount = JSON.parse(this.$store.getters[GET_DATA]("status"));
      this.multiAccountNumber = JSON.parse(this.$store.getters[GET_DATA]("multiAccountNumber"));
      this.paIneligibleMultiaccount = JSON.parse(this.$store.getters[GET_DATA]("paIneligibleMultiaccount"));
      let params = {};
      // two params depends on multiaccount and normal account
      if (this.isMultiAccount == true || this.paIneligibleMultiaccount == true) {
        params = {
          accountNumber: this.multiAccountNumber ? this.multiAccountNumber : null,
          emailAddress: this.emailAddressOfRecord,
          paymentConfirmationNumber: this.confirmationNumber,        
        };
      } else {
        params = {
          accountNumber: this.AcodAccountNumber,
          emailAddress: this.emailAddressOfRecord,
          paymentConfirmationNumber: this.confirmationNumber,        
        }
      }
      try {
        // success call api
        const res = await axios.post(constants.PaymentSuccessURL, params);
        // to show acod register button
        if(res.data.acodSuccess == true) {
          this.acodSuccess = true;
          window.dataLayer = window.dataLayer || [];
          // gtm for acod eligible
          window.dataLayer.push({
            'page':{
                'pageType': 'qbp',
                'pageName': 'qbp success'
            },
            'event': 'qbp_acod_eligible',
            'flow':{
                'name': 'qbp',
                'step': 'success screen',
            },
            'accountNumber': pii(this.accountNumber),
          });
        }
        if(res.data.acodSuccess == false) {
          // gtm for acod ineligible
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'page':{
              'pageType': 'qbp',
              'pageName': 'qbp success'
            },
            'event':'ACOD QBP ineligible',
            'flow':{
              'name': 'qbp',
              'step': 'success screen'
            }
          });
        }
        if (
          res.data.paymentConfirmationNumber !== null &&
          res.data.paymentConfirmationNumber !== ""
        ) {
          this.isWaiting = false;
          this.isDataAvailable = true;
          if (
            res.data.paymentAmount !== null &&
            res.data.convenienceFee !== null &&
            res.data.totalPayment !== null &&
            res.data.paymentDate !== null &&
            res.data.paymentMethod !== null &&
            res.data.paymentAmount !== "" &&
            res.data.convenienceFee !== "" &&
            res.data.totalPayment !== "" &&
            res.data.paymentDate !== "" &&
            res.data.paymentMethod !== "" &&
            res.data.paymentAcctLast4
          ) {
            // this fields to set from response to display page
            this.showPaymentBlock = false;
            this.paymentAmount =
              res.data.paymentAmount != null
                ? "$ " + Number(res.data.paymentAmount).toFixed(2)
                : "NA";
            this.payedAmount = res.data.paymentAmount != null
                ? Number(res.data.paymentAmount).toFixed(2)
                : "NA";
            this.convenienceFee =
              res.data.convenienceFee != null
                ? "$ " + Number(res.data.convenienceFee).toFixed(2)
                : "NA";
            this.totalPayment =
              res.data.totalPayment != null
                ? "$ " + Number(res.data.totalPayment).toFixed(2)
                : "NA";
            this.paymentAcctLast4 =
              res.data.paymentAcctLast4 != null
                ? res.data.paymentAcctLast4
                : "NA";
            this.paymentDate =
              res.data.paymentDate != null ? res.data.paymentDate : "NA";
            this.paymentDate = this.getHumanReadbledt( this.paymentDate)
            this.paymentMethod = 
              res.data.paymentMethod;
            this.paymentDetail = this.paymentVal;
            this.qbpPaymentAmount = res.data.paymentAmount != null ? res.data.paymentAmount : "";
            if(this.qbpPaymentAmount && this.elgibleAmtPa) {
              if(parseFloat(this.qbpPaymentAmount) >= parseFloat(this.elgibleAmtPa)) {
                this.eligibleForPa = true;
              } else {
                this.eligibleForPa = false;
              }
            }
            attributesData.set('showLoading', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData}); 
            this.balance = this.balance == 'NA' ? this.balance : JSON.parse(this.balance);   
            if((this.balance != null && this.payedAmount == this.balance ) || (this.totalBalanceOwed != null && this.payedAmount == this.totalBalanceOwed)){
              this.viewPaymentDetailBlockBasedOnAccount = true
            } else {
              this.viewPaymentDetailBlockBasedOnAccount = false;
            }
            
          } else {
            this.showPaymentBlock = true;
            attributesData.set('showLoading', false);
                this.$store.dispatch(SET_DATA, {attributes:attributesData});
          }
        } else {
          this.isWaiting = false;
          this.isDataAvailable = false;
          attributesData.set('showLoading', false);
                this.$store.dispatch(SET_DATA, {attributes:attributesData});
        }
      } catch (error) {
        console.log(error);
        this.isWaiting = false;
        this.isDataAvailable = false;
        attributesData.set('showLoading', false);
                this.$store.dispatch(SET_DATA, {attributes:attributesData});
      }
    },
    // print page
    printPage() {
      var restorePage = document.body.innerHtml;
      var printContent = document.getElementById('qbp-intro-wrapper').innerHtml;
      document.body.innerHtml = printContent;
      window.print();
      document.body.innerHtml = restorePage;
    },
    startOverAgain () {
      if((window.location.href.indexOf('qbp') !== -1 )){
        if(window.location.href.indexOf('qbp/multiAccount') !== -1) {
          this.$router.push("/qbp/multiAccount");
        }else {
          this.$router.push("/qbp/identify");
          this.$store.dispatch(RESET);
          history.pushState(null, null, location.href);
        }
      }
    },
    
    async getAcodRegisterationDetail() {
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      // gtm for acod register button click
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'page':{
          'pageType': 'qbp',
          'pageName': 'qbp success'
        },
        'event':'qbp_acod_register_click',
        'flow':{
          'name': 'qbp',
          'step': 'success screen'
        },
        'buttonName': 'Register'
      });
      this.isWaiting = true;
      this.firstName = this.$store.getters[GET_DATA]('firstName') ? JSON.parse(this.$store.getters[GET_DATA]('firstName')) : "NA";
      this.lastName = this.$store.getters[GET_DATA]('lastName') ? JSON.parse(this.$store.getters[GET_DATA]('lastName')) : "NA";
      let params = {};
      params = {
        accountNumber: this.AcodAccountNumber,
        emailAddress: this.emailAddressOfRecord,
        firstName: this.firstName,
        lastName: this.lastName 
      };
      try{
          // acod profile creation call with params
          const acodResult = await axios.post(constants.ACODRegisterationURL, params);
          if(acodResult.data.statusInfo.message == "4000 Login already exists in system" 
            || acodResult.data.statusInfo.message == "800 This account is already registered to a user"){
            this.acodSuccessModal = false;
            this.acodFailModal = false;
            this.loginExist = true;
            this.acodSuccess = false;
            this.openModalNow();
            this.isWaiting = false;
            attributesData.set('showLoading', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
          } else if (acodResult.data.acodprofileCreated == true) {
            this.showEmailAddress = acodResult.data.emailAddress;
            this.acodSuccessModal = true;
            this.acodFailModal = false;
            this.loginExist = false;
            this.acodSuccess = false;
            this.openModalNow();
            this.isWaiting = false;
            attributesData.set('showLoading', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'page':{
                  'pageType': 'qbp',
                  'pageName': 'acod success'
              },
              'event': 'qbp_acod_success',
              'flow':{
                  'name': 'qbp',
                  'step': 'success screen'
              },
              'accountNumber': pii(this.accountNumber)
            });
          } else if (acodResult.data.acodprofileCreated == false) {
            this.acodFailModal = true;
            this.acodSuccessModal = false;
            this.loginExist = false;
            this.acodSuccess = true;
            this.openModalNow();
            this.isWaiting = false;
            attributesData.set('showLoading', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
          } else {
            this.acodFailModal = true;
            this.acodSuccessModal = false;
            this.loginExist = false;
            this.acodSuccess = true;
            this.openModalNow();
            this.isWaiting = false;
            attributesData.set('showLoading', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'page':{
                  'pageType': 'qbp',
                  'pageName': 'acod error'
              },
              'event': 'qbp_acod_error',
              'flow':{
                  'name': 'qbp',
                  'step': 'success screen'
              },
              'accountNumber': pii(this.accountNumber)
            });
          }
      } catch(error) {
          console.log(error);
          this.acodFailModal = true;
          this.acodSuccessModal = false;
          this.loginExist = false;
          this.acodSuccess = true;
          this.openModalNow();
          this.isWaiting = false;
          attributesData.set('showLoading', false);
          this.$store.dispatch(SET_DATA, {attributes:attributesData});
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'page':{
                'pageType': 'qbp',
                'pageName': 'acod error'
            },
            'event': 'qbp_acod_error',
            'flow':{
                'name': 'qbp',
                'step': 'success screen'
            },
            'accountNumber': pii(this.accountNumber)
          });
      }
    },
    // open the popup
    openModalNow() {
      const modal = document.getElementById("myModal");
      document.body.appendChild(modal);
      var myModal = new Modal(modal, {
          keyboard: false,
      });
      myModal.show();
    },
    // close modal
    modalClose() {
      var modalElement = document.getElementById('myModal');
      var modal = Modal.getInstance(modalElement);
      modal.hide();
    },
  },
};
</script>

<style>
#acodContent {
  background-color: aliceblue;
}

.row-acod {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.marg-top-bottom {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.icon-style-bill {
  width: 60px;
  height: 60px;
  margin: 1rem 0 1rem 0;
}
.icon-style-dollar {
  width: 61px;
  height: 61px;
  margin: 1rem 0 1rem 0;
  filter: invert(14%) sepia(76%) saturate(3589%) hue-rotate(213deg) brightness(109%) contrast(101%);
}
.icon-style-ts {
  width: 50px;
  height: 50px;
  margin: 1rem 0 1rem 0;
}

.img-people {
  width: 293px;
  height: 224px;
}
@media (max-width: 580px) {
  .img-people {
    width: 293px;
    height: 224px;
  }
}
@media (min-width:581px ) {
  .img-people {
    float: left;
  }
}
.acod-content {
  width: 100%;
  position: relative;
  margin: auto;
}
.norton-logo-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 768px) and (min-width: 240px) {
  .left-grid {
    width: 100%;
    font-family: MaisonNeue-Light,sans-serif;
    color: #000000;
  }
  
}
@media (min-width: 770px) {
  .left-grid {
    width: 100%;
    font-family: MaisonNeue-Light,sans-serif;
    color: #000000;
  }
}
 #norton-logo {
margin: 20px 0 20px 0;
text-align: left;
}
.data-placement {
  margin-left: 0.8rem;
}
.get-top-margin {
  margin-top: 1rem;
}
.div-placement {
  line-height: 2em;
  font-size: medium;
  
}
#qbp-left-column-content {
  position: relative;
}
@media (min-width: 768px) {
  #qbp-left-column-content {
   width: 65%;
   margin: 0 auto;
  }
  #qbp-left-column {
    min-height: 285px;
  }
  .acod-content {
    width: 65%;
  }
  .norton-logo-container {
    width: 65%;
    padding: 0;
  }
}
@media (min-width: 992px) {
  #qbp-left-column {
    min-height: 280px;
  }
}
.checkmark {
  height: 32px;
  width: 32px;
  color: #077C49;
}
.text-position {
  text-align: left;
  padding-left: 3.5rem;
}
.text-color {
  color: #0047bb;
  font-weight: bold;
  text-decoration: none !important;
}
.button-color {
  background-color: #48d597;
  width: 10rem;
  font-size: 18px;
  border-radius: 0;
  box-sizing: border-box;
  color: #000;
  display: inline;
  font-family: MaisonNeue-Medium, serif;
  font-weight: bold;
  height: 50px;
  line-height: 30px;
  margin: 1.5rem auto 1.5rem;
  padding: 6px 15px;
  position: relative;
  text-align: center;
  text-decoration: none;
  z-index: 1;
  zoom: 1;
}
.print-receipt {
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 16px;
}
.home-page {
  color: #0d6efd;
  cursor: pointer;
  text-decoration: none;
}
.align-div-header {
    position: relative;
}

.align-div-header img {
  position: absolute;
  top: 0px;
  left: 0px;
}

.home-page:hover {
  color: #0a58ca;
}
#qbp-intro-wrapper h1 {
  padding-left: 45px;
  color: #14161A;
  letter-spacing: 0px;
  margin-bottom: 16px;
  opacity: 1;
  font-size: 36px;
  font-weight: 400;
  font-family: MaisonNeue-Light, serif;
}
#qbp-intro-wrapper h2 {
  color: #14161A;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.24px;
  margin-bottom: 0;
}
.acct-num {
  font-family: MaisonNeue-Medium, sans-serif;
  color: #000000;
  margin: 0;
  text-align: left;
  font-size: 16px;
  width: 772px;
  height: 19px;
}
.acod-reg {
  text-align: left;
  padding-left: 3.5rem;
  margin-top: 20px;
  margin-bottom: 20px;
}
.success-heading img{
  position: absolute;
}
.success-heading h3 {
  padding-left: 50px;
  font-weight: bold;
}

@media print {
    #acodContent {
        display: none;
    }
    #requestContent {
        display: none;
    }
}

.text-email{
font-family: Maison Neue, sans-serif;
font-size: 16px;
color: #000000;
text-align: left;
margin-top: 15px;
}

.text-conf-num{
font-family: MaisonNeue-Medium, sans-serif;
font-size: 14px;
color: #14161ABF;
text-align: left;
margin-top: 8px;
}

.text-num{
font-family: Maison Neue, sans-serif;
font-size: 36px;
color: #14161A;
text-align: left;
}

.text-summary{
font-family: MaisonNeue-Medium, sans-serif;
font-size: 24px;
color: #14161A;
text-align: left;
margin: 15px 15px 20px 30px;
}

.text-pmnt{
font-family: Maison Neue, sans-serif;
font-size: 16px;
color: #000000;
text-align: left;
margin-top: 0;
/* margin-left: 100px; */
display: inline-block;
vertical-align: middle;
margin-left: 15px;
}

.text-pmnt-amt{
font-family: Maison Neue, sans-serif;
font-size: 16px;
color: #000000;
text-align: right;
margin-top: 15px;
margin-left: 15px;
margin-right: 25px;
opacity: 0.6;
}

.text-conv{
font-family: Maison Neue, sans-serif;
font-size: 16px;
color: #000000;
text-align: left;
margin-top: 10px;
margin-left: 93px;
opacity: 0.6;
}

.text-conv-fee{
font-family: Maison Neue, sans-serif;
font-size: 16px;
color: #000000;
text-align: right;
padding-right: 25px;
margin-top: 10px;
opacity: 0.6;
}

.text-pay{
font-family: Maison Neue, sans-serif;
font-size: 16px;
color: #000000;
text-align: left;
margin-top: 5px;
margin-left: 93px;
margin-bottom: 15px;
opacity: 0.6;
}

.text-pay-amt{
font-family: Maison Neue, sans-serif;
font-size: 16px;
color: #000000;
text-align: left;
margin-top: 5px;
opacity: 0.6;
}

#qbp-left-column-conten{
background-color: #FAFAFA;
}

.grid{
position: relative;
margin-top: 15px;
}

.grid img {
position: absolute;
top:12px;
left: 0;
}

.payment{
font-family: Maison Neue, sans-serif;
font-size: 16px;
color: #000000;
text-align: left;
margin-top: 20px;
margin-left: 50px;
}

.payment-method{
font-family: Maison Neue, sans-serif;
font-size: 16px;
color: #000000;
text-align: left;
margin-top: -45px;
padding-left: 94px;
}

.qbp-calendar-circle{
position: relative;
width: 48px;
height: 48px;
border-radius: 50%;
background: #E0E8F7 0% 0% no-repeat padding-box;
opacity: 1;
display: inline-block;
vertical-align: middle;
margin-left: 30px;
}

.qbp-calendar-circle img {
position: absolute;
top: 11px;
left: 11px;
}

.payment-method-icon{
position: relative;
width: 48px;
height: 48px;
border-radius: 50%;
background: #E0E8F7 0% 0% no-repeat padding-box;
opacity: 1;
display: inline-block;
vertical-align: middle;
margin-left: 30px;
}

.payment-method-icon img{
position: absolute;
top: 20px;
left: 20px;
}

.details{
position: relative;
border-radius: 8px;
border: 1px solid #EEEEEE33;
box-shadow: 0px 1px 4px #00000029;
background: #FFFFFF 0% 0% no-repeat padding-box;
margin-top: 20px;
}

.qbp-success-circle{
position: relative;
width: 48px;
height: 48px;
border-radius: 50%;
background: #E0E8F7 0% 0% no-repeat padding-box;
opacity: 1;
display: inline-block;
vertical-align: middle;
margin-left: 30px;
}

.qbp-success-circle img{
position: absolute;
top: 11px;
left: 11px;
}

.QBP-pmnt-icon-circle{
position: relative;
width: 48px;
height: 48px;
border-radius: 50%;
background: #E0E8F7 0% 0% no-repeat padding-box;
opacity: 1;
display: inline-block;
vertical-align: middle;
margin-left: 30px;
}

.QBP-pmnt-icon-circle img{
position: absolute;
top: 11px;
left: 11px;
}

.horizontal-row {
position: relative;
}

.horizontal-border {
width: 66%;
position: absolute;
top: 0;
right: 25px;
border: 1px solid #00000029;
}

@media (min-width: 420px) {
.horizontal-border {
width: 71%;
}
}

@media (min-width: 500px) {
.horizontal-border {
width: 75%;
}
}

@media (min-width: 992px) {
.horizontal-border {
width: 81%;
}
}

@media (min-width: 1200px) {
.horizontal-border {
width: 85%;
}
}

@media (min-width: 1400px) {
.horizontal-border {
width: 88%;
}
}

@media (min-width: 1900px) {
.horizontal-border {
width: 91%;
}
}

.text-pay-pmt{
text-align: right;
padding-right: 25px;
margin-top: 5px;
}

#printButton{
position: relative;
padding-left: 30px;
color: #0047BB;
text-decoration: underline;
cursor: pointer;
}

#printButton::before {
content: "";
position: absolute;
background: url(/img/print_black.d0767416.svg) no-repeat;
width: 20px;
height: 18px;
background-size: 20px 18px;
left: 0px;
top: 0px;
}

.print-icon{
margin-top: 20px;
text-align: right;
}

#print-icon a{
position: absolute;

}

.text_print-icon{
margin-top: 10px;
text-align: right;
margin-right: 90px;
}

.textprint img{
position: absolute;
}
a.signin-cta {
    text-decoration: none;
    border: 1px solid #0047BB;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    width: 210px;
    margin: 0 auto;
}
.modal-footer {
  justify-content: center;
}
.qbp-acod-container {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .line {
      border-right: 1px solid #cfc7c0;
  }
  .qbp-acod-container {
    width: 65%;
  }
}

</style>