<template>
    <div class="container">
        <div class="row">
            <div v-if="(HCDE110)">
                <div class="align-failure-message text-start">
                    <div data-galink-type="chat">
                        <h2><img class="error-img-size" src="../../images/epwfBlueInfoCirc.png" alt="errorImg" />Your session has timed out.</h2>
                        <p class="text-placement left-margin">For the security of your account, your session was ended due to inactivity.</p>
                        <p class="text-placement left-margin">Would you like to <a class="no-text-decor" :href="`/postbill/payment/view?token=${token}`" id="startoverandtryagain">start over and try again</a>? </p>
                    </div>
                </div>
            </div>
            <div v-if="(HCDE011)"> 
                <div class="align-failure-message text-start" data-galink-type="chat">
                    <h2><img class="width-img" src="../../images/epwfRedExclamation.png" alt="errorImg" /> Sorry - you've reached the maximum allowable attempts.</h2>
                    <p class="text-placement left-margin">We do this for security purposes.</p>
                </div>
            </div>
            <div v-if="HCDE10017">
                <div class="align-failure-message text-start">
                    <h2><img src="../../images/icon-important-orange.svg" alt="orangeImportantIcon" />There's a problem with your account information.</h2>
                    <p class="text-placement">The email address for your account appears to be invalid. Before you can make a payment online, please 
                    <a :href="EmailPreferencesURL" target="_blank" id="update_the_email_address">update the email address</a> 
                    you have on record with us.</p>
                    <p class="text-placement">If you believe your email address is valid and you continue seeing this error message, please 
                    <a href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" id="epwf_10017_chatwithus" data-galink-type="chat">chat with us.</a>
                    so we can help.</p>
                </div>
            </div>
            <div  v-if="HCDE128">
                <div class="align-failure-message text-start">
                    <h2 class="hcde128-err-msg"><img class="error-img-size" src="../../images/epwfRedExclamation.png" alt="errorImg" />Something didn't go quite as expected.</h2>				
                    <p class="hcde128-err-text text-placement left-margin">No payment was submitted. Would you like to 
                        <a class="no-text-decor" :href="`/postbill/payment/view?token=${token}`" id="paybillduplicatesession">start over and try again</a>?</p>
                </div>
            </div>
            <div  v-if="HCDE400">
                <div class="align-failure-message text-start">
                    <h2><img class="error-img-size" src="../../images/epwfBlueInfoCirc.png" alt="errorImg" />Sorry. Your payment was not completed.</h2>
                    <p class="text-placement left-margin">It looks like maybe you've already made an identical payment today.</p>
                    <p class="text-placement para-line-align left-margin">If you're intentionally trying to make an extra payment on your CenturyLink account, you'll have to 
                        <a class="no-text-decor" :href="`/postbill/payment/view?token=${token}`" id="tryagain"><strong>try again</strong></a>
                        using a different payment method or for a different dollar amount.</p>					
                </div>
            </div>
            <div  v-if="HCDE403 || (brimOn && brimErrorCode == '3803')">
                <div class="align-failure-message text-start">
                    <h2><img class="error-img-size" src="../../images/epwfBlueInfoCirc.png" alt="errorImg" />Sorry. Your payment cannot be completed.</h2>
                    <p class="text-placement left-margin">It looks like you've made several payments lately.</p>
                    <p class="text-placement para-line-align left-margin">We only allow up to 5 payments every 30 days per CenturyLink account. Please 
                        <a class="no-text-decor" :href="PaymentHistoryMya" target="_blank" id="paymenthistory"><strong>review your payment history</strong></a> 
                        and try again at a later date.</p>					
                </div>
            </div>
            <div v-if="HCDE111 || (brimOn && brimErrorCode != '3803')">
                <div class="align-failure-message text-start">
                    <h2 class="text-start text-placement"><strong>Pay Bill</strong></h2>
                    <p class="text-start text-placement">Current Amount Due: <strong>${{currentAmountDue}}</strong> Payment due by: {{paymentDate}}</p>
                    <h2 class="text-color-black"><img class="error-img-size" src="../../images/epwfRedExclamation.png" alt="errorImg" />We're very sorry.</h2>
                    <p class="text-start text-placement left-margin">We're experiencing technical difficulties with our payment system.</p>
                    <p class="text-start text-placement left-margin">Please <a :href="`/postbill/payment/view?token=${token}`">try again</a> or <a id="chatWithUs-11002" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" data-galink-type="chat">chat with us</a>.</p>
                    <p class="text-start text-placement left-margin">We sincerely apologize for the inconvenience.</p>
                </div>
            </div>
            <div class="align-failure-message text-placement" v-if="!HCDE400 && !HCDE403 && !HCDE128 && !HCDE110 && !HCDE10017 && !HCDE011 && !AP003 && !HCDE111 && !brimOn">
                <h2>Our apologies, this page is experiencing technical issues.</h2>
            </div>
        </div>
    </div>
</template>
<script>
import links from '../../js/links'
import constants from "../../js/constants"
import { GET_DATA, SET_DATA } from "../../store/sessioncontants"
import { SET_DATA as local_setdata} from "../../store/storecontants";
import axios from "axios"
export default {
    name: 'postBillFailure',
    data() {
        return {
            HCDE400: null,
            HCDE403: null,
            HCDE128: null,
            HCDE110: null,
            HCDE011: null,
            HCDE10017: null,
            AP003: null,
            HCDE111: null,
            errorCode: "",
            accountNumber: "",
            isBillerEnsemble: false,
            PaymentHistoryMya: links.PaymentHistoryMya,
            EmailPreferencesURL: links.EmailPreferencesURL,
            currentAmountDue: '',
            paymentDate: '',
            token: null,
            brimOn: false,
            brimErrorCode: null
        }
    },
    mounted() {
        this.brimOn = process.env.VUE_APP_BRIM;
        if(this.brimOn == 'true') this.brimErrorCode = this.$store.getters[GET_DATA]("errorCd");
        let localData = new Map();
        localData.set('showLoading', true);
        this.$store.dispatch(local_setdata, {attributes:localData});
        let result = this.$route.query.ErrorCode ? this.$route.query.ErrorCode: ''
        this.errorCode = result.includes("-") ? result.replace(/-/g, "") : result
        this.accountNumber = this.$store.getters[GET_DATA]("accountNumber")
        this.currentAmountDue = this.$store.getters[GET_DATA]('currentAmountDue')
        this.paymentDate = this.$store.getters[GET_DATA]('paymentDate')
        this.token = this.$store.getters[GET_DATA]('token')
        switch(this.errorCode) {
            case 'HCDE111':
            this.errorMessage = ', account is UNENROLL RESTRICTED';
            break;
            case 'AP003':
            this.errorMessage = ', account is SUSPENDED';
            break;
            case 'HCDE110':
            this.errorMessage = ', account is SESSION TIMED OUT';
            break;
            case 'HCDE011':
            this.errorMessage = ', account is MAX RETRY LIMIT ERROR';
            break;
        }
        if (this.accountNumber) {
            this.setLogger();
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        } else {
            localData.set('showLoading', false);
            this.$store.dispatch(local_setdata, {attributes:localData});
        }
        this.getPostBillFailureDetails();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'lbp_error',
            errorName: this.errorCode, 
            flow: {
                name: 'logged in bill pay',
                step: 'error'
            },
        });
    },
    methods: {
        getPostBillFailureDetails() {
            if (this.errorCode != null || this.errorCode != "") {
                if (this.errorCode === "HCDE400") {
                    this.HCDE400 = true;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE403") {
                    this.HCDE400 = false;
                    this.HCDE403 = true;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE128") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = true;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE110") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = true;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;

                    window.dataLayer?.push({
                      event: 'bp_error',
                      errorName: 'HCDE110 HCDE timeout',
                      flow:{
                        name: 'billpay',
                        step: 'error'
                      },
                    });
                } else if (this.errorCode === "HCDE10017") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = true;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE011") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = true;
                    this.AP003 = false;
                    this.HCDE111 = false;
                } else if (this.errorCode === "AP003") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = true;
                    this.HCDE111 = false;
                } else if (this.errorCode === "HCDE111") {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = true;
                } else {
                    this.HCDE400 = false;
                    this.HCDE403 = false;
                    this.HCDE128 = false;
                    this.HCDE110 = false;
                    this.HCDE10017 = false;
                    this.HCDE011 = false;
                    this.AP003 = false;
                    this.HCDE111 = false;
                }
            }
        },
        async setLogger() {
            let params = {}
            if (this.errorCode) {
                params = {
                    logString: "Postbill payment failure for accountNumber=" + this.accountNumber + ", HCDE ErrorCode=" + this.errorCode + this.errorMessage
                };
            } else {
                params = {
                    logString: "Postbill payment failure for accountNumber=" + this.accountNumber
                };
            }
            try {
                await axios.get(constants.PaymentFailureCallForOrchestrator, { params });
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>
<style>
.align-failure-message {
    margin-top: 2rem;
}
.text-placement {
    margin: 0.5rem 0 1rem 0;
}
.icon-error-outline {
    width: 25px;
    height: 25px;
    margin-right: 0.5rem;
}
.text-color-black {
    color: #000000;
}
.error-img-size {
    width: 25px;
    margin-right: 0.5rem;
}
.width-img {
    width: 25px;
}
.left-margin {
    margin-left: 2rem;
}
.no-text-decor {
    color: #0047bb;
    text-decoration: none !important;
    cursor: pointer;
}
.para-line-align {
    line-height: 2rem;
}
</style>
