<template>
  <div id="paymentError">
    <h2 class="iframe-error-margin text-start"><img src="../../images/error_icon.png" alt="error-icon"/>We're Sorry.</h2>
    <p class="text-start content-margin">We're experiencing technical difficulties retrieving your AutoPay information
      from the billing and payment
      system.<br />
      We do all we can to make paying your bill through My CenturyLink perform perfectly all the time, but the
      truth is...sometimes it just doesn't.</p>
    <p class="text-start content-margin">If this is the first time you're seeing this message <a class="text-color" :href="`/autopay/manage?token=${token}`"
        id="tryagainepwferror">try
        again</a> in 15 minutes or so. Or,
      If you need help with your current AutoPay settings, please
      <span v-if="isBillerEnsemble">
        <a class="text-color" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})"
          id="unenrollment_restricted_chatwithus" data-galink-type="chat">chat with us.</a>
      </span>
      <span v-if="!isBillerEnsemble">
        <a class="text-color" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})"
          id="unenrollment_restricted_chatwithus" data-galink-type="chat">chat with us.</a>
      </span>
    </p>
    <p class="text-start content-margin">And again, we sincerely apologize for the inconvenience. Your patience is
      appreciated.</p>
  </div>
</template>
<script>
import links from "../../js/links";
import { pii } from "../../js/pii";
import { GET_DATA, SET_DATA } from "../../store/sessioncontants";
import { SET_DATA as local_setdata} from "../../store/storecontants";

export default {
  name: "autoPay",
  data() {
    return {
      MyaEntryPointURL: links.MyaEntryPointURL,
      token: null
    };
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "ap_epwf_error",
      accountNumber: pii(this.accountNumber),
      errorName: "autopay enabled",
      flow: {
        name: "autopay",
        step: "payment flow",
        event: "autopay enabled",
      },
    });
    this.token = this.$store.getters[GET_DATA]("token");
  },
};
</script>
<style scoped>
.text-color {
  color: #0176d3;
  text-decoration: none !important;
}
.content-margin {
  margin: 2rem 0 2rem 0;
}

.iframe-error-margin {
  margin: 1rem 0 1rem 0;
  display: flex;
}

#qbp-exception-message h3 {
  font-weight: bold;
}

#qbp-exception-message p {
  margin-bottom: 5px;
}

.qbpTextBox {
  position: relative;
}

.qbpTextBox:before {
  content: "";
  position: absolute;
  background: url(../../images/icon-important-orange.svg) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
}

@media (max-width: 767px) {
  #qbp-exception-message h3 {
    min-height: 30px;
  }
}
</style>