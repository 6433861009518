<template>
  <div class="pleaseEnterEmailMessage">
    <div class="qbpTextBox">
      <p>
        We're sorry. We cannot complete your payment request at this time.
        Please try again later.
      </p>
    </div>
  </div>
</template>
<script>
import { pii } from "../../js/pii";

export default {
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "qbp_error",
      accountNumber: pii(this.accountNumber),
      errorName: "undefined error",
      flow: {
        name: "qbp",
        step: "payment flow",
      },
    });
  },
};
</script>

<style scoped>
.pleaseEnterEmailMessage {
  mix-blend-mode: multiply;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.35;
  padding: 5px 2px 11px 45px;
  background: url(../../images/icon-important-orange.svg) no-repeat 1px 1px;
}
</style>