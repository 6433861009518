<template>
    <div class="justify-content-center">
        <div class="row">
            <div>
                <p>Please wait while we are fetching the data for you.</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { SET_DATA } from "../store/storecontants";

    export default {
        name: 'PASuccessIframe',
        mounted() {
            // get the payment confirmation ID from url
            if(this.$route.query.PaymentID != null){
                let attributesData = new Map();
                attributesData.set('showLoading', true);
                attributesData.set('paymentConfirmationNumber', this.$route.query.PaymentID ? this.$route.query.PaymentID: this.$route.params.PaymentID);
                this.$store.dispatch(SET_DATA, {attributes:attributesData});

                // navigate to success page
                window.parent.location = "/pa/payment/success#loaded";
            }
        }
    }
</script>
<style scoped>
</style>