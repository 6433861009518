<template>
    <div class="purl-schedule">
        <div class="row justify-content-center ">
            <div class="col-12 col-sm-6 col-md-7" id="left_content">
                <PurlPayments/>
            </div>
            <div class="col-12 col-sm-6 col-md-5 px-1" id="right_content">
                <PurlRightBar/>
            </div>
        </div>
    </div>
</template>

<script>
import PurlPayments from '../components/purl/PurlPayments.vue'
import PurlRightBar from '../components/purl/PurlRightBar.vue'
export default {
   name: 'ScheduleToPayPurl',
   components: {
       PurlPayments,
       PurlRightBar
   }
}
</script>
<style scoped>
.purl-schedule {
    max-width: 900px;
    font-family: Maison Neue,sans-serif;
    margin: 0 auto;
    padding: 0 20px;
}
#left_content{
    padding-right:20px;
}
#app .purl-schedule {
    text-align: left;
}
@media(max-width: 899px) {
    .purl-schedule {
        max-width: 700px;
    }
}
</style>