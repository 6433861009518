<template>
  <div class="justify-content-center">
    <WGLatePay/>
  </div>
</template>
<script>
  import WGLatePay from '../components/WalledGarden/WGLatePay.vue'
  export default {
    name: 'WalledGardenLP',
    components: { WGLatePay }
  };
</script>

<style>
</style>