<template>
    <div class="container col-xs-12 col-sm-12 col-md-12 no-pad">
        <div class="row mb-2 mt-5">
            <div class="col" id="dssp-wrapper">
                <h1 class="text-center" id="pa-header">
                    Payment Plan
                </h1>
            </div>
        </div>

        <div class="row mb-lg-3 mb-sm-2 justify-content-center">
            <div class="col-lg-9">
                <p class="text-start pa-description">
                    A payment plan is a plan that allows you to change the timing of your bill payments to CenturyLink for a short period of time. Please follow the steps below to select your payment options.
                </p>
            </div>
        </div>
        <!-- ##### BMD-17442 New PA design ##### -->
        <!-- ##### ACCOUNT INFORMATION TABLE ##### -->
        <div class="row mb-5 ms-0 me-0 justify-content-center d-none d-sm-flex d-lg-flex">
            <div class="amountDataDiv">
                <table class="table my-3" aria-describedby="acctinfo">
                    <tr>
                        <th v-if="this.paData.maskedAccount">{{ this.paData.maskedAccount }}</th>
                        <th v-else>{{ this.paData.accountEntered }}</th>
                        <th v-if="this.paData.pastDue">${{ this.paData.pastDueAmount }}</th>
                        <th v-else>$0.00</th>
                        <th v-if="this.paData.pastDue">${{ this.paData.adjustedAmount }}</th>
                        <th v-else>${{ this.paData.totalAmtDue }}</th>
                    </tr>
                    <tr>
                        <td>Account number</td>
                        <td>Past due</td>
                        <td>Total amount due</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 mt-4 justify-content-center" v-if="this.validateData(this.isMultiAccount)">
                <a id="changeAcctBtn" @click="changeAcct()" v-on:keyup.enter="changeAcct()" tabindex="0">Change Account</a>
            </div>
        </div>

        <!-- ##### ACCOUNT INFORMATION TABLE MOBILE ##### -->
        <div class="row mb-5 ms-0 me-0 justify-content-center d-lg-none d-sm-none d-flex">
            <div class="amountDataDivMobile m-2 col-sm-6">
                <table class="table" aria-describedby="acctinfo">
                    <tr>
                        <th v-if="this.paData.maskedAccount">{{ this.paData.maskedAccount }}</th>
                        <th v-else>{{ this.paData.accountEntered }}</th>
                    </tr>
                    <tr>
                        <td>Account number</td>
                    </tr>
                </table>
            </div>
            <div class="amountDataDivMobile m-2 col-lg-1">
                <table class="table" aria-describedby="acctinfo">
                    <tr>
                        <th v-if="this.paData.pastDue">${{ this.paData.pastDueAmount }}</th>
                        <th v-else>$0</th>
                    </tr>
                    <tr>
                        <td>Past due</td>
                    </tr>
                </table>
            </div>
            <div class="amountDataDivMobile m-2">
                <table class="table" aria-describedby="acctinfo">
                    <tr>
                        <th v-if="this.paData.pastDue">${{ this.paData.adjustedAmount }}</th>
                        <th v-else>${{ this.paData.totalAmtDue }}</th>
                    </tr>
                    <tr>
                        <td>Total amount due</td>
                    </tr>
                </table>
            </div>
            <div class="col-12 mt-4 justify-content-center" v-if="this.validateData(this.isMultiAccount)">
                <a id="changeAcctBtn" @click="changeAcct()" v-on:keyup.enter="changeAcct()" tabindex="0">Change Account</a>
            </div>
        </div>

        <div class="row mb-3 justify-content-center">
            <div class="col-12 col-lg-9 col-md-12">
                <!-- ##### OPTIONS STEPS ##### -->
                <div class="accordion accordion-flush" id="accordionOptionsPanels">
              
                    <!-- ##### STEP 1 ##### -->
                    <div class="text-start">
                        <img class="circleNumber" src="../../images/svg/1-circle.svg" alt="1-circle-svg" />
                        <span class="steps">SELECT PAYMENT PLAN</span>
                    </div>
    
                    <div v-if="this.brim" class="mb-3 my-2 ms-0 me-0 justify-content-center">
                        <div class="container paymentOptionDiv">
                            <p class="text-start mx-1 mt-3">
                                <span class="fullAmountTitle">Full Amount: </span>
                            </p>

                            <!-- ##### PAY FULL AMOUNT ##### -->
                            <div v-if="this.brimOptions.totalDue" class="row mx-1 make1payDiv">
                                <div class="col-2 col-md-1 col-lg-1 p-3">
                                    <div class="pa-icon-circle">
                                        <img src="../../images/svg/Empty.svg" alt="calendar-empty-svg" class='pa-icons' />
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-8 col-10 p-3 px-4 px-sm-0 px-md-4 px-lg-4 px-xl-3 px-xxl-2 text-start">
                                    <span>
                                        <span class="pa-description">Pay full amount</span>
                                        <br>
                                        <span class="optionDesc">This option will allow you to pay the full owed balance.</span>
                                    </span>
                                </div>
                                <div class="col-lg-2 col-md-3 col-10 offset-2 offset-md-0 offset-sm-2 offset-lg-0 p-lg-4 p-md-4 pt-2 pb-3 px-4 px-sm-0 text-left">
                                    <button 
                                        id="selectButton1"
                                        type="submit"
                                        class="pa-button"
                                        role="button"
                                        @click="toggleSelectButton('selectButton1');
                                        this.paData.pastDue ? selectPaymentSchedule('FullAmountToday') : selectPaymentSchedule('Make1Pay');
                                        gtmLogging(this.paData.pastDue ? 'FullAmountToday' : 'Make1Pay');" 
                                        v-on:keyup.enter="toggleSelectButton('selectButton1');
                                        this.paData.pastDue ? selectPaymentSchedule('FullAmountToday') : selectPaymentSchedule('Make1Pay');
                                        gtmLogging(this.paData.pastDue ? 'FullAmountToday' : 'Make1Pay');" 
                                        tabindex="0"
                                    >Select</button>
                                </div>
                            </div>

                            <!-- ##### PAY PAST DUE ##### -->
                            <div v-if="this.brimOptions.pastDue" class="row mx-1 make1payDiv">
                                <div class="col-2 col-md-1 col-lg-1 p-3">
                                    <div class="pa-icon-circle">
                                        <img src="../../images/svg/Dollar.svg" alt="currency-dolar-svg" class='pa-icons' />
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-8 col-10 p-3 px-4 px-sm-0 px-md-4 px-lg-4 px-xl-3 px-xxl-2 text-start">
                                    <span>
                                        <span class="pa-description">Pay only past due amount</span>
                                        <br>
                                        <span class="optionDesc">This option will allow you to pay the past due balance on your account.</span>
                                    </span>
                                </div>
                                <div class="col-lg-2 col-md-3 col-10 offset-2 offset-md-0 offset-sm-2 offset-lg-0 p-lg-4 p-md-4 pt-2 pb-3 px-4 px-sm-0 text-left">
                                    <button
                                        id="selectButton2" 
                                        type="submit" 
                                        class="pa-button"
                                        role="button"
                                        @click="toggleSelectButton('selectButton2');
                                        selectPaymentSchedule('PayPastDue');
                                        gtmLogging('PayPastDue');" 
                                        v-on:keyup.enter="toggleSelectButton('selectButton2');
                                        selectPaymentSchedule('PayPastDue');
                                        gtmLogging('PayPastDue');" 
                                        tabindex="0"
                                    >Select</button>
                                </div>
                            </div>

                            <!-- ##### MAKE 2 PAYMENTS ##### -->
                            <div v-if="this.brimOptions.installments" class="row mx-1 make1payDiv">
                                <div class="col-2 col-md-1 col-lg-1 p-3">
                                    <div class="pa-icon-circle">
                                        <img src="../../images/svg/Empty.svg" alt="calendar-empty-svg" class='pa-icons' />
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-8 col-10 p-3 px-4 px-sm-0 px-md-4 px-lg-4 px-xl-3 px-xxl-2 text-start">
                                    <span>
                                        <span class="pa-description">Make 2 monthly payments</span>
                                        <br>
                                        <span class="optionDesc">This option will split your full amount owed across two monthly payments.</span>
                                    </span>
                                </div>
                                <div class="col-lg-2 col-md-3 col-10 offset-2 offset-md-0 offset-sm-2 offset-lg-0 p-lg-4 p-md-4 pt-2 pb-3 px-4 px-sm-0 text-left">
                                    <button
                                        id="selectButton3" 
                                        type="submit" 
                                        class="pa-button"
                                        role="button"
                                        @click="toggleSelectButton('selectButton3');
                                        selectPaymentSchedule('Make2Pay');
                                        gtmLogging('Make2Pay');" 
                                        v-on:keyup.enter="toggleSelectButton('selectButton3');
                                        selectPaymentSchedule('Make2Pay');
                                        gtmLogging('Make2Pay');" 
                                        tabindex="0"
                                    >Select</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="mb-3 my-2 ms-0 me-0 justify-content-center">
                        <div class="container paymentOptionDiv">
                            <p class="text-start mx-1 mt-3">
                                <span class="fullAmountTitle">Full Amount: </span>
                            </p>

                            <!-- ##### PAY FULL AMOUNT ##### -->
                            <div class="row mx-1 make1payDiv">
                                <div class="col-2 col-md-1 col-lg-1 p-3">
                                    <div class="pa-icon-circle">
                                        <img src="../../images/svg/Empty.svg" alt="calendar-empty-svg" class='pa-icons' />
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-8 col-10 p-3 px-4 px-sm-0 px-md-4 px-lg-4 px-xl-3 px-xxl-2 text-start">
                                    <span>
                                        <span class="pa-description">Pay full amount</span>
                                        <br>
                                        <span class="optionDesc">This option will allow you to pay the full owed balance.</span>
                                    </span>
                                </div>
                                <div class="col-lg-2 col-md-3 col-10 offset-2 offset-md-0 offset-sm-2 offset-lg-0 p-lg-4 p-md-4 pt-2 pb-3 px-4 px-sm-0 text-left">
                                    <button 
                                        id="selectButton1"
                                        type="submit"
                                        class="pa-button"
                                        role="button"
                                        @click="toggleSelectButton('selectButton1');
                                        this.paData.pastDue ? selectPaymentSchedule('FullAmountToday') : selectPaymentSchedule('Make1Pay');
                                        gtmLogging(this.paData.pastDue ? 'FullAmountToday' : 'Make1Pay');" 
                                        v-on:keyup.enter="toggleSelectButton('selectButton1');
                                        this.paData.pastDue ? selectPaymentSchedule('FullAmountToday') : selectPaymentSchedule('Make1Pay');
                                        gtmLogging(this.paData.pastDue ? 'FullAmountToday' : 'Make1Pay');" 
                                        tabindex="0"
                                    >Select</button>
                                </div>
                            </div>

                            <!-- ##### PAY PAST DUE OR MAKE 2 PAYMENTS ##### -->
                            <div class="row mx-1 make1payDiv">
                                <div class="col-2 col-md-1 col-lg-1 p-3">
                                    <div class="pa-icon-circle">
                                        <img v-if="this.paData.pastDue" src="../../images/svg/Dollar.svg" alt="currency-dolar-svg" class='pa-icons' />
                                        <img v-else src="../../images/svg/Empty.svg" alt="calendar-empty-svg" class='pa-icons' />
                                    </div>
                                </div>
                                <div class="col-lg-9 col-md-8 col-10 p-3 px-4 px-sm-0 px-md-4 px-lg-4 px-xl-3 px-xxl-2 text-start">
                                    <span>
                                        <span class="pa-description"> {{ optionTwo }}</span>
                                        <br>
                                        <span class="optionDesc">{{ descTwo }}</span>
                                    </span>
                                </div>
                                <div class="col-lg-2 col-md-3 col-10 offset-2 offset-md-0 offset-sm-2 offset-lg-0 p-lg-4 p-md-4 pt-2 pb-3 px-4 px-sm-0 text-left">
                                    <button
                                        id="selectButton2" 
                                        type="submit" 
                                        class="pa-button"
                                        role="button"
                                        @click="toggleSelectButton('selectButton2');
                                        this.paData.pastDue ? selectPaymentSchedule('PayPastDue') : selectPaymentSchedule('Make2Pay');
                                        gtmLogging(this.paData.pastDue ? 'PayPastDue' : 'Make2Pay');" 
                                        v-on:keyup.enter="toggleSelectButton('selectButton2');
                                        this.paData.pastDue ? selectPaymentSchedule('PayPastDue') : selectPaymentSchedule('Make2Pay');
                                        gtmLogging(this.paData.pastDue ? 'PayPastDue' : 'Make2Pay');" 
                                        tabindex="0"
                                    >Select</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
    
                    <!-- ##### STEP 2 ##### -->
                    <div class="text-start">
                        <img class="circleNumber" src="../../images/svg/2-circle.svg" alt="2-circle-svg" />
                        <span class="steps">PAYMENT SCHEDULE</span>
                    </div>
                    <!-- ##### FULL AMOUNT TODAY ##### -->
                    <div v-show="showFullAmountToday" class="mb-3 my-2 ms-0 me-0">
                        <div class="container paymentOptionDiv">
                            <p class="text-start mx-1 mt-3 mb-4">
                                <span class="pa-description">Customer Payment Schedule </span>
                                <br>
                                <span id="fullAmountDesc">
                                    Please arrange a payment to pay the full amount today.
                                </span>
                            </p>
                            <div class="mx-1">
                                <form class="row">
                                    <div class="text-start mb-2 col-12 col-lg-12 col-md-12 col-sm-12">
                                        <strong class="installmentLabel">Installment:</strong>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="text-start form-control py-3">${{ this.paData.adjustedAmount }}</div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="pa-datepicker text-start form-control py-3">
                                            <div class="d-inline-block pa-date-input">{{ currentDate() }}</div>
                                            <img src="../../images/svg/Empty.svg" alt="calendar-empty-svg" class='pa-input-icons' />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div v-show="!continueDisabled" class="text-center mb-4">
                                <button
                                    class="pa-button"
                                    type="submit"
                                    value="Continue"
                                    id="FullAmountContinue"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#panel-Iframe"
                                    aria-controls="panel-Iframe"
                                    @click="submitFullAmount();" 
                                    v-on:keyup.enter="submitFullAmount();"
                                    tabindex="0"
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- ##### MAKE 1 PAYMENT ##### -->
                    <div v-show="showMake1Pay" class="mb-3 my-2 ms-0 me-0">
                        <div class="container paymentOptionDiv">
                            <p class="text-start mx-1 mt-3 mb-4">
                                <span class="pa-description">Customer Payment Schedule </span>
                                <br>
                                <span id="fullAmountDesc">
                                    Please arrange a payment schedule by filling in the amounts and dates you wish to pay. 
                                    There is a minimum of five dollars per payment and the date must be within the next 30 days.
                                </span>
                            </p>
                            <div class="mx-1">
                                <form class="row">
                                    <div class="text-start mb-2 col-12 col-lg-12 col-md-12 col-sm-12">
                                        <strong class="installmentLabel">Installment:</strong>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="text-start form-control py-3">${{ this.paData.totalAmtDue }}</div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="pa-datepicker text-start form-control py-3">
                                            <label for="datepicker2" class="w-100">
                                                <input
                                                    class="pa-date-input"
                                                    type="text"
                                                    id="datepicker2"
                                                    placeholder="Select a Payment Date"
                                                    v-model="dates.datepicker2"
                                                    readonly
                                                />
                                                <img src="../../images/svg/Empty.svg" alt="calendar-empty-svg" class='pa-input-icons' />
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div v-show="!continueDisabled" class="text-center mb-4">
                                <button
                                    class="pa-button"
                                    type="submit"
                                    value="Continue"
                                    id="make1PayContinue"
                                    :data-bs-toggle="!v$.datepicker2.$error ? 'collapse' : 'modal'"
                                    :data-bs-target="!v$.datepicker2.$error ? '#panel-Iframe' : '#make1PanelError'"
                                    aria-controls="panel-Iframe"
                                    @click.prevent="submitMake1Pay();" 
                                    v-on:keyup.enter="submitMake1Pay();"
                                >
                                    Continue
                                </button>
                            </div>
                            <!-- ##### MODAL ERROR ##### -->
                            <div
                                class="modal fade"
                                id="make1PanelError"
                                tabindex="0"
                                aria-labelledby="make1PanelError"
                                aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-body modal-body-error">
                                            Please select a date for Total Amount Payment to
                                            continue.
                                        </div>
                                        <div class="modal-footer modal-footer-error">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                data-bs-dismiss="modal"
                                                @click="continueDisabled = false" 
                                                v-on:keyup.enter="continueDisabled = false"
                                            >
                                                OK
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ##### MAKE 2 PAYMENT ##### -->
                    <div v-show="showMake2Pay"  id="make2Pay" class="mb-3 my-2 ms-0 me-0">
                        <div class="container paymentOptionDiv">
                            <p class="text-start mx-1 mt-3 mb-4">
                                <span class="pa-description">Customer Payment Schedule </span>
                                <br>
                                <span id="fullAmountDesc">
                                    Please arrange a payment schedule by filling in the amounts and dates you wish to pay. 
                                    There is a minimum of five dollars per payment and the date must be within the next 30 days.
                                </span>
                            </p>
                            <div class="mx-1">
                                <form class="row">
                                    <div class="text-start mb-2 col-12 col-lg-12 col-md-12 col-sm-12">
                                        <strong class="installmentLabel">Installment 1:</strong>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="pa-datepicker text-start form-control py-3">
                                            <span v-show="this.dates.installment1 != null">$</span>
                                            <input
                                                type="text"
                                                autofocus
                                                class="border-0 pa-installment1"
                                                id="installment1"
                                                placeholder="Payment Amount (Minimum $5.00)"
                                                @input="onInputInstallment1()"
                                                @change="onChangeInstallment1();"
                                                v-model="dates.installment1"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="pa-datepicker text-start form-control py-3">
                                            <label for="datepicker3" class="w-100">
                                                <input
                                                    class="pa-date-input"
                                                    type="text"
                                                    id="datepicker3"
                                                    placeholder="Select a Payment Date"
                                                    v-model="dates.datepicker3"
                                                    @click="this.checkInstallment1()"
                                                    @focus="clearNextDate()"
                                                    readonly
                                                />
                                                <img src="../../images/svg/Empty.svg" alt="calendar-empty-svg" class='pa-input-icons' />
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="mx-1">
                              <form class="row">
                                <div class="text-start mb-2 col-12 col-lg-12 col-md-12 col-sm-12">
                                  <strong class="installmentLabel">Installment 2:</strong>
                                </div>
                                <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-4">
                                  <div class="text-start form-control py-3">
                                    <div v-if="dates.installment1 >= 5">${{ installment2 }}</div>
                                    <div v-else id="installmentPlaceholder" @click="this.checkInstallment1()">Payment Amount (Minimum $5.00)</div>
                                  </div>
                                </div>
                                <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-4">
                                  <div class="pa-datepicker text-start form-control py-3">
                                    <label for="datepicker4" class="w-100">
                                      <input
                                        class="pa-date-input"
                                        type="text"
                                        id="datepicker4"
                                        placeholder="Select a Payment Date"
                                        v-model="dates.datepicker4"
                                        @click="this.checkInstallment1()"
                                        readonly
                                      />
                                      <img src="../../images/svg/Empty.svg" alt="calendar-empty-svg" class='pa-input-icons' />
                                    </label>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div v-show="!continueDisabled" class="text-center mb-4">
                                <button
                                    class="pa-button"
                                    type="submit"
                                    value="Continue"
                                    id="make2PayContinue"
                                    :data-bs-toggle="
                                        !v$.datepicker3.$error &&
                                        !v$.datepicker4.$error &&
                                        !v$.installment1.$error
                                        ? 'collapse'
                                        : 'modal'
                                    "
                                    :data-bs-target="
                                        !v$.datepicker3.$error &&
                                        !v$.datepicker4.$error &&
                                        !v$.installment1.$error
                                        ? '#panel-Iframe'
                                        : '#make2PayModalError'
                                    "
                                    aria-controls="panel-Iframe"
                                    @click.prevent="submitMake2Pay();" 
                                    v-on:keyup.enter="submitMake2Pay();"
                                >
                                    Continue
                                </button>
                            </div>
                            <!-- ##### MODAL ERROR ##### -->
                            <div
                              class="modal fade"
                              id="make2PayModalError"
                              tabindex="0"
                              aria-labelledby="make2PayModalError"
                              aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div v-if="v$.datepicker3.$error" class="modal-body">
                                            Please select a date for Installment 1 to continue.
                                        </div>
                                        <div v-else-if="v$.datepicker4.$error" class="modal-body">
                                            Please select a date for Installment 2 to continue.
                                        </div>
                                        <div v-else-if="v$.installment1.$error" class="modal-body">
                                            Please add $5 or more for Installment 1 to continue.
                                        </div>
                                        <div class="modal-footer">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                data-bs-dismiss="modal"
                                                @click="continueDisabled = false" 
                                                v-on:keyup.enter="continueDisabled = false"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ##### PAST DUE AMOUNT ##### -->
                    <div v-show="showPayPastDue" class="mb-3 my-2 ms-0 me-0">
                        <div class="container paymentOptionDiv">
                            <p class="text-start mx-1 mt-3 mb-4">
                                <span class="pa-description">Customer Payment Schedule </span>
                                <br>
                                <span id="fullAmountDesc">
                                    Please arrange a payment schedule by filling in the amounts and dates you wish to pay. 
                                    There is a minimum of five dollars per payment and the date must be within the next 10 days.
                                </span>
                            </p>
                            <div class="mx-1">
                                <form class="row">
                                    <div class="text-start mb-2 col-12 col-lg-12 col-md-12 col-sm-12">
                                        <strong class="installmentLabel">Installment:</strong>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="text-start form-control py-3">${{ this.paData.pastDueAmount }}</div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-md-6 col-sm-12 mb-3">
                                        <div class="pa-datepicker text-start form-control py-3">
                                            <label for="datepicker5" class="w-100">
                                                <input
                                                    class="pa-date-input"
                                                    type="text"
                                                    id="datepicker5"
                                                    placeholder="Select a Payment Date"
                                                    v-model="dates.datepicker5"
                                                    readonly
                                                />
                                                <img src="../../images/svg/Empty.svg" alt="calendar-empty-svg" class='pa-input-icons' />
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div v-show="!continueDisabled" class="text-center mb-4">
                                <button
                                    class="pa-button"
                                    type="submit"
                                    value="Continue"
                                    id="PastDueContinue"
                                    :data-bs-toggle="!v$.datepicker5.$error ? 'collapse' : 'modal'"
                                    :data-bs-target="!v$.datepicker5.$error ? '#panel-Iframe' : '#pastDuePanelError'"
                                    aria-controls="panel-Iframe"
                                    @click="submitPastDue();" 
                                    v-on:keyup.enter="submitPastDue();"
                                    tabindex="0"
                                >
                                    Continue
                                </button>
                            </div>
                            <!-- ##### MODAL ERROR ##### -->
                            <div
                              class="modal fade"
                              id="pastDuePanelError"
                              tabindex="0"
                              aria-labelledby="pastDuePanelError"
                              aria-hidden="true"
                            >
                                <div class="modal-dialog modal-dialog-centered">
                                    <div class="modal-content">
                                        <div class="modal-body modal-body-error">
                                            Please select a date for Past Due Payment to continue.
                                        </div>
                                        <div class="modal-footer modal-footer-error">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                data-bs-dismiss="modal"
                                                @click="continueDisabled = false" 
                                                v-on:keyup.enter="continueDisabled = false"
                                            >
                                                OK
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ##### DEFAULT MESSAGE ##### -->
                    <div v-if="!showFullAmountToday && !showMake1Pay && !showMake2Pay && !showPayPastDue" class="mb-3 my-2 ms-0 me-0">
                        <div class="container paymentOptionDiv">
                            <p class="text-start mx-1 mt-3 mb-4">
                                <span class="pa-description">Customer Payment Schedule </span>
                                <br>
                                <span id="fullAmountDesc">Select a payment plan above before choosing your payment schedule.</span>
                            </p>
                        </div>
                    </div>
                    <br>
                    <!-- ##### STEP 3 ##### -->
                    <div class="text-start">
                        <img class="circleNumber" src="../../images/svg/3-circle.svg" alt="3-circle-svg" />
                        <span class="steps">PAYMENT INFORMATION</span>
                    </div>

                    <div v-show="this.iframeURL == null" class="mb-3 my-2 ms-0 me-0 justify-content-center">
                        <div class="container paymentOptionDiv">
                            <p class="text-start mx-1 mt-3 mb-4">
                                <span class="pa-description">Choose your method of Payment </span>
                                <br>
                                <span id="fullAmountDesc">Select a payment plan above before choosing your method of payment.</span>
                            </p>
                        </div>
                    </div>
    
                    <!-- ##### IFRAME ##### -->
                    <div
                        v-if="!this.brim"
                        id="panel-Iframe"
                        class="accordion-collapse collapse col-12"
                        aria-labelledby="panelsStayOpen-headingThree"
                        data-bs-parent="#payInfo"
                        :class="{ collapsing: isActive }"
                    >
                        <div class="accordion-body text-start">
                            <h3 style="margin-bottom: 0px">
                                <iframe
                                    style="height: 1300px; width: 100%; border: none; overflow:hidden"
                                    :src="this.iframeURL" title="paIframe"
                                ></iframe>
                            </h3>
                        </div>
                    </div>
                    <div v-else-if="this.brim"
                        id="panel-Iframe"
                        class="accordion-collapse collapse col-12 card-bank-payment"
                        aria-labelledby="panelsStayOpen-headingThree"
                        data-bs-parent="#payInfo"
                        :class="{ collapsing: isActive }">
                        <button v-if="this.isAchAllowed" type="button" class="pa-payment-cta" @click="bankOption">Pay With Bank Account</button>
                        <button v-if="this.isCardAllowed" type="button" class="pa-payment-cta" @click="cardOption">Pay With Credit or Debit Card</button>
                    </div>
                </div>
            </div>
    
            <!-- ##### AutoPay Message ##### -->
            <div
                class="modal fade"
                id="isAutoPay"
                tabindex="0"
                aria-labelledby="isAutoPay"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div v-if="this.releaseAutopayFlag" class="modal-body modal-body-error">
                            <p class="text-primary text-start">Important Note:</p>
                            <p class="text-start">
                                Your account is currently on AutoPay, once you setup a 
                                payment plan your AutoPay will be removed from 
                                your account. When your payment plan is complete 
                                you will receive an email to re-enroll in AutoPay. By 
                                continuing in the payment plan flow you are 
                                agreeing to have AutoPay removed from your account.
                            </p>
                        </div>
                        <div v-else class="modal-body modal-body-error">
                            <p class="text-primary text-start">Important Note:</p>
                            <p class="text-start">
                                If you are enrolled in AutoPay and you set up payment
                                plans, you must take action to prevent your next
                                automatic payment being charged as scheduled.
                            </p>
                            <p class="text-start">
                                Please do the following:
                                <a :href="this.signInURL" id="signintomyaccount">
                                    Sign In
                                </a>
                                > Go to My Bill > Select AutoPay in the Billing Preferences
                                area > Select Remove AutoPay from this account. Once your
                                payment plan is complete, you can re-enroll in AutoPay.
                            </p>
                        </div>
                        <div class="modal-footer modal-footer-error">
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >
                                OK
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header no-border">
                    <h5 v-if="this.bankOptionTitle" class="modal-title">Enter bank account info</h5>
                    <h5 v-if="this.cardOptionTitle" class="modal-title">Enter card info</h5>
                    <button type="button" class="btn" id="modalCloseBtn" @click="closeModal()" aria-label="Close"> 
                        <img src="../../images/svg/icon-navigation-close_24px.svg" alt="outline-info"/>
                    </button>
                </div>
                <div class="bank-img-section" v-if="this.cardImg">
                    <img class="visa-img" src="../../images/visa.svg" alt="visa">
                    <img class="mastercard-img" src="../../images/mastercard.svg" alt="mastercard">
                    <img class="discover-img" src="../../images/discover.svg" alt="discover">
                </div>
                <div class="modal-body">
                    <div class="hcde-comp">
                        <hcde-tokenization v-if="this.hcdeReady" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showCardResponse" callbackField="myTokenVar" statusFunction="showTokenStatus" statusField="statusVar" heartBeatFrequency="60" srcSysTransId="307902021108">Loading...</hcde-tokenization>
                        <hcde-wallet v-if="this.hcdeReadyWallet" :env="this.env" clientId="MYA_PAY" :sessionId="this.sessionId" callbackFunction="showBankResponse" callbackRefNumField="walletRefNum"  callbackInstrumentField="walletInstrumentId" statusFunction="showWalletStatus" statusField="statusVar" srcSysTransId="1234567890">Loading...</hcde-wallet>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
    import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
    import useValidate from "@vuelidate/core";
    import { required } from "@vuelidate/validators";
    import { reactive, computed } from "vue";
    import { SET_DATA, GET_DATA } from "../../store/storecontants";
    import axios from "axios";
    import constants from "../../js/constants";
    import links from '../../js/links'
    import { pii } from "../../js/pii";

    export default {
        name: "PAOptions",
        data() {
            return {
                continueDisabled: false,
                isActive: true,
                make2Payment: "Make 2 monthly payments",
                make2PaymentDesc: "This option will split your full amount owed across two monthly payments.",
                onlyPastAmount: "Pay only past due amount",
                onlyPastAmountDesc: "This option will allow you to pay the past due balance on your account.",
                iframeURL: null,
                signInURL: process.env.VUE_APP_SIGNIN_AZURE_URL,
                isMultiAccount: this.$store.getters[GET_DATA]("isMultiAccount"),
                releaseAutopayFlag: constants.AutopayReleaseFlag,
                showFullAmountToday: false,
                showMake1Pay: false,
                showMake2Pay: false,
                showPayPastDue: false,
                hcdeReady: false,
                hcdeReadyWallet: false,
                myTokenVar: null,
                walletInstrumentId: null,
                hcdeTagString: null,
                brim: false,
                WalletJSEncrypt: null,
                hcdeWalletServiceApi: null,
                hcdeTokenServiceApi: null,
                hcdeWalletComponent: null,
                hcdeTokenComponent: null,
                paymentModal: null,
                sessionId: null,
                formattedDate: '',
                bankOptionTitle: false,
                cardOptionTitle: false,
                cardImg: false,
                env: null,
                brimOn: false,
                selectedOption: null,
                currentDay: new Date().toISOString().split("T")[0],
                paymentPlan: null,
                paymentAmount: null,
                paymentProcessedDate: null,
                isCardAllowed: false,
                isAchAllowed: false
            };
        },

        setup() {
            const validator = (value) => value != null;
            const fiveMinimum = (value) => value >= 5;
            const dates = reactive({
                datepicker2: null,
                datepicker3: null,
                datepicker4: null,
                datepicker5: null,
                installment1: null,
                installment2: null,
            });

            const paData = reactive({
                accountEntered: null,
                accountNumber: null,
                maskedAccount: null,
                accountType: null,
                accountZipCode: null,
                adjustedAmount: null,
                autoPay: null,
                pastDue: null,
                pastDueDate: null,
                pastDueAmount: null,
                paymentDueDate: null,
                paymentStatus: null,
                totalAmtDue: null,
                totalAmtDueDate: null,
            });
            
            const brimOptions = reactive({
                totalDue: false,
                pastDue: false,
                installments: false,
                totalInstallmentAmt: null
            });

            const rules = computed(() => {
                return {
                    datepicker2: {
                        required,
                        validator,
                    },
                    datepicker3: {
                        required,
                        validator,
                    },
                    datepicker4: {
                        required,
                        validator,
                    },
                    datepicker5: {
                        required,
                        validator,
                    },
                    installment1: {
                        required,
                        fiveMinimum
                    }
                };
            });

            const v$ = useValidate(rules, dates);

            return {
                paData,
                brimOptions,
                dates,
                v$,
            };
        },

        computed: {
            installment2: function () {
                let totalPastDue = this.paData.adjustedAmount;
                if (!this.brim) totalPastDue = this.paData.adjustedAmount;
                else if (this.brim) totalPastDue = this.brimOptions.totalInstallmentAmt;
                if (this.dates.installment1 > 0)
                    return (parseFloat(totalPastDue) - parseFloat(this.dates.installment1)).toFixed(2);
                else return parseFloat(totalPastDue);
            },

            optionTwo: {
                get() {
                    if (this.paData.pastDue) {
                        return this.onlyPastAmount;
                    } else {
                        return this.make2Payment;
                    }
                },
            },

            descTwo: {
                get() {
                    if (this.paData.pastDue) {
                        return this.onlyPastAmountDesc;
                    }
                    else {
                        return this.make2PaymentDesc;
                    }
                }
            },
        },

        mounted() {
            let attributesData = new Map();
            attributesData.set('showLoading', true);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            this.v$.$touch();
            this.responseData = JSON.parse(this.$store.getters[GET_DATA]("data"));
            this.paData.accountEntered = JSON.parse(this.$store.getters[GET_DATA]('accountEntered'));
            this.paData.maskedAccount = this.responseData.maskedAccount;
            this.paData.accountNumber = this.responseData.ban;
            this.paData.accountZipCode = this.responseData.billingAddress.zip;
            this.paData.adjustedAmount = Number(this.responseData.adjustedAmount).toFixed(2);
            if (this.paData.adjustedAmount.includes('$')) this.paData.adjustedAmount = this.paData.adjustedAmount.replace('$', '');
            this.paData.paymentDueDate = this.responseData.paymentDueDate;
            // TOGGLE THIS.PADATA.AUTOPAY IN LOCAL FOR AUTO PAY MESSAGE
            this.paData.autoPay = this.responseData.autoPay;
            // Fetching card or bank store value after checking which payment mode is allowed
            this.isAchAllowed = JSON.parse(this.$store.getters[GET_DATA]('isAchAllowed'));
            this.isCardAllowed = JSON.parse(this.$store.getters[GET_DATA]('isCardAllowed'));
            // TOGGLE THIS.PADATA.PASTDUE IN LOCAL FOR ELIGIBILITY OPTIONS
            this.paData.pastDue = this.responseData.pastDue;
            this.paData.pastDueDate = this.responseData.pastDueDate;
            this.paData.pastDueAmount = this.responseData.pastDueAmt;
            if (this.paData.pastDueAmount == null) this.paData.pastDueAmount = "0.00";
            if (this.paData.pastDueAmount.includes('$')) this.paData.pastDueAmount = this.paData.pastDueAmount.replace('$', '');
            this.paData.pastDueAmount = Number(this.paData.pastDueAmount).toFixed(2);
            this.isAutoPay();

            this.paymentModal = new Modal(document.getElementById('myModal'),{
                backdrop: 'static',
                keyboard: false
            });

            this.brimOn = process.env.VUE_APP_BRIM;
            this.env = process.env.VUE_APP_env;
            if(this.brimOn == "true"){
                this.WalletJSEncrypt = links.WalletJSEncrypt;
                this.hcdeWalletServiceApi = links.hcdeWalletServiceApi;
                this.hcdeTokenServiceApi = links.hcdeTokenServiceApi;
                this.hcdeWalletComponent = links.hcdeWalletComponent;
                this.hcdeTokenComponent = links.hcdeTokenComponent;
                this.brim = true;
                if (this.responseData.arrangementOptionDetails != null) {
                    for (const option of this.responseData.arrangementOptionDetails) {
                        if (option.option == "TOTAL_DUE") {
                            this.paData.totalAmtDue = option.installments[0].installmentAmount;
                            if (this.paData.totalAmtDue.includes('$')) this.paData.totalAmtDue = this.paData.totalAmtDue.replace('$', '');
                            this.paData.totalAmtDueDate = option.installments[0].installmentDate;
                            this.brimOptions.totalDue = true;
                        } else if (option.option == "PAST_DUE") {
                            this.paData.pastDue = true;
                            this.paData.pastDueAmt = option.installments[0].installmentAmount;
                            this.paData.pastDueDate = option.installments[0].installmentDate;
                            this.brimOptions.pastDue = true;
                        } else if (option.option == "INSTALLMENTS") {
                            this.brimOptions.installments = true;
                            this.brimOptions.totalInstallmentAmt = Number(option.installments[0].installmentAmount) + Number(option.installments[1].installmentAmount);
                        }
                    }
                }
            }else {
                this.brim = false;
                if (this.responseData.arrangementOptionDetails != null) {
                    for (const option of this.responseData.arrangementOptionDetails) {
                        if (option.option == "TOTAL_DUE") {
                            this.paData.totalAmtDue = option.installments[0].installmentAmount;
                            if (this.paData.totalAmtDue.includes('$')) this.paData.totalAmtDue = this.paData.totalAmtDue.replace('$', '');
                            this.paData.totalAmtDueDate = option.installments[0].installmentDate;
                        }
                    }
                }
            }

            window.dataLayer?.push({
                page: {
                    pageType: 'pa',
                    pageName: 'ctl|account|payment_arrangement|payment_options'
                }
            });

            window.dataLayer?.push({
                event: 'pa_customer_eligible',
                accountNumber: pii(this.paData.accountNumber),
                accountType: this.paData.accountType,
                paymentStatus: (this.paData.pastDue) ? 'Past Due' : 'Not Past Due',
                flow:{
                    name: 'pa',
                    step: 'payment_options_page'
                },
            });

            var thirtyDaysDate = new Date();
            thirtyDaysDate.setDate(thirtyDaysDate.getDate() + 29);
            var dueDate = new Date();
            dueDate.setDate(dueDate.getDate() + 10);
            attributesData.set('account', this.paData.accountNumber);
            attributesData.set('zip', this.paData.accountZipCode);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});
            /* global $ */
            $("#datepicker2")
                .datepicker({
                    autoclose: true,
                })
                .on("changeDate", () => {
                    this.dates.datepicker2 = $("#datepicker2").val();
                    this.disableIframeAccordion();
                });
            $(document).on("change", "#datepicker2", function () {
                this.dispatchEvent(new Event("input"));
            });
            $("#datepicker2").datepicker("setStartDate", new Date());
            $("#datepicker2").datepicker("setEndDate", thirtyDaysDate);
            $("#datepicker3")
                .datepicker({
                    autoclose: true,
                })
                .on("changeDate", () => {
                    this.dates.datepicker3 = $("#datepicker3").val();
                    this.dates.datepicker4 = null;
                    this.disableIframeAccordion();
                });
            $(document).on("change", "#datepicker3", function () {
                this.dispatchEvent(new Event("input"));
            });
            $("#datepicker3")
                .datepicker()
                .on("changeDate", function (selected) {
                    var selectedDate = new Date(selected.date.valueOf());
                    var minDate = new Date(selectedDate);
                    minDate.setDate(selectedDate.getDate() + 1);
                    $("#datepicker4").datepicker("setStartDate", minDate);
                });
            $("#datepicker3").datepicker("setStartDate", new Date());
            $("#datepicker3").datepicker("setEndDate", thirtyDaysDate);
            $("#datepicker4")
                .datepicker({
                    autoclose: true,
                })
                .on("changeDate", () => {
                    this.dates.datepicker4 = $("#datepicker4").val();
                    this.disableIframeAccordion();
                });
            $(document).on("change", "#datepicker4", function () {
                this.dispatchEvent(new Event("input"));
            });
            $("#datepicker4").datepicker("setEndDate", thirtyDaysDate);
            $("#datepicker5")
                .datepicker({
                    autoclose: true,
                })
                .on("changeDate", () => {
                    this.dates.datepicker5 = $("#datepicker5").val();
                    this.disableIframeAccordion();
                });
            $(document).on("change", "#datepicker5", function () {
                this.dispatchEvent(new Event("input"));
            });
            $("#datepicker5").datepicker("setStartDate", new Date());
            $("#datepicker5").datepicker("setEndDate", dueDate);
            attributesData.set('showLoading', false);
            this.$store.dispatch(SET_DATA, {attributes:attributesData});

            // eslint-disable-next-line
            const self = this;
            window.showCardResponse = function (){
                self.createCardPaymentPlan(window.myTokenVar);
            };
            // eslint-disable-next-line
            window.showBankResponse = function(){
                self.createBankPaymentPlan(window.walletInstrumentId);
            };
        },
        watch: {
            showMake2Pay() {
                let make2Pay = document.getElementById('make2Pay');
                if(this.showMake2Pay){
                    make2Pay.style.display = "block";
                    this.focusInstallment1();
                } else {
                    make2Pay.style.display = "none";
                }
            }
        },
        updated() {
        this.isBrimOn = process.env.VUE_APP_BRIM;
        if(this.isBrimOn == 'true'){
            this.createHcdeTagListener()
            }   
        },
        methods: {
            async createCardPaymentPlan(token){
                try {
                    const accountData = JSON.parse(this.$store.getters[GET_DATA]("data"));
                    let params = {
                        accountNumber: accountData.ban,
                        accountType: 'CON',
                        ban: accountData.ban,
                        firstName: accountData.firstName,
                        lastName: accountData.lastName,
                        businessName: null,
                        emailId: accountData.emailAddressOfRecord,
                        paymentAmount: this.paymentAmount,
                        paymentProcessedDate: this.paymentProcessedDate,
                        cardToken: token,
                        bankToken: null,
                        walletReferenceNumber: null,
                        instrumentId: null,
                        paymentSubmittedBy: 'MYACCOUNT',
                        isEnrollAutopayReq: 'N',
                        isPaymentPlan: 'Y',
                        paymentPlanDetails: {
                            paymentPlan: this.paymentPlan
                        },
                        paymentPlanOption: this.selectedOption
                    }
                    const res = await axios.post(constants.BrimQuickBillPayCreate, params);
                    if (res.data.transactionStatus === 'S') {
                        let attributesData = new Map();
                        attributesData.set('paymentConfirmationNumber', res.data.masterPaymentId);
                        this.$store.dispatch(SET_DATA, {attributes:attributesData});
                        this.closeModal();
                        this.$router.push({
                            name: "PaymentArrngmtsSuccessScheduledPmts",
                            params: {},
                        });
                    }  else if (res.data.transactionStatus === 'F') {
                        this.closeModal();
                        this.$router.push({
                            name: "PaymentArrngmtsFailure",
                            params: {},
                        });
                    }
                } catch (error) {
                    console.log(error);
                    this.closeModal();
                    this.$router.push({
                        name: "PaymentArrngmtsFailure",
                        params: {},
                    });
                }
            },
            async createBankPaymentPlan(token){
                try {
                    const accountData = JSON.parse(this.$store.getters[GET_DATA]("data"));
                    let params = {
                        accountNumber: accountData.ban,
                        accountType: 'CON',
                        ban: accountData.ban,
                        firstName: accountData.firstName,
                        lastName: accountData.lastName,
                        businessName: null,
                        emailId: accountData.emailAddressOfRecord,
                        paymentAmount: this.paymentAmount,
                        paymentProcessedDate: this.paymentProcessedDate,
                        cardToken: null,
                        bankToken: token,
                        walletReferenceNumber: null,
                        instrumentId: null,
                        paymentSubmittedBy: 'MYACCOUNT',
                        isEnrollAutopayReq: 'N',
                        isPaymentPlan: 'Y',
                        paymentPlanDetails: {
                            paymentPlan: this.paymentPlan
                        },
                        paymentPlanOption: this.selectedOption
                    }
                    const res = await axios.post(constants.BrimQuickBillPayCreate, params);
                    if (res.data.transactionStatus === 'S') {
                        let attributesData = new Map();
                        attributesData.set('paymentConfirmationNumber', res.data.masterPaymentId);
                        this.$store.dispatch(SET_DATA, {attributes:attributesData});
                        this.closeModal();
                        this.$router.push({
                            name: "PaymentArrngmtsSuccessScheduledPmts",
                            params: {},
                        });
                    }  else if (res.data.transactionStatus === 'F') {
                        this.closeModal();
                        this.$router.push({
                            name: "PaymentArrngmtsFailure",
                            params: {},
                        });
                    }
                } catch (error) {
                    console.log(error);
                    this.closeModal();
                    this.$router.push({
                        name: "PaymentArrngmtsFailure",
                        params: {},
                    });
                }
            },
            closeModal() {
                this.paymentModal.hide();
                this.hcdeReady = false;
                this.hcdeReadyWallet = false;
            },
            removeJSfile(oldfilename) {
                let targetelement = 'script';
                let targetattr = 'src';
                let allsuspects = document.getElementsByTagName(targetelement);
                for (let i = allsuspects.length; i >= 0; i--) {
                    //search backwards within nodelist for matching elements to remove
                    if (allsuspects[i] && allsuspects[i].getAttribute(targetattr) != null && allsuspects[i].getAttribute(targetattr).indexOf(oldfilename) != -1) {
                        allsuspects[i].parentNode.removeChild(allsuspects[i]);
                    }
                }
            },
            async createHcdeTagListener() {
                const scripts = [
                    this.WalletJSEncrypt,
                    this.hcdeTokenServiceApi,
                    this.WalletJSEncrypt,
                    this.hcdeWalletServiceApi
                ];
                scripts.forEach(script => {
                    let tag = document.head.querySelector(`[src="${ script }"`);
                    if (!tag) {
                        tag = document.createElement("script");
                        tag.setAttribute("src", script);
                        tag.setAttribute("type", 'text/javascript');
                        document.head.appendChild(tag); 
                    }
                });
            },
            bankOption() {
                this.paymentModal.show();
                this.bankOptionTitle = true;
                this.cardOptionTitle = false;
                this.cardImg = false;
                this.hcdeReady = false;
                this.hcdeReadyWallet = true;
                const bankscripts = [
                    this.hcdeWalletComponent				
                ];
                bankscripts.forEach(script => {
                    let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                    if (scriptFound) {
                        this.removeJSfile(script);
                    }
                    let tag = document.head.querySelector(`[src="${ script }"`);
                    if (!tag) {
                        tag = document.createElement("script");
                        tag.setAttribute("src", script);
                        tag.setAttribute("type", 'text/javascript');
                        document.head.appendChild(tag); 
                        tag.addEventListener('load', function () {
                            // eslint-disable-next-line no-undef
                            this.hcdeTagString = new HCDEWalletServiceComponent();

                            const digitsOnlyRegExp = /^[0-9]*$/;

                            let hcdeBankDetailCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                            hcdeBankDetailCon.setAttribute('id', 'hcdeBankDetailContainer');
                            hcdeBankDetailCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                            let hcdeRouteNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                            hcdeRouteNumLabel.innerText = "";

                            let hcdeRouteNumInput = document.getElementById('bankRoutingNumber');
                            hcdeRouteNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                            hcdeRouteNumInput.setAttribute('placeholder', ' ');
                            hcdeRouteNumInput.setAttribute('maxlength', '9');
                            hcdeRouteNumInput.setAttribute('oninput', 'onChangeRouteNum()');
                            hcdeRouteNumInput.addEventListener("keypress", e => {
                                if (!digitsOnlyRegExp.test(e.key)) {
                                    e.preventDefault();
                                }
                            });

                            let newRoutingNumberLabel = document.createElement('label');
                            newRoutingNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                            newRoutingNumberLabel.setAttribute('id', 'hcdeRouteNumberLabel');
                            newRoutingNumberLabel.innerText = "Routing Number";
                            hcdeBankDetailCon.appendChild(newRoutingNumberLabel);

                            let hcdeAcctNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                            hcdeAcctNumCon.setAttribute('id', 'hcdeAccountNumberContainer');
                            hcdeAcctNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                            let hcdeAcctNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                            hcdeAcctNumLabel.innerText = "";

                            let hcdeAcctNumInput = document.getElementById('bankAccountNumber');
                            hcdeAcctNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                            hcdeAcctNumInput.setAttribute('placeholder', '');
                            hcdeAcctNumInput.setAttribute('maxlength', '17');
                            hcdeAcctNumInput.setAttribute('oninput', 'onChangeAcctNum()');
                            hcdeAcctNumInput.addEventListener("keypress", e => {
                                if (!digitsOnlyRegExp.test(e.key)) {
                                    e.preventDefault();
                                }
                            });

                            let newAcctNumberLabel = document.createElement('label');
                            newAcctNumberLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                            newAcctNumberLabel.setAttribute('id', 'hcdeAccountNumberLabel');
                            newAcctNumberLabel.innerText = "Account Number";
                            hcdeAcctNumCon.appendChild(newAcctNumberLabel);

                            let hcdeAcctTypeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                            hcdeAcctTypeCon.setAttribute('id', 'hcdeAccountTypeContainer');
                            hcdeAcctTypeCon.setAttribute('class', 'hcdeComponentInputContainer col-12');

                            let hcdeAcctTypeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                            hcdeAcctTypeLabel.innerHTML = '';

                            let hcdeAcctTypeInput = document.getElementById('bankAccountType');
                            hcdeAcctTypeInput.options[0].innerText = 'Bank account type';
                            hcdeAcctTypeInput.setAttribute('onchange', 'onChangeAcctType()');
                            hcdeAcctTypeInput.setAttribute('class', 'hcdeComponentDropdown');

                            let hcdeBankButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                            hcdeBankButtonCon.setAttribute('id', 'hcdeSaveBankButtonContainer');

                            let hcdeSaveBankButton = document.getElementById('hcdeComponentSubmitButton');
                            hcdeSaveBankButton.setAttribute('class', 'hcdeComponentSubmitButton');
                            hcdeSaveBankButton.setAttribute('value', 'Submit');

                            // eslint-disable-next-line no-undef
                            checkBankInfo();
                        });
                    }   
                });
            },
            cardOption() {
                this.paymentModal.show();
                this.bankOptionTitle = false;
                this.cardOptionTitle = true;
                this.cardImg = true;
                this.hcdeReady = true;
                this.hcdeReadyWallet = false;  
                const cardscripts = [
                    this.hcdeTokenComponent				
                ];
                cardscripts.forEach(script=>{
                    let scriptFound = document.head.querySelector(`[src="${ script }"`) ? true : false;
                    if (scriptFound) {
                        this.removeJSfile(script);
                    }
                    let tag = document.head.querySelector(`[src="${ script }"`);
                    if (!tag) {
                        tag = document.createElement("script");
                        tag.setAttribute("src", script);
                        tag.setAttribute("type", 'text/javascript');
                        document.head.appendChild(tag);
                        tag.addEventListener('load', function () {
                            // eslint-disable-next-line no-undef
                            this.hcdeTagString = new HCDETokenServiceComponent();

                            const digitsOnlyRegExp = /^[0-9]*$/;

                            let hcdeCardNumCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1)');
                            hcdeCardNumCon.setAttribute('id', 'hcdeCardNumberContainer');
                            hcdeCardNumCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-12');

                            let hcdeCardNumLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(1) > label');
                            hcdeCardNumLabel.innerText = "";

                            let hcdeCardNumInput = document.getElementById('hcdeCardNumber');
                            hcdeCardNumInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                            hcdeCardNumInput.setAttribute('placeholder', ' ');
                            hcdeCardNumInput.setAttribute('maxlength', '16');
                            hcdeCardNumInput.setAttribute('oninput', 'onChangeCardNum()');
                            hcdeCardNumInput.addEventListener("keypress", e => {
                                if (!digitsOnlyRegExp.test(e.key)) {
                                    e.preventDefault();
                                }
                            });

                            let newCardNumLabel = document.createElement('label');
                            newCardNumLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                            newCardNumLabel.setAttribute('id', 'hcdeCardNumberLabel');
                            newCardNumLabel.innerText = "Card Number";
                            hcdeCardNumCon.appendChild(newCardNumLabel);

                            let hcdeExpMonthCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2)');
                            hcdeExpMonthCon.setAttribute('id', 'hcdeExpMonthContainer');
                            hcdeExpMonthCon.setAttribute('class', 'hcdeComponentInputContainer col-6 pe-1');

                            let hcdeExpMonthLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(2) > label');
                            hcdeExpMonthLabel.innerText = "";

                            let hcdeExpMonthInput = document.getElementById('hcdeCardExpiryMonth');
                            hcdeExpMonthInput.options[0].innerText = 'Expiration Month';
                            hcdeExpMonthInput.setAttribute('class', 'hcdeComponentDropdown');
                            hcdeExpMonthInput.setAttribute('onchange', 'onChangeExpMth()');

                            let hcdeExpYearCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3)');
                            hcdeExpYearCon.setAttribute('id', 'hcdeExpYearContainer');
                            hcdeExpYearCon.setAttribute('class', 'hcdeComponentInputContainer col-6 ps-1');

                            let hcdeExpYearLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(3) > label');
                            hcdeExpYearLabel.innerText = "";

                            let hcdeExpYearInput = document.getElementById('hcdeCardExpiryYear');
                            hcdeExpYearInput.options[0].innerText = 'Expiration Year';
                            hcdeExpYearInput.setAttribute('class', 'hcdeComponentDropdown');
                            hcdeExpYearInput.setAttribute('onchange', 'onChangeExpYr()');

                            let hcdeZipCodeCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4)');
                            hcdeZipCodeCon.setAttribute('id', 'hcdeZipCodeContainer');
                            hcdeZipCodeCon.setAttribute('class', 'hcdeComponentInputContainer form-floating form-group col-6 pe-1');

                            let hcdeZipCodeLabel = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentContainer > div:nth-child(4) > label');
                            hcdeZipCodeLabel.innerText = "";

                            let hcdeZipCodeInput = document.getElementById('hcdeZipCode');
                            hcdeZipCodeInput.setAttribute('class', 'hcdeComponentTextBox form-control');
                            hcdeZipCodeInput.setAttribute('placeholder', ' ');
                            hcdeZipCodeInput.setAttribute('maxlength', '5');
                            hcdeZipCodeInput.setAttribute('oninput', 'onChangeZipCode()');
                            hcdeZipCodeInput.addEventListener("keypress", e => {
                                if (!digitsOnlyRegExp.test(e.key)) {
                                    e.preventDefault();
                                }
                            });

                            let newZipCodeLabel = document.createElement('label');
                            newZipCodeLabel.setAttribute('class', 'hcdeComponentLabel d-inline-block text-start text-truncate form-label col-12');
                            newZipCodeLabel.setAttribute('id', 'hcdeZipCodeLabel');
                            newZipCodeLabel.innerText = "Zip Code";
                            hcdeZipCodeCon.appendChild(newZipCodeLabel);

                            let hcdeButtonCon = document.querySelector('#hcdeComponentMainContainer > div.hcdeComponentButtonContainer');
                            hcdeButtonCon.setAttribute('id', 'hcdeSaveButtonContainer');

                            let hcdeSaveButton = document.getElementById('hcdeComponentSubmitButton');
                            hcdeSaveButton.setAttribute('value', 'Submit');

                            // eslint-disable-next-line no-undef
                            checkCardInfo();
                        });
                    } 
                });
            },
            focusInstallment1() {
                let installment1 = document.getElementById('installment1');
                if(installment1 != null){
                    installment1.focus();
                }
            },
            checkInstallment1() {
                let installment1 = document.getElementById('installment1').value;
                if(installment1 == ""){
                    $("#datepicker3").datepicker("hide");
                    $("#datepicker4").datepicker("hide");
                    this.focusInstallment1();
                }
            },
            // shows one of the payment schedule options based off the sent value
            selectPaymentSchedule(paymentPlan) {
                this.iframeURL = null;
                switch (paymentPlan) {
                    case 'FullAmountToday': 
                        this.showFullAmountToday = !this.showFullAmountToday;
                        this.showMake1Pay = false
                        this.showMake2Pay = false;
                        this.showPayPastDue = false;
                        break;
                    case 'Make1Pay':
                        this.showFullAmountToday = false;
                        this.showMake1Pay = !this.showMake1Pay;
                        this.showMake2Pay = false;
                        this.showPayPastDue = false;
                        break;
                    case 'Make2Pay':
                        this.showFullAmountToday = false;
                        this.showMake1Pay = false;
                        this.showMake2Pay = !this.showMake2Pay;
                        this.showPayPastDue = false;
                        break;
                    case 'PayPastDue':
                        this.showFullAmountToday = false;
                        this.showMake1Pay = false
                        this.showMake2Pay = false;
                        this.showPayPastDue = !this.showPayPastDue;
                        break;
                }
            },
            // ensures that no special characters can be inputted as the first installment for the Make 2 Payments option
            onInputInstallment1(){
                this.dates.installment1 = this.dates.installment1.replace(/[^0-9.]/g, "");
                if(this.iframeURL != null) this.disableIframeAccordion();
            },
            // changes the first installment value of the Make 2 Payments option by formatting it with two decimals as well as setting the minimum and maximum value.
            onChangeInstallment1(){
                this.dates.installment1 = (Math.round(this.dates.installment1 * 100) / 100).toFixed(2);
                const minAmount = Number(5).toFixed(2);
                let userInput = Number(this.dates.installment1);
                let totalAmount = null
                if (!this.brim) {
                    totalAmount = Number.parseFloat(this.paData.totalAmtDue);
                } else if (this.brim) {
                    totalAmount = Number.parseFloat(this.brimOptions.totalInstallmentAmt);
                }
                let maxAmount = (Math.round((totalAmount - 5) * 100) / 100).toFixed(2);
                // if the inputted value is greater than the max value, the installment value will be changed to the max value.
                if(userInput > maxAmount) {
                    this.dates.installment1 = maxAmount;
                // if the inputted value is less than five dollars or NaN, the installment value will be changed to five dollars.
                } else if(userInput < minAmount || isNaN(userInput)) {
                    this.dates.installment1 = minAmount;
                }
            },
            // clears the second installment date when the user clicks to set the first installment date for the Make 2 Payments option
            clearNextDate() {
                document.getElementById('datepicker4').value = '';
            },
            // changes the appearance of the select buttons when clicked to show 'Selected' and changes the color
            toggleSelectButton(buttonId) {
                this.isActive = !this.isActive;
                this.continueDisabled = false;
                var button1=document.getElementById('selectButton1');
                var button2=document.getElementById('selectButton2');
                var button3=document.getElementById('selectButton3');
                switch (buttonId) {
                    case 'selectButton1':
                        if (button2 !== null) button2.innerHTML = 'Select';
                        if (button2 !== null) button2.classList.contains('active') ? button2.classList.toggle('active') : null;
                        if (button3 !== null) button3.innerHTML = 'Select';
                        if (button3 !== null) button3.classList.contains('active') ? button3.classList.toggle('active') : null;
                        button1.classList.toggle('active');
                        (button1.innerHTML == 'Select') ? (button1.innerHTML = 'Selected') : (button1.innerHTML = 'Select');
                        break;
                    case 'selectButton2':
                        if (button1 !== null) button1.innerHTML = 'Select';
                        if (button1 !== null) button1.classList.contains('active') ? button1.classList.toggle('active') : null;
                        if (button3 !== null) button3.innerHTML = 'Select';
                        if (button3 !== null) button3.classList.contains('active') ? button3.classList.toggle('active') : null;
                        button2.classList.toggle('active');
                        (button2.innerHTML == 'Select') ? (button2.innerHTML = 'Selected') : (button2.innerHTML = 'Select');
                        break;
                    case 'selectButton3':
                        if (button1 !== null) button1.innerHTML = 'Select';
                        if (button1 !== null) button1.classList.contains('active') ? button1.classList.toggle('active') : null;
                        if (button2 !== null) button2.innerHTML = 'Select';
                        if (button2 !== null) button2.classList.contains('active') ? button2.classList.toggle('active') : null;
                        button3.classList.toggle('active');
                        (button3.innerHTML == 'Select') ? (button3.innerHTML = 'Selected') : (button3.innerHTML = 'Select');
                        break;
                }
            },
            // removes the iframe from the page when the user changes the date(s) or value(s)
            disableIframeAccordion() {
                if(this.continueDisabled == true){
                    this.continueDisabled = false;
                    $("#panel-Iframe").collapse('toggle');
                    this.iframeURL = null;
                }
            },
            // calls the orchestrator to retrieve the iframe url
            async retrieveIframe(paymentOption) {
                let attributesData = new Map();
                attributesData.set('showLoading', true);
                this.$store.dispatch(SET_DATA, {attributes:attributesData});
                try {
                    this.iframeURL = null;
                    const paymentArrangementResponse = JSON.parse(this.$store.getters[GET_DATA]("data"));
                    var arrangementOptionType = null;
                    var totalOrFirstInstallPaymentAmt = null;
                    var totalOrFirstInstallmentPaymentDate = null;
                    var totalPaymentAmt = null;
                    var noOfInStallments = null;
                    var secondInstallPaymentAmt = null;
                    var secondInstallmentPaymentDate = null;
                    if (paymentOption == "fullAmt" || paymentOption == "make1Pay") {
                        arrangementOptionType = "TOTAL_DUE";
                        totalOrFirstInstallPaymentAmt =
                            paymentArrangementResponse.adjustedAmount;
                        if (paymentOption == "fullAmt") {
                            let currentDay = new Date().toISOString().split("T")[0];
                            totalOrFirstInstallmentPaymentDate = this.getFormattedDateBackend(currentDay);
                        } else if (paymentOption == "make1Pay") {
                            totalOrFirstInstallmentPaymentDate = this.getFormattedDateBackend(this.dates.datepicker2);
                        }
                        totalPaymentAmt = paymentArrangementResponse.adjustedAmount;
                        noOfInStallments = 1;
                        secondInstallPaymentAmt = null;
                        secondInstallmentPaymentDate = null;
                    } else if (paymentOption == "make2Pay") {
                        arrangementOptionType = "INSTALLMENTS";
                        totalOrFirstInstallPaymentAmt = this.dates.installment1;
                        totalOrFirstInstallmentPaymentDate = this.getFormattedDateBackend(this.dates.datepicker3);
                        totalPaymentAmt = paymentArrangementResponse.adjustedAmount;
                        noOfInStallments = 2;
                        secondInstallPaymentAmt = this.installment2;
                        secondInstallmentPaymentDate = this.getFormattedDateBackend(this.dates.datepicker4);
                    } else if (paymentOption == "pastDueAmt") {
                        arrangementOptionType = "PAST_DUE";
                        totalOrFirstInstallPaymentAmt = this.paData.pastDueAmount;
                        totalOrFirstInstallmentPaymentDate = this.getFormattedDateBackend(this.dates.datepicker5);
                        totalPaymentAmt = this.paData.pastDueAmount;
                        noOfInStallments = 1;
                        secondInstallPaymentAmt = null;
                        secondInstallmentPaymentDate = null;
                    }
                    const makePaymentArrangement = {
                        number: paymentArrangementResponse.ban,
                        billingAccountNumber: paymentArrangementResponse.ban,
                        billingTelephoneNumber: paymentArrangementResponse.billingTelephoneNumber,
                        confirmationEmailAddress: paymentArrangementResponse.confirmationEmailAddress,
                        customerCode: paymentArrangementResponse.customerCode,
                        customerCommonName: paymentArrangementResponse.customerCommonName,
                        firstName: paymentArrangementResponse.firstName,
                        lastName: paymentArrangementResponse.lastName,
                        adjustedAmountAvailable: paymentArrangementResponse.adjustedAmountAvailable,
                        adjustedAmountDue: paymentArrangementResponse.adjustedAmount,
                        pmtArrangementEligibilityId: paymentArrangementResponse.pmtArrangementEligibilityId,
                        iframeUrl: paymentArrangementResponse.iframeUrl,
                        streetAddress1: paymentArrangementResponse.billingAddress.addressLine1,
                        streetAddress2: paymentArrangementResponse.billingAddress.addressLine2,
                        stateProvince: paymentArrangementResponse.billingAddress.stateCode,
                        city: paymentArrangementResponse.billingAddress.city,
                        postalCode: paymentArrangementResponse.billingAddress.zip,
                        arrangementOptionType: arrangementOptionType,
                        totalOrFirstInstallPaymentAmt: totalOrFirstInstallPaymentAmt,
                        totalOrFirstInstallmentPaymentDate: totalOrFirstInstallmentPaymentDate,
                        totalPaymentAmt: totalPaymentAmt,
                        noOfInStallments: noOfInStallments,
                        secondInstallPaymentAmt: secondInstallPaymentAmt,
                        secondInstallmentPaymentDate: secondInstallmentPaymentDate,
                    };
                    let attributesData = new Map();
                    attributesData.set('confirmationEmailAddress', paymentArrangementResponse.confirmationEmailAddress);
                    attributesData.set('account', this.paData.accountNumber);
                    attributesData.set('zip', this.paData.accountZipCode);
                    attributesData.set('maskedAccount', this.paData.maskedAccount);
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                    const res = await axios.post(constants.PaymentArrangementIframeURL, {
                        paymentArrangementResponse,
                        makePaymentArrangement,
                    });
                    if (res.data != "null") {
                        this.iframeURL = res.data.sessionURL;
                        this.sessionId = res.data.sessionId;
                    }
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                } catch (error) {
                    console.log(error);
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, {attributes:attributesData});
                }
            },
            // formats the dates to match the expected format in the backend
            getFormattedDateBackend(d) {
                const originalDate = d;
                try {
                    const d0 = new Date(d);
                    d = d0.toISOString();
                    d = d.substring(0,10).includes('/') ? d.substring(0,10).replaceAll('/', '-') + d.substring(10, d.length) : d;
                    d = (d.substring(0,10).indexOf('-') > 0 || d.substring(0,10).indexOf('/') > 0) && (d.substring(11, d.length).indexOf(':') > 0) ? d.substring(0,10) + 'T' + d.substring(11, d.length) : d;
                    d = d.endsWith('Z') ? d.substring(0, d.length - 1) : d;
                    const date = new Date(d.length == 10 ? (d + 'T00:00:00') : d);
                    return this.getDateAccurateToOriginal(date, originalDate);
                } catch(err) {
                    return originalDate;
                }
            },
            // a part of formatting the date for the backend
            getDateAccurateToOriginal(date, originalDateStr) {
                var d = new Date();
                var h = d.getHours();
                h = (h < 10) ? ("0" + h) : h ;
                var m = d.getMinutes();
                m = (m < 10) ? ("0" + m) : m ;
                var s = d.getSeconds();
                s = (s < 10) ? ("0" + s) : s ;
                if (originalDateStr.substr(2,1) == "/" && originalDateStr.substr(5,1) == "/") {
                    return originalDateStr.substr(6,4) + "-" + originalDateStr.substr(0,2) + "-" + originalDateStr.substr(3,2) + "T" + h + ":" + m + ":" + s + ".000-06:00";
                }
                return date.getFullYear() + "-" + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2) + "T" + h + ":" + m + ":" + s + ".000-06:00";
            },
            // if the account is autopay, display the autopay message
            isAutoPay: function () {
                if (this.paData.autoPay) {
                    $("#isAutoPay").modal("show");
                }
            },
            // getting the ordinal number suffix
            get_nth_suffix(date) {
                switch (date) {
                    case 1:
                    case 21:
                    case 31:
                        return "st";
                    case 2:
                    case 22:
                        return "nd";
                    case 3:
                    case 23:
                        return "rd";
                    default:
                        return "th";
                }
            },
            // calculating today's date
            currentDate() {
                const current = new Date();
                const day = new Date(current).toLocaleString("en-us", {
                    weekday: "long",
                });
                const month = current.toLocaleString("en-us", {
                    month: "short",
                });
                const ordinal = this.get_nth_suffix(current);
                const today =
                    "Today, " + day + ", " + month + " " + current.getDate() + ordinal;
                return today;
            },
            // formats paymentProcessedDate for the createPaymentPlan call to the orchestrator
            formatPaymentProcessedDate(date){
                const day = date.toLocaleString("en-us", {
                    day: "2-digit",
                });
                const month = date.toLocaleString("en-us", {
                    month: "2-digit",
                });
                const year = date.getFullYear();
                const formattedDate = year + '-' + month + '-' + day;
                return formattedDate;
            },
            // fired when the user clicks the Continue button for the Full Amount option
            submitFullAmount() {
                this.continueDisabled = !this.continueDisabled;
                this.selectedOption = 'TOTAL_DUE';
                this.gtmLogging('FullAmountContinue');
                this.retrieveIframe('fullAmt');
                
                const date = new Date();
                this.paymentAmount = this.paData.adjustedAmount;
                this.paymentProcessedDate = this.formatPaymentProcessedDate(date);
                this.paymentPlan= [
                    {
                        paymentAmount: this.paData.adjustedAmount,
                        paymentProcessedDate: this.formatPaymentProcessedDate(date)
                    }
                ];
            },
            // fired when the user clicks the Continue button for the Make 1 Payment option
            submitMake1Pay() {
                this.v$.datepicker2.$touch();
                this.continueDisabled = !this.continueDisabled;
                this.selectedOption = 'TOTAL_DUE';
                this.gtmLogging('make1PayContinue');
                this.retrieveIframe('make1Pay');

                const date = new Date(this.dates.datepicker2);
                this.paymentAmount = this.paData.totalAmtDue;
                this.paymentProcessedDate = this.formatPaymentProcessedDate(date);
                this.paymentPlan= [
                    {
                        paymentAmount: this.paData.totalAmtDue,
                        paymentProcessedDate: this.formatPaymentProcessedDate(date)
                    }
                ];
            },
            // fired when the user clicks the Continue button for the Make 2 Payments option
            submitMake2Pay() {
                this.v$.datepicker3.$touch();
                this.v$.datepicker4.$touch();
                this.v$.installment1.$touch();
                this.continueDisabled = !this.continueDisabled;
                this.selectedOption = 'INSTALLMENTS';
                this.gtmLogging('make2PayContinue');
                if (!this.v$.datepicker3.$error &&
                    !this.v$.datepicker4.$error &&
                    !this.v$.installment1.$error) this.retrieveIframe('make2Pay');

                const date1 = new Date(this.dates.datepicker3);
                const date2 = new Date(this.dates.datepicker4);
                this.paymentAmount = this.brimOptions.totalInstallmentAmt;
                this.paymentProcessedDate = this.formatPaymentProcessedDate(date1);
                this.paymentPlan= [
                    {
                        paymentAmount: this.dates.installment1,
                        paymentProcessedDate: this.formatPaymentProcessedDate(date1)
                    },
                    {
                        paymentAmount: this.installment2,
                        paymentProcessedDate: this.formatPaymentProcessedDate(date2)
                    }
                ];
            },
            // fired when the user clicks the Continue button for the Past Due option
            submitPastDue() {
                this.v$.datepicker5.$touch();
                this.continueDisabled = !this.continueDisabled;
                this.selectedOption = 'PAST_DUE';
                this.gtmLogging('PastDueContinue');
                if (!this.v$.datepicker5.$error) this.retrieveIframe('pastDueAmt');

                const date = new Date(this.dates.datepicker5);
                this.paymentAmount = this.paData.pastDueAmount;
                this.paymentProcessedDate = this.formatPaymentProcessedDate(date);
                this.paymentPlan= [
                    {
                        paymentAmount: this.paData.pastDueAmount,
                        paymentProcessedDate: this.formatPaymentProcessedDate(date)
                    }
                ];
            },
            // fires a gtm event based on the value
            gtmLogging(event) {
                if(event == 'Make1Pay') {
                    window.dataLayer?.push({
                        event: 'pa_payment_options',
                        accountNumber: pii(this.paData.accountNumber),
                        accountType: this.paData.accountType,
                        paymentStatus: (this.paData.pastDue) ? 'Past Due' : 'Not Past Due',
                        paymentOption: '1 payment 30 days',
                        flow:{
                            name: 'pa',
                            step: 'payment options screen'
                        },
                    });
                } else if(event == 'FullAmountToday') {
                    window.dataLayer?.push({
                        event: 'pa_payment_options',
                        accountNumber: pii(this.paData.accountNumber),
                        accountType: this.paData.accountType,
                        paymentStatus: (this.paData.pastDue) ? 'Past Due' : 'Not Past Due',
                        paymentOption: 'full amount',
                        flow:{
                            name: 'pa',
                            step: 'payment options screen'
                        },
                    });
                } else if(event == 'Make2Pay') {
                    window.dataLayer?.push({
                        event: 'pa_payment_options',
                        accountNumber: pii(this.paData.accountNumber),
                        accountType: this.paData.accountType,
                        paymentStatus: (this.paData.pastDue) ? 'Past Due' : 'Not Past Due',
                        paymentOption: '2 payment 30 days',
                        flow:{
                            name: 'pa',
                            step: 'payment options screen'
                        },
                    });
                } else if(event == 'PayPastDue') {
                    window.dataLayer?.push({
                        event: 'pa_payment_options',
                        accountNumber: pii(this.paData.accountNumber),
                        accountType: this.paData.accountType,
                        paymentStatus: (this.paData.pastDue) ? 'Past Due' : 'Not Past Due',
                        paymentOption: 'past due amount',
                        flow:{
                            name: 'pa',
                            step: 'payment options screen'
                        },
                    });
                } else if(event == 'FullAmountContinue') {
                    window.dataLayer?.push({
                        event: 'pa_payment_schedule',
                        accountNumber: pii(this.paData.accountNumber),
                        accountType: this.paData.accountType,
                        paymentStatus: (this.paData.pastDue) ? 'Past Due' : 'Not Past Due',
                        paymentOption: 'full amount',
                        flow:{
                            name: 'pa',
                            step: 'payment schedule'
                        },
                    });
                } else if(event == 'PastDueContinue') {
                    window.dataLayer?.push({
                        event: 'pa_payment_schedule',
                        accountNumber: pii(this.paData.accountNumber),
                        accountType: this.paData.accountType,
                        paymentStatus: (this.paData.pastDue) ? 'Past Due' : 'Not Past Due',
                        paymentOption: 'past due amount',
                        flow:{
                            name: 'pa',
                            step: 'payment schedule'
                        },
                    });
                } else if(event == 'make1PayContinue') {
                    window.dataLayer?.push({
                        event: 'pa_payment_schedule',
                        accountNumber: pii(this.paData.accountNumber),
                        accountType: this.paData.accountType,
                        paymentStatus: (this.paData.pastDue) ? 'Past Due' : 'Not Past Due',
                        paymentOption: '1 payment 30 days',
                        flow:{
                            name: 'pa',
                            step: 'payment schedule'
                        },
                    });
                } else if(event == 'make2PayContinue') {
                    window.dataLayer?.push({
                        event: 'pa_payment_schedule',
                        accountNumber: pii(this.paData.accountNumber),
                        accountType: this.paData.accountType,
                        paymentStatus: (this.paData.pastDue) ? 'Past Due' : 'Not Past Due',
                        paymentOption: '2 payment 30 days',
                        flow:{
                            name: 'pa',
                            step: 'payment schedule'
                        },
                    });
                }
            },
            // validates whether or not there are multiple accounts
            validateData(dataReview){
                return dataReview.toString() === 'false' ? false: true
            },
            // navigates the user back to the multi-account page
            changeAcct() {
                window.dataLayer?.push({
                    event: 'pa_change_acct_link',
                    flow:{
                        name: 'pa',
                        step: 'ctl|account|payment_arrangement|options|link|change_acct'
                    },
                });
                this.$router.push({
                    name: "PAMultiAccount",
                    params: {},
                });
            },
        },
    };
</script>

<style>
    #modalCloseBtn:active {
        border: none;
    }
    .bank-img-section{
        text-align: left;
        padding: 0px 16px;
    }
    .visa-img, .mastercard-img, .discover-img {
        height: 30px;
        width: 32px;
    }
    .visa-img {
        margin-left: 0px;
    }
    .mastercard-img, .discover-img {
        margin-left: 10px;
    }
    @media(max-width:992px) {
        .pa-payment-cta {
            width: 100%;
            margin: 10px 0;
        }
    }
    .pa-payment-cta {
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid #0047BB;
        color: #0047BB;
        margin: 10px;
        padding: 6px 12px;
    }
    .card-bank-payment {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #00000029;
        border-radius: 8px;
        opacity: 1;
        padding: 15px 10px;
        text-align: center;
    }
    @-moz-document url-prefix() {
        #installmentPlaceholder {
            opacity: 0.55;
        }
    }
    #installmentPlaceholder, ::placeholder{
        color: #14161A61;
    }
    .pa-datepicker {
        height: 58px;
    }
    .pa-date-input {
        width: 91%;
        border: none;
    }
    .pa-installment1 {
        width: 96%;
    }
    .pa-installment1:focus-visible, .pa-date-input:focus-visible {
        outline: none;
    }
    .installmentLabel {
        color: #14161A;
        opacity: 0.70;
        font-size: 12px;
        font-family: MaisonNeue-Light, serif;
    }
    #changeAcctBtn {
        text-decoration: none;
        color: #0d6efd;
    }

    #changeAcctBtn:hover {
        text-decoration: underline;
        color: #0a58ca;
    }

    #pa-header {
        color: #0047bb;
    }

    .modal-footer-error {
        justify-content: center;
    }

    .modal-body-error {
        text-align: center;
    }

    .amountDataDiv {
        top: 326px;
        left: 422px;
        width: 436px;
        height: 93px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #00000029;
        border: 1px solid #EEEEEE33;
        border-radius: 8px;
        opacity: 1;
    }

    .amountDataDivMobile{
        width: 131px !important;
        height: 67px !important;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #00000029;
        border: 1px solid #EEEEEE33;
        border-radius: 8px;
        opacity: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-spacing: 0;
        border-collapse: separate;
        padding: 5px 0 0 0; 
    }

    .accordion-button-paymentOptions.accordion-button {
        background-color: transparent !important;
        color: black !important;
    }

    .accordion-button-paymentOptions.accordion-button:focus {
        border-color: transparent !important;
        color: black !important;
    }

    #payScheduleButton:active(.collapsed)::after {
        transform: rotate(380deg);
    }

    button[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .accordion-button-paymentOptions.accordion-button::after {
        background-image: url(../../assets/images/accordion-up-arrow.svg);
        order: -1;
        margin-left: 0;
        margin-right: 0.5em;
    }
    .accordion-button-paymentOptions.accordion-button:not(.collapsed)::after {
        background-image: url(../../assets/images/accordion-up-arrow.svg);
    }

    .accordion-button-paymentOptions.accordion-button {
        font-size: 24px;
    }

    .accordion-collapse {
        font-size: 16px;
    }

    #datepicker2, #datepicker3, #datepicker4, #datepicker5, label{
        cursor: pointer;
    }
    .okBtn {
        padding-right: 20px;
        padding-bottom: 20px;
    }
    .okBtn a{
        color: #fff ;
        text-decoration: none ;
    }
    .okBtn a:hover{
        color: #fff;
        text-decoration: none;
    }
    .modalContent {
        padding-bottom: 0;
        padding-top: 35px;
    }
    #dssp-wrapper h1 {
        color: #14161A;
        font-size: 36px;
        letter-spacing: 0px;
        margin-bottom: 16px;
        opacity: 1;
        padding: 0;
    }
    .pa-description {
        text-align: left;
        font-family: MaisonNeue-Light, serif;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #000000;
        opacity: 1;
    }
    table {
        border-collapse:separate;
        border-spacing:0 8px 0 0;
    }
    .amountDataDiv td, .amountDataDivMobile td, .optionDesc {
        font-family: MaisonNeue-Light, serif;
        letter-spacing: 0.14px;
        color: #14161A;
        opacity: 0.7;
        font-size: 14px;
    }
    .amountDataDiv th, .amountDataDivMobile th {
        font-family: MaisonNeue-Medium, serif;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #000000;
        opacity: 1;
        font-weight: normal;
    }
    .circleNumber{
        transform: scale(1.25) translateY(-1px) translateX(-14px);
        stroke-width: 6px;
    }
    .pa-icons{
        transform: translateY(12px);
    }
    .pa-icons, .pa-input-icons{
        filter: invert(14%) sepia(76%) saturate(3589%) hue-rotate(213deg) brightness(109%) contrast(101%);
    }
    .steps{
        text-align: left;
        letter-spacing: 0.45px;
        color: #14161ABF;
        font-family: MaisonNeue-Medium, serif;
        text-transform: uppercase;
        opacity: 1;
    }
    .paymentOptionDiv{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #00000029;
        border: 1px solid #EEEEEE33;
        border-radius: 8px;
        opacity: 1;
    }
    .fullAmountTitle{
        text-align: left;
        font-family: MaisonNeue-Medium, serif;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #000000;
        opacity: 1;
    }

    #fullAmountDesc{
        text-align: left;
        font-family: MaisonNeue-Light, serif;
        font-size: 16px;
        letter-spacing: 0.16px;
        color: #000000;
        opacity: 0.6;
    }

    .make1payDiv{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #00000029;
        border: 1px solid #EEEEEE33;
        border-radius: 8px;
        opacity: 1;
        margin-bottom: 12px;
    }

    .paymentOptionDiv .pa-icon-circle {
        width: 48px;
        height: 48px;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        background: #E0E8F7 0% 0% no-repeat padding-box;
        opacity: 1;
    }

    .pa-button{
        border: 1px solid #0047BB;
        color: #0047bb;
        border-radius: 4px;
        font-family: MaisonNeue-Medium, serif;
        font-size: 14px;
        opacity: 1;
        background: #FFFFFF;
        width: 76px;
        height: 36px;
        padding: 1px 6px;
    }

    .pa-button.active {
        color: #FFFFFF;
        background-color: #0047bb;
    }

    @media (max-width: 768px) {
        .circleNumber{
            padding-left: 18px;
        }
        #dssp-wrapper h1{
            font-size: 48px;
        }
    }
    #panel-Iframe .accordion-body {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #00000029;
        border: 1px solid #EEEEEE33;
        border-radius: 8px;
        opacity: 1;
        margin-top: 20px;
        padding-top: 10px;
    }
    .text-left {
        text-align: left;
    }
    .pa-datepicker input {
        font-size: 16px;
    }
</style>