<template>
    <div class="container d-flex justify-content-center bill-box">
        <post-bill-failure/>
    </div>
</template>
<script>
import postBillFailure from '../components/Postbill/postBillFailure.vue'
export default {
    name: 'PostLoginBillFailure',
    components: {
        postBillFailure
    }
}
</script>
<style>
.bill-box {
    width: 60%;
    height: 100%;
    border-radius: 5px;
}

@media screen and (min-width: 375px) and (max-width: 979px) {
    .bill-box {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }
}
</style>