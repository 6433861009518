<template>
    <!-- show message something wrong start -->
    <div class="container qbp-wrapper col-xs-12 col-sm-12 col-md-12 no-pad" v-if="!isWaiting && !isDataAvailable">
        <div class="row mb-3">
            <div class="col text-start">
                <p>
                    Sorry, We could not find the page which you have requested.
                    Kindly navigate to our <a class="home-page" href="/pa/identify">Home Page.</a>
                </p>
            </div>
        </div>
    </div>
    <div class="container qbp-wrapper col-xs-12 col-sm-12 col-md-12 no-pad" v-if="isWaiting && !isDataAvailable">
        <div class="row mb-3">
            <div class="col text-start">
                <p>Please wait while we are fetching the data for you.</p>
            </div>
        </div>
    </div>
    <!-- show message something wrong end -->
    <!-- if everything looks good show the content respectively start -->
    <div v-if="!isWaiting && isDataAvailable">
        <div class="container paSuccessBox">
            <div id="pa-success-wrapper" class="col-xs-12">
                <div class="display-flex-style text-start">
                    <h2 class="checkmark-heading">
                        <img alt="checkmark-icon" class="checkmark-marg" src="../../images/checkmark_nucleus.png" />
                        Thanks for scheduling your payment plan!
                    </h2>
                </div>
                <div class="col-12 text-font-style marg-top-heading">Your upcoming payments have been scheduled. Your first payment will be <span v-if="this.today === 'today'"> today, </span><span v-else>on </span><b>{{ this.datePaidDisplay }}.</b></div>
                <div class="col-12 text-start payment-for marg-top-heading"><img alt="account-receipt"
                        src="../../images/receipt_long_black_24dp.svg" />PAYMENT PLAN FOR {{ this.accountEntered }}</div>
                <div class="col-12 text-start marg-top-heading">Confirmation Number</div>
                <div class="col-12 text-start marg-confirmation-num"><b>{{ this.paymentConfirmationNumber
                }}</b></div>

                <div>
                    <div v-if="this.paymentArrangementPayments && this.paymentArrangementPayments.length > 1">
                        <div v-if="this.paymentArrangementPayments[0].paymentMethod == 'CARD'">
                            
                            <div class="details">
                                <div class="text-summary">
                                    <h2>Payment Summary</h2>
                                </div>
                                <div class="row">
                                    <div class="col-2 col-xl-1">
                                        <div class="bill-icon">
                                        <img alt="payment-icon"
                                            src="../../images/attach_money_black_24dp.svg" />
                                        </div>
                                    </div>
                                    <div class="text-pmnt col-7 col-xl-9">Total installment plan</div>
                                    <div class="payment-amt col-3 col-xl-2">${{ this.payArrangmentTotalPayAmtSumDec }}</div>
                                </div>
                                <div class="horizontal-bord offset-2 offset-xl-1"></div>
                                <div class="row">
                                    <div class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Installment 1</div>
                                    <div class="text-conv-fee payment-amt col-3 col-xl-2">${{
                                        Number(this.paymentArrangementPayments[0].totalPaymentAmt).toFixed(2) }}</div>
                                    
                                </div>
                                <div class="row">
                                    <div class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Convenience fee</div>
                                    <div class="text-conv-fee payment-amt col-3 col-xl-2">${{
                                        this.paymentArrangementPayments[0].convenienceFeeAmt }}</div>
                                </div>
                                <div class="row">
                                    <i class="text-conv col-10 col-xl-11 offset-2 offset-xl-1">Charged <span v-if="this.today === 'today'">today</span><span v-if="this.todayText === ''">on {{ getHumanReadbledt(this.datePaidDisplay) }} </span> with Installment 1</i>
                                </div>
                                <div class="row marg-top-detail">
                                    <div class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Installment 2</div>
                                    <div class="text-conv-fee payment-amt col-3 col-xl-2">${{
                                        Number(this.paymentArrangementPayments[1].totalPaymentAmt).toFixed(2) }}</div>
                                </div>
                                <div class="row marg-top-detail">
                                    <span class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Convenience fee</span>
                                    <span class="text-conv-fee payment-amt col-3 col-xl-2">${{  this.paymentArrangementPayments[1].convenienceFeeAmt }}</span>
                                </div>
                                <div class="row">
                                    <i class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Will be charged on {{ getHumanReadbledt(this.paymentArrangementPayments[1].datePaid) }}</i>
                                </div>
                                <div class="row marg-top-detail">
                                    <div class="col-2 col-xl-1">
                                        <div class="bill-icon"><img alt="payment-method-icon"
                                            src="../../images/payments_black_24dp.svg" /></div>
                                    </div>
                                    <div class="col-10 col-xl-11">
                                        <div class="payment-method">Payment method</div>
                                        <div class="payment-id">{{ this.paymentArrangementPayments[0].maskedPaymentAccountID }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div v-for="(installment, index) in this.paymentArrangementPayments" :key="installment.id">
                                    <div class="details row">
                                        <div class="col-1"><img alt="card-wallet" src="../../images/card_wallet.png"
                                                class='icon-circle' /></div>
                                        <div class="col-11">
                                            <div class="row">
                                                <div class="col-12 text-start ms-4 ms-lg-2 ps-4">Installment {{ index + 1 }}:
                                                    <strong>${{ installment.amountPaid }}</strong></div>
                                                <div class="col-12 text-start ms-4 ms-lg-2 ps-4 text-font-style">Your account
                                                    will be charged this amount on
                                                    <strong>{{ getHumanReadbledt(installment.datePaid) }}.</strong></div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="this.paymentArrangementPayments && this.paymentArrangementPayments.length > 0">
                        <div v-if="this.paymentArrangementPayments[0].paymentMethod == 'CARD'">
                            <div class="details">
                                <div class="text-summary">
                                    <h2>Payment Summary</h2>
                                </div>
                                <div class="row">
                                    <div class="col-2 col-xl-1">
                                        <div class="bill-icon"><img alt="payment-icon"
                                                src="../../images/attach_money_black_24dp.svg" /></div>
                                    </div>
                                    <div class="text-pmnt col-7 col-xl-9">Total Payment</div>
                                    <div class="payment-amt col-3 col-xl-2">${{ this.paymentArrangementPayments[0].totalPaymentAmt }}</div>
                                </div>
                                <div class="horizontal-bord offset-2 offset-xl-1"></div>
                                <div class="row">
                                    <div class="text-conv col-7 col-xl-9 offset-2 offset-xl-1">Convenience fee</div>
                                    <div class="text-conv-fee payment-amt col-3 col-xl-2">${{ this.paymentArrangementPayments[0].convenienceFeeAmt}}</div>
                                </div>
                                <div class="row">
                                    <i class="text-conv col-10 col-xl-11 offset-2 offset-xl-1">Will be charged <span v-if="this.todayText === ''">on
                                        <strong>{{ getHumanReadbledt(this.datePaidDisplay) }}.</strong>
                                    </span><span v-if="this.today === 'today'">today.</span> with the amount</i>
                                </div>
                                <div class="row marg-top-detail">
                                    <div class="col-2 col-xl-1">
                                        <div class="bill-icon"><img alt="payment-method-icon"
                                                src="../../images/payments_black_24dp.svg" /></div>
                                    </div>
                                    <div class="col-10 col-xl-11">
                                        <div class="payment-method">Payment method</div>
                                        <div class="payment-id">{{ this.paymentArrangementPayments[0].maskedPaymentAccountID
                                    }}</div>
                                        
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="container styleMargin detail-box" v-else>
                            <div class="row p-3">
                                <div class="col-1"><img alt="card-wallet" src="../../images/card_wallet.png"
                                        class='icon-circle' /></div>
                                <div class="col-11">
                                    <div class="row">
                                        <div class="col-12 text-start ms-4 ms-lg-2 ps-4">Amount: <strong>${{
                                            this.paymentArrangementPayments[0].amountPaid }}</strong></div>
                                        <div class="col-12 text-start ms-4 ms-lg-2 ps-4 text-font-style">Your account will be
                                            charged this amount on <b>{{ this.datePaidDisplay }}.</b><br></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-end text-style" id="printButton">
                        <img alt="print" class="icon-style" src="../../images/print_black.svg" />
                        <span class="print-style text-end" @click="printPage" v-on:keyup.enter="printPage" tabindex="0">Print this page for your records</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- if everything looks good show the content respectively end -->
    <!-- show the acod profile creation button when acod flag is true on success call -->
    <div v-if="acodSuccess" class="row-margin">
        <div v-if="acodSuccess" class="row acod-container" id="acodContent">
            <div class="col-12 col-md-6 text-start row-acod">
                <div class="left-grid">
                    <h2 class="mb-2">Next time, just sign in!</h2>
                    My CenturyLink is an easy way to pay your bill, get troubleshooting help, and submit repair requests. It
                    only takes a few minutes to set up your online account and get the most CenturyLink has to offer you.
                    <div class="registerStyle mt-3">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal"
                            @click="getAcodRegisterationDetail()">Complete Your Account</button>

                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 order-first order-xl-2 order-lg-2 order-md-2 row-acod">
                <div class="right-grid">
                    <img alt="acod-image" class="img-people" src="../../images/Layer2.svg" />
                </div>
            </div>
        </div>
    </div>
    <div id="requestContent">
        <div v-if="acodSuccess" class="row paSuccessBox marg-top-bottom">
            <div v-if="!isWaiting && isDataAvailable" class="col-12 col-xl-9 text-start marg-two">
                <h2>Why create an account?</h2>
                <p>When you're signed in, we can give you targeted and specific information about your account, bill,
                    service, and more.</p>
            </div>
            <div v-if="!isWaiting && isDataAvailable" class="row justify-content-center">
                <div class="col-12 col-md-4 line">
                    <img alt="bill-icon" class="icon-style-bill" src="../../images/headset_mic_black_24dp.svg" />
                    <p>Submit repair requests</p>
                    <label>Skip the phone call and waiting on hold to make a repair appointment. Request service help from
                        My CenturyLink.</label>
                </div>
                <div class="col-12 col-md-4 line">
                    <img alt="bill-icon" class="icon-style-dollar" src="../../images/svg/Dollar.svg" />
                    <p>Pay your bills</p>
                    <label>Easy and complete access to your current and past bills, plus easy enrollment in AutoPay.</label>
                </div>
                <div class="col-12 col-md-4">
                    <img alt="bill-icon" class="icon-style-ts" src="../../images/icon-action-build_24px.svg" />
                    <p>Get troubleshooting help</p>
                    <label>When you’re signed in, we can provide detailed troubleshooting steps for your specific service
                        and equipment.</label>
                </div>
            </div>
        </div>
    </div>
    <div class="paSuccessBox">
        <div id="norton-logo"> </div>
    </div>
    <!-- show modal for acod registration -->
    <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content text-placement">
                <div v-if="acodSuccessModal" class="modal-body">
                    <div class="align-heading success-heading">
                        <img alt="success icon" class="checkmark" src="../../images/successicon.gif" />
                        <h3>Your profile has been created</h3>
                    </div>
                    <span>An email has been sent to <strong> {{ showEmailAddress }} </strong> with your
                        account with a link to activate your profile and set your password.</span>
                </div>
                <div v-else-if="acodFailModal" class="modal-body">
                    <h3 class="align-heading">Sorry! There seems to be an issue. Please try again later.
                    </h3>
                </div>
                <div v-else-if="loginExist" class="modal-body">
                    <p class="text-start">Great news! You already have an online profile. To connect this account with your existing profile, 
                    <a :href="CtlSignInURL">Sign into My CenturyLink</a> and add this account to your profile.
                    </p>
                    <a class="signin-cta" :href="CtlSignInURL">Sign into My CenturyLink</a>
                </div>
                <div class="modal-footer closeButton">
                    <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal"
                        @click="modalClose()">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SET_DATA, GET_DATA } from "../../store/storecontants";
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import constants from "../../js/constants";
import axios from "axios";
import links from "../../js/links";
import { pii } from "../../js/pii";

export default {
    name: "PASuccessSchedulePmts",
    data() {
        return {
            paymentConfirmationNumber: "",
            confirmationEmailAddress: "",
            emailAddressOfRecord: "",
            accountNumber: "",
            zipCode: "",
            paymentArrangementPayments: null,
            payArrangmentConvenFeeSumDec: null,
            payArrangmentTotalPayAmtSumDec: null,
            isWaiting: true,
            isDataAvailable: false,
            accountEntered: '',
            isAutopay: false,
            paymentMethod: '',
            releaseAutopayFlag: constants.AutopayReleaseFlag,
            firstName: '',
            lastName: '',
            commonName: '',
            acodSuccess: false,
            ACODRegisterationURL: constants.ACODRegisterationURL,
            AcodAccountNumber: '',
            acodSuccessModal: false,
            acodFailModal: false,
            loginExist: false,
            showEmailAddress: '',
            datePaidDisplay: '',
            datePaidRaw: '',
            todayText: '',
            CtlSignInURL: links.CtlSignInURL,
        }
    },
    mounted() {
        window.dataLayer?.push({
            page: {
                pageType: 'pa',
                pageName: 'ctl|account|payment_arrangement|success|scheduled_payments'
            }
        });

        // get all the required field to display success page 
        this.isAutopay = (this.$store.getters[GET_DATA]("autopay") == "true") ? true : false;
        this.paymentConfirmationNumber = this.$store.getters[GET_DATA]('paymentConfirmationNumber') ? this.$store.getters[GET_DATA]('paymentConfirmationNumber') : "NA";
        this.accountEntered = (this.$store.getters[GET_DATA]('maskedAccount') != "null") ? this.$store.getters[GET_DATA]('maskedAccount') : this.$store.getters[GET_DATA]('accountEntered') ? JSON.parse(this.$store.getters[GET_DATA]('accountEntered')) : "NA";
        this.accountNumber = this.$store.getters[GET_DATA]('account') ? this.$store.getters[GET_DATA]('account') : "NA";
        this.zipCode = this.$store.getters[GET_DATA]('zip') ? this.$store.getters[GET_DATA]('zip') : "NA";
        this.firstName = this.$store.getters[GET_DATA]('firstName') ? JSON.parse(this.$store.getters[GET_DATA]('firstName')) : "NA";
        this.lastName = this.$store.getters[GET_DATA]('lastName') ? JSON.parse(this.$store.getters[GET_DATA]('lastName')) : "NA";
        this.confirmationEmailAddress = this.$store.getters[GET_DATA]('confirmationEmailAddress') ? this.$store.getters[GET_DATA]('confirmationEmailAddress') : "NA";
        this.emailAddressOfRecord = this.$store.getters[GET_DATA]('emailAddress') ? JSON.parse(this.$store.getters[GET_DATA]('emailAddress')) : "NA";
        this.AcodAccountNumber = this.$store.getters[GET_DATA]('AcodAccountNumber') ? JSON.parse(this.$store.getters[GET_DATA]('AcodAccountNumber')) : "NA";
        this.getPaymentDetails();

        window.dataLayer?.push({
            event: 'pa_payment_success',
            accountNumber: pii(this.accountNumber),
            paymentOption: this.paymentMethod,
            flow: {
                name: 'pa',
                step: 'success screen'
            },
        });
    },
    methods: {
        parseDateString(dateString) {
            const dateOnlyRegex = /^([0-9]([0-9]([0-9][1-9]|[1-9]0)|[1-9]00)|[1-9]000)(-(0[1-9]|1[0-2])(-(0[1-9]|[1-2][0-9]|3[0-1])))$/
            if (dateOnlyRegex.test(dateString)) {
                const utcDate = new Date(dateString)
                const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000)
                return localDate  

            }
            return new Date(dateString)
        },
        // show popup for acod registration 
        openModalNow() {
            const modal = document.getElementById("myModal");
            document.body.appendChild(modal);
            var myModal = new Modal(modal, {
                keyboard: false,
            });
            myModal.show();
        },
        // close popup for acod registration 
        modalClose() {
            var modalElement = document.getElementById('myModal');
            var modal = Modal.getInstance(modalElement);
            modal.hide();
        },
        // Acod registration api call 
        async getAcodRegisterationDetail() {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'page': {
                    'pageType': 'pa',
                    'pageName': 'pa success'
                },
                'event': 'pa_acod_register_click',
                'flow': {
                    'name': 'pa',
                    'step': 'success screen',
                },
                'buttonName': 'Register'
            });
            let attributesData = new Map();
            attributesData.set('showLoading', true);
            this.$store.dispatch(SET_DATA, { attributes: attributesData });
            this.isWaiting = true;
            const params = {
                accountNumber: this.AcodAccountNumber,
                firstName: this.firstName,
                lastName: this.lastName,
                emailAddress: this.emailAddressOfRecord
            };
            try {
                const acodResult = await axios.post(constants.ACODRegisterationURL, params);
                // already registered
                if (acodResult.data.statusInfo.message == "4000 Login already exists in system"
                    || acodResult.data.statusInfo.message == "800 This account is already registered to a user") {
                    this.acodSuccessModal = false;
                    this.acodFailModal = false;
                    this.loginExist = true;
                    this.acodSuccess = false;
                    this.openModalNow();
                    this.isWaiting = false;
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                } else if (acodResult.data.acodprofileCreated == true) {
                    // register success
                    this.showEmailAddress = acodResult.data.emailAddress;
                    this.acodSuccessModal = true;
                    this.acodFailModal = false;
                    this.loginExist = false;
                    this.acodSuccess = false;
                    this.openModalNow();
                    this.isWaiting = false;
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'page': {
                            'pageType': 'pa',
                            'pageName': 'acod success'
                        },
                        'event': 'pa_acod_success',
                        'flow': {
                            'name': 'pa',
                            'step': 'success screen'
                        },
                        'accountNumber': pii(this.accountNumber)
                    });
                } else if (acodResult.data.acodprofileCreated == false) {
                    // something fail
                    this.acodFailModal = true;
                    this.acodSuccessModal = false;
                    this.loginExist = false;
                    this.acodSuccess = true;
                    this.openModalNow();
                    this.isWaiting = false;
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                } else {
                    // general sorry message
                    this.acodFailModal = true;
                    this.acodSuccessModal = false;
                    this.loginExist = false;
                    this.acodSuccess = true;
                    this.openModalNow();
                    this.isWaiting = false;
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'page': {
                            'pageType': 'pa',
                            'pageName': 'acod error'
                        },
                        'event': 'pa_acod_error',
                        'flow': {
                            'name': 'pa',
                            'step': 'success screen'
                        },
                        'accountNumber': pii(this.accountNumber)
                    });
                }
            } catch (error) {
                console.log(error);
                this.acodFailModal = true;
                this.acodSuccessModal = false;
                this.isWaiting = false;
                this.acodSuccess = true;
                this.openModalNow();
                attributesData.set('showLoading', false);
                this.$store.dispatch(SET_DATA, { attributes: attributesData });
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'page': {
                        'pageType': 'pa',
                        'pageName': 'acod error'
                    },
                    'event': 'pa_acod_error',
                    'flow': {
                        'name': 'pa',
                        'step': 'success screen'
                    },
                    'accountNumber': pii(this.accountNumber)
                });
            }
        },
        async getPaymentDetails() {
            // main for api success call
            this.isWaiting = true;
            let attributesData = new Map();
            // params for success call
            const params = {
                accountNumber: this.AcodAccountNumber,
                paymentConfirmationNumber: this.paymentConfirmationNumber,
                emailAddress: this.emailAddressOfRecord,
                zipCode: this.zipCode ? this.zipCode : null
            };
            try {
                // api for success call
                const res = await axios.post(constants.SchedulePaymentSuccessURL, params);
                //Check for acodsuccess true to display register button
                if (res.data.acodSuccess == true) {
                    this.acodSuccess = true;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'page': {
                            'pageType': 'pa',
                            'pageName': 'pa success'
                        },
                        'event': 'pa_acod_eligible',
                        'flow': {
                            'name': 'pa',
                            'step': 'success screen',
                        },
                        'accountNumber': pii(this.accountNumber),
                    });
                } else {
                    window.dataLayer?.push({
                        page: {
                            pageType: 'pa',
                            pageName: 'pa success'
                        },
                        event: 'ACOD PA ineligible',
                        flow: {
                            name: 'pa',
                            step: 'success screen'
                        }
                    });
                }
                // get the details of paymentArrangementPayments from api to display
                if (res.data.paymentArrangementPayments != null) {
                    this.isWaiting = false;
                    this.isDataAvailable = true;
                    this.paymentMethod = res.data.paymentArrangementPayments[0].paymentMethod;
                    this.paymentArrangementPayments = res.data.paymentArrangementPayments;
                    this.datePaidRaw = this.paymentArrangementPayments[0].datePaid;
                    this.datePaidDisplay = this.getHumanReadbledt(this.datePaidRaw);
                    this.payArrangmentConvenFeeSumDec = res.data.payArrangmentConvenFeeSumDec;
                    let payTotalSumString = res.data.payArrangmentTotalPayAmtSumDec;
                    let payTotalSumNum = parseFloat(payTotalSumString)
                    this.payArrangmentTotalPayAmtSumDec = payTotalSumNum.toFixed(2);
                    if (this.paymentArrangementPayments.length > 0 && this.paymentMethod === 'CARD') {
                        const date = new Date();
                        const m = String(date.getMonth() + 1).padStart(2, '0');
                        const d = String(date.getDate()).padStart(2, '0');
                        const y = String(date.getFullYear());
                        const formatedDate = [m, d, y].join('/');
                        const firstInstallmentDate = this.paymentArrangementPayments[0].datePaid;
                        if (formatedDate === firstInstallmentDate) {
                            this.todayText = 'today';
                        } else { this.todayText = '' }
                    }
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                } else {
                    //something went wrong navigate to basic success page
                    this.$router.push({
                        name: "PaymentArrngmtsSuccess",
                        params: {
                            accountNumber: this.accountNumber ? this.accountNumber : null,
                            paymentConfirmationNumber: this.paymentConfirmationNumber
                        },
                    });
                    this.isWaiting = false;
                    this.isDataAvailable = false;
                    this.paymentMethod = "NA"
                    attributesData.set('showLoading', false);
                    this.$store.dispatch(SET_DATA, { attributes: attributesData });
                }
            } catch (error) {
                console.log(error);
                //something went wrong navigate to basic success page
                this.$router.push({
                    name: "PaymentArrngmtsSuccess",
                    params: {
                        accountNumber: this.accountNumber ? this.accountNumber : null,
                        paymentConfirmationNumber: this.paymentConfirmationNumber
                    },
                });
                this.isWaiting = false;
                this.isDataAvailable = false;
                this.paymentMethod = "NA"
                attributesData.set('showLoading', false);
                this.$store.dispatch(SET_DATA, { attributes: attributesData });
            }
        },
        //two decimal function
        twoDecimal(twodecimal) {
            let num = parseFloat(twodecimal)
            let with2Decimals = num.toFixed(2)
            return with2Decimals
        },
        // print page
        printPage() {
            var restorePage = document.body.innerHtml;
            var printContent = document.getElementById('pa-success-wrapper').innerHtml;
            document.body.innerHtml = printContent;
            window.print();
            document.body.innerHtml = restorePage;
        },
        // formatted date
        getHumanReadbledt(timeStamp) {
            if (timeStamp == 'NA') {
                return null;
            }
            const monthArr = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
            ];
            const date = this.parseDateString(timeStamp);
            const year = date.getFullYear();
            const month = monthArr[date.getMonth()];
            const newDate = date.getDate();
            const readableDate = month + " " + newDate + ", " + year;
            return readableDate;

        },
    },
}
</script>

<style>
.importantMessage {
    background-image: url(../../assets/images/icon-important.svg);
    background-repeat: no-repeat;
    height: 32px;
    width: 32px;
    position: absolute;
}

#pmtarrSuccessP2PPrint {
    color: #0047BB;
}

#makePayment {
    background-color: #0047BB;
    font-family: MaisonNeue-Medium, sans-serif;
}

#norton-logo {
    background-image: url(../../assets/images/mya_paybill_sprite_blue.png);
    background-repeat: no-repeat;
    background-position: -40px -335px;
    height: 70px;
    width: 110px;
    margin-bottom: 20px;
}
@media (max-width: 767px){
    #norton-logo {
        margin-left: 20px;
    }
}
h2 {
    font-size: 36px;
    font-family: MaisonNeue-Light, sans-serif;
}

p,
span,
form,
strong {
    font-family: Maison Neue, sans-serif;

}

label {
    font-size: 12px;
    font-family: Maison Neue, sans-serif;
}

@media(min-width: 768px) {
    .qbp-wrapper {
        max-width: 700px;
    }
}

@media(min-width: 1024px) {
    .qbp-wrapper {
        max-width: 900px;
    }
}

.registerStyle {
    margin-bottom: 1rem;
    font-family: Maison Neue, sans-serif;
}

.closeButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-placement {
    text-align: center;
}

.checkmark {
    height: 25px;
    width: 25px;
    flex-flow: column wrap;
    align-content: flex-start;
    display: inline-flex;
    margin-right: 8px;
}

.align-heading {
    margin-bottom: 20px;
}

.success-heading img {
    position: absolute;
    left: 20px;
}

.success-heading h3 {
    padding-left: 50px;
    font-weight: bold;
    text-align: left;
}


.icon-circle {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0 0 2px #888;
    padding: 0.5em 0.6em;
    color: #0047bb;
    background-color: #e0e8f7;
    height: 48px;
    width: 48px;
}

.icon-style {
    width: 20px;
    height: 18px;
}

.print-style {
    font-size: 14px;
    color: #0047BB;
    text-decoration: underline;
    cursor: pointer;
}

.row-margin {
    background: #ebeff6 0% 0% no-repeat padding-box;
}

.complete-account-button {
    background-color: #0047BB;
    box-shadow: 0px 2px 4px #00000033;
    border: 1px solid #0047bb;
    color: #ffffff;
    width: 12rem;
}

.complete-account-button:hover {
    background-color: rgba(0, 71, 187, 0.039);
    color: #0047BB;
    border: 1px solid #0047BB;
}

.row-acod {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.marg-two {
    margin: 2rem 0 2rem 0;
}

.paSuccessBox.marg-top-bottom {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.icon-style-bill {
    width: 60px;
    height: 60px;
    margin: 1rem 0 1rem 0;
}

.icon-style-ts {
    width: 50px;
    height: 50px;
    margin: 1rem 0 1rem 0;
}

.icon-style-dollar {
    width: 61px;
    height: 61px;
    margin: 1rem 0 1rem 0;
    filter: invert(14%) sepia(76%) saturate(3589%) hue-rotate(213deg) brightness(109%) contrast(101%);
}
.paSuccessBox {
    margin: 0 auto;
    width: 100%;
    border-radius: 5px;
}
@media (min-width: 768px) {
    .paSuccessBox {
        width: 60%;
    }
}
@media (max-width: 768px) and (min-width: 240px) {
    .styleMargin {
        display: inline-flex;
        margin-top: 1rem;
        font-size: 16px;
        font-family: MaisonNeue-Light, sans-serif;
    }

    .left-grid {
        width: 100%;
        font-family: MaisonNeue-Light, sans-serif;
        color: #000000;
    }
}

@media (min-width: 770px) {
    .styleMargin {
        display: inline-flex;
        margin-top: 1rem;
        font-size: 16px;
        font-family: MaisonNeue-Light, sans-serif;
    }

    .left-grid {
        width: 100%;
        font-family: MaisonNeue-Light, sans-serif;
        color: #000000;
    }
}

.display-flex-style {
    position: relative;
    margin-left: 0;
}

.text-font-style {
    font-size: 14px;
    color: #14161A;
    opacity: 0.7;
    text-align: left;
}

.detail-box {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border: 1px solid #EEEEEE33;
    border-radius: 8px;
    opacity: 1;
}

.text-style {
    color: #14161ABF;
    margin-bottom: 1rem;
    margin-top: 1rem;
    font-size: 14px;
    font-family: MaisonNeue-Medium, sans-serif;
}

.checkmark-marg {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 3px;
    left: 0;
}
@media (min-width: 768px) {
    .line {
        border-right: 1px solid #cfc7c0;
    }
}

@media print {
    #acodContent {
        display: none;
    }

    #requestContent {
        display: none;
    }
}

.details {
    position: relative;
    border-radius: 8px;
    border: 1px solid #EEEEEE33;
    box-shadow: 0px 1px 4px #00000029;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin: 20px 0 20px 0;
    padding: 15px;
}

.text-summary {
    font-family: MaisonNeue-Medium, sans-serif;
    font-size: 24px;
    color: #14161A;
    text-align: left;
    margin-bottom : 20px;
}

.bill-icon {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E0E8F7 0% 0% no-repeat padding-box;
    opacity: 1;
}

.bill-icon img {
    position: absolute;
    top: 11px;
    left: 11px;
}

.text-pmnt {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
    margin-top: 0;
    display: flex;
    align-items: center;
}

.payment-amt {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.horizontal-bord {
    border: 1px solid #dad7d7;
}

.text-conv {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
    margin-top: 10px;
    opacity: 0.6;
}

.text-conv-fee {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: right;
    margin-top: 10px;
    opacity: 0.6;
}

#qbp-intro-wrapper h1 {
    padding-left: 45px;
    color: #14161A;
    letter-spacing: 0px;
    margin-bottom: 16px;
    opacity: 1;
    font-size: 36px;
    font-weight: 400;
    font-family: MaisonNeue-Light, serif;
}

#qbp-intro-wrapper h2 {
    color: #14161A;
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 0.24px;
    margin-bottom: 0;
}

.qbp-success-circle {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E0E8F7 0% 0% no-repeat padding-box;
    opacity: 1;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
}

.qbp-success-circle img {
    position: absolute;
    top: 11px;
    left: 11px;
}

.payment-method {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left;
}
.payment-id {
    font-family: Maison Neue, sans-serif;
    font-size: 16px;
    color: #000000;
    text-align: left; 
    opacity: 0.6;
}

.payment-method-icon {
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #E0E8F7 0% 0% no-repeat padding-box;
    opacity: 1;
    display: inline-block;
    vertical-align: middle;
    margin-left: 30px;
}

.payment-method-icon img {
    position: absolute;
    top: 20px;
    left: 20px;
}

.marg-top-detail {
    margin-top: 1rem;
}

.marg-top-heading {
    margin-top: 25px;
}

.marg-right {
    margin-right: 0.5rem;
}

.ms-2rem {
    margin-left: 2rem;
}

.marg-confirmation-num {
    margin-top: 12px;
    font-size: 36px;
}
.checkmark-heading {
    position: relative;
    padding-left: 40px;
}
.payment-for {
    position: relative
}

.acod-container {
    width: 100%;
    margin: 0 auto;
}
@media (min-width: 768px) {
    .acod-container {
        width: 60%;
    }  
    .payment-for img {
        position: absolute;
        top: 0px;
        left: -30px;
        width: 24px;
        height: 24px;
    } 
}
a.signin-cta {
    text-decoration: none;
    border: 1px solid #0047BB;
    padding: 5px 10px;
    border-radius: 5px;
}
</style>
