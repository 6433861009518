<template>
    <div class="post-login-bill-error">
        <h3>We're very sorry.</h3>
        <p>
        We're experiencing technical difficulties with our payment system. We do
        all we can to make paying your bill perform perfectly all the time, but
        the truth is....sometimes it just doesn't.
        </p>
        <p>
        Until we get things cleared up behind the scenes, here are some
        alternative payment options:
        </p>
        <div id="paymentErrorList" class="paymentErrorLinkBlock">
        <ul class="paymentErrorLinkBlock">
            <li>
            <a :href="PaymentMethodsURL" id="paymentMethods" target="_blank">
                Different ways to pay your bill</a
            >
            </li>
            <li>
            <a :href="PayByPhoneURL" id="PayByPhoneURL" target="_blank">
                Check out pay by phone</a
            >
            </li>
        </ul>
        </div>

        <p>
        Another option would be to
        <a href="/postbill/payment/view" id="tryagain">try again</a> in 15 minutes or so.
        Or,
        <a :href="ChatWithUs" id="chatwithus">chat with us.</a>
        </p>

        <p>
        We sincerely apologize for the inconvenience. Your patience is
        appreciated.
        </p>
    </div>
</template>

<script>
import links from '../../js/links'

export default{
    name: 'postBillIframeError',
    data() {
        return {
            PaymentMethodsURL: links.PaymentMethodsURL,
            PayByPhoneURL: links.PayByPhoneURL,
            ChatWithUs: links.ChatWithUs
        }
    }
}
</script>

<style>
.post-login-bill-error {
    padding-top: 20px;
}
</style>
