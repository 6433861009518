<template>
    <div class="justify-content-center">
        <div class="row">
            <div>
                <PAIneligible/>
            </div>
        </div>
    </div>
</template>

<script>

import PAIneligible from '../components/paymentArrngmts/PAIneligible.vue'

export default {
  name: 'PaymentArrngmtsIneligible',
  components: {
    PAIneligible,
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      page: {
        pageType: 'pa',
        pageName: 'ctl|account|payment_arrangement|ineligibile'
      }
    });
  }
}
</script>