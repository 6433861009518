<template>
    <div v-if="isDataAvailable">
            <div class="row">
                <h1 class="text-start text-placement"><strong>Pay Bill</strong></h1>
                <p class="text-start text-placement text-color-black">Current Amount Due: <strong>${{currentAmountDue}}</strong> Payment due by: <strong>{{paymentDate}}</strong></p>
                <p v-if="isAutopayEnrolled" class="text-start text-placement text-color-black"><img class="info-icon-style" src="../../images/icon-important-orange.svg" alt="InfoIcon"/><em> Reminder! You're signed up for Autopay.</em></p>
                <div class="cancel-block">
                    <div class="text-start get-row-same"><img class="blueInfoIconStyle" src="../../images/epwfBlueInfoCirc.png" alt="blueInfoIcon"/><h2 class="payment-cancel">Your transaction was cancelled - no payment was submitted.</h2></div>
                    <p class="text-start margin-after-icon text-placement text-color-black">Would you like to <a class="try-again" :href="`/postbill/payment/view?token=${token}`">start over and try again</a> ?</p>
                </div>
            </div>
    </div>
    <div v-if="!isDataAvailable">
        <h2 class="text-placement">Sorry, We could not find the page which you have requested.</h2>
        <div class="cancel-block">
            <p class="text-placement">Would you like to <a class="try-again" :href="`/postbill/payment/view?token=${token}`">start over and try again</a> ?</p>
        </div>
    </div>
</template>

<script>
import constants from '../../js/constants'
import axios from 'axios'
import { GET_DATA } from '../../store/sessioncontants'
import { SET_DATA as local_setdata} from "../../store/storecontants";
export default {
    name: 'PostLoginBillCancel',
    data() {
        return {
            accountNumber: '',
            currentAmountDue: '',
            paymentDate: '',
            isAutopayEnrolled: false,
            isDataAvailable: false,
            token: null
        }
    },
    mounted() {
        let localData = new Map();
        localData.set('showLoading', true);
        this.$store.dispatch(local_setdata, {attributes:localData});
        this.accountNumber = this.$store.getters[GET_DATA]('accountNumber');
        this.currentAmountDue = this.$store.getters[GET_DATA]('currentAmountDue')
        this.paymentDate = this.$store.getters[GET_DATA]('paymentDate')
        this.isAutopayEnrolled = this.$store.getters[GET_DATA]('isAutopayEnrolled')
        this.token = this.$store.getters[GET_DATA]('token')
        if (this.currentAmountDue === null && this.paymentDate === null && this.isAutopayEnrolled === null) {
            this.isDataAvailable = false
        } else {
            this.isDataAvailable = true;
        }
        localData.set('showLoading', false);
        this.$store.dispatch(local_setdata, {attributes:localData});
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'lbp_cancel',
            flow: {
                name: 'logged in bill pay',
                step: 'cancel bill pay'
            },
            buttonName: 'cancel'
        });
    },
    methods: {
        async setCancelLogger() {
            const params = 'Postbill payment cancelled for accountNumber' + this.accountNumber
            try {
                await axios.get(constants.PaymentFailureCallForOrchestrator, { params });
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style>
.text-color-black {
    color: #000;
    font-size: 1.1rem;
}
.text-placement {
    margin: 0.5rem 0 1rem 0;
}

.cancel-block {
    margin: 1.5rem 0 1rem 0;
}

.try-again {
    color: #0047bb;
    text-decoration: none !important;
    cursor: pointer;
}

.info-icon-style {
    height: 22px;
}

.margin-after-icon {
    margin-left: 2rem;
}

.blueInfoIconStyle {
    height: 22px;
    width: 22px;
    margin-top: 0.5rem;
}

.payment-cancel {
    font-weight: lighter;
    margin-left: 1rem;
}

.get-row-same {
    display: inline-flex;
}

@media screen and (min-width: 375px) and (max-width: 979px) {
    .info-icon-style {
        height: 18px;
        width: 18px;
    }

    .blueInfoIconStyle {
        height: 18px;
        width: 18px;
    }

    .margin-after-icon {
        margin-left: 0;
    }
}
h1.text-placement {
    font-size: 36px;
}
</style>