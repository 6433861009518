
import { defineComponent } from 'vue';
import links from '../../js/links';
export default defineComponent({
	name: 'PayArrngmtsRightBar',
	props: {
		msg: String,
	},
	data () {
		return {
			RetrieveAmountURL: links.RetrieveAmountURL,
			FindAccountNumberURL : links.FindAccountNumberURL,
			PrivacyPolicyURL: links.PrivacyPolicyURL,
			PaymentMethodURL: links.PaymentMethodURL,
			NeedYourServiceURL: links.NeedYourServiceURL,
			OnlineSecurityURL: links.OnlineSecurityURL,
		}
	},
	methods: {
		gtmLogging(event: string) {
			const pageName = 'ctl|account|payment_arrangement|accountnumber'
			let gtmEventName = '';
			let gtmFlowStep = '';
			if(event == 'RetrieveAccountByEmail') {
				gtmEventName = 'pa_retrieve_account_by_email_link';
				gtmFlowStep = pageName + '|rightrail|link|retrieveAccountByEmail';
			} else if(event == 'FindAccountFromBill') {
				gtmEventName = 'pa_find_account_from_bill_link';
				gtmFlowStep = pageName + '|rightrail|link|findAccountFromBill';
			} else if(event == 'HowWeProtect') {
				gtmEventName = 'pa_protect_info_link';
				gtmFlowStep = pageName + '|rightrail|link|howweprotectyourinformation';
			} else if(event == 'PrivacyPolicy') {
				gtmEventName = 'pa_privacy_policy_link';
				gtmFlowStep = pageName + '|rightrail|link|privacypolicy';
			} else if(event == 'PaymentMethods') {
				gtmEventName = 'pa_methods_accepted_link';
				gtmFlowStep = pageName + '|rightrail|link|methodsacceptedforbillpayment';
			} else if(event == 'ServiceRestore') {
				gtmEventName = 'pa_need_service_restore_link';
				gtmFlowStep = pageName + '|rightrail|link|needservicerestore';
			}
			window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
				event: gtmEventName,
				flow:{
					name: 'pa',
					step: gtmFlowStep
				},
			});
		}
	}
});
