<template>
    <div class="container">
        <div class="row">
            <h1 class="text-decor-left">Your AutoPay Details</h1>
            <div id="autopay-cancel-wrapper">
                <div class="green-checkmark-round-icon">
                    <h3 data-test="autopayCancel">Your request to make changes to AutoPay has been cancelled.</h3>
                    <p>Would you like to <a id="autopay-start-over" class="autopay-link" :href="`/autopay/manage?token=${token}`">start over and try again</a>?</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import constants from "../../js/constants";
import { GET_DATA, SET_DATA } from "../../store/sessioncontants";
import { SET_DATA as local_setdata} from "../../store/storecontants";
import axios from "axios";
export default {
   name: 'CancelAutopay',
  data() {
    return {
      accountNumber: "",
      token: null
    };
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'ap_cancel',
        flow: {
            name: 'autopay',
            step: 'cancel'
        }
    });

    let attributesData = new Map();
    let localData = new Map();
    localData.set('showLoading', true);
    this.$store.dispatch(local_setdata, {attributes:localData});
    this.accountNumber = this.$store.getters[GET_DATA]("accountNumber");
    this.token = this.$store.getters[GET_DATA]("token");

    if (this.accountNumber) {
      this.setLogger();
      localData.set('showLoading', false);
      this.$store.dispatch(local_setdata, {attributes:localData});
    } else {
      localData.set('showLoading', false);
      this.$store.dispatch(local_setdata, {attributes:localData});
    }
  },
  methods: {
    async setLogger() {
      const params = {
        logString:
          "AutoPay cancel for AccountNumber  " +
          this.accountNumber,
      };
      await axios.get(constants.PaymentFailureCallForOrchestrator,
                { params }
                );
    }
  },
}
</script>
<style scoped>
#autopay-cancel-wrapper {
    margin: 20px auto;
    text-align: left;
}
#autopay-cancel-wrapper h3 {
    text-align: left;
    margin: 20px 0;
    color: #14161a;
    font-size: 24px;
    font-weight: normal;
}
#autopay-cancel-wrapper p {
   color: #717274;
    margin-bottom: 15px;
    font-size: 16px; 
}
@media screen and (max-width: 767px) {
    .green-checkmark-round-icon:before {
        left: 5px;
    }
}
.autopay-link {
  color: #0047BB;
}
</style>