<template>
  <div id="qbp-exception-message" class="qbpMessageBox">
    <div class="qbpTextBox">
        <p>This account has been flagged to prevent online access. If you believe this is in error or have questions, please 
            <a href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})">contact us</a>.
        </p>
    </div>
</div>
</template>
<script>
import links from "../../js/links";
import { pii } from "../../js/pii";

export default {
  name: "BlockedAccount",
  data() {
    return {
      MyaEntryPointURL: links.MyaEntryPointURL,
    };
  },
  mounted() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "qbp_blocked account",
      accountNumber: pii(this.accountNumber),
      errorName: "blocked account",
      flow: {
        name: "qbp",
        step: "payment flow",
        event: "blocked account",
      },
    });
  },
};
</script>
<style scoped>
.text-color {
  color: #0176d3;
  text-decoration: none !important;
}
.content-margin {
  margin: 2rem 0 2rem 0;
}

.iframe-error-margin {
  margin: 1rem 0 1rem 0;
  display: flex;
}

#qbp-exception-message h3 {
  font-weight: bold;
}

#qbp-exception-message p {
  margin-bottom: 5px;
}

.qbpTextBox {
  position: relative;
}

.qbpTextBox:before {
  content: "";
  position: absolute;
  background: url(../../images/icon-important-orange.svg) no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
}

@media (max-width: 767px) {
  #qbp-exception-message h3 {
    min-height: 30px;
  }
}
</style>