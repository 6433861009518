<template>
  <div class="row" id="dssp-intro-wrapper">
    <h1 class="text-center" data-test="messageHead">Quick Bill Pay</h1>
  </div>
  <div id="paymentError">
    <h2 data-test="messageData"><span class="green-checkmark-round-icon">Your transaction was cancelled - no payment was submitted.</span></h2>
    <span class="align-div">
      Would you like to<a tabindex="0" @click="startOverAgain()" @keydown.enter="startOverAgain()" style="margin-left: 5px;" id="startoverandtryagain"> start over and try again</a>?
    </span>
  </div>

</template>
<script>
import store from "../../store";
import constants from "../../js/constants";
import axios from "axios";
import { RESET, SET_DATA, GET_DATA } from "../../store/storecontants";
export default {
  name: "Payment Cancel",
  data() {
    return { 
      accountNumber: "",
      urlRouter: ""
    };
  },
  mounted() {
    //gtm for cancel page
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'qbp_cancel',
      flow:{
        name: 'qbp',
        step: 'cancel_page'
      },
    });
    // get the required value from store to display cancel page
    let attributesData = new Map();
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    this.accountNumber = JSON.parse(this.$store.getters[GET_DATA]("selectedAccountNumber"))
    ? JSON.parse(this.$store.getters[GET_DATA]("selectedAccountNumber"))
    : "NA"; 
    let result = this.$route.query["amp;ErrorCode"] ? this.$route.query["amp;ErrorCode"] : this.$route.query.ErrorCode
    this.urlRouter = result.includes("-") ? result.replace(/-/g, "") : result;
    this.setLogger();
    attributesData.set('showLoading', false);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
  },
  methods: {
    // splunk log for cancel account number
    async setLogger () {
      const hcdeErrorCode = this.urlRouter ? this.urlRouter : "NA";
        let params = "qbp Payment cancel for accountNumber=" + this.accountNumber + " & HCDE ErrorCode=" + hcdeErrorCode; 
        try {
          await axios.get(constants.SplunkLogger, { params });
        } catch (error) {
          console.log(error);
        }
    },
    startOverAgain () {
      if((window.location.href.indexOf('qbp') !== -1 )){
        if(window.location.href.indexOf('qbp/multiAccount') !== -1) {
          this.$router.push("/qbp/multiAccount");
        }else {
          this.$router.push("/qbp/identify");
          this.$store.dispatch(RESET);
          history.pushState(null, null, location.href);
        }
      }
    }
  },
};
</script>
<style scoped>
.payment{
  text-align:left;
  padding-top: 1px ;
}
#startoverandtryagain {
  color: #0d6efd;
  cursor: pointer;
}
#startoverandtryagain:hover {
  color: #0a58ca;
}
.align-div {
  display: inline-flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  height: auto;
  vertical-align: middle;
}
#dssp-intro-wrapper h1 {
  color: #14161A;
  font-size: 36px;
  letter-spacing: 0px;
  margin-bottom: 16px;
  opacity: 1;
}
</style>