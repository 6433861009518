<template>
    <div class="lds-spinner"></div>
</template>
<script>
export default {
};
</script>
<style scoped>
.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  background: url('../assets/loading.gif') no-repeat center center;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>