<template>
  <div class="root">
    <div class="row" id="dssp-intro-wrapper">
      <h1 class="text-center">Payment Plans</h1>
      <h2 class="text-center" data-test="messageHead">Request extra time to pay your bill</h2>
    </div>

    <div class="row row-margin">
        <div class="col-xl-7 col-lg-7 col-md-9 col-sm-10 col-12 m-auto p-5 p-sm-0 justify-content-center">
          <div class="pmtIneligible">
            <h1>
              Sorry, you are not eligible.
            </h1>
            <!-- Ineligible error code 10017 and not getting proper value to calculate starts-->
            <p v-if="this.thresholdErrorMessage">
              {{this.thresholdErrorMessage}}
            </p>
            <!--Ineligible error code 10017 and not getting proper value to calculate ends-->
            <!-- depends on Ineligible error code display message starts --> 
            <div v-else-if="this.paymentArrangementIneligibleErrorCodes=='10017'">
              <p>You must make a payment of {{ this.elgibleAmtPa }} to reduce your account balance. Then you will be eligible to start a payment plan.</p>   
              <button
                type="button"
                class="btn btn-success btn-lg text-center btn-threshold"
                @click="submitForm"
              >
                Make a Payment</button
              >
            </div>
            <p v-else-if="this.paymentArrangementIneligibleErrorCodes=='10015'">
              You do not qualify to make a payment plan at this time because
              there is an insufficient amount due on your account.        
            </p>
            <p v-else-if="this.paymentArrangementIneligibleErrorCodes=='10016'">
              We do not currently allow Business Customers to make a payment plan online. 
              To make a payment plan please call 800-603-6000.        
            </p>
            <p v-else-if="this.paymentArrangementIneligibleErrorCodes=='11001'">
              You do not currently qualify to make a payment plan. 
              It seems that there is an issue with your account's status. 
              Please <a id="chatWithUs-11001" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" data-galink-type="chat" @click="gtmLogging('ChatWithUs')">chat with us</a> to find out more information about your account's status.     
            </p>
            <p v-else-if="this.paymentArrangementIneligibleErrorCodes=='11002'">
              You do not qualify to make a payment plan. There is already a payment plan applied to this account. 
              If you want to modify the current payment plan on this account, 
              please <a id="chatWithUs-11002" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" data-galink-type="chat" @click="gtmLogging('ChatWithUs')">chat with us</a>.     
            </p>
            <p v-else-if="this.paymentArrangementIneligibleErrorCodes=='11003'">
              You do not qualify to make a payment plan due to recent account suspensions. 
              You are only eligible to pay the current amount due on your bill today.       
            </p>
            <p v-else-if="this.paymentArrangementIneligibleErrorCodes=='11004'">
              You do not currently qualify to make a payment plan, due to recent issues with payments on your account. 
              You are only eligible to pay the current amount due on your bill today.     
            </p>
            <div v-else-if="this.paymentArrangementIneligibleErrorCodes=='11005'">
              <p>Due to recent issues with payments on your account, you are only eligible to pay the current amount due on your bill today.</p>
              <button
                type="button"
                class="btn btn-success btn-lg text-center btn-threshold"
                @click="submitForm"
              >
                Make a Payment</button
              >
            </div>
            <p v-else-if="this.paymentArrangementIneligibleErrorCodes=='11006'">
              You do not currently qualify to make a payment plan due to recent issues with a payment plan on your account. 
              You are only eligible to pay the current amount due on your bill today.      
            </p>
            <p v-else-if="this.paymentArrangementIneligibleErrorCodes=='11007'">
              You do not currently qualify to make an online payment plan due to complexities with your account. 
              Please <a id="chatWithUs-11007" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" data-galink-type="chat" @click="gtmLogging('ChatWithUs')">chat with us</a> to find out more information.     
            </p>
            <p v-else-if="this.paymentArrangementIneligibleErrorCodes=='11008'">
              You do not qualify to make a payment plan. There is already a payment plan applied to this account. 
              If you want to modify the current payment plan on this account, 
              please <a id="chatWithUs-11008" href="javascript:startChat({skillName:'Watson_24_7_Embedded_Service',buttonId:'Watson_24_7_Chat_Bot'})" data-galink-type="chat" @click="gtmLogging('ChatWithUs')">chat with us</a>.        
            </p>
            <!-- depends on Ineligible error code display message ends -->
            <br />
            <!-- for multiaccount show the link start--> 
            <p v-if="this.validateData(this.isMultiAccount)">
              Account: {{ this.multiAcctData.selectedAcctNumber }}
              <a id="changeAcctBtn" class="ps-2" @click="changeAcct()">Change Account</a>
            </p>
            <!-- for multiaccount show the link end-->
          </div>
        </div>
      </div>

    <!-- already on payment arrangement then show the installments start -->
    <div v-if="!this.validateData(this.noAmtDue) && (this.paymentArrangementIneligibleErrorCodes=='11002' || this.paymentArrangementIneligibleErrorCodes=='11008')"
    class="col-xl-7 col-lg-7 col-md-9 col-sm-10 col-12 m-auto p-5 p-sm-0 justify-content-center">
      <div >
        <div
          id="payBillDisclaimer"
          class="payBillDisclaimerCont qbpP2P_mt_0 qbpP2P_mb_0 qbpP2P_bb_none"
        >
          <div class="pmtArr qbpP2P_padding qbpP2P_fs_init qbpP2P_Bold">
            You are currently on a Payment Plan
          </div>
        </div>
      </div>
      <div>
        <hr class="qbpP2P_hr" />
      </div>

      <div>
        <div class="text-start qbpP2P_Bold qbpP2P_fs_16">
          Remaining Arrangement amount:
        </div>
      </div>

      <div>
        <div class="text-start qbpP2P_fs_40">
          ${{ this.paData.remainingArrAmt }}
        </div>
      </div>

      <div>
        <div class="qbpP2P_fs_16 qbpP2P_Bold text-start">
          Arrangement Schedule
        </div>
      </div>
      <div class="qbpP2P_space_1"></div>

      <div>
        <table class="table table-borderless mb-0">
          <caption style="display: none;">Installment description</caption>
          <th style="display: none;"></th>
          <tbody>
            <tr 
            v-for="(installment, index) in this.paData.installments"
            :key="installment.installNumber">
              <td>
                <strong>Installment {{ index+1 }}:</strong>
              </td>
              <td v-if="installment.status=='Open'">
                ${{twoDecimal(installment.installAmount - installment.coveredAmount)}}
              </td>
              <td v-else-if="installment.status=='Completed'">
                  Paid  ${{twoDecimal(installment.installAmount) }}
              </td>
              <td v-if="installment.status=='Open'">
                Date:{{formatDate(installment.installDate)}}
              </td>
              <td v-else-if="installment.status=='Completed'">
                Paid:{{formatDate(installment.statusDate)}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="text-start paddin-eight">
        Your installment amount must be paid in addition to your monthly bill on or before the installment date.
      </div>

      <div>
        <div class="col-6 col-md-4 col-sm-4 d-grid gap-2">
          <br /><button
            type="button"
            class="btn btn-success btn-lg text-center"
            @click="submitForm"
          >
            Make a Payment</button
          ><br />
        </div>
      </div>
    </div>
    <!-- already on payment plan then show the installments end -->
  </div>
</template>

<script>
import { reactive, computed } from "vue";
import { Popover } from "bootstrap/dist/js/bootstrap.bundle";
import axios from "axios";
import constants from "../../js/constants";
import { GET_DATA, SET_DATA } from "../../store/storecontants";
import store from "../../store";
import links from "../../js/links";
import { pii } from "../../js/pii";

export default {
  name: "PAIneligible",
  data() {
     return {
      noAmtDue: this.$store.getters[GET_DATA]("noAmtDue"),
      isMultiAccount: this.$store.getters[GET_DATA]("isMultiAccount"),
      paymentArrangementIneligibleErrorCodes: null,
      eligibleThresholdAmt: null
    };
  },

  setup() {
    const paData = reactive({
      remainingArrAmt: null,
      installments: null,
      noOfInstallments: null,
      enteredAccountNumber: null,
      enteredZipCode: null,
    });

    const multiAcctData = reactive({
      data: null,
      //isMultiAccount: false,
      selectedAcctNumber: null,
      zipCode: null,
    });

    return {
      paData,
      multiAcctData,
    };
  },

  methods: {
    // submit form will jump to qbp to pay amount
    submitForm() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pa_pay_full_amount',
        accountNumber: pii(this.paData.enteredAccountNumber),
          flow:{
            name: 'pa',
            step: 'ineligibility flow'
          },
      });
      // call api
      this.getAccount();
    },
    formatDate(dateString) {
      const date = dateString.split("-");
      return date[1] + "/" + date[2] + "/" + date[0];
    },

    changeAcct() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pa_change_acct_link',
        flow:{
          name: 'pa',
          step: 'ctl|account|payment_arrangement|ineligibile|link|change_acct'
        },
      });

      this.$router.push({
        name: "PAMultiAccount",
        params: {},
      });
    },
    thresholdCalc() {
      if(this.paymentArrangementIneligibleErrorCodes == "10017") {
        let thresholdVal,adjustedAmountVal,elgibleAmtPa;
        thresholdVal = this.$store.getters[GET_DATA]("thresholdVal") ? this.$store.getters[GET_DATA]("thresholdVal") : '';
        adjustedAmountVal = this.$store.getters[GET_DATA]("adjustedAmount") ? this.$store.getters[GET_DATA]("adjustedAmount") : '';
        if(thresholdVal && adjustedAmountVal) {
          this.elgibleAmtPa = parseFloat(adjustedAmountVal) - parseFloat(thresholdVal) + 1 
          this.elgibleAmtPa = this.elgibleAmtPa.toFixed(2);
          let attributesData = new Map();
          attributesData.set("elgibleAmtPa", JSON.stringify(this.elgibleAmtPa));
          this.$store.dispatch(SET_DATA, { attributes: attributesData });
        } else {
          this.thresholdErrorMessage = "Something went wrong! Please try again." 
        }
      }
    },
    async getAccount() {
      let attributesData = new Map();
      attributesData.set('showLoading', true);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      // identify api call with params
      try {
        const params = {
          accountNumber: this.paData.enteredAccountNumber,
          zipCode: this.paData.enteredZipCode,
        };
        const res = await axios.post(constants.QBPIdentifyURL, params);  
        if (
          res != null &&
          res.data.account.pmtArrangementOnAccount == true
        ) {
          // jump to promise to pay qbp by setting required fields
          if (res.data.account.promiseToPay == true) {
            if (res.data.account.currMonthlyAmt < 0) {
              let attributesData = new Map();
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.paData.enteredAccountNumber)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set("currentMonthlyAmount", JSON.stringify(0));
              attributesData.set(
                "amountOwed",
                JSON.stringify(res.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(res.data.account.installments)
              );
              attributesData.set(
                "remainingAmount",
                JSON.stringify(res.data.account.remainingArrAmt)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "QbpPromiseToPay",
                params: {},
              });
            } else {
              let attributesData = new Map();
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.paData.enteredAccountNumber)
              );
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set(
                "currentMonthlyAmount",
                JSON.stringify(res.data.account.currMonthlyAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(res.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(res.data.account.installments)
              );
              attributesData.set(
                "remainingAmount",
                JSON.stringify(res.data.account.remainingArrAmt)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              this.$router.push({
                name: "QbpPromiseToPay",
                params: {},
              });
            }
          } else if (res.data.account.scheduledPmts == true) {     
            // jump to schedule to pay qbp by setting required fields       
            if (res.data.account.currMonthlyAmt < 0) {
              let attributesData = new Map();
              if(this.isMultiAccount == true) {
                attributesData.set("selectedAccountNumber", JSON.stringify(this.multiAcctData.selectedAcctNumber));
                attributesData.set("multiAccountNumber", JSON.stringify(this.paData.enteredAccountNumber));
                attributesData.set("paIneligibleMultiaccount", true);
              }else {
                this.singleAccount = JSON.stringify(this.paData.enteredAccountNumber);
                attributesData.set("selectedAccountNumber", this.singleAccount);
              }
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set("currentMonthlyAmount", JSON.stringify(0));
              attributesData.set(
                "amountOwed",
                JSON.stringify(res.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(res.data.account.installments)
              );
              attributesData.set(
                "remainingAmount",
                JSON.stringify(res.data.account.remainingArrAmt)
              );
              attributesData.set(
                "pmtArrangementOnAccount", 
                JSON.stringify(res.data.account.pmtArrangementOnAccount)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "QbpScheduledToPay",
                params: {},
              });
            } else {
              let attributesData = new Map();
              if(this.isMultiAccount == true) {
                attributesData.set("selectedAccountNumber", JSON.stringify(this.multiAcctData.selectedAcctNumber));
                attributesData.set("multiAccountNumber", JSON.stringify(this.paData.enteredAccountNumber));
                attributesData.set("paIneligibleMultiaccount", true);
              }else {
                this.singleAccount = JSON.stringify(this.paData.enteredAccountNumber);
                attributesData.set("selectedAccountNumber", this.singleAccount);
              }
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "adjustedAmountAvl",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set(
                "currentMonthlyAmount",
                JSON.stringify(res.data.account.currMonthlyAmt)
              );
              attributesData.set(
                "amountOwed",
                JSON.stringify(res.data.account.totalBalanceOwed)
              );
              attributesData.set(
                "installmentsArray",
                JSON.stringify(res.data.account.installments)
              );
              attributesData.set(
                "remainingAmount",
                JSON.stringify(res.data.account.remainingArrAmt)
              );
              attributesData.set(
                "pmtArrangementOnAccount", 
                JSON.stringify(res.data.account.pmtArrangementOnAccount)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "QbpScheduledToPay",
                params: {},
              });
            }
          } 
        } else {
          if (res.data.status == 0 && res.data.errorInfo == null) {
            if (res.data.epwfSession != null 
            && this.paymentArrangementIneligibleErrorCodes == "10017") {
              // jump to qbp confirm by setting required fields   
              if (this.elgibleAmtPa) {
                let attributesData = new Map();
                if(this.isMultiAccount == true) {
                  attributesData.set("selectedAccountNumber", JSON.stringify(this.multiAcctData.selectedAcctNumber));
                  attributesData.set("multiAccountNumber", JSON.stringify(this.paData.enteredAccountNumber));
                  attributesData.set("paIneligibleMultiaccount", true);
                }else {
                  this.singleAccount = JSON.stringify(this.paData.enteredAccountNumber);
                  attributesData.set("selectedAccountNumber", this.singleAccount);
                }
                const iFrame = JSON.stringify(res.data.epwfSession.sessionURL);
                attributesData.set(
                  "EpwfIframe",
                  JSON.stringify(res.data.epwfSession.sessionURL)
                );
                attributesData.set(
                  "status",
                  JSON.stringify(res.data.account.multiAccount)
                );
                attributesData.set(
                  "balanceDue",
                  JSON.stringify(res.data.account.adjustedAmountDue)
                );
                attributesData.set(
                  "paymentDate",
                  JSON.stringify(res.data.account.paymentDueDate)
                );
                attributesData.set("errorInfo", null);
                attributesData.set(
                  "prepaidAccount",
                  JSON.stringify(res.data.account.prepaidAccount)
                );
                attributesData.set(
                  "prepaidInfo",
                  JSON.stringify(res.data.account.prepaidSuspendedAccount)
                );
                this.$store.dispatch(SET_DATA, { attributes: attributesData });
                this.$router.push({
                  name: "Confirm",
                  params: {},
                });
              }else {
                this.thresholdErrorMessage = "Something went wrong! Please try again." 
              }
            } else if (res.data.epwfSession != null 
              && this.paymentArrangementIneligibleErrorCodes == "11005") {
              // jump to qbp confirm by setting required fields
              this.singleAccount = JSON.stringify(this.paData.enteredAccountNumber);
              const iFrame = JSON.stringify(res.data.epwfSession.sessionURL);
              let attributesData = new Map();
              attributesData.set("selectedAccountNumber", this.singleAccount);
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "status",
                JSON.stringify(res.data.account.multiAccount)
              );
              attributesData.set(
                "balanceDue",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set("errorInfo", null);
              attributesData.set(
                "prepaidAccount",
                JSON.stringify(res.data.account.prepaidAccount)
              );
              attributesData.set(
                "prepaidInfo",
                JSON.stringify(res.data.account.prepaidSuspendedAccount)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "Confirm",
                params: {},
              });
            } else if (res.data.epwfSession != null) {
              // jump to qbp confirm by setting required fields  
              this.singleAccount = JSON.stringify(this.paData.enteredAccountNumber);
              const iFrame = JSON.stringify(res.data.epwfSession.sessionURL);
              let attributesData = new Map();
              attributesData.set("selectedAccountNumber", this.singleAccount);
              attributesData.set(
                "EpwfIframe",
                JSON.stringify(res.data.epwfSession.sessionURL)
              );
              attributesData.set(
                "status",
                JSON.stringify(res.data.account.multiAccount)
              );
              attributesData.set(
                "balanceDue",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set("errorInfo", null);
              attributesData.set(
                "prepaidAccount",
                JSON.stringify(res.data.account.prepaidAccount)
              );
              attributesData.set(
                "prepaidInfo",
                JSON.stringify(res.data.account.prepaidSuspendedAccount)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "Confirm",
                params: {},
              });
            } else if (res.data.epwfSession == null &&
              this.paymentArrangementIneligibleErrorCodes == "10017") {
              // jump to qbp confirm by setting required fields iframe will not display  
              if (this.elgibleAmtPa) {
                let attributesData = new Map();
                if(this.isMultiAccount == true) {
                  attributesData.set("selectedAccountNumber", JSON.stringify(this.multiAcctData.selectedAcctNumber));
                  attributesData.set("multiAccountNumber", JSON.stringify(this.paData.enteredAccountNumber));
                  attributesData.set("paIneligibleMultiaccount", true);
                }else {
                  this.singleAccount = JSON.stringify(this.paData.enteredAccountNumber);
                  attributesData.set("selectedAccountNumber", this.singleAccount);
                }
                attributesData.set("EpwfIframe", null);
                attributesData.set(
                  "status",
                  JSON.stringify(res.data.account.multiAccount)
                );
                attributesData.set(
                  "balanceDue",
                  JSON.stringify(res.data.account.adjustedAmountDue)
                );
                attributesData.set(
                  "paymentDate",
                  JSON.stringify(res.data.account.paymentDueDate)
                );
                attributesData.set("errorInfo", null);
                attributesData.set(
                  "prepaidAccount",
                  JSON.stringify(res.data.account.prepaidAccount)
                );
                attributesData.set(
                  "prepaidInfo",
                  JSON.stringify(res.data.account.prepaidSuspendedAccount)
                );
                this.$store.dispatch(SET_DATA, { attributes: attributesData });
                this.$router.push({
                  name: "Confirm",
                  params: {},
                });
              } else {
                this.thresholdErrorMessage = "Something went wrong! Please try again."
              }
            } else if (res.data.epwfSession == null) {
              // jump to qbp confirm by setting required fields without iframe 
              this.singleAccount = JSON.stringify(this.paData.enteredAccountNumber);
              let attributesData = new Map();
              attributesData.set("selectedAccountNumber", this.singleAccount);
              attributesData.set("EpwfIframe", null);
              attributesData.set(
                "status",
                JSON.stringify(res.data.account.multiAccount)
              );
              attributesData.set(
                "balanceDue",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set("errorInfo", null);
              attributesData.set(
                "prepaidAccount",
                JSON.stringify(res.data.account.prepaidAccount)
              );
              attributesData.set(
                "prepaidInfo",
                JSON.stringify(res.data.account.prepaidSuspendedAccount)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });
              this.$router.push({
                name: "Confirm",
                params: {},
              });
            }
          } else if (res.data.status == 0 && res.data.account.accountEmbargo) {
            this.embargo = true
            this.status =
              "Your account is currently unavailable due to planned maintenance. This maintenance period may take up to 6 hours. Please try again later";
          } else if (res.data.status == 0 && res.data.errorInfo != null) {
            // with diffrent error message route to qbp confirm
            if (
              res.data.errorInfo.errorMessage == "QBP_NO_BALANCE" ||
              res.data.errorInfo.errorMessage == "QBP_AUTOPAY" ||
              res.data.errorInfo.errorMessage == "QBP_BILLING_NAME_UNAVAILABLE" ||
              res.data.errorInfo.errorMessage == "QBP_SERVICE_ERROR" ||
              res.data.errorInfo.errorMessage == "QBP_BLOCKED_ACCOUNT" ||
              res.data.errorInfo.errorMessage == "QBP_AMOUNT_DUE_UNAVAILABLE" ||
              res.data.errorInfo.errorMessage == "QBP_SIMPLE_PAY_ACCOUNTS" ||
              res.data.errorInfo.errorMessage == "QBP_PREPAID_NON_SUSPENDED_ACCOUNT"
            ) {
              let attributesData = new Map();
              attributesData.set(
                "selectedAccountNumber",
                JSON.stringify(this.paData.enteredAccountNumber)
              );
              attributesData.set("EpwfIframe", null);
              attributesData.set(
                "status",
                JSON.stringify(res.data.account.multiAccount)
              );
              attributesData.set(
                "balanceDue",
                JSON.stringify(res.data.account.adjustedAmountDue)
              );
              attributesData.set(
                "paymentDate",
                JSON.stringify(res.data.account.paymentDueDate)
              );
              attributesData.set(
                "errorInfo",
                JSON.stringify(res.data.errorInfo.errorMessage)
              );
              attributesData.set(
                "prepaidAccount",
                JSON.stringify(res.data.account.prepaidAccount)
              );
              attributesData.set(
                "prepaidInfo",
                JSON.stringify(res.data.account.prepaidSuspendedAccount)
              );
              this.$store.dispatch(SET_DATA, { attributes: attributesData });

              this.$router.push({
                name: "Confirm",
                params: {},
              });

            }
          } else if (
            res.data.account.prepaidAccount == "true" &&
            res.data.errorInfo.errorMessage == "QBP_PREPAID_BILLING_NAME_ERROR"
          ) {
            let attributesData = new Map();
            attributesData.set(
              "selectedAccountNumber",
              JSON.stringify(this.paData.enteredAccountNumber)
            );
            attributesData.set("EpwfIframe", null);
            attributesData.set(
              "status",
              JSON.stringify(res.data.account.multiAccount)
            );
            attributesData.set(
              "balanceDue",
              JSON.stringify(res.data.account.adjustedAmountDue)
            );
            attributesData.set(
              "paymentDate",
              JSON.stringify(res.data.account.paymentDueDate)
            );
            attributesData.set("errorInfo", res.data.errorInfo.errorMessage);
            attributesData.set(
              "prepaidAccount",
              JSON.stringify(res.data.account.prepaidAccount)
            );
            attributesData.set(
              "prepaidInfo",
              JSON.stringify(res.data.account.prepaidSuspendedAccount)
            );
            this.$store.dispatch(SET_DATA, { attributes: attributesData });

            this.$router.push({
              name: "Confirm",
              params: {},
            });
          } else {
            this.status = res.data.statusMessage;
          }
        }
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      } catch (error) {
        console.log(error);
        this.status = "Something went wrong! Please try again.";
      attributesData.set('showLoading', false);
      this.$store.dispatch(SET_DATA, {attributes:attributesData});
      }
    },

    validateData(dataReview){
      return dataReview.toString() === 'false' ? false: true
    },
    gtmLogging(event) {
      if(event == 'ChatWithUs') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'pa_chat_link',
          flow:{
            name: 'pa',
            step: 'ctl|account|payment_arrangement|ineligibile|link|chat_with_us'
          },
        });
      }
    },
    twoDecimal(twodecimal) {
      let num = twodecimal
      let with2Decimals = num.toFixed(2)
      return with2Decimals
    }
  },
  mounted() {
    // get the required field to display ineligible page
    this.paymentArrangementIneligibleErrorCodes = JSON.parse(this.$store.getters[GET_DATA]("paymentArrangementIneligibleErrorCodes")) ? JSON.parse(this.$store.getters[GET_DATA]("paymentArrangementIneligibleErrorCodes")) : 'NA';
    this.responseData = JSON.parse(this.$store.getters[GET_DATA]("status"));
    this.paData.enteredZipCode = this.$store.getters[GET_DATA]("enteredZipCode");
    this.paData.enteredAccountNumber = this.$store.getters[GET_DATA]("enteredAccountNumber");
    let attributesData = new Map();
    attributesData.set('showLoading', true);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
    if (this.validateData(this.isMultiAccount)) {
      this.paData.enteredAccountNumber = this.$store.getters[GET_DATA]("selectedAcctNumber");
      this.multiAcctData.data = JSON.parse(this.$store.getters[GET_DATA]("status"));
      this.multiAcctData.selectedAcctNumber = this.$store.getters[GET_DATA]("enteredAccountNumber");
      this.multiAcctData.zipCode = this.$store.getters[GET_DATA]("enteredZipCode");
      this.thresholdCalc();
    }
    if (!this.validateData(this.noAmtDue)) {
      this.paData.remainingArrAmt = this.responseData.arrangementsHistoryDetails.remainingArrAmt.toFixed(2);
      this.paData.installments = this.responseData.arrangementsHistoryDetails.installments;
      this.paData.noOfInstallments = this.responseData.arrangementsHistoryDetails.noOfInstallments;
      this.thresholdCalc();
    }
    attributesData.set('showLoading', false);
    this.$store.dispatch(SET_DATA, {attributes:attributesData});
  },
  
};
</script>


<style scoped>
.btn-success {
  background-color: #0047bb;
  border-color: #0047bb;
}
.btn.btn-success:active {
  background-color: #0047bb;
  border-color: #0047bb;
}

#changeAcctBtn {
  text-decoration: none;
  color: #0047BB;
  font-weight: 400;
}

#changeAcctBtn:hover {
  text-decoration: underline;
  color: #0047BB;
  cursor: pointer;
}

#norton-logo {
  background-image: url(../../assets/images/mya_paybill_sprite_blue.png);
  background-repeat: no-repeat;
  background-position: -40px -335px;
  height: 70px;
  width: 110px;
}

.pmtArr {
  width: 100%;
  display: inline-block;
  zoom: 1;
  background-image: url(/static/images/icon_info.png);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  text-align: left;
  font-size: 16px;
  font-style: italic;
  padding: 3px 0;
  text-indent: 25px;
  font-weight: bold;
}
.ineligible_pi {
  position: initial !important;
}
.pmtarrOptionsP2PInstallmentsULOverride {
  margin-bottom: 20px !important;
  margin-top: 1rem;
  list-style-type: none;
}
.pmtarrOptionsP2PInstallmentsULOverride li {
  margin-bottom: 1rem;
}
.pmtarrOptionsP2PInstallmentsULOverride li:last-child {
  margin-bottom: 0px;
}
.pmtarrOptionsP2PInstlW2 {
  display: inline-block;
  width: 14rem;
  margin-right: 2rem;
}
.pmtarrOptionsP2PBold {
  font-family: MaisonNeue-Bold, sans-serif;
}
.pmtarrOptionsP2PInstlW1 {
  display: inline-block;
  width: 6rem;
}
.pmtarrOptionsP2PFR {
  float: right;
}
.sub-text {
  font-weight: normal;
  margin-top: 0px;
  padding-left: 20px;
}
@media (max-width: 522px) {
  .pmtarrOptionsP2PInstlW2 {
    margin-right: 16rem;
  }
  #make3MobileDates {
    margin-left: 117px;
  }
}

#payBillDisclaimer div.pmtArr {
  background-size: 25px 25px !important;
  font-size: 20px !important;
  line-height: inherit;
  padding-left: 2rem !important;
}
.qbpP2P_fs_16 {
  font-size: 17px !important;
  font-weight: bolder;
}

.qbpP2P_mt_0 {
  margin-top: 0 !important;
}
.qbpP2P_mb_0 {
  margin-bottom: 0 !important;
}
.qbpP2P_bb_none {
  border-bottom: none !important;
}
.qbpP2P_padding {
  padding-left: 1.5rem !important;
  text-indent: 0 !important;
  background-position: 0 0.5rem;
}
.qbpP2P_fs_init {
  font-style: initial !important;
}
.qbpP2P_fs_40 {
  font-size: 40px;
}
.qbpP2P_space_1 {
  margin: 1rem;
}
.qbpP2P_Bold {
  font-family: MaisonNeue-Bold, sans-serif;
}
.qbpP2P_InstlW1 {
  display: inline-block;
  width: 6rem;
}
.qbpP2P_InstlW2 {
  display: inline-block;
  width: 11.5rem;
  margin-right: 0.5rem;
}
.qbpP2P_FR {
  float: right;
}
#qbpP2P_InstallmentsULOverride {
  margin-bottom: 1rem;
  list-style-type: none;
  padding-left: 2px;
}
.qbpP2P_OtherAmtW3 {
  display: inline-block;
  width: 11rem;
}
.qbpP2P_display_inline_block {
  display: inline-block;
}
.qbpP2P_w_8 {
  width: 8rem;
}
.qbpP2P_v_align_bottom {
  vertical-align: bottom;
}
.qbpP2P_hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-top: 2px solid rgb(167, 164, 164);
}
.pl-20 {
  padding-left: 20px;
}

#pa-header {
  color: #0047bb;
}

#pa-sub-header {
  font-size: 18px;
}

.modal-footer-error {
  justify-content: center;
}

.modal-body-error {
  text-align: center;
}

.amountDataDiv {
  background: #eee;
  border: 0;
  height: 100%;
  padding: 15px 15px;
  border-radius: 0px 0px 4px 4px;
  margin: 0px;
}

.accordion-button-paymentOptions.accordion-button {
  background-color: transparent !important;
  color: black !important;
  font-size: 24px;
}

.accordion-button-paymentOptions.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: 0 0 0 0.25rem transparent !important;
  color: black !important;
}

#payScheduleButton:active(.collapsed)::after {
  transform: rotate(380deg);
}

button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.accordion-button-paymentOptions.accordion-button::after {
  background-image: url(../../assets/images/accordion-up-arrow.svg);
  order: -1;
  margin-left: 0;
  margin-right: 0.5em;
}
.accordion-button-paymentOptions.accordion-button:not(.collapsed)::after {
  background-image: url(../../assets/images/accordion-up-arrow.svg);
}

.accordion-collapse {
  font-size: 16px;
}

#panel-Make1Pay {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 8px;
  border-top: 0;
}

html * {
  outline: 0 !important;
}

.row-bottom-margin {
  margin-bottom: 1px;
}
table {
  text-align: left;
}
.paddin-eight {
  padding-left: 8px;
}
#qbp-intro-wrapper {
  padding-bottom: 0;
}
#ineligibleErrorMsg-11008 {
 margin-bottom: 0;
}
@media(max-width: 767px) {
  .payBillDisclaimerCont {
    padding: 0 10px;
  }
}
.btn-threshold {
  display: block;
  margin-top: 20px;
}
#dssp-intro-wrapper h1, .pmtIneligible h1 {
	color: #14161A;
  font-size: 36px;
	letter-spacing: 0px;
  margin-bottom: 16px;
	opacity: 1;
  padding: 0;
}
#dssp-intro-wrapper h2 {
    color: #14161A;
    font-size: 24px;
    letter-spacing: 0.24px;
    opacity: 1;
    margin-bottom: 0;
}
.row-margin {
    background: #ebeff6 0% 0% no-repeat padding-box;
    height: 343px;
  }
</style>
